import { createSlice } from '@reduxjs/toolkit'
import axiosConnect from '../network/http-request'

const initialState = {
  value: 'needAgree',
}

export const possibleChatSlice = createSlice({
  name: 'possibleChat',
  initialState,
  reducers: {
    possibleChat: (state, action) => {
      state.value = action.payload
    },
  },
})

export const fetchPossibleChat = () => (dispatch) => {
  axiosConnect('/rest/main/checkPossibleChat', 'POST').then((result) => {
    dispatch(possibleChat(result.data))
  })
}

export const { possibleChat } = possibleChatSlice.actions
export default possibleChatSlice.reducer
