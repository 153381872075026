import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function SOSCallInforationView() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const possibleChat = useSelector((state: any) => state.possibleChat.value)
  const clickChat = () => {
    if (possibleChat === 'needlogin') {
      navigate('/login')
    } else if (possibleChat === 'usable') {
      window.open('/#/sos/chat/room', '_blank')
    } else if (possibleChat === 'needAgree') {
      navigate('/sos/terms')
    } else if (possibleChat === 'needPurchase') {
      navigate('/purchase/information')
    } else if (possibleChat === 'agreeButYet') {
      alert('관리자 승인전입니다.')
      navigate('/')
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['서비스 이용안내']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="text-center">
          <div className="step-bg mt-3">
            <span className="line"></span>
            <ol className="start-step d-flex justify-content-center">
              <li>
                <Link to="/sos/terms">
                  <span>
                    <strong>1</strong>
                    <small>{language['약관동의']}</small>
                  </span>
                </Link>
              </li>
              <li className="active">
                <Link to="/sos/information">
                  <span>
                    <strong>2</strong>
                    <small>{language['이용안내']}</small>
                  </span>
                </Link>
              </li>
              <li>
                <a onClick={() => {}}>
                  <span>
                    <strong>3</strong>
                    <small>{language['SOS 콜 채팅']}</small>
                  </span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <div className="cardline blue mt-2">
              <div className="card-body">
                <h5 className="card-title">{language['이제 이지트래블케어 서비스를 이용할 수 있습니다.']}</h5>
                <ul className="list">
                  <li>{language['맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!']}</li>
                  <li className="half">
                    {language['우측 하단의 채팅 아이콘']}
                    {language.code !== 'en' ? <img src="/resources/home/img/chaticon.png" alt="sosIcon" className="icon-sm" /> : null}

                    {language['을 통해 온라인 채팅 상담이 가능합니다.']}
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-center pt-1">
              <a onClick={clickChat} className="btn btn-primary btn-block btn-lg">
                {language['SOS 콜 채팅']}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    <i className="bi bi-exclamation-triangle-fill mr-1"></i>
                    {language['서비스 이용 권장사양']}
                  </h5>
                  <ul className="list secondary">
                    <li>{language['HTML5 지원 브라우저,']}</li>
                    <li>{language['Chrome 14 버전 이상,']}</li>
                    <li>{language['Internet Explorer 10 버전 이상,']}</li>
                    <li>{language['Safari 6 버전 이상,']}</li>
                    <li>{language['Opera 12.10 버전 이상,']}</li>
                    <li>{language['Firefox 11 버전 이상,']}</li>
                    <li>{language['안드로이드 브라우저 4.4 이상']}</li>
                  </ul>
                  <br />
                  {language['이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.']}
                  <br />
                  {language['위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.']}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SOSCallInforationView
