import { useSelector } from 'react-redux'
import ChatBotAnswerView from './ChatBotAnswerView'

function ChatBotView(props: any) {
  const language = useSelector((state: any) => state.language.value)

  const { chat, chatBotClick, isEnable, createChatRoom } = props
  return (
    <>
      <div className="message">
        <a>
          <img src="/resources/home/img/svg/soschat.svg" alt="soschat" className="message-profile" />
        </a>
        <div className="message-body">
          <h5>{language['ChatBot']}</h5>
          <div className="message-box" dangerouslySetInnerHTML={{ __html: chat.questionList[0].chatBotContent }} />
          <div className="message-date">{chat.currentTime}</div>
        </div>
      </div>
      <ChatBotAnswerView chat={chat.answerList} chatBotClick={chatBotClick} isEnable={isEnable} createChatRoom={createChatRoom} />
    </>
  )
}

export default ChatBotView
