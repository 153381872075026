import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axiosConnect from '../../../network/http-request'
import { validateEmail, validateId, validatePassword } from '../../../utils/checkValidity'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateUtil } from '../../../utils/date-format'
import { useSelector } from 'react-redux'

function SignUpPage() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const [loginId, setLoginId] = useState('')

  const [country, setCountry] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')
  const [gender, setGender] = useState('Male')
  const [name, setName] = useState('')
  const [passportNumber, setPassportNumber] = useState('')
  const [birthDay, setBirthDay] = useState(new Date())

  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [validFailPassword, setValidFailPassword] = useState(false)
  const [validFailEmail, setValidFailEmail] = useState(false)

  const [serviceTerms, setServiceTerms] = useState(false)
  const [validServiceTerms, setValidServiceTerms] = useState(false)

  const [countryList, setCountryList] = useState([])

  useEffect(() => {
    fetchCountry()
  }, [])

  function fetchCountry() {
    let lang = 'en'
    if (language.code === 'kr') {
      lang = 'ko'
    }

    axiosConnect(`/rest/member/getCountryList?lang=${lang}`, 'GET')
      .then((result) => {
        setCountryList(result.data)
        setCountry(result.data[0].sq)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const confirmPassword = () => {
    if (password === passwordCheck) {
      return true
    }
    return false
  }

  const initValide = () => {
    setValidServiceTerms(false)
  }

  const validate = () => {
    let valid = true
    initValide()
    if (!serviceTerms) {
      setValidServiceTerms(true)
      return false
    }

    if (!validateId(loginId)) {
    }

    if (!validatePassword(password) || !confirmPassword()) {
      valid = false
      setValidFailPassword(true)
    }

    if (!validateEmail(email)) {
      valid = false
      setValidFailEmail(true)
    }

    return valid
  }

  const loginIdDucplicationCheck = () => {
    if (!validateId(loginId)) {
      alert(language['사용할 수 없는 아이디 입니다.'])
      return
    }
    axiosConnect(
      `/rest/member/checkIdDuplication`,
      'GET',
      {},
      {
        memberId: loginId,
      }
    )
      .then((result) => {
        if (result.data != null && result.data === 0) {
          alert(language['사용 가능한 아이디 입니다.'])
        } else {
          alert(language['이미 가입된 아이디 입니다.'])
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  const signUp = () => {
    validate() &&
      axiosConnect(`/rest/member/signup`, 'POST', {
        memberId: loginId,
        memberPwd: password,
        memberCountry: country,
        memberGender: gender,
        memberName: name,
        memberPassportNum: passportNumber,
        memberBirthday: DateUtil.convertDate(birthDay),
        memberEmail: email,
        memberPhone: phoneNumber,
      })
        .then((result) => {
          navigate('/sign-up/completed')
        })
        .catch((error) => {
          alert(error)
        })
  }

  const getCountryName = () => {
    if (language.code === 'en') {
      return 'countryNameEn'
    } else if (language.code === 'cn') {
      return 'countryNameCn'
    } else if (language.code === 'jp') {
      return 'countryNameJp'
    } else {
      return 'countryNameKo'
    }
  }

  return (
    <div className="container loginwrap">
      <div className="row no-gutters">
        <div className="col-12">
          <Link to="/" className="login-brand">
            <img src="/resources/home/img/svg/logo.svg" alt="이지트래블케어" />
          </Link>
          <h2>{language['회원가입']}</h2>
          <form>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['아이디']}</label>
              <div className="input-group">
                <input type="text" className="form-control" id="inputID" placeholder={language['아이디']} value={loginId} onChange={(e) => setLoginId(e.target.value)} />
                <div className="input-group-append">
                  <a onClick={loginIdDucplicationCheck} className="btn btn btn-primary btn-lg">
                    {language['중복체크']}
                  </a>
                </div>
              </div>
            </div>
            <div className="form-group row no-gutters">
              <label htmlFor="inputPassword" className="sr-only">
                {language['비밀번호']}
              </label>
              <div className="input-group">
                <input type="password" className={`form-control ${validFailPassword ? 'valid is-invalid' : ''}`} id="inputPassword" placeholder={language['비밀번호 : 영문/숫자/특수문자, 숫자 6~16자']} value={password} onChange={(e) => setPassword(e.target.value)} />
                {validFailPassword ? <p className="invalid-feedback p-2 text-left m-0">{language['비밀번호 조건에 맞지 않습니다.']}</p> : <></>}
              </div>
            </div>
            <div className="form-group row no-gutters">
              <label htmlFor="inputPasswordRe" className="sr-only">
                {language['비밀번호 확인']}
              </label>
              <div className="input-group">
                <input type="password" className={`form-control ${validFailPassword ? 'valid is-invalid' : ''}`} id="inputPasswordRe" placeholder={language['비밀번호 확인']} value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} />
                {validFailPassword ? <p className="invalid-feedback p-2 text-left m-0">{language['비밀번호 조건에 맞지 않습니다.']}</p> : <></>}
              </div>
            </div>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['국적']}</label>
              <select className="form-control" value={country} onChange={(e) => setCountry(e.target.value)}>
                {countryList.map((country: any, index: number) => {
                  return (
                    <option key={`country_${index}`} value={country.countryCode}>
                      {country[getCountryName()]}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="form-group row no-gutters">
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customMale" name="gender" checked={gender === 'Male'} className="custom-control-input" value="Male" onClick={() => setGender('Male')} />
                <label className="custom-control-label" htmlFor="customMale">
                  {language['남성']}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customFemale" name="gender" checked={gender === 'Female'} className="custom-control-input" value="Female" onClick={() => setGender('Female')} />
                <label className="custom-control-label" htmlFor="customFemale">
                  {language['여성']}
                </label>
              </div>
            </div>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['이름']}</label>
              <input type="text" className="form-control" id="inputName" placeholder={language['이름']} value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['여권번호']}</label>
              <input type="text" className="form-control" id="inputPassport" placeholder={language['여권번호(외국인등록번호)']} value={passportNumber} onChange={(e) => setPassportNumber(e.target.value)} />
            </div>
            <div className="form-group row no-gutters">
              <label htmlFor="inputBirthday" className="sr-only">
                {language['생년월일']}
              </label>
              <DatePicker className="form-control" id="inputBirthday" dateFormat="yyyy-MM-dd" selected={birthDay} onChange={(date: Date) => setBirthDay(date)} />
            </div>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['이메일']}</label>
              <input type="email" className={`form-control ${validFailEmail ? 'valid is-invalid' : ''}`} id="inputEmail" placeholder={language['이메일']} value={email} onChange={(e) => setEmail(e.target.value)} />
              {validFailEmail ? <p className="invalid-feedback p-2 text-left m-0">{language['올바른 형식의 이메일 주소가 아닙니다.']}</p> : <></>}
            </div>
            <div className="form-group row no-gutters">
              <label className="sr-only">{language['휴대폰번호']}</label>
              <input type="text" className="form-control" id="inputPhone" placeholder={language['휴대폰번호( 하이폰 - 포함해서 작성해주세요)']} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            </div>
            <div className="form-group row no-gutters mb-0">
              <label className="sr-only">{language['필수항목 체크']}</label>
              <div className={`custom-control custom-checkbox ${validServiceTerms ? 'custom-checkbox is-invalid' : ''}`}>
                <input type="checkbox" className="custom-control-input" id="customCheck1" checked={serviceTerms} onChange={() => setServiceTerms(!serviceTerms)} />
                <label className="custom-control-label" htmlFor="customCheck1"></label>
                <Link to="/terms" target="_blank" className="link">
                  {language['이용약관']}
                </Link>
                ,{' '}
                <Link to="/terms/privacy" target="_blank" className="link">
                  {language['개인정보처리방침']}
                </Link>
                ,{' '}
                <Link to="/terms/email-collection" target="_blank" className="link">
                  {language['이메일무단수집거부']}
                </Link>
                {language['동의']} <small className="text-danger">{'(' + language['필수'] + ')'}</small>
              </div>
              {validServiceTerms ? <p className="invalid-feedback p-2 text-left m-0">{language['이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.']}</p> : <></>}
            </div>
            <p className="clearfix"></p>
            <div className="form-group row no-gutters">
              <a className="btn btn-primary btn-block btn-lg" onClick={signUp}>
                {language['동의하고 가입하기']}
              </a>
            </div>

            <p className="text-copy mt-5">© Easy Travel Care</p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUpPage
