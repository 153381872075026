import { useSelector } from 'react-redux'

function TravelerServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['여행자 안심케어']}</h2>
            </div>
            <h5>
              {language['여행 중 발생할 수 있는']}
              <br />
              {language['휴대품 손해, 배상책임에서']}
              <br />
              {language['사망까지']}
            </h5>
            <ul className="list">
              <li>{language['상해 및 질병']}</li>
              <li>{language['국내여행 중 배상책임']}</li>
              <li>{language['국내여행 중 휴대품 손해']}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_traveler.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service_traveler.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['여행자 안심 보험 서비스']}</h5>
                  <hr className="mfull dark mt-0" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행중 상해사망']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['1억원']}</div>
                    <div className="col-12 col-md-5 offset-md-1">{language['15세 미만 제외']}</div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행중 상해후유장해']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['1억원']}</div>
                    <div className="col-12 col-md-5 offset-md-1"></div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행중 질병사망/ 80%고도후유장해']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['2천만원']}</div>
                    <div className="col-12 col-md-5 offset-md-1">{language['15세 미만/ 80세이상 제외']}</div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행중 일괄배상']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['5백만원']}</div>
                    <div className="col-12 col-md-5 offset-md-1"></div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행중 휴대품손해']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['20만원']}</div>
                    <div className="col-12 col-md-5 offset-md-1">{language['분실제외']}</div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행 상해급여_의료실비보장']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['1천만원']}</div>
                    <div className="col-12 col-md-5 offset-md-1">{language['통원 1회당 10만원 한도']}</div>
                  </div>
                  <hr className="mfull" />
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <strong>{language['국내여행 상해비급여_의료실비보장']}</strong>
                    </div>
                    <div className="col-12 col-md-2 price">{language['1천만원']}</div>
                    <div className="col-12 col-md-5 offset-md-1">{language['통원 1회당 10만원 한도']}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TravelerServiceView
