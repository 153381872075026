export const validateId = (value: string): boolean => {
  let bool = false
  if (value !== '') {
    bool = /^[0-9a-zA-Z]{6,14}$/.test(value) || /^easy[0-9]{4}$/.test(value)
  }
  return bool
}

export const validateEmail = (value: string): boolean => {
  let bool = false
  if (value !== '') {
    bool = /^[\w+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/.test(value)
  }
  return bool
}

export const validatePassword = (value: string): boolean => {
  let bool = false
  if (value !== '') {
    bool = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{6,16}$/.test(value)
  }
  return bool
}
