import { useParams } from 'react-router-dom'
import PagenationView from '../../../common/PaginationView'
import { useEffect, useState } from 'react'
import axiosConnect from '../../../../network/http-request'
import { useSelector } from 'react-redux'
import { DateUtil } from '../../../../utils/date-format'

function B2BServicePurchase() {
  const language = useSelector((state: any) => state.language.value)

  const { id } = useParams()
  const [pageNo, setPageNo] = useState(1)
  const [purchase, setPurchase] = useState<any>({})
  const [memberList, setMemberList] = useState<any>({
    totalPages: 1,
    content: [],
  })

  useEffect(() => {
    axiosConnect(`/api/purchase/${id}`, 'GET')
      .then((result) => {
        setPurchase(result.data)
      })
      .catch((error) => {
        alert(error)
      })
    getMemberList()
  }, [])

  const getMemberList = () => {
    axiosConnect(`/api/purchase/myMemberListBySq/${id}/${pageNo}`, 'GET')
      .then((result) => {
        let resultData = result.data
        if (resultData.totalPages === 0) {
          resultData.totalPages = 1
        } else {
          if (resultData.numberOfElements < resultData.pageable.pageSize) {
            resultData.totalPages = resultData.totalPages - 1
          }
        }
        setMemberList(resultData)
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['서비스 구매']}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 mb-5">
        <h5 className="mt-2">{language['서비스 정보']}</h5>
        <div className="boxwrap">
          <table className="table table-bordered">
            <colgroup>
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>{language['제목']}</th>
                <td className="text-left">{purchase.title}</td>
              </tr>
              <tr>
                <th>{language['작성일']}</th>
                <td className="text-left">{purchase.createDatetime}</td>
              </tr>
              <tr>
                <th>{language['첨부파일명']}</th>
                <td className="text-left">{purchase.fileName}</td>
              </tr>
              <tr>
                <th>{language['요청']}</th>
                <td className="text-left">{purchase.requestCount}</td>
              </tr>
              <tr>
                <th>{language['승인']}</th>
                <td className="text-left">{purchase.approvalCount}</td>
              </tr>
              <tr>
                <th>{language['상태']}</th>
                <td className="text-left">{purchase.status}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="boxwrap mt-4">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col">{language['번호']}</th>
                <th scope="col">{language['상태']}</th>
                <th scope="col">{language['아이디']}</th>
                <th scope="col">{language['이름']}</th>
                <th scope="col">{language['성별']}</th>
                <th scope="col">{language['생년월일']}</th>
                <th scope="col">{language['이메일']}</th>
                <th scope="col">{language['서비스 이용기간']}</th>
                <th scope="col">{language['구매 연동']}</th>
              </tr>
            </thead>
            <tbody>
              {memberList.content &&
                memberList.content.map((member: any, index: number) => (
                  <tr>
                    <td>{member.seq}</td>
                    <td>{member.memberPaymentStatus}</td>
                    <td>{member.memberId}</td>
                    <td>{member.memberName}</td>
                    <td>{member.memberGender === 'Male' ? language['남성'] : language['여성']}</td>
                    <td>{DateUtil.convertDate(member.memberBirthday)}</td>
                    <td>{member.memberEmail}</td>
                    <td>
                      {DateUtil.convertDate(member.memberStartDate)}~{DateUtil.convertDate(member.memberEndDate)}
                    </td>
                    <td>{member.title}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* <div className="text-center">
            <PagenationView
              currentPage={pageNo}
              totalPages={memberList.totalPages}
              updatePage={(index: number) => {
                setPageNo(index)
              }}
            />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default B2BServicePurchase
