function HyundaiCNView() {
  return (
    <>
      <h6>（必填）个人（信用）信息处理同意事项</h6>
      <h6>个人(信用)信息的收集、使用相关事项</h6>
      个人信用信息的收集、使用目的
      <br />
      - 保险合同的核保、签订与维持管理(包括复效及更新) <br />
      - 保险金等的支付审查
      <br />
      - 保险净费率的计算验证
      <br />
      - 信访处理及纠纷应对
      <br />
      - 合法性及事故调查(包括保险欺诈调查)
      <br />
      - 保险募集秩序的维持
      <br />
      - 金融交易相关业务（金融交易申请、自动转账等）
      <br />
      <br />
      收集、使用的个人（信用）信息内容
      <br />
      - 个人识别信息（姓名、身份证号、外国人登陆证号、护照号码、地址、性别、职业、电话号码、电子邮件）
      <br />
      - 金融交易业务（保险费及保险金等的收支）相关信息
      <br />
      <br />
      个人（信用）信息使用期限
      <br />
      - 自同意收集、使用之日起至（金融）交易结束后5年
      <br />
      <br />
      <h6>个人（信用）信息查询相关事项</h6>
      个人信用信息的查询目的 <br />
      - 保险合同的核保、签订与维持管理（包括复效及更新）、保险金等的支付与审查、 保险事故调查（包括保险欺诈调查） <br />
      <br />
      需要查询的个人（信用）信息 <br />
      - 信用信息集中机构（生命保险协会、损害保险协会、韩国信用信息院）及保险费率 <br />
      计算机构收集管理的保险合同信息、保险金支付相关信息（包括事故信息）、被保险人的疾病及伤害相关信息 <br />
      <br />
      同意查询的有效期及个人（信用）信息的持有使用期限 <br />
      - 自同意收集使用之日起至交易结束后5年（但超过交易结束后5年的，限于保险金支付、金融事故调查、保险欺诈行为的预防与揭发、信访处理、法律义务履行等事项,将保留使用并另行保管） <br />
      <h6>（必填）有关签订保险合同的同意事项</h6>
      <h6>集体保险规程同意事项</h6>
      第一条（目的）
      <br />
      本规程旨在规定为（株）PREMIUMPASS INTERNATIONAL会员提供的服务使用相关协议事项。
      <br />
      <br />
      第二条 (集体投保认购等)
      <br />
      1.（株）PREMIUMPASS INTERNATIONAL可以作为投保人为会员与保险公司签订集体保险合同。
      <br />
      2. 希望以被保险人身份签订（株）PREMIUMPASS INTERNATIONAL作为投保人的集体保险合同的会员，可以按规定程序提出参保申请。
      <br />
      3. 拟申请参保的会员，应如实回答保险公司和（株）PREMIUMPASS INTERNATIONAL要求告知的事项。
      <br />
      <br />
      <h6>基于保险合同签订、履行等目的的个人（信用）信息处理 </h6>
      个人信用信息收集、使用相关事项 <br />
      根据个人信息保护法、信用信息使用及保护相关法律, 本合同所规定的个人(信用)信息收集、使用内容具体如下。 是否同意？ <br />
      <br />
      个人信用信息的收集、使用目的
      <br />
      - 保险合同的核保、签订与维持管理(包括复效及更新)
      <br />
      - 保险金等的支付审查
      <br />
      - 保险净费率的计算验证
      <br />
      - 信访处理及纠纷应对
      <br />
      - 合法性及事故调查(包括保险欺诈调查)
      <br />
      - 保险募集秩序的维持
      <br />
      - 金融交易相关业务（金融交易申请、自动转账等）
      <br />
      <br />
      收集、使用的个人（信用）信息内容
      <br />
      - 个人识别信息（姓名、身份证号、外国人登陆证号、护照号码、地址、性别、职业、电话号码、电子邮件）
      <br />
      - 金融交易业务（保险费及保险金等的收支）相关信息 <br />
      <br />
      个人（信用）信息使用期限
      <br />- 自同意收集、使用之日起至（金融）交易结束后5年
    </>
  )
}

export default HyundaiCNView
