function PageHeaderView(props: any) {
  const { image, title } = props
  return (
    <div id="carousel-visual" className="carousel slide" data-ride="carousel">
      <div className="carouselwrap">
        <div className="carousel-inner visual" role="listbox">
          <div className="item active">
            <div style={{ backgroundImage: `url(${image})` }} className="carousel-bg carousel-bg-sub"></div>
            <div className="carousel-caption carousel-caption-sub">
              <div className="container">
                <h3>{title}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHeaderView
