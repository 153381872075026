import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function SignUpCompletedPage() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="container loginwrap">
      <div className="row no-gutters">
        <div className="col-12">
          <Link to="/" className="login-brand">
            <img src="/resources/home/img/svg/logo.svg" alt="이지트래블케어" />
          </Link>
          <h2>{language['회원가입 완료']}</h2>
          <form>
            <div className="form-group row no-gutters mt-4">
              {language['이지트래블케어 회원가입이 완료되었습니다.']}
              <br />
              {language['회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.']}
              <br />
              {language['로그인 후 이용가능합니다.']}
              <br />
            </div>
            <p className="clearfix mt-4"></p>
            <p className="clearfix"></p>
            <div className="form-group row no-gutters">
              <Link to="/login" className="btn btn-primary btn-block btn-lg">
                {language['로그인']}
              </Link>
            </div>

            <p className="text-copy mt-5">© Easy Travel Care</p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUpCompletedPage
