import { useSelector } from 'react-redux'

function MainHeaderView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div>
      <div id="carouselVisual" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselVisual" data-slide-to="0" className="active"></li>
          <li data-target="#carouselVisual" data-slide-to="1"></li>
          <li data-target="#carouselVisual" data-slide-to="2"></li>
          <li data-target="#carouselVisual" data-slide-to="3"></li>
          <li data-target="#carouselVisual" data-slide-to="4"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div style={{ backgroundImage: `url(/resources/home/img/slide1.jpg)` }} className="carousel-bg"></div>
            <div className="carousel-caption">
              <div className="container m-center">
                <h3>
                  {language['즐겁고 안전한 관광']}
                  <br />
                  {language['안심보험에서 안심여행까지!']}
                </h3>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{ backgroundImage: `url(/resources/home/img/slide2.jpg)` }} className="carousel-bg"></div>
            <div className="carousel-caption">
              <div className="container m-center">
                <h3>
                  {language['외국인 관광객 니즈에 맞는']}
                  <br />
                  {language['맞춤형 안심케어 서비스']}
                </h3>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{ backgroundImage: `url(/resources/home/img/slide3.jpg)` }} className="carousel-bg"></div>
            <div className="carousel-caption">
              <div className="container m-center">
                <h3>
                  {language['안심보험&컨시어지 서비스']}
                  <br />
                  {language['K-Travel 안심케어']}
                </h3>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{ backgroundImage: `url(/resources/home/img/slide4.jpg)` }} className="carousel-bg"></div>
            <div className="carousel-caption">
              <div className="container m-center">
                <h3>
                  {language['편안하고']}
                  <br />
                  {language['안전하고']}
                  <br />
                  {language['품위있는 가치관광']}
                </h3>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{ backgroundImage: `url(/resources/home/img/slide5.jpg)` }} className="carousel-bg"></div>
            <div className="carousel-caption">
              <div className="container m-center">
                <h3>
                  {language['안전하고 즐거운 한국여행']}
                  <br />
                  {language['이지트래블케어 안심보험과 함께하세요!']}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselVisual" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon btn-arrow-circle btn_prev-w ml-3">
            <span className="sr-only">{language['이전']}</span>
          </span>
        </a>
        <a className="carousel-control-next" href="#carouselVisual" role="button" data-slide="next">
          <span className="carousel-control-next-icon btn-arrow-circle btn_next-w mr-3">
            <span className="sr-only">{language['다음']}</span>
          </span>
        </a>
      </div>
    </div>
  )
}

export default MainHeaderView
