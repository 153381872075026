function HyundaiENView() {
  return (
    <>
      <h6>Data Processing Agreement</h6>
      Matters related to the Collection of Personal Credit Information
      <br />
      Purpose of Collection and Use of Personal Credit Information
      <br />
      - Maintaining the Insurance Underwriting (Including Resurrection and Renewal)
      <br />
      - Examining the Insurance Payment
      <br />
      - Calculating the Net Insurance Premium Rate
      <br />
      - Handling Civil Appeals
      <br />
      - Investigating the Propriety and Accidents (Including Insurance Fraud)
      <br />
      - Maintaining the order of Insurance Business Act
      <br />
      - Financial Transaction (Applying Financial Transaction and Direct Debit)
      <br />
      <br />
      Contents of Personal (Credit) Information to collect and use.
      <br />
      - Personal Identification Information (Name, Resident Registration Number, Passport Number, Address, Gender, Job, Mobile, E-mail Address)
      <br />
      - Financial Transaction Related Information (Receipt of Insurance Premium)
      <br />
      <br />
      Holding Period of Personal (Credit) Information
      <br />
      - Up to 5 years from the Date of Personal Information Collection and Usage Agreement
      <br />
      <br />
      <h6>Matters Related to Personal Information(Credit) Inquiry</h6>
      Purpose of Personal Information(Credit) Inquiry
      <br />
      - Maintaining the Insurance Underwriting (Including Resurrection and Renewal), Examining the Insurance Payment, Investigating the Propriety and Accidents (Including Insurance Fraud)
      <br />
      <br />
      Contents of Personal Information(credit) to Inquire
      <br />
      - Credit Information Collection Agency (Korea Life Insurance Association, General Insurance Association of Korea, Korea Credit Information Services) and Premium Rate Insurance Contract Information that output agencies collect and manage, Information about the Insurance Payment (Including accident information, information about disease and injury) of the insured.
      <br />
      <br />
      Expiration Date of Agreement on Information Inquiry and Holding Period of Personal Information(credit)
      <br />
      - Up to 5 years after Complete Transaction from the Date of Personal Information Collection and Usage Agreement. However, five years after the transaction is completed, the personal information is collected and kept separately only for insurance payment, financial accident investigation, prevention of insurance fraud, handling of civil appeals, and fulfillment of legal obligations.
      <br />
      <br />
      <h6>Agreement on Conclusion of the Insurance Contract</h6>
      Agreement on Regulations of Group Insurance
      <br />
      Article 1. (Purpose)
      <br />
      The purpose of this regulation is to make agreements between members regarding the usage of service provided for members of Premiumpass International Co., Ltd.
      <br />
      <br />
      Article 2. Group Insurance Subscription
      <br />
      1. Premium Pass International Co., Ltd. can settle a group insurance contract with the insurance company for its members as a contractor.
      <br />
      2. Members who wish to take out a group insurance contract in which Premiumpass International is a contractor can apply for insurance through the specific formalities.
      <br />
      3. Members who wish to take out an insurance shall faithfully respond to the requests made by the insurance company and Premiumpass International.
      <br />
      <br />
      <h6>Agreement on Processing of Personal Information(credit) for the Conclusion and the Fulfillment of a Contract</h6>
      Matters concerning the Collection and Use of Personal Credit Information
      <br />
      We would like to collect and use your personal information(credit) regarding this contract based on the law of personal information protection act and protection of the credit information.
      <br />
      Do you agree as above? <br />
      <br />
      Purpose of Collection and Use of Personal Credit Information
      <br />
      - Maintaining the Insurance Underwriting (Including Resurrection and Renewal)
      <br />
      - Examining the Insurance Payment
      <br />
      - Calculating the Net Insurance Premium Rate
      <br />
      - Handling Civil Appeals
      <br />
      - Investigating the Propriety and Accidents (Including Insurance Fraud)
      <br />
      - Maintaining the order of Insurance Business Act
      <br />
      - Financial Transaction (Applying Financial Transaction and Direct Debit)
      <br />
      <br />
      Contents of Personal Information(credit) to collect and use
      <br />
      - Personal Identification Information (Name, Resident Registration Number, Passport Number, Address, Gender, Job, Mobile, E-mail Address.)
      <br />
      - Financial Transaction Related Information (Receipt of Insurance Premium)
      <br />
      <br />
      Holding Period of Personal Information(credit)
      <br />
      - Up to 5 years from the Date of Personal Information Collection and Usage Agreement
      <br />
      <br />
    </>
  )
}

export default HyundaiENView
