import { DateUtil } from '../../../../utils/date-format'

function ChatImageView(props: any) {
  const { isLoginUser, chat } = props
  function isUserMessageClass() {
    return isLoginUser ? 'message-me' : 'message'
  }

  return (
    <div className={isUserMessageClass()}>
      <div className="message-body">
        <img
          src={chat.msgContent}
          style={{
            width: '100%',
          }}
        />
        <div className="message-date">{DateUtil.getFormatDate(chat.msgSendTime)}</div>
      </div>
    </div>
  )
}

export default ChatImageView
