import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function PurchaseCompletedView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <form className="form-add">
              <section>
                <div className="greybox mt-0">
                  <hr className="dark" />
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h5>
                        {language['컨시어지 서비스 구매를']}
                        <br className="d-block d-md-none" />
                        {language['완료했습니다.']}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">{language['이지트래블케어와 함께 즐거운 여행되세요.']}</div>
                  </div>
                  <hr className="dark" />
                </div>
              </section>
            </form>

            <div className="row">
              <div className="col-6">
                <Link to="/" className="btn btn-outline-dark btn-lg">
                  {language['홈으로 가기']}
                </Link>
              </div>
              <div className="col-6 text-right">
                <Link to="/my-page/purchase" className="btn btn-primary btn-lg">
                  {language['구매정보 확인']}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseCompletedView
