import { Outlet, useLocation } from 'react-router-dom'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import InnerPathTabView from '../../common/InnerPathTabView'
import { useSelector } from 'react-redux'

function FAQPage() {
  const location = useLocation()
  const language = useSelector((state: any) => state.language.value)

  const tabItems = [
    {
      link: '/help/faq',
      title: language['자주하는 질문'],
    },
    {
      link: '/help/qna',
      title: language['문의'],
    },
  ]

  const getTitle = (path: string) => {
    if (path === '/help/faq') {
      return language['자주하는 질문']
    }
    return language['문의']
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub_cs.jpg" title={getTitle(location.pathname)} />
      <InnerPathTabView tabItems={tabItems} />
      <BreadcrumbView title={getTitle(location.pathname)} link={location.pathname} />
      <Outlet />
    </>
  )
}

export default FAQPage
