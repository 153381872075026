import { useLocation } from 'react-router-dom'
import MemberShipStyles from './Theme/MemberShip'

function MemberShipView() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return (
    <>
      <MemberShipStyles />
      <body>
        <div className="membershipArea">
          <img src="/resources/home/img/subscription.jpg" />
          <div className="membership_group">
            <div className="membership_name">{queryParams.get('name')}</div>
            <div className="membership_email">{queryParams.get('email')}</div>
            <div className="membership_date">{queryParams.get('date')}</div>
          </div>
          <div className="membership_number">{queryParams.get('number')}</div>
          <div className="member_print" onClick={() => window.print()}>
            <button>PRINT</button>
          </div>
        </div>
      </body>
    </>
  )
}

export default MemberShipView
