import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosConnect from '../../../../network/http-request'
import { possibleChat } from '../../../../store/possibleChat'
import EasyTravelCareTermENView from '../../purchase/view/terms/EasyTravelCareTermENView'
import EasyTravelCareTermJPView from '../../purchase/view/terms/EasyTravelCareTermJPView'
import EasyTravelCareTermCNView from '../../purchase/view/terms/EasyTravelCareTermCNView'
import EasyTravelCareTermKRView from '../../purchase/view/terms/EasyTravelCareTermKRView'
import HyundaiENView from '../../purchase/view/terms/HyundaiENView'
import HyundaiJPView from '../../purchase/view/terms/HyundaiJPView'
import HyundaiCNView from '../../purchase/view/terms/HyundaiCNView'
import HyundaiKRView from '../../purchase/view/terms/HyundaiKRView'

function SOSCallTermsView() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const language = useSelector((state: any) => state.language.value)
  const loginUser = useSelector((state: any) => state.user.value)

  const [termsAll, setTermsAll] = useState(false)
  const [easyTravelTerms, setEasyTravelTerms] = useState(false)
  const [hyundaiTerms, setHyundaiTerms] = useState(false)

  const termsAllChange = () => {
    setTermsAll(!termsAll)
    setEasyTravelTerms(!termsAll)
    setHyundaiTerms(!termsAll)
  }

  useEffect(() => {
    setTermsAll(easyTravelTerms && hyundaiTerms)
  }, [easyTravelTerms, hyundaiTerms, termsAll])

  const nextPage = () => {
    if (!termsAll) {
      alert(language['약관에 동의해주시기 바랍니다.'])
      return
    }
    updateB2BAuth()
  }

  function updateB2BAuth() {
    axiosConnect(`/rest/member/updateB2BAuth?memberSid=${loginUser.memberSid}`, 'GET')
      .then((rs) => {
        axiosConnect('/rest/main/checkPossibleChat', 'POST').then((result) => {
          dispatch(possibleChat(result.data))
          navigate('/sos/information')
        })
      })
      .catch((error) => {
        alert(error)
      })
  }

  const getEasyTravelCareTerm = () => {
    if (language.code === 'en') {
      return <EasyTravelCareTermENView />
    } else if (language.code === 'jp') {
      return <EasyTravelCareTermJPView />
    } else if (language.code === 'cn') {
      return <EasyTravelCareTermCNView />
    } else {
      return <EasyTravelCareTermKRView />
    }
  }

  const getHyundaiTerm = () => {
    if (language.code === 'en') {
      return <HyundaiENView />
    } else if (language.code === 'jp') {
      return <HyundaiJPView />
    } else if (language.code === 'cn') {
      return <HyundaiCNView />
    } else {
      return <HyundaiKRView />
    }
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['서비스 약관 동의']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="text-center">
          <div className="step-bg mt-3">
            <span className="line"></span>
            <ol className="start-step d-flex justify-content-center">
              <li className="active">
                <Link to="/sos/terms">
                  <span>
                    <strong>1</strong>
                    <small>{language['약관동의']}</small>
                  </span>
                </Link>
              </li>
              <li>
                <a onClick={() => {}}>
                  <span>
                    <strong>2</strong>
                    <small>{language['이용안내']}</small>
                  </span>
                </a>
              </li>
              <li>
                <a onClick={() => {}}>
                  <span>
                    <strong>3</strong>
                    <small>{language['SOS 콜 채팅']}</small>
                  </span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <form className="form-add">
              <div className="cardline mt-2">
                <div className="card-body">
                  <h5 className="card-title">{language['이지트래블케어 서비스에 가입해주셔서 감사합니다.']}</h5>
                  <ul className="list secondary">
                    <li>{language['본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.']}</li>
                    <li>{language['이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.']}</li>
                    <li>
                      <strong>
                        <u>{language['약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.']}</u>
                      </strong>
                    </li>
                    <li>{language['지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.']}</li>
                  </ul>
                </div>
              </div>
              <section>
                <h5 className="mt-5">
                  {language['약관동의']}
                  <strong className="required"></strong>
                </h5>

                <div className="form-group row align-items-center">
                  <div className="col-12">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id="customCheckboxInlineA" name="customCheckboxInlineA" className="custom-control-input" checked={termsAll} onChange={termsAllChange} />
                      <label className="custom-control-label" htmlFor="customCheckboxInlineA">
                        <strong>{language['아래의 약관에 모두 동의합니다.']}</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <hr className="mfull dark" />
                <h6>{language['이지트래블케어 이용 약관']}</h6>
                <div className="form-group border terms">{getEasyTravelCareTerm()}</div>
                <div className="form-group row align-items-center">
                  <div className="col-12">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id="customCheckboxInlineT" name="customCheckboxInlineT" className="custom-control-input" checked={easyTravelTerms} onChange={() => setEasyTravelTerms(!easyTravelTerms)} />
                      <label className="custom-control-label" htmlFor="customCheckboxInlineT">
                        <strong>{language['이지트래블케어 이용 약관에 동의합니다.']}</strong>
                      </label>
                    </div>
                  </div>
                </div>

                <h6>{language['현대해상 화재보험 약관']}</h6>
                <div className="form-group border terms">{getHyundaiTerm()}</div>
                <div className="form-group row align-items-center">
                  <div className="col-12">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id="customCheckboxInlineH" name="customCheckboxInlineH" className="custom-control-input" checked={hyundaiTerms} onChange={() => setHyundaiTerms(!hyundaiTerms)} />
                      <label className="custom-control-label" htmlFor="customCheckboxInlineH">
                        <strong>{language['현대해상 화재보험 약관에 동의합니다.']}</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <hr className="dark mfull mb-0" />
              </section>
            </form>

            <div className="text-center pt-4">
              <a onClick={nextPage} className="btn btn-primary btn-block btn-lg">
                {language['다음']}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="divide" />
    </>
  )
}

export default SOSCallTermsView
