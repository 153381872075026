import { createGlobalStyle } from 'styled-components'

const MemberShipStyles = createGlobalStyle`
.member_print {
    display: block;
    padding: 10px 0;
    text-align: center;
 }
.member_print button {
    border: none;
    padding: 10px 50px;
    font-weight: 800;
    background-color: brown;
    color: #fff;
}
.membershipArea {
    position:relative;
    width:100%;
}
.membershipArea img {
    max-width: 100%;
}
.membership_group {
    position: absolute;
    top: 34.5vw;
    left: 32vw;
    font-size: 2.4vw;
    font-weight: 400;
    color: #000;
    display: flex;
    flex-direction: column;
}
.membership_group > div {
    padding-bottom: 3.1vw;
}
.membership_number {
    position: absolute;
    left: 11.3vw;
    bottom: 27vw;
    font-size: 4.5vw;
    font-weight: 600;
    color: #000;
}
@media print {
    .membership_group {
        top: 35vw;
    }
}
@media only screen and (max-width:500px) {
    .membership_group {
        top: 35vw;
    }
}
@media only screen and (max-width:360px) {
    .membership_group {
        top: 34.5vw;
    }
}
@media only screen and (max-width:350px) {
    .membership_group > div {
        padding-bottom: 2.2vw;
    }
}
`

export default MemberShipStyles
