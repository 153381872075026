import { useSelector } from 'react-redux'

function MedicalServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['의료 안심케어']}</h2>
            </div>
            <h5>
              {language['여행 중 발생할 수 있는']} <br className="d-block d-md-none" />
              {language['여러 사고에 준비되어 있는']} <br />
              {language['의료 지원 서비스']}
            </h5>
            <ul className="list">
              <li>
                {language['교통사고, 안전사고 등 맞춤형 케어 서비스']}
                <br />
                {'('} {language['의료통역사 수배, 의료비 해결방안 모색,']} <br className="d-block d-md-none" />
                {language['여행자보험 & 의료지원 등 케어']} {')'}
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_medical.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service-medical.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['교통사고 시 이용 안내']}</h5>
                  <ul className="list secondary">
                    <li>{language['여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['안전사고 시 이용 안내']}</h5>
                  <ul className="list secondary">
                    <li>{language['여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['맞춤형 케어 이용 안내']}</h5>
                  <ul className="list secondary">
                    <li>{language['안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MedicalServiceView
