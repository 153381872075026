import { useEffect, useState } from 'react'
import axiosConnect from '../../../../network/http-request'
import { useSelector } from 'react-redux'
import { DateUtil } from '../../../../utils/date-format'
import PagenationView from '../../../common/PaginationView'
import { Navigate } from 'react-router-dom'

function MyPurchaseListView() {
  const language = useSelector((state: any) => state.language.value)
  const [purchaseList, setPurchaseList] = useState<any>({ content: [] })
  const [purchaseStatus, setPurchaseStatus] = useState<any>()
  const [pageNo, setPageNo] = useState(1)
  const loginUser = useSelector((state: any) => state.user.value)

  useEffect(() => {
    fetchPurchaseList()
    fetchPurchaseStatus()
  }, [])

  useEffect(() => {
    fetchPurchaseList()
  }, [pageNo])

  function fetchPurchaseStatus() {
    axiosConnect(`/api/member/paymentList/status`, 'GET')
      .then((result) => {
        setPurchaseStatus(result.data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  function fetchPurchaseList() {
    axiosConnect(`/api/member/paymentList/${loginUser.memberSid}/${pageNo}`, 'GET')
      .then((result) => {
        let resultData = result.data
        if (resultData.totalPages === 0) {
          resultData.totalPages = 1
        } else {
          if (resultData.numberOfElements < resultData.pageable.pageSize) {
            resultData.totalPages = resultData.totalPages - 1
          }
        }
        setPurchaseList(result.data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const openMemberShip = (purchase: any) => {
    const windowFeatures = 'width=800,height=1280,resizable,scrollbars=yes,toolbar=no'
    const date = DateUtil.convertDate(purchase.memberStartDate) + ' ~ ' + DateUtil.convertDate(DateUtil.addDate(purchase.memberStartDate, purchase.memberSelectTerm - 1))
    window.open(`/#/purchase/member-ship?name=${loginUser.memberName}&email=${loginUser.memberEmail}&date=${date}&number=${purchase.memberShipNum}`, '_blank', windowFeatures)
  }

  function getPaymentStatus(status: number) {
    if (status === 0) {
      return language['사용가능']
    } else if (status === 1) {
      return language['결제취소']
    } else if (status === 3) {
      return language['대기']
    } else {
      return language['서비스만료']
    }
  }

  if (loginUser === null || loginUser.memberAuth === 6) {
    return <Navigate to="/b2b/service_purchase" />
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['구매내역']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-md-12">
            <ul className="list secondary">
              <li>
                <strong>{language['나의 구매 내역을 확인할 수 있습니다.']}</strong>
              </li>
              <li>{language['고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)']}</li>
              <li>{language['컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.']}</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4 mb-5">
          <div className="col-md-10 offset-md-1">
            <div className="border border-dark pt-4 pb-3">
              <div className="row">
                <div className="col-4 text-center">
                  <figure className="info-ico">
                    <i className="bi bi-journal-check"></i>
                  </figure>
                  <div className="info-status">
                    <h6 className="mb-0">{language['사용가능']}</h6>
                    <h2 className="mt-0 mb-0 text-secondary">{purchaseStatus?.possible}</h2>
                  </div>
                </div>
                <div className="col-4 text-center border-right border-left">
                  <figure className="info-ico">
                    <i className="bi bi-journal-minus"></i>
                  </figure>
                  <div className="info-status">
                    <h6 className="mb-0">{language['서비스만료']}</h6>
                    <h2 className="mt-0 mb-0 text-primary">{purchaseStatus?.expiration}</h2>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <figure className="info-ico">
                    <i className="bi bi-journal-x"></i>
                  </figure>
                  <div className="info-status">
                    <h6 className="mb-0">{language['결제취소']}</h6>
                    <h2 className="mt-0 mb-0 text-danger">{purchaseStatus?.cancel}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row tblist">
          <div className="col-12">
            <hr className="dark mt-0" />
            <div className="row align-items-center">
              <div className="col-md-2 text-center d-none d-md-block">
                <strong>{language['결제일']}</strong>
              </div>
              <div className="col-md-4 text-center d-none d-md-block">
                <strong>{language['유효기간']}</strong>
              </div>
              <div className="col-md-1 text-center d-none d-md-block">
                <strong>{language['금액']}</strong>
              </div>
              <div className="col-md-1 text-center d-none d-md-block">
                <strong>{language['결제수단']}</strong>
              </div>
              <div className="col-md-2 text-center d-none d-md-block">
                <strong>{language['상태']}</strong>
              </div>
              <div className="col-md-2 text-center d-none d-md-block">
                <strong>{language['가입증서']}</strong>
              </div>
            </div>
            <hr className="d-none d-md-block" />
            {purchaseList.content &&
              purchaseList.content.map((purchase: any, index: number) => {
                return (
                  <>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-2 date">{DateUtil.convertDateYYYMMDDHHMM(purchase.memberPaymentDate)}</div>
                      <div className="col-12 col-md-4 date">
                        {DateUtil.convertDate(purchase.memberStartDate)} ~ {DateUtil.convertDate(DateUtil.addDate(purchase.memberStartDate, purchase.memberSelectTerm - 1))}
                        <br />
                        <strong className="text-secondary">({purchase.memberSelectTerm + language['Days']})</strong>
                      </div>
                      <div className="col-12 col-md-1 date">
                        <strong className="text-secondary">$ {purchase.servicePriceDollar}</strong>
                      </div>
                      <div className="col-12 col-md-1 date">{purchase.memberPaymentMethod}</div>
                      <div className="col-12 col-md-2 date">{getPaymentStatus(purchase.memberPaymentStatus)}</div>
                      <div className="col-12 col-md-2 date">
                        <a onClick={() => openMemberShip(purchase)} className="btn btn-primary btn-sm">
                          {language['가입증서 보기']}
                        </a>
                      </div>
                    </div>
                    <hr className={purchaseList.content.length - 1 === index ? 'dark' : ''} />
                  </>
                )
              })}
            <PagenationView
              currentPage={pageNo}
              totalPages={purchaseList.totalPages}
              updatePage={(index: number) => {
                setPageNo(index)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MyPurchaseListView
