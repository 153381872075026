import { useSelector } from 'react-redux'

function B2BTermJPView() {
  const language = useSelector((state: any) => state.language.value)
  return (
    <>
      <>
        <h6>{language['이지트래블케어 이용 약관']}</h6>
        これからの大韓民国旅行は、 安心して楽しく、快適にお楽しみください！！
        <br />
        <br />
        大韓民国の旅行中に助けが必要な時は、いつでもEasy Travel Careセンターまでご連絡ください。 Easy Travel Careセンターは1年365日年中無休です。
        <br />
        <br />
        Easy Travel Careセンターは、韓国の観光地を旅行する観光客のために、モバイルチャット、 電話、 メールを利用して、安心観光保険&ケアサービスを提供しております。
        <br />
        <br />
        常にお客様に安全で快適な大韓民国観光をお楽しみいただけるようお手伝いし、大韓民国を訪れる外国人観光客のニーズに合わせたコンシェルジュサービスを提供することはもちろん、快適で安全で品位ある価値観光特化コンシェルジュサービスを提供し、観光の品格を高めます。
        <br />
        <br />
        <h6>Easy Travel Careの紹介</h6>
        Easy Travel Careは、大韓民国を訪れる外国人観光客がリラックスして楽しく、安心して 観光できるよう、外国人専用の旅行者保険サービスに合わせたコンシェルジュサービスを提供する安心観光プラットフォームサービスです。
        <br />
        主なサービスとしては、外国人専用旅行者保険サービスと㈱プレミアムパスインターナショナルが提供している医療安心ケア、トラベル安心ケア、空港安心ケア、ビジネス安心ケア、旅行者安心保険、そしてプレミアムメンバーシップ特別特典を追加提供いたします。
        <br />
        サービス提供会社である(株)プレミアムパスインターナショナルは、2007年度に儀典コンシェルジュサービス専門企業として設立し、現在グローバルホスピタリティ専門企業として急成長している企業です。
        <br />
        (株)プレミアムパスインターナショナルは、MICE事業、儀典輸送事業、空港関連サービス事業、旅行事業、コンシェルジュ事業など、観光トータルサービス専門企業で、現在観光および交通の主要拠点にトラベルセンター&ラウンジを運営しております。 <br />
        <br />
        <h6>Easy Travel Care 概要</h6>
        1. Easy Travel Careサービス加入
        <br />
        Easy Travel Careサービスに加入するためには、所属国名、氏名、生年月日、 性別、携帯電話番号, Eメールアドレスなどの登録が必要となり、サービス提供過程でその情報を通じた本人確認が行われます。 情報が一致しない場合は、サービス提供が制限されることがあります。
        <br />
        Easy Travel Careに加入する際は、加入証明書、サービスマニュアルがプラットフォーム を通して提供されます。 別途、登録証明書などの電子メールでのご要請があれば紙面でもご提供できます。
        <br />
        登録者は、登録時に記録された本人情報が正しいか必ず確認してください。 特に、緊急時に連絡可能な電話番号、Eメールアドレスなどは正しく記載されているか確認して、エラーがある場合は直ちにEasy Travel Careセンターまたはチャットサービスで修正してください。
        <br />
        旅行中に緊急事態や緊急事態が発生した場合はパスポート番号を教えていただかなければサービスをご利用いただけません。なお、応急処理後にはパスポートで本人確認をしていただきます。
        <br />
        <br />
        2. Easy Travel Careセンターの連絡方法
        <br />
        助けが必要な場合、または非常事態発生時にはEasy Travel Careプラットフォーム内のチャット サービスであるSOS申し込み、またはEasy Travel Careセンター(1644-1493、82-2-2063-3544)に連絡してください。
        <br />
        電話、Eメール、プラットフォームチャットサービスによるSOS申し込み、またはカカオトークを通じて24時間ケアセンターに連絡することができ、通話が必要な場合は連絡可能な電話番号を教えていただければ担当コーディネーターが電話番号に電話を差し上げます。 韓国のUSIMチップを使用しない電話番号は連絡が制限されることがあります。
        <br />
        <br />
        ※ Eメールまたはカカオトーク
        <br />
        Easy Travel Careセンター代表のEメール(care@tothepp.com)またはカカオトークプラスの友達
        <br />
        (@ Easy Travel Care CENTER)でサービス要請が可能です。
        <br />
        <br />
        <h6>Easy Travel Careサービス普通約款</h6>
        <h6>第一条 目的</h6>
        本約款は、㈱プレミアムパスインターナショナル（以下、「プレミアムパス」という。）が提供する安心観光プラットフォームサービスであるEasy Travel Careサービスに関連し、 会員および加入者間の権利義務を明確にすることを目的とします。
        <br />
        <br />
        <h6>第二条 用語の定義</h6>
        1. 会員
        <br />
        Easy Travel Careサービスの安心観光プラットフォームサービスへの加入申し込みを目的に、 電話, 電子メール、ウェブページ、モバイルアプリケーションなどを通じて、あるいはEasy Travel Care広告代行提携業者を通じて、完全なサービス利用のためにEasy Travel Careが要求する個人識別情報や旅行スケジュール情報をEasy Travel Careセンターに提供した個人や団体。
        <br />
        2. 加入者
        <br />
        Easy Travel Careの約款に明示された権利と義務に同意し、承諾の意味でEasy Travel Careが提示した加入費を納付することで加入証明番号を付与された会員。
        <br />
        3. Easy Travel Careセンター
        <br />
        会員が旅行中に発生した困難に対して便宜、保険などのサービスを提供するため、非対面1次サービス応対を行うトータルサービスセンター
        <br />
        4. 加入証明書
        <br />
        Easy Travel Careセンターが加入者に提供される証明書で, 加入証明番号とともに 個人識別情報や主要加入条件などが明示されている書類。
        <br />
        5. ケアマネジャー
        <br />
        約款に基づいて加入者が要請した医療および現場サービスと保険サービスに対する対応について、直接•間接的に関連するすべての事項に干渉し決定する権限を与え、サービス提供を評価•調整•監督する専門サービスマネージャー。
        <br />
        6. メンバーシップの特別特典
        <br />
        正常なEasy Travel Careサービス資格を取得した会員が受けられる特別特典として, Easy Travel Careサービス運営者であるプレミアムパスが決定します。
        <br />
        <br />
        <h6>第三条 サービスの内容</h6>
        Easy Travel Careが提供する安心観光プラットフォームサービスは 以下の通りです。
        <br />
        1. 医療安心ケア
        <br />
        大韓民国の旅行中に発生しうる交通事故、事故などのオーダーメイド型ケアサービスで医療通訳士の手配、医療費の解決方法の模索、旅行者保険&医療支援などのケアサービスを提供
        <br />
        2. トラベル安心ケア
        <br />
        大韓民国の旅行中に緊急事態が発生した際に提供される様々なトラベルコンシェルジュサービス
        <br />
        - 財布、パスポートなどを紛失した場合の支援ケアサービス
        <br />
        - 旅行中に緊急事項が発生した場合のケアサービス
        <br />
        - 交通便の予約発券及び車両手配サービス
        <br />
        - ギブ級宿泊予約支援サービスなど
        <br />
        3. 空港安心ケア
        <br />
        国際空港という特別な空間での旅行客のための安心ケアサービス
        <br />
        - 高齢者などの空港でのサポート及び不便事項のケア
        <br />
        - 国際空港におけるコンシェルジュサービスの提供
        <br />
        - ビザおよび緊急航空券ケアサービス
        <br />
        4. ビジネス安心ケア
        <br />
        快適で安全な旅行とビジネス成功のための特化コンシェルジュサービス
        <br />
        - 緊急通訳•翻訳、人材手配サービス
        <br />
        - 会議施設及びミーティング場所の予約支援サービス
        <br />
        - KTX、高速バス、航空券などの予約支援サービス
        <br />
        5. 旅行者安心保険
        <br />
        旅行安心保険サービスは「プレミアムパス」の提携保険会社のサービスで、提携保険会社の規定および保険約款に従い安心保険サービスが提供されます。
        <br />
        -（国内旅行中の） 傷害(死亡):1億ウォン(15歳未満除く)
        <br />
        -（国内旅行中の）傷害後遺障害：１億ウォン
        <br />
        -（国内旅行中の）疾病死亡及び80%高度後遺障害：２千万ウォン 15歳未満、８０歳以上保障除外
        <br />
        -（国内旅行中の）傷害給与_医療実費保障：１千万ウォン 通院一回当たり　10万ウォン限定
        <br />
        -（国内旅行中の）傷害非給与_ 医療実費保障：1千万ウォン　通院１回当たり　10万ウォン、通院回数　年間100回限定
        <br />
        -（国内旅行中の）賠償責任: 500万ウォン　　自己負担金 10,000ウォン
        <br />
        -（国内旅行中の）携帯用品(紛失除外)損害(外国人専用)：20万ウォン　自己負担金 10,000ウォン
        <br />
        6. メンバーシップの特別特典
        <br />
        Easy Travel Careサービス会員に提供するメンバーシップ特化サービスで、空港、旅行、ビジネスなどの様々なコンシェルジュサービスおよび割引サービスを提供しています。 メンバーシップサービスは、 Easy Travel Careサービス の提供 会社である「プレミアムパス」によって随時変更されることがあるので、ウェブページ及びモバイルアプリケーションなどで変更事項を確認することができます。
        <br />
        7. その他のサービス提供に伴う注意事項
        <br />
        - 加入者が大韓民国旅行中に交通事故や安全事故に遭った場合、緊急対処方法の案内、関係機関との連結および通訳サービス、現場出動を提供します。
        <br />
        - 本支援は、大韓民国内で発生した事故に対して処理することができ、母国または乗り継ぎ局など国外地域での事故にはサービスの提供が制限されます。 - 本サービスは加入者に事故が発生したらすぐにEasy Travel Careセンター へサービス要請を勧め、Easy Travel Careが関連業務を任される前、またはEasy Travel Careのマネージャが事故現場を受け付ける前に他社の関係者または3者が先に業務を遂行した場合、Easy Travel Careは事故処理の手続きが円滑に進められる のに役立つことがあります。
        <br />
        <br />
        <h6>第4条 サービスの開始及び終了</h6>
        Easy Travel Care加入者に対するサービス開始は、加入期間の初日に 始まり、最終日または大韓民国出国後すぐに終了し、日数別基準時間は24時を基準に算定します。
        <br />
        <br />
        <h6>第5条 約款の効力等</h6>
        1. 本約款は、Easy Travel Careがウェブページ、モバイルアプリケーション等に掲示し, 又は加入者に告知することによってその効力が生じます。
        <br />
        2. Easy Travel Careが本約款を改定する場合は、適用日及び改定理由を明示し 、その適用日の1週間前から適用日の前日まで1項と同じ方法で掲示または告知します。 この時、その改正約款は、サービス開始日がその適用日以降に該当する契約にのみ適用され、それ以前にすでに始まった契約については、改正前の約款条項が適用されます。
        <br />
        3. 登録者は改定された約款に同意しない場合、登録の取消しを要請することができます。
        <br />
        4. 加入者は契約の取り消しを要請せずにサービスを利用することで、改定された約款に同意したものとみなされます。
        <br />
        <br />
        <h6>第6条 約款適用の優先順位</h6>
        1. Easy Travel Careサービスと保険会社の保険商品と連携したサービスの場合 、保険と関連するすべての事項は当該保険商品の普通約款および特別約款が適用されます。
        <br />
        2. Easy Travel Careの普通約款と特別約款の内容が衝突する場合、特別約款 の効力はこの約款に優先されます。
        <br />
        <h6>第7条 会員及び加入者資格の取得</h6>
        1. すべての個人及び団体は、登録申請を希望する明確な意味で、Easy Travel Careが要求する 個人識別情報(国籍、氏名、生年月日、性別、電話番号、Eメールアドレスなど)及び旅行日程情報をEasy Travel Careが提供する媒体を通して入力または提供することにより、会員資格を取得します。 ただし、Easy Travel Careサービスの提供を受けるためには、必ずパスポートを提示しなければ なりません。
        <br />
        2. 会員が、Easy Travel Careが要求する個人識別情報及び旅行日程情報を不完全に 、又は虚偽で 入力(提供)し、又は12時間以上登録申請段階を行わない場合、Easy Travel Careは会員が入力した 個人識別情報及び旅行日程情報をすべて削除し、会員資格を剥奪する権利があります。
        <br />
        3. Easy Travel Careは、登録申請手続きを進めるため、会員の個人識別情報及び 旅行日程情報 を活用することができます。
        <br />
        4.1項により会員資格を取得した会員のうち、登録手続きを経て登録証明番号の発行を受けることにより、加入者資格が得られます。
        <br />
        <br />
        <h6>第8条 EASY TRAVEL CAREの義務と権利</h6>
        1. Easy Travel Careは、安定したサービス提供のため努力を続け、会員または加入者から苦情が 寄せられる場合は、誠実に応対します。 ただし、必要性と適切性によって提携会社が代わりに応じることができます。
        <br />
        2. Easy Travel Careは、次の各号の1に該当する場合、サービスの全部または一部を制限 したり中止することができます。
        <br />
        カ. システム及び設備の保守等。
        <br />
        ナ. サービスの根幹となる基幹通信事業者の網サービスの提供中止。
        <br />
        ダ. 国家非常事態、停電、通信網接続の輻輳等その他の不可抗力的な場合。
        <br />
        3. EASY TRAVEL CAREがサービスの提供を制限または中止したときは、その理由及び 制限期間等を第5条1項の方法により遅滞なく会員及び加入者に通知します。
        <br />
        4.新規加入の有効期間開始以前の既往症、つまり既存に保有していた疾病、或いは既存に発生した事故や疾病に起因する症状及びその合併症がある場合、加入が制限され、又はEasy Travel Careの保険金支払い義務が免責されることがあります。
        <br />
        5. Easy Travel Careはサービスの提供及び紛争予防のため、通信上の会話内容を保存保管し、 監視する権利を有します。
        <br />
        6。 加入者は、本手続きおよび提供されたサービスに関し、紛争発生時に証明資料として保存保管された内容を使用することに明示的に同意したものです。
        <br />
        7. Easy Travel Careが円滑かつ正確なサービス提供のために要請する場合、加入者は医療記録 (旅行前に発生したものも含め、どのような種類でもEasy Travel Careがサービスを提供する ために必要だと判断した全ての記録)を作成して、Easy Travel Careセンターに提出しなければならず、もし提出を拒否して円滑で正確なサービスが履行されなかった場合は、Easy Travel Careはこれに対して責任が免除されます。
        <br />
        8。 Easy Travel Careは、加入者に発生した事故や疾病等の事件により、加入者本人に提供されるサービスに関する個人、法的個体、公共機関を含む第三者に対する加入者の全ての権利と行動を直ちに、そして自動的に代理して行使する権利があり、加入者はこのような権利と活動を委譲し、これを取り消すことはできず、またこれに必要な全ての法的手続きに従わなければならず、加入者に要求される全てのことに協力しなければなりません。
        <br />
        9. Easy Travel Careサービスのうち、旅行者安心保険については 「プレミアムパス」が提携している保険会社の規定と約款に基づいて支払われます。
        <br />
        10. Easy Travel Careサービスは安心観光コンシェルジュサービス基盤であるため、旅行者保険金に該当する保障は「プレミアムパス」の提携保険会社を通じて保障を受けることができ、コンシェルジュサービスの提供によって実際に発生する費用については会員が負担します。
        <br />
        <br />
        <h6>第9条 会員及び加入者の義務及び権利</h6>
        1. 会員及び加入者は、本約款に規定する事項及びサービス利用案内又は注意事項等、Easy Travel Careが告知する事項を遵守しなければなりません。
        <br />
        2. 会員及び加入者は、個人識別情報の不正使用が発生しないよう注意しなければならず、不正使用を知った場合は、直ちにEasy Travel Careに通知して被害防止に協力しなければなりません。
        <br />
        3. 会員及び加入者は、サービスに関する権利義務の一部又は全部を第三者に譲渡し、又は担保にすることはできません。
        <br />
        4. 会員及び加入者は、法律で定める場合を除き、本人の同意なく本人の個人情報が第三者に提供、利用されることを拒否する権利があります。
        <br />
        <br />
        <h6>第10条 加入の取消し及び中途解約</h6>
        1. Easy Travel Care登録証明書に記載された開始日以前には、いつでも契約の解約(取消)ができ、すでに納付された登録費全額が払い戻されます。
        <br />
        2. サービス期間の変更や取消は、開始日以前のみ可能となります。
        <br />
        3. サービス開始中であっても、個人識別情報の不正及び悪意、故意の事故発生の恐れがあると「プレミアムパス」が判断した場合、サービスを中途解約することができます。
        <br />
        4. 契約証明書に記載された開始日以降の解約の際は(中途解約)、使用期間に該当する契約費を差し引いた全額を払い戻し、差し引き額の算定は保険短期料率とサービス料料率に従います。 加入費返還請求権は加入資格を喪失した日から3年間行使しないと消滅時効になります。 ただし、保険料返還請求権に関する規定は保険業法を準用します。
        <br />
        5. 取り消し及び中途解約事由による提出書類
        <br />
        カ. 開始日以前のキャンセル: キャンセル申請書又は電子メールによるキャンセルの意思表示通帳のコピー
        <br />
        ナ. 単純変心による中途解約: 解約申請書 パスポートのコピー 通帳のコピー (解約申請書の受付日を基準に払い戻し)
        <br />
        ダ。 早期帰国による中途解約: パスポートのコピー通帳のコピー帰国航空券E-TICKETのコピー、または出入国事実証明書(E-TICKETの場合、加入保険期間内の再入国日程が含まれた書類の認定不可、出入国事実証明書の場合は加入保険期間内の最終帰国日を基準に還付)
        <br />
        <br />
        <h6>第11条 旅行の延長によるEasy Travel Care再加入</h6>
        1. 加入後、国内滞在中に加入期間が満了し、再加入または延長しなければならない場合、加入者は加入期間満了前にSOS申請及び電話やメールなどでセンターに再加入または延長意思を表示しなければなりません。
        <br />
        2. Easy Travel Careの案内を問わず以前の加入期間満了日が経過した場合、または以前の加入期間満了日前に再加入手続きが完了しない場合は再加入の意思がないものとみなされ、その後の再加入が制限されます。
        <br />
        3. 再加入の有効期間開始以前の既往証、つまり既存に持っていた疾病、あるいは既存に発生した事故や疾病に起因する症状及びその合併症がある場合、再加入が制限され、またはEasy Travel Careの提携保険会社は保険金の支払い義務が免責されることがあります。
        <br />
        <br />
        <h6>第12条団体保険加入申込等</h6>
        1. Easy Travel Careは、会員及び加入者のために契約者として保険会社と団体保険契約を締結し、会員に提供できます。
        <br />
        2. Easy Travel Careが契約者である団体保険契約に被保険者として加入することを希望する会員は、所定の手続きに従って保険が含まれたプランに加入申請(保険契約)をすることができ、加入手続きが完了した会員は加入者となります。
        <br />
        3. 保険が含まれたプランに加入申請をしようとする会員は、Easy Travel Care提携保険会社の加入案内事項および保険約款などを十分に読んで理解した上で、加入申請をしなければならず、保険会社とEasy Travel Careが告知を要求する事項に対して誠実に応じなければなりません。
        <br />
        4。 保険が含まれたプランを加入する場合、会員が加入する保険の受益者は、保険加入者(被保険者)本人とその法定相続人となります。
        <br />
        5. Easy Travel Careが契約した団体保険に関して、その他、本規約に定められていない事項についてはEasy Travel Careが保険会社と約定した内容に従います。
        <br />
        <br />
        <h6>第13 条旅行保険便宜支援</h6>
        Easy Travel Careプラン内の旅行保険は、契約者が「プレミアムパス」の団体保険で保険料に対する団体割引が適用されており、各プランは最適の保険保障内訳および加入金額になるようになっています。 また、保険会社への保険金請求代行、比例補償保険金受付代行および最終案内をいたします。
        <br />
        <br />
        <h6>第14条 Easy Travel Care 保険サービス支援の制限</h6>
        Easy Travel Careは、その原因の直接または間接を問わず、以下の理由を原因として発生したサービス発生に対してはサポートしないことが原則であり、加入者の要請によりサービス進行時に別途の費用が発生することがあります。
        <br />
        ① 加入証明書に記載されていないサービス内容
        <br />
        ②加入者又は保護者（所属機関含む）の故意又は重大な過失。
        <br />
        ③ 加入者の疾病又は傷害による診断費、治療費、薬剤費等の医療費の一切。
        <br />
        ④ 死刑または加入者の自害、自殺、自殺未遂、刑法上の犯罪行為または暴力行為。 ただし、刑法上正当行為、緊急避難及び正当防衛と認められる場合は支援します。
        <br />
        ⑤ 加入者の心神喪失または精神疾患。
        <br />
        ⑥ Easy Travel Careあるいは提携機関の承認なしに加入者が他の医療機関から受けた治療の結果生じた傷害、疾病又は合併症。
        <br />
        ⑦ Easy Travel Careの加入者ではなく、その同伴旅行者に発生した一切の費用。
        <br />
        ⑧ 加入者が伝染病発生国に対する政府の旅行禁止または予防接種措置に応じないで当該国を旅行して帰ってから発生した伝染病。
        <br />
        ⑨ 加入者が自発的に戦争、外国の武力行使、革命、内乱、事変、暴動、騒擾、労働争議、その他これらに類する事態が発生した地域に入って発生したサービス内容
        <br />
        ⑩ 核燃料物質（使用済燃料を含みます。 以下同じです）又は核燃料物質によって汚染された物（原子核分裂生成物を含む。）の放射性、爆発性又はその他の有害な特性による事故。（その他の放射線を浴びるもの又は放射能の汚染を含む。）
        <br />
        ⑪ 偶然かつ急激な事故や疾病に起因しない妊娠、出産、流産または外科的手術その他の医療処置であるか、旅行の目的自体が既存の傷害または疾病治療等の医療行為であることが確認された場合。
        <br />
        ⑫ 地震、噴火、津波又はこれと類する天災地変
        <br />
        ⑬ その他本約款上の免責事由
        <br />
        <br />
        <h6>第15条紛争の解決</h6>
        1. Easy Travel Careと加入者は、サービスに関して紛争が発生した場合、これを円満に解決するよう努めなければなりません。
        <br />
        2. 前項の規定にかかわらず、当事者間の紛争により訴訟が提起される場合、加入者とEasy Travel Careとの間の契約関係に関するすべての法的事項については、大韓民国の裁判所を合意に基づく管轄裁判所とします。
        <br />
        3.契約に関して紛争がある場合には大韓民国の法律に基づき、保険と関連した紛争が発生した場合には提携保険会社の普通約款および特別約款の内容に従います。
        <br />
        <br />
        PREMIUMPASS International Corp. ソウル市江西区麻谷中央4路22(麻谷洞)ファインスクエアA棟501号
        <br />
        PREMIUMPASS International Corp. ケアセンター +82-2 2063-3544
        <br />
        PREMIUMPASS International Corp. ケアセンターEメール care@tothepp.com
        <br />
        PREMIUMPASS International Corp. ケアセンター カカオトークID (@Easy Travel Care CENTER)
        <br />
        PREMIUMPASS International Corp. 加入相談 1644-1493
        <br />
        PREMIUMPASS International Corp. FAX +82-2-2138-0164
        <br />
        <br />
      </>
      <h6>{language['현대해상 화재보험 약관']}</h6>
      <>
        <h6>（必須）個人（信用）情報処理のための同意</h6>
        <h6>個人（信用）情報の収集利用に関する事項</h6>
        個人信用情報の収集利用目的
        <br />
        - 保険契約の引受審査締結の維持管理(復活及び更新を含む) <br />
        - 保険金等の支払審査 <br />
        - 純保険料率の算出検証 <br />
        - 苦情処理及び紛争対応 <br />
        - 適否および事故調査(保険詐欺調査を含む) <br />
        - 保険募集秩序の維持 <br />
        - 金融取引関連業務(金融取引申請、自動振替など) <br />
        <br />
        収集利用する個人（信用）情報の内容
        <br />
        - 個人識別情報(氏名、住民登録番号、外国人登録番号、パスポート番号、住所、性別、職業、電話番号、電子メールアドレス)
        <br />
        - 金融取引業務（保険料及び保険金等の出納・受納）関連情報
        <br />
        <br />
        個人（信用）情報保有及び利用期間
        <br />
        - 収集利用同意日から(金融)取引終了後 5年まで
        <br />
        <h6>個人（信用）情報の照会に関する事項</h6>
        個人信用情報の照会目的
        <br />
        - 保険契約の引受審査の締結維持管理(復活及び更新を含む)、保険金等の支給、審査
        <br />
        保険事故調査(保険詐欺調査を含む)
        <br />
        <br />
        照会する個人（信用）情報
        <br />
        - 信用情報集中機関(生命保険協会、損害保険協会、韓国信用情報院)及び保険料率 算出機関が収集管理する保険契約情報、保険金支給に関する情報(事故情報を含む) 被保険者の疾病及び傷害に関する情報
        <br />
        <br />
        照会同意の有効期限及び個人(信用)情報の保有及び利用期間
        <br />
        - 収集利用同意日から取引種類後5年まで(ただし、取引終了後5年が経過した後は、保険金の支払い、金融事故調査、保険詐欺防止摘発、苦情処理、法令上の 義務付けのための場合に限り保有及び利用し、別途保管)
        <br />
        <br />
        <h6>（必須）保険契約締結のための同意</h6>
        <h6>団体保険に関する規約同意</h6>
        第一条（目的）
        <br />
        この規約は、(株)プレミアムパスインターナショナル会員のために提供するサービス利用に関して、会員間協約事項をその目的とします。
        <br />
        <br />
        第二条（団体保険加入の申込み等）
        <br />
        1. (株)プレミアムパスインターナショナルは会員のために契約者として保険会社と団体保険契約を締結することができます。
        <br />
        2. (株)プレミアムパスインターナショナルが契約者である団体保険契約に被保険者として加入することを希望する会員は所定の手続きに従って保険加入を申請します。
        <br />
        3. 保険加入申請をしようとする会員は保険会社と(株)プレミアムパスインターナショナルが告知を要求ずる事項に対して誠実に答えます。
        <br />
        <br />
        <h6> 保険契約の締結、履行等のための個人(信用)情報処理に関しての同意 </h6>
        保険契約の締結、履行等のための個人(信用)情報処理に関しての同意個人信用情報の収集利用に関する事項 個人情報保護法及び信用情報の利用及び保護に関する法律に基づき、本契約と関連して お客様の個人(信用)情報を次のように収集·利用したいと思います。 以上について同意いたしますか？
        <br />
        <br />
        個人信用情報の収集利用目的
        <br />
        - 保険契約の引受審査締結の維持管理(復活及び更新を含む)
        <br />
        - 保険金等の支払審査
        <br />
        - 純保険料率の算出検証
        <br />
        - 苦情処理及び紛争対応
        <br />
        - 適否および事故調査(保険詐欺調査を含む)
        <br />
        - 保険募集秩序の維持
        <br />
        - 金融取引関連業務(金融取引申請、自動振替など)
        <br />
        <br />
        収集利用する個人（信用）情報の内容
        <br />
        - 個人識別情報(氏名、住民登録番号、外国人登録番号、パスポート番号、住所、性別、職業、電話番号、電子メールアドレス)
        <br />
        - 金融取引業務（保険料及び保険金等の出納・受納）関連情報
        <br />
        <br />
        個人（信用）情報保有及び利用期間
        <br />
        - 収集利用同意日から(金融)取引終了後 5年まで
        <br />
        <br />
      </>
      <h6>{language['이용약관']}</h6>
      <>
        <h6>第１条（目的）</h6>
        この規約は、プレミアムパスインターナショナル（以下「会社」といいます）の運営するイージートラベルケア（easytravelcare）インターネットサイト（以下「サイト」といいます）を通じ、
        <br />
        提供する電子コマースのサービス（以下「サービス」といいます）を利用することにとって、会員の権利と義務及び責任事項を
        <br />
        規定することを目的にします。
        <br />
        <br />
        この規約は、PC通信、スマホ（Androidフォン、iPhoneなど）アプリ、無線などを利用する電子コマースについても、その
        <br />
        性質に反しない限り、準用されます。
        <br />
        <br />
        <h6>第２条（用語の正義）</h6>
        規約から使用する用語の定義は、次のようであります。
        <br />
        1.「サイト」とは、会社に会員として登録した利用者が
        <br />
        様々な情報とサービスを提供されるように会社が製作、運営するインターネットウェブサイトを意味します。
        <br />
        現在、会社の運営する「サイト」(2020.01 現在)は、
        <br />
        次のようであります。
        <br />
        *www.easytravelcare.co.kr
        <br />
        - 後ほど、会社からお知らせし、提供する他のウェブサイト。
        <br />
        <br />
        2.販売者とは、商品または、サービスのなだらかな販売のために、「会社」から販売代行サービスを提供される者を
        <br />
        いいます。但し、オープンマーケットのサービスの場合は、「会員」が購買した商品及びサービスなどの取引情報についての <br />
        <br />
        直接的な責任を負う者をいいます。
        <br />
        <br />
        3.「会員」というのは、「サイト」（以下「会社」）に、
        <br />
        個人情報を提供し、会員登録をした使用者として、
        <br />
        会員登録の時、設定したIDで、「サイト」に自由に
        <br />
        接続し、会社が提供する情報を持続的に提供されるにしろ、
        <br />
        「会社」のサービスを、頻りに利用できる使用者のことを
        <br />
        いいます。
        <br />
        <br />
        4.「商品」というのは、「会社」が 「サイト」を通じ、
        <br />
        会員に販売する商品または、クーポンなどをいいます。
        <br />
        <br />
        5.「ID」というのは、「会員」の識別とサービスの利用のために、
        <br />
        「会員」が選定し、会社が承認して、登録した文字または、数字の組合をいいます。
        <br />
        <br />
        6.「パスワード」というのは、「会員」の同一性の確認と「会員」 の権益及び、秘密保護のために、「会員」自らが設定し、
        <br />
        「サイト」に登録した文字と数字の組合をいいます。
        <br />
        <br />
        7.予約の確認バウチャー：商品とサービス（以下「商品 など」といいます）を購買した価格で利用できるデジタル証券と
        <br />
        いいます。
        <br />
        <br />
        8.掲示板：「会員」が「サービス」を利用することにおいて、
        <br />
        「サイト」上に、掲示した符号、文字、音声の形の字、写真、動画及び各種のファイルとリンクなどを意味します。
        <br />
        <br />
        9.「チャット」というのは、商品などを購買の後、緊急サービス
        <br />
        のご相談をご希望の時、使用できるチャットサービスといいます。
        <br />
        <br />
        10.この規約から正義されていない用語は、関連法令が伝える
        <br />
        ことに従います。
        <br />
        <br />
        <h6>第３条（規約などの明示と説明及び改正）</h6>
        1.「会社」は、この規約の内容と会社の「商号」、営業所の所在地、代表者の氏名、事業者登録番号、連絡先（電話番号、ファックス番号、電子メールアドレスなど）、通信販売業の 申告番号、個人情報管理責任者などと共に、会員が簡単に 確認できるように、「サイト」の初期「サービス」の画面（前面）に掲示する。但し、規約の具体的な内容は、会員が連結 画面を通じて見られます。
        <br />
        <br />
        2.「会社」は、規約の規制に関する法律、電子コマースなどの 消費者保護に関する法律、電子文書及び、電子取引の基本法、 電子署名法、情報通信網の利用促進などに関する法律、消費者基本法、電子金融取引法などの関連法令を反しない限りの 範囲からこの規約を改正できます。
        <br />
        <br />
        3.「会社」が規約を改正する場合においては、適用日付及び、改正理由を明示し、現行規約と共に「サイト」の公知事項または、初期の画面にその適用日付の7日以前から適用日付の前日までお知らせする。但し、「会員」に重大な影響を与える不利な内容に変更する場合は、30日以前からお知らせします。
        <br />
        <br />
        4.前項により、改正規約は、適用日付の以降に締結された 契約だけ適用され、法令に特別な規制または、他のやむを得ない事由がない限り改正規約の適用日付の以前に遡及し、 適用されません。
        <br />
        <br />
        5.前項によって、公知された適用日付の以降に、会員が 「会社」の「サービス」を、頻りに利用する場合においては、 改正された規約に同意することにする。 改正された規約に同意しない「会員」は、いつでも自由に 「サービス」の利用契約が解約できます。
        <br />
        <br />
        6.この規約に明示しない事項と、この規約の解釈に関しては、 電子コマースなどでの消費者保護に関する法律、規約の規制 などに関する法律、公正取引委員会が定める電子コマース などでの消費者保護指針と関連法令の規正と一般の商慣習に 従います。
        <br />
        <br />
        <h6>第4条（個別サービス利用規約及び、利用条件）</h6>
        「会社」は提供する「サービス」内の「個別サービス」に 関する別の利用契約及び、利用条件を各の「サービス」別に し、「個別サービス」から別途に適用される規約においての 同意は、「回当サービス」を最初に利用する場合、別途の同意手続きを経った後、利用し、「サービス」の内容を同意したことにし、この場合、「個別サービス」においての利用規約などは、本、規約に 優先します。
        <br />
        <br />
        <h6>第5条（サービス提供の中止）</h6>
        1.「会社」は、次の各号に当てはまる場合、「サービス」の提供が 中止できます。
        <br />
        1)「サービス」利用設備の保守点検、交替及び、故障などの工事に よるやむを得ない場合。
        <br />
        2)電気通信事業法に規定された基幹通信事業者が電気通信「サービス」 を中止した場合。
        <br />
        3)他の不可抗力的な事由がある場合。 <br />
        <br />
        2.「会社」は、国家非常事態、停電、「サービス」設備の障害または、
        <br />
        「サービス」利用の暴注など、正常的な「サービス」の利用に
        <br />
        支障がある時は、「サービス」の全部または、一部を制限するにしろ、停止できます。
        <br />
        <br />
        <h6>第6条（利用契約の成立）</h6>
        1. 利用契約は、「会員」の規約同意、利用のお申し込みに対する 「会社」の承認後、成り立ちます。
        <br />
        <br />
        2. 利用契約の成立は、「会社」が登録完了の表示を、お申し込み 手続きの上から承認または、表した起点にします。
        <br />
        <br />
        <h6>第7条（利用お申し込み及び、承諾）</h6>
        1.「会社」は、次の各号に当てはまる利用のお申し込みに対しては、 承認を拒否したり、留保及び、事後、利用契約が解約できます。
        <br />
        1) 既に登録された「会員」の氏名及び、電子メールアドレスが 同一な場合。
        <br />
        2) 他人の名義を盗用したり、虚偽表示、記載漏れなどがある場合。
        <br />
        3) 登録内容に虚偽の情報を記載したり、瘦せ我慢がある場合。
        <br />
        4) 利用申請書が、この規約によって、以前に会員の資格を喪失した ことがある場合。
        <br />
        5)「会社」により、利用資格の喪失後、、三ヶ月が経った後、「会社」 の会員再登録の承諾を得る場合にあたっては、例外にする。
        <br />
        6)否定した用途または、営利を追求する目的として、「サービス」を 利用しようとする場合（但し、「会社」が承認した範囲内での サービス利用は例外にする。）
        <br />
        7)「サービス」の運営によっての関連設備の余裕がなかったり、 技術上または、業務上の支障または、問題がある場合、但し、 その事由が解決されるまで承諾が留保できる。
        <br />
        8)関連法令、美風良俗または、社会の安寧秩序及び、他の諸般事項を 違反し、申し込んだり、会員の帰責事由に応じて、承認が不可能な 場合。
        <br />
        9)他のこの規約に違反されたり、違法または、不当な利用申請が ご確認された場合、「会社」が合理的な判断によって、必要だと 認める場合。
        <br />
        <br />
        3. 第1項による利用申請にあたって、「会社」は専門機関を通じ、実名確認及び、本人認証が要請できます。
        <br />
        <br />
        4. 「会社」は「会員」に対する利用回数、「サービス」のメニューなどを細分し、会社の政策に従って、等級別に分けてあります。
        <br />
        <br />
        5.「会社」とは、「会員」について青少年保護法及び、ビデオ物の 振興に関する法律などによる年齢遵守及び、等級によって、利用 制限や等級別制限することができます。
        <br />
        <br />
        <h6>第8条（サービスの利用時間）</h6>
        「サービス」の利用は、「会社」の業務または、技術上に特別な支障がない場合、365日1日24時間を原則的にします。但し、定期点検の事由で、「会社」がお知らせした日または、時間は除外されます。
        <br />
        <br />
        <h6>第9条（会員のID及び、パスワードについての義務）</h6>
        1.「ID」と「パスワード」に関する管理の責任は、全的に「会員」に あり、これをおろそかにして、発生した全ての民.刑事上の責任は「会員」の自らにあります。
        <br />
        <br />
        2.「会員」は、自らの「ID」及び、「パスワード」を第3者に貸与 及び、利用させてはいけない。
        <br />
        <br />
        3.「会員」は、自らの「ID」及び、「パスワード」を盗難または、第3者が使用しているのを認知した場合、直ちに、「会社」に通知し、 「会社」の措置がある場合にあたって、それに従わなければなりません。
        <br />
        <br />
        4.「会員」が、前項による通知をしなかったり、「会社」の措置に 応じなくて、発生する全ての不利益についての責任は、全的に 「会員」にあります。
        <br />
        <br />
        <h6>第10条（利用契約の解約及び、終了）</h6>
        1.「会員」の解約
        <br />
        1)「会員」は、いつでも「会社」に連絡し、解約の意思を通知するこ とにとって、利用契約を解約（会員脱退を要請）できるので、「会 員」の勘定登録資料の削除が要請できます。
        <br />
        2) 利用契約は、「会員」の解約意思が「会社」に到達した時に終了されます。
        <br />
        3)前項により、解約をした「会員」（脱退した会員）は、「会社」が 定めた規正規約により、会員登録手続きを通し、会員として、再び 登録できますが、解約（脱退）以前の会員登録の時、適用された 等級、積金、ポイントなどの保有条件は、消滅され、払い戻しなど 適用されません。 但し、再登録を日程期間の間は際限できます。
        <br />
        <br />
        2.会社の解約 「会社」は、次のような事由がある場合、利用契約が解約できるし、 該当「会員」に対する保有情報は、個人情報取扱い方針に従います。
        <br />
        1) 第7条の第1項から定めている利用契約の承諾拒否事由があるのが 確認された場合。
        <br />
        2)「会員」が「会社」または、他の「会員」、その他の他人の権利 や名誉、信用以外の正当な利益を侵害したり、善良な風俗、他の 社会秩序、大韓民国の法令に違反される行為をした場合。
        <br />
        3)「会社」が販売する「商品」または、「サービス」について、確 認されていない事実と内容に不信を助長する行為をした場合。
        <br />
        4) 取引秩序を妨害する目的で、重複購買など、サイトのなだらかな 進行を妨害する行為を試みた場合。
        <br />
        5) 他の「会社」が合理的な判断による「会員」がこの規約に違反される行為をした場合。
        <br />
        6)「会社」は、電子メール、電話、ファックス他の方法を通じ、解約の意思を明らかにして、会員に解約の事由をお知らせします。 但し、会社は、該当の会員に前もって解約の事由についての意見陳述の機会を付与します。
        <br />
        <br />
        3.本条項により、利用契約が終了される場合、「会員」は、サービスを 利用する権限がなくなり、会員の個人情報及び、記録などは全部削除 され、前もって購買する商品などの払い戻し処理に関しては、会社の 払い戻しの規正に依拠します。
        <br />
        <br />
        4.利用契約の終了と関連し、発生した損害は、利用契約が終了された 該当「会員」が責任を負うべきであり、「会社」は一体の責任を負いません。
        <br />
        <br />
        <h6>第11条（会員の義務）</h6>
        1.「会員」は、関連法令、本の規約規定、利用案内など「会社」が告知 する内容を遵守しなければならず、他の「会社」の業務に妨害する行為をしてはいけません。
        <br />
        <br />
        2.「会員」は、「サービス」の利用と関連して、つぎの各号の行為を してはいけません。
        <br />
        1)「サービス」のお申し込みまたは、変更時、虚偽の内容の登録。
        <br />
        2) 他人の情報盗用など。
        <br />
        3)「会社」が掲示した情報の許可を受けていない変更。
        <br />
        4)「会社」が定めた情報以外の情報（コンピュータープログラムなど） の送信または、掲示。
        <br />
        5) 「会社」及び、他の第3者の著作権、肖像権など、知識の財産権に 対する侵害。
        <br />
        6) 「会社」及び、他の第3者の名誉を損傷させたり、業務を妨害する 行為。
        <br />
        7) わいせつまたは、暴力的なメッセージ、動画、音声他の公共秩序、 美風良俗に反する情報を「サイト」に公開または、掲示する行為。
        <br />
        8) 他の関連法令や「会社」で、定めた規定に違反される行為。
        <br />
        <br />
        <h6>第12条（会社の義務）</h6>
        1.「会社」は、法令と、この規約を禁止したり、公序良俗に反する 行為をせずに、この規約が定めることによって、持続的で、安定的な「サービス」を提供するために、最善を尽くさなければなりません。
        <br />
        <br />
        2.「会社」は、会員が安全に「サービス」が利用できるように、「会員」の個人情報（信用情報含め）保護のためのセキュリティシステムを備えなければならず、個人情報取り扱い方針を公示して、 遵守するのである。
        <br />
        <br />
        <h6>第13条（購買申請及び、個人情報提供の同意など）</h6>
        1.「会員」は、「サイト」上から、次のような、これに類似の方法によって、購買を申請し、「会社」は利用者が購買申請をするにあたって、次の各内容を分かりやすく提供しなければなりません。
        <br />
        1) 商品などの検索及び、選択
        <br />
        2) 商品利用に関する予約情報の入力（日付）
        <br />
        3) 予約者とサービスを受ける人の氏名、国籍、パスポート番号、 住民登録の個人別固有番号、電子メールアドレス（又は、携帯電話）などの入力。
        <br />
        4)各、商品別の構成、包含事項及び、規約内容、請約撤回権が 際 限されるサービスなどの費用負担と関する内容についての確認。
        <br />
        5)この規約に同意し、上4号の事項を確認したり、拒否する表示。 （例：マウス クリックなど）
        <br />
        6)商品などの購買申請及び、これに関する確認又は、「会社（サイト）」確認についての同意。
        <br />
        7)決済方法の選択
        <br />
        <br />
        2.会社は会員の購買申請がある場合、会員に購買確認通知をし、こ の通知が到達した視点に購買契約が成立します。
        <br />
        <br />
        3.「会社」が第3者に購買者の個人情報を提供、提供する必要がある場合、提供される個人情報を提供受ける者、個人情報を提供される者の個人情報の利用目的、提供する個人情報の項目、個人情報を提供受ける者の個人情報保有及び、利用期間を購買者に知らせ、 同意を受けなければなりません。（同意を受ける事項が変更される場合にも同じである。）
        <br />
        <br />
        4.「会社」が第3者に、購買者の個人情報を扱えるように、業務を委託する場合は、個人情報の扱い委託を受ける者、個人情報の扱い 委託をする義務の内容を購買者に知らせ、同意を受けなければなりません。（同意を受ける事項が変更される場合にも同じである。）但し、サービスの提供に関する契約移行のため、必要だし、 購買者の便宜増進と関連する場合は、情報通信網利用促進及び、 情報保護などに定めている方法で、個人情報の扱い方針を通じて、 知らせることにとって、告知手続き、同意手続きを踏まなくても いいです。
        <br />
        <br />
        <h6>第14条（契約の成立）</h6>
        1.「会社」と「会員」は、各購買商品の予約確定まで、かかる時間について、各商品ページへの記載内容を遵守するのに同意します。
        <br />
        <br />
        2.「会社」は、第13条のような、「会員」の購買申請について、次の
        <br />
        各号に当てはまると、承諾しない場合があります。
        <br />
        1) 申請内容に虚偽、記載漏れ、意地のある場合。
        <br />
        2) 他の購買申請に承諾することが「会社」の技術上に、著しく支障があると判断する場合と、情報誤記載、情報漏れ、システムミス、 品切れなどの事由で、購買申請した「商品」と「サービス」提供不可の場合、但し、この場合は、「会社」は「会員」に、これを 事後 知らせ、必要な措置を移行しなければならない。
        <br />
        3) 第3者のID、名義又は、決済手段を盗用し、購買申請する場合。
        <br />
        4) 再販売の目的又は、「商品など」を購買申請の状況上、不当な目的に購買したことが疑われる場合。
        <br />
        5) 購買意思なしに、商品の購買と返品を無分別に繰り返し、他の利用者の購買機会を剝奪する場合。
        <br />
        6) 「会社」が提供する割引クーポン、イベントなどを利用して、非常的な取引をした場合。
        <br />
        7) 関連法令に違反されたり、社会の安寧秩序あるいは、美風良俗を 阻害するおそれのある目的に申し込んだ場合。
        <br />
        8) 他のこの規約に違反されたり、悪用使用とする不当な利用申請であることが確認された場合及び、「会社」が合理的な判断により、 必要があると認める場合。
        <br />
        <br />
        3.「会社」の承諾が、第19条 第1項の規定による、受信確認通知の形で、「会員」に到達した時点で、契約が成立したと思います。
        <br />
        <br />
        4.「会社」の承諾の意思表示には、「会員」の購買申請に対する確認 及び、販売可能の可否、購買申請の訂正取り消しなどに関する情報などを含める必要があります。
        <br />
        <br />
        <h6>第15条（決済方法）</h6>
        1.「サイト」で購買した商品または、サービスに対する代金の支払い方法は、次の各号の方法中、利用可能な方法で行うことができます。 但し、「サイト」は、利用者の支払方法について、財貨サービスなどの代金にいかなる名目の手数料も追加して、徴収することはできません。
        <br />
        1) 現金、クレジットカードなど。
        <br />
        2) 各種のPay
        <br />
        3) 他の「会社」が追加指定する決済手段及び、電子的支払方法による代金支払など。
        <br />
        2.「会員」が購買代金の決済と関して、入力した情報及び、その情報 と関して、発生した責任と不利益は全的に会員負担となります。
        <br />
        <br />
        3.「会社」は、「会員」が購買代金の決済時、使用した決済手段に 対する、本人情報の確認又は、正当な使用を勧める有無について確認でき、これに対する確認が完了するまで、取引を中断したり、確認が不可能な場合、該当取引は、取引又は、払い戻し保留などができる。
        <br />
        <br />
        4.「会社」は、「会員」が購買した商品の購買した商品の購買証明書 （現金領収証など）を要請すれば、実購買額で発行します。
        <br />
        <br />
        <h6>第16条（予約確認証または、バウチャー転送）</h6>
        1.「会社」は「会員」に購買した「サイト」の商品の詳細内容をSNS 又は、電子めーるなどの方式で発送します。
        <br />
        <br />
        2.「予約確認証または、バウチャー」伝送作業は、祝日と「会社」の 勤務時間と休業日または、処理過程の状況によって、変更される場合があり、これは、サイト内の該当コンテンツに明示された内容に従います。
        <br />
        <br />
        <h6>第17条（受信確認通知、購買申請変更及び、取り消し）</h6>
        1.「サイト」は「会員」の購買申請がある場合、「会員」に受信確認通知をします。
        <br />
        <br />
        2. 受信確認通知を受けた「会員」は、意思表示の不一致などがある場合は、受信確認通知を受けた後、直ちに、購買申請変更及び、 キャンセルを要請することができ、「サイト」は予約確定の前に、 「会員」の要請がある場合は、遅滞なくその要請に応じて、処理しなければなりません。但し、既に代金を支払った場合は、第20条の申し込みの撤回などに関する規定に従います。
        <br />
        <br />
        <h6>第18条（請約撤回など）</h6>
        1.「会社」の購買取り消し及び、請約撤回の規定は、電子商取引など における消費者保護に関する法律など、関連法令を遵守します。
        <br />
        <br />
        2.「会員」は商品を引き渡された（アップロード含め）場合、次の 各号の1に該当する場合は、返品及び、交換できません。
        <br />
        1) 引き渡された（アップロード含め）データを「会員」の責任ある 事由により、滅失または、毀損された場合。
        <br />
        2) 引き渡された（アップロード含め）データを一定期間（販売者設定期間）が経過した場合。
        <br />
        3) 引き渡された（アップロード含め）データを複製可能な場合。
        <br />
        <br />
        3.「会員」の単純な心変わりによるキャンセル及び、払い戻しの場合、 異議処理に発生する手数料は、会員負担となります。
        <br />
        <br />
        4.「会員」は「商品など」の内容が表示、広告内容と異なる場合や契 約内容と異なる場合は、その商品などを供給受けた日から30日以内 に、その事実の請約を撤回することができます。
        <br />
        <br />
        5.クレジットカード又は、電子マネーで決済した「会員」の場合、 遅滞なく、当該決済手段を提供した事業者に対し、代金の請求を 停止または、取り消すよう要請します。
        <br />
        <br />
        6.購買をキャンセルした場合、既に決済していた内訳をキャンセルし、 キャンセル時点によって、予約のキャンセル料を再承認する。 この場合、購買取り消し時点と該当カード会社の払い戻し処理基準によって、キャンセル金額の払い戻し方法と払い戻し日は、多少異なる 場合があり、使用済みクレジットカードの払い戻しポリシーについては、クレジットカード会社に直接お問い合わせください。
        <br />
        <br />
        7.「会員」が他人の個人情報を盗用し、本規約で禁止する不正な方法に より、予約後不当な利益詐取と疑われる場合、「会社」は「会員」のチケットの購買をキャンセル処理または、制限することができ、 「会員」が十分な釈明資料を提出するまで、払い戻しを保留することができます。
        <br />
        <br />
        8.その他、この規約及び、「サイト」の利用案内に規定されていない キャンセル及び、払い戻しに関する事項については、消費者被害補償 規定で定めるところに従います。
        <br />
        <br />
        <h6>第19条（商品の払い戻し特約）</h6>
        1.「会員」はサービス購買後、次の各号に該当する場合、払い戻し または、交換を要請することはできません。
        <br />
        1) サービス開始後の単純な心変わりは払い戻し対象から除外され、 各「販売者」の販売規定に従います。
        <br />
        2) 本製品の使用期間と、前もって時間がお客様の選択によって制限された場合。
        <br />
        <br />
        2.「会社」が商品の返品手続きに従って、返金する場合、「販売者」 又は、「保険会社」との協議を終えたことを確認した後、 払い戻しが行われます。
        <br />
        <br />
        <h6>第20条（個人情報保護）</h6>
        1.「サイト」は「会員」の個人情報収集時に、サービス提供のために、 必要な範囲で最小限の個人情報を収集します。
        <br />
        <br />
        2.「サイト」は会員登録の際、購買契約の履行に必要な情報を事前に 収集しません。但し、関連法令上の義務付けのために、購買契約の 以前に本人確認が必要な場合として、最小限の特定個人情報を 収集する場合はこの限りではありません。
        <br />
        <br />
        3.「サイト」は「会員」の個人情報を収集、利用する時には、当該 利用者にその目的を告知し、同意を得ます。
        <br />
        <br />
        4.「サイト」は収集された個人情報を目的以外の用途で利用すること ができず、新たな利用目的が発生した場合または、第3者に提供す る場合には、利用提供段階で、当該「会員」に、その目的を告知し、 同意を得ます。但し、関連法令に異なる定めがある場合は、例外に します。
        <br />
        <br />
        5.「サイト」は、第2項と第3項により、利用者の同意を得なければ ならない場合。 個人情報管理責任者の身元（所属、氏名または、電話番号、その他の連絡先）、情報の収集目的及び、利用目的、第3者に対する情報提供関連事項（提供された者、提供目的及び、提供する情報の内容）など、情報通信網の利用促進及び、情報保護などに関する、法律 第２２条第2項が規定する事項をあらかじめ明示し、又は、告知しなければならず、「会員」は、いつでも移動意思を撤回することが できます。
        <br />
        <br />
        6.「会員」は、いつでも「会社」が持っている自分の個人情報に対し て、閲覧及び、エラー訂正を要求することができ、「会社」は これに対して、遅滞なく、必要な措置を取る義務を負います。 「会社」がエラーの訂正を要求した場合は、「会社」はそのエラー を訂正するまで、当該個人情報を利用しません。 但し、閲覧及び、エラー訂正の手続きは、「会社」の定めるところに従います。
        <br />
        <br />
        7.「会社」は、個人情報保護のために、「会員」の個人情報を取り扱う者を最小限に制限しなければならず、クレジットカード、銀行口座 などを含む会員の個人情報の紛失、盗難、流出、同意のない第3者 提供、変造などによる、「会員」の損害に対して、全ての責任を負います。
        <br />
        <br />
        8.「会社」または、それから個人情報の提供を受けた第3者は、個人情報の収集目的又は、提供を受けた目的を達成した時は、当該個人情報を遅滞なく、破棄します。
        <br />
        <br />
        9.「会社」は、個人情報の収集.利用.提供に関する同意欄を、あらかじめ選択したものに設定しておきません。又は、個人情報の収集. 利用.提供に関する、「会員」の同意拒否時に、制限されるサービスを具体的に明示し、必修収集項目ではなく、個人情報の収集.利用. 提供に関する「会員」の同意拒否を理由に会員登録などのサービス 提供を制限したり、拒否したりしません。
        <br />
        <br />
        <h6>第21条（著作権の帰属及び、利用制限）</h6>
        1.「サイト」が作成した著作物に対する、著作権その他の知的財産権は「サイト」に帰属します。
        <br />
        <br />
        2.「会員」は「サイト」を利用することで得た情報、イメージ、データの内、「サイト」に知的財産権が帰属する情報を「サイト」の事前承諾なしに、複製、送信、出版、配布、放送、その他の方法により、営利目的で利用し、又は、第3者に利用させてはなりません。
        <br />
        <br />
        3.「会員」は「サイト」で購買した商品のデータ画像を「サイト」の 事前承諾なしに、任意で加工、複製、送信、出版、配布、放送その他の方法により、営利目的で利用したり、第3者に利用させては なりません。
        <br />
        <br />
        <h6>第22条（会員資格の制限又は、停止）</h6>
        1.「会員」は「会社」に、いつでも脱退を要請することができ、「会社」は、直ちに会員脱退を処理します。
        <br />
        <br />
        2.「会員」が次の各号の事由に該当する場合、「会社」は、会員資格を直ちに、制限または、停止させることができます。
        <br />
        1) 会員登録申請または、変更時に、虚偽の内容を登録した場合。
        <br />
        2) 「サイト」を利用して購買した商品などの代金、その他「サイト」 利用に関して、会員が負担する債務を期日に履行しない場合。
        <br />
        3) 他人のサイト利用を妨害したり、その情報を盗用するなど、電子商取引秩序を脅かす場合。
        <br />
        4) 「サイト」を利用して法令、この規約が禁止したり、公序良俗に反する行為をした場合。
        <br />
        5) 「サイト」の健全な運営を害したり、サイトの業務を妨害したりする場合。
        <br />
        A.「サイト」の運営に関して、根拠のない事実と虚偽の事実を 明示したり、流布して「会社」の信頼性を害する場合。
        <br />
        B.「サイト」の利用過程で、「会社」の職員に暴言、脅迫または、 わいせつな言動などで、サイトの運営を妨害する場合。
        <br />
        C.商品などを購買した後、正当な理由なしに、常習的または、 繰り返しにキャンセル.返品して、会社の業務を妨害する場合。
        <br />
        D.「サイト」を通じて、購買の後、商品などに特別な欠陥が ないにもかかわらず、一部使用後に常習的なキャンセル.全部 または、一部返品などで「会社」の業務を妨害する場合。
        <br />
        E.「サイト」を通じて、購買した商品などに、特別な欠陥が ないにもかかわらず、個人的な単純な心変わり、根拠のない 不満などで、「会社」の業務を妨害する場合。
        <br />
        <br />
        3.会社が会員資格を制限.停止させた後、同じ行為が2回以上繰り返されたり、30日以内に、その理由が是正されない場合、「会社」は利用契約を解約することができます。
        <br />
        <br />
        <h6>第23条（免責条項）</h6>
        1.「会社」は天災地変または、これに準ずる不可抗力により、 「サービス」を提供できない場合は、「サービス」提供に対する、 責任が免除されます。
        <br />
        <br />
        2.「会社」は「会員」の帰責事由による、「サービス」利用の障害に ついては、責任を負いません。
        <br />
        <br />
        3.「会社」は「会員」が掲載した情報、資料など、事実の信頼度と 正確性などの内容については、責任を負いません。
        <br />
        <br />
        4.「会社」は「会員」の間、または、「会員」と第３者間で行われた「サービス」に関しては、いかなる責任も負いません。
        <br />
        <br />
        5.「会社」は「会員」が「サービス」を利用して得た、期待収益の 喪失と「サービス」を通じて、得た資料に対する損害に対しては、 いかなる責任も負いません。
        <br />
        <br />
        <h6>第24条（紛争解決）</h6>
        1.「サイト」は「会員」が提起する、正当な意見や不満を反映し、 その被害を補償処理するために、被害補償処理機構を設置、運営 します。
        <br />
        <br />
        2.「サイト」は、利用者から提出される苦情及び、意見は優先的に 処理します。但し、迅速な処理が困難な場合は、利用者に、その 理由と処理日程を、直ちに、通知します。
        <br />
        <br />
        <h6>第25条（裁判権および、準拠法）</h6>
        1.「サイト」は「会員」との間で、電子商取引紛争に関する訴訟は、 提訴当時の「会員」の住所により、住所がない場合、居所を管轄 する地方法院の専属管轄とします。 但し、提訴当時の利用者の住所または、居所が明確じゃなかったり、 外国居住者の場合は、民事訴訟法上の管轄裁判所に提起します。
        <br />
        <br />
        2.「サイト」は「会員」の間に、提起された電子商取引訴訟には、 韓国法が適用されます。
        <br />
        <br />
        付則
        <br />
        1. この規約は、２０２１年1月1日から施行されます。
        <br />
        <br />
        2. この改正規約は、施行日以後に、締結される契約にのみ適用され、 それ以前に、締結された契約に対しては、改正前の規約条項を そのまま適用します。
        <br />
        <br />
      </>
      <>
        <h6>個人情報処理方針</h6>
        （株）プレミアムパス インターナショナル（以下「本社」といいます） は、貴下の個人情報保護を非常に重要視して、「個人情報保護法」を 遵守しております。「本社」は、個人情報処理方針を通じて、貴下が 提供する個人情報がどのような用途と方式で利用されており、個人情報保護のために、どのような措置が取られているかをお知らせします。
        <br />
        <br />
        <h6>1. 処理する個人情報の項目</h6>「本社」は、安心ケア、保険サービス提供のために、必要な 最小限の個人情報だけを収集します。選択項目は、入力しなくても、 サービス利用には制限がありません。
        <br />
        <br />
        <h6>【会員登録時の収集項目】</h6>
        ㅡ必修項目：氏名、イーメール、生年月日、性別、住民（登録の 個人別固有）番号（内国人）、パスポート番号（外国人）、電話番号
        <br />
        ㅡサービス利用過程や、サービス提供業務処理過程で、次のような 情報が自動的に生成され、収集されることがあります。 （サービス利用記録、接続ログ、クッキー、接続IP情報）
        <br />
        <br />
        <h6>2. 個人情報の処理目的</h6>
        「本社」は、収集した個人情報を次の目的のために、活用します。 お客様が提供した全ての情報は、次に記述した目的以外は使用されず、利用目的が変更された場合は、事前同意を求めるつもりです。
        <br />
        ㅡホームページの会員登録及び、管理、本人確認、個人識別、不正使用防止、費認可の使用防止
        <br />
        ㅡお客様の身元把握、金融取引及びサービス業務、本人確認、個人識別、不正使用防止、不認可の使用防止、苦情処理
        <br />
        ㅡ現代海上保険の登録、安心ケアサービス提供、金融取引及び、 サービス業務、料金の取立
        <br />
        ㅡ苦情処理
        <br />
        ㅡサービス広報及び、マーケティング業務
        <br />
        <br />
        <h6>3.個人情報の処理及び、保有期間</h6>
        個人情報の収集目的または、提供された目的が達成された時は、 お客様の個人情報を遅滞なく、破棄します。
        <br />
        ㅡ会員情報：会員脱退及び、除名時まで保有
        <br />
        ㅡ契約または、請約撤回などに関する記録、代金決済及び、財貨など の供給に関する記録: 5年間保有
        <br />
        ㅡ消費者不満、紛争処理: 3年間保有
        <br />
        ㅡサービス広報及び、マーケティング業務のための情報：３年 但し、次は、例外とします。
        <br />
        A.料金精算上の問題で、決済が完了していない場合
        <br />
        B.他の一般会員を保護する必要がありますので、当社が保存する 必要があると判断される場合
        <br />
        C.商法など、他の法令の規定により、保存する必要性がある場合
        <br />
        D.訴訟や、紛争において、根拠資料として、保存する必要性が ある場合
        <br />
        E.その他、個別に利用者の同意を得た場合
        <br />
        F.その他、当社の内部規定により、日程期間保存する必要性が ある場合
        <br />
        しかし、この場合にも正当な根拠と共に、事前に個人情報が保有 される期間を告知して、その後、完全に破棄します。
        <br />
        <br />
        <h6>4.個人情報の破棄に関する事項</h6>
        「本社」は、『個人情報処理目的』が達成されたり、『処理及び、保有期間』が経過した後は、当該個人情報を直ちに、破棄します。 <br />
        ㅡ電子ファイル形式で、保存されている個人情報は、記録を再生できない技術的方法を使用して作成
        <br />
        ㅡ紙に出力された個人情報は、粉砕機で粉砕または、焼却して破棄
        <br />
        <br />
        <h6>5.個人情報の第３者提供に関する事項</h6>
        「本社」は、貴下の同意があったり、関連法令の規定による場合を 除き、貴下の情報を第３者に提供しません。
        <br />
        <br />
        第３者 提供 項目 提供 目的 (ここに( )に書かなくてはならない。決済会社) 提供情報：氏名、電話番号、イーメール、商品名 提供目的：電子決済
        <br />
        現代海上提供情報：氏名、住民登録番号、パスポート番号 （外国人）、性別、電話番号、イーメール、、商品名 提供目的：保険の登録
        <br />
        ㅡ個人情報の保有期間：目的に応じた個人情報提供時から、提供目的 達成時まで、または、３条に明示された期限まで。
        <br />
        <br />
        <h6>6.情報主体の権利∙義務及び、その行事方法に関する事項</h6>
        貴下は、安心ケアサービスのために、個人情報の収集∙利用及び、 提供について、同意した内容をいつでも撤回できます。 貴下は、本人の個人情報の閲覧、訂正、処理停止、削除の要請が でき、個人情報保護責任者に書面、電話または、ファックスなどで 連結すれば、遅滞なく、破棄するなど、必要な措置を取ります。 但し、法律で定められた義務事項がある場合、権利行使が制限されることがあります。
        <br />
        <br />
        <h6>7.個人情報保護責任者</h6> 貴下の個人情報を保護し、個人情報に関する、苦情を処理するために、 下記の通り、個人情報保護責任者があります。
        <br />
        氏名: チェハンナ
        <br />
        役目: 職員
        <br />
        部署: イージートラベルケア 担当
        <br />
        連絡先: 02-1644-1493
        <br />
        <br />
        <h6>8.個人情報の安全性確保措置</h6>利用者に送ってくださる全ての情報は、防火壁など、セキュリティ システムによって、安全に管理されています。 又は、「本社」は、利用者の個人情報保護のための管理的対策として、 個人情報に対する、アクセス権限を差等付与していて、利用者の個人情報を扱う人数を最小限に制限して、持続的なセキュリティ教育を 行っています。 又は、個人情報を処理するシステムの使用者を指定して、ログ記録を 保管しています。
        <br />
        <br />
        <h6>9.個人情報処理方針の変更に関する事項</h6>この個人情報処理方針とは、2021年1月1日に制定されており、法令∙ 政策または、保安技術の変更により、内容の追加∙削除及び、修正が ある場合は、変更される個人情報処理方針を実施する少なくとも 7日の前に、「本社」のホームページを通じて、変更事由及び、内容 などをお知らせするようにします。
        <br />
        <br />
        ㅡ広告の日付: 2021年1月1日 / 施行日: 2021年1月1日
        <br />
        <br />
      </>
      <h6>{language['이메일무단수집거부']}</h6>
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>本、サイトに掲示された、電子メールアドレスが電子メール収集プログラムや、その他の技術的装置を利用して、無断で収集されることを拒否し、これに違反した場合、情報通信網法により、刑事処罰されることを ご留意ください。</li>
            <li>情報通信網の利用及び、情報保護に関する法律（一部改正2002.12.18 法律　第０６９７号）</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">第５０条の２（電子メールアドレスの無断収集行為などの禁止）</h6>
              <ul className="list secondary">
                <li>誰でも、電子メールアドレスの収集を拒否する意思が明示された、 インターネットホームページで、自動的に電子メールアドレスを 収集するプログラム、その他の技術的装置を利用して電子メール アドレスを収集してはいけません。</li>
                <li>誰でも、第１項の規定を違反して、収集された電子メール アドレスを販売、流通してはいけません。</li>
                <li>誰でも、第１項および、第２項の規定によって、収集∙販売及び、流通が禁止された、電子メールアドレスであることを知り、これを 情報伝送に利用してはいけません。</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">第６５条の２（罰則）次の各号の１に該当するものは、１千万ウォン 以下の罰金に処されます。</h6>
              <ul className="list secondary">
                <li>第５０条の第4項の規定を違反して、技術的な措置をした者。</li>
                <li>第５０条の第6項の規定を違反して、営利目的の広告性情報を伝送 した者。</li>
                <li>第５０条の第2項の規定を違反して、電子メールアドレスを収集.販売. 流通または、情報伝送に利用した者。</li>
              </ul>
            </div>
          </div>
          掲示日：2021-01-04
        </div>
      </div>
    </>
  )
}

export default B2BTermJPView
