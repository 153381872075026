function EmailCollectionENView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>Collection of the email address specified on this website through email collecting software programs and other technology involved is strictly prohibited by law and would be subject to criminal punishment.</li>
            <li>Act On Promotion of Information and Communications Network Utilization and Information Protection (partial revision 2002.12.18. Act no. 0697)</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">Article 50.2 (Prohibition of Unauthorized Collection of Email Addresses)</h6>
              <ul className="list secondary">
                <li>Collection of the email addresses on websites through email collecting software programs and other technology involved is strictly prohibited.</li>
                <li>Sales or trades of email addresses collected through violation of term 1 are strictly prohibited.</li>
                <li>Transfer of email addresses collected through violation of terms 1 and 2 is strictly prohibited.</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">Article 65.2 (penalty) Whoever corresponds to any of the following is fined 10 million won or less.</h6>
              <ul className="list secondary">
                <li>Whoever took technical measures violating Article 50.4</li>
                <li>Whoever transferred promotional information for commercial purposes violating Article 50.6</li>
                <li>Whoever collected, sold, or traded email addresses violating Article 50.2</li>
              </ul>
            </div>
          </div>
          Posted on Jan 4, 2021
        </div>
      </div>
    </section>
  )
}

export default EmailCollectionENView
