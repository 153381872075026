import { useSelector } from 'react-redux'

function FAQListView() {
  const language = useSelector((state: any) => state.language.value)
  const faqList = [
    {
      title: '가입시 연령제한이 있나요?',
      content: '연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.',
    },
    {
      title: '가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?',
      content: '업무일 기준, 개시일 24시간 전까지입니다.',
    },
    {
      title: '가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?',
      content: '이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.',
    },
    {
      title: '이지트래블케어 가입시 달러로만 결제하나요?',
      content: '달러로만 결제 가능합니다.',
    },
    {
      title: '카드 결제시 오류가 나면 어떻게 하나요?',
      content: '고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.',
    },
    {
      title: '서비스에 가입했다가 다시 취소할 수 있나요?',
      content: '서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.',
    },
    {
      title: '상해발생 지역이 해외여도 가능한가요?',
      content: '한국 국내 상황에만 적용됩니다.',
    },
    {
      title: '특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?',
      content: '특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.',
    },
    {
      title: '멤버십 증서는 어디서 확인하나요?',
      content: '서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.',
    },
    {
      title: '컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?',
      content: '멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.',
    },
  ]

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-12">
          <ul className="list secondary">
            <li>{language['이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.']}</li>
          </ul>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="accordion faq" id="accordionFAQ">
            {faqList.map((faq, index) => {
              return (
                <div className="card" key={`faq_${index}`}>
                  <div className="card-header" id="FAQheadingOne">
                    <h2 className="mt-0 mb-0">
                      <a className="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target={`#FAQcollapse_${index}`} aria-expanded="true" aria-controls={`FAQcollapse_${index}`}>
                        <strong>{language[faq.title]}</strong>
                      </a>
                    </h2>
                  </div>
                  <div id={`FAQcollapse_${index}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionFAQ">
                    <div className="card-body" dangerouslySetInnerHTML={{ __html: language[faq.content] }} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQListView
