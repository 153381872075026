function EasyTravelCareTermCNView() {
  return (
    <>
      从今天开始，请安心享受大韩民国旅行吧！
      <br />
      在大韩民国旅行途中需要帮助时，请随时联系Easy Travel Care中心。Easy Travel Care中心一年365天，全年无休。
      <br />
      Easy Travel Care中心，通过手机聊天、有线通话、电子邮件等方式，为访问韩国旅游的游客提供安心旅游保险及管理服务。 <br />
      无论何时，都会帮助游客在大韩民国享受安全舒适的旅行，不但为海外游客提供量身定制的礼宾服务，而且还提供舒适、安全以及有品位的价值观光、优质礼宾服务，最终提高旅游的品质。
      <br />
      <h6>Easy Travel Care 介绍</h6>
      Easy Travel Care服务是种安心旅游平台服务，为访问韩国的外国游客在韩国享受安心、舒适、愉快旅游而提供集外国人专用旅行者保险服务及定制型礼宾服务于一体的优质服务。
      <br />
      主要服务包括外国人专用旅行者保险和(株)PREMIUMPASS INTERNATIONAL提供的医疗安心服务、旅游安心服务、机场安心服务、商务安心服务、旅行者安心保险及高端会员特别优惠。
      <br />
      服务提供公司(株)PREMIUMPASS INTERNATIONAL，2007年成立礼宾服务专门企业，目前迅速成长为全球性旅游服务（Hospitality）专门企业。
      <br />
      (株)PREMIUMPASS INTERNATIONAL是旅游综合服务专门企业, 运营MICE事业、礼宾及车辆事业、机场相关服务事业、旅游事业、礼宾服务事业等，且在旅游景点及交通的主要据点运营旅游中心及休息室。
      <br />
      <br />
      <h6>Easy Travel Care 服务的概要</h6>
      1. Easy Travel Care 服务 注册会员 <br />
      为注册Easy Travel Care服务，需要填写所属国家名称、姓名、出生年月日、性别、手机号码、电子邮箱等信息，在提供服务的过程中，根据该信息进行本人确认。如信息不一致，服务会受限制。 <br />
      注册Easy Travel Care服务时，通过平台提供注册确认书以及服务指南。如申请要求以电子邮件的方式接收注册确认书时，另外发送至该用户的电子邮箱。 <br />
      注册会员时，必须确认填写的本人信息是否正确。尤其要特别确认，在紧急情况时，可联络的手机号码、电子邮箱等是否正确填写，如有错误，请立即通过Easy Travel Care服务中心或聊天服务进行修改。
      <br />
      在旅行中发生紧急情况或应急状况时，需告知护照号码方可接受服务，应急处理完毕后，通过护照信息进行本人确认。
      <br />
      2. Easy Travel Care服务联系方式
      <br />
      需要帮肋时或发生紧急情况时，可通过Easy Travel Care的联络服务平台SOS申请或Easy Travel Care服务中心（1644-1493，82-2-2063-3544）联系我们。
      <br />
      可通过电话、电子邮箱、平台的聊天服务申请SOS，或通过KAKAO TALK联系24小时服务中心，如需要有线通话时，请告知可联络的手机号码，负责人会拨打电话给您。未使用韩国USIM卡的电话号码，可能会被限制联系。
      <br />
      ※电子邮件 或 KAKAO TALK
      <br />
      可通过以下联络方式申请服务：
      <br />
      Easy Travel Care服务中心的代表电子邮箱（care@tothepp.com）
      <br />
      KAKAO TALK 添加朋友（@Easy Travel Care CENTER）
      <br />
      <br />
      <h6>Easy Travel Care服务的普通条款</h6>
      <h6>第一条 目的</h6>
      本条款的目的在于明确规定在使用(株)PREMIUMPASS INTERNATIONAL（以下称“PREMIUMPASS”）提供的安心旅游平台：Easy Travel Care服务（即：Easy Travel Care）时，会员及用户之间的权利及义务。
      <br />
      <br />
      <h6>第二条 用语的定义</h6>
      1. 会员
      <br />
      以申请签订Easy Travel Care服务中心的安心旅游平台服务合同为目的，通过电话、电子邮箱、网页、手机APP等方式，或者通过Easy Travel Care服务的广告代理合作企业，为了顺利使用服务，提供Easy Travel Care服务平台所要求的个人识别信息及旅游日程信息的个人及团体。
      <br />
      2. 用户
      <br />
      同意Easy Travel Care服务条款中明示的权利和义务，缴纳Easy Travel Care服务指定的服务费，并获取签订证明书编号的会员。
      <br />
      3. Easy Travel Care服务中心
      <br />
      针对会员在旅行中遇到的困难，提供便利、保险等服务，以非面对面的方式进行基本服务接待的综合服务中心。
      <br />
      4. 签订证明书
      <br />
      Easy Travel Care服务中心提供给用户的证明书，包括签订证明编号、个人识别信息及主要签订条件等内容。
      <br />
      5. 管理服务经理
      <br />
      根据条款，对用户申请的医疗、现场服务及保险服务接待，赋予直接或间接的干涉权及决定权，进行评价、调整、监督的专门服务经理。
      <br />
      6. 会员特惠
      <br />
      以正常途径获得Easy Travel Care服务资格的会员，可以享受的特惠，其决定权在 Easy Travel Care服务运营商，即PREMIUMPASS。
      <br />
      <br />
      <h6>第三条 服务内容</h6>
      Easy Travel Care服务提供的安心旅游平台服务，如下：
      <br />
      1. 医疗安心服务
      <br />
      为在大韩民国旅行中可能发生的各种交通事故、安全事故等定制型服务，提供医疗翻译，医疗费用解决方案，协助处理旅行者保险以及医疗支援等服务。
      <br />
      2. 旅游安心服务
      <br />
      在大韩民国旅行中发生紧急事项时，提供各种旅行管理服务。
      <br />
      - 遗失钱包、护照等重要证件时，提供支援服务
      <br />
      - 旅行途中发生的紧急事件时，提供跟踪管理服务
      <br />
      - 提供各种交通工具预订出票服务及车辆安排服务
      <br />
      - 提供紧急住宿咨询及预订服务
      <br />
      3. 机场安心服务
      <br />
      在国际机场特别区域，为顾客提供安心管理服务。
      <br />
      - 为老弱者提供机场支援及协助解决不便事项
      <br />
      - 提供国际机场咨询中心服务
      <br />
      - 签证及紧急机票的管理服务
      <br />
      4. 商务安心服务
      <br />
      为舒适安全的旅游及成功的商机而特化的支援服务。
      <br />
      - 紧急咨询及安排专业翻译服务
      <br />
      - 会议设施及小规模洽谈场地的咨询及预约服务
      <br />
      - KTX、机票等咨询及预约服务
      <br />
      5. 旅行者安心保险
      <br />
      旅行者安心保险由“PREMIUMPASS”的合作保险公司提供，因此提供的安心保险服务根据合作保险公司的规定及保险条款。
      <br />
      伤害死亡（限在韩旅游过程中）：1亿韩元(未满15岁除外)
      <br />
      伤害后遗障碍（限在韩旅游过程中）：1亿韩元
      <br />
      疾病死亡/80%高度后遗障碍：2千万韩元（未满15岁及80岁以上均除外）
      <br />
      赔偿责任（限在韩旅游过程中）：5百万韩元 （个人负担1万韩元）
      <br />
      携带物品损毁（遗失除外）：20万韩元 （个人负担1万韩元）
      <br />
      国内旅游伤害补助_医疗实际费用保障：1千万韩元（每次就诊以10万韩元为限）
      <br />
      国内旅游伤害非补助_医疗实际费用保障：1千万韩元（每次就诊以10万韩元为限, 年就诊次数仅限100次）
      <br />
      6. 会员特惠
      <br />
      为使用Easy Travel Care服务的会员提供的会员特色服务，提供机场及旅游、商务等多种礼宾服务，同时可享受折扣优惠。会员特惠服务，随时由Easy Travel Care服务的提供方“PREMIUMPASS”变更其优惠内容，会员可这网页或手机APP等程序确认变更事项。
      <br />
      7. 提供服务其他相关注意事项
      <br />
      - 用户在大韩民国旅行途中遇到交通事故及安全事故时，提供紧急应急方法、协助联系有关机构及派遣翻译人员、现场出动等服务。
      <br />
      - 该支援服务可对大韩民国境内发生的事故进行处理，对出发国家或换乘国家发生的事故，服务会受限制或无法提供服务。
      <br />
      - 该服务建议用户在发生事故后，立即联系Easy Travel Care服务中心申请服务， Easy Travel Care服务中心尚未接受该事故的委托业务，或Easy Travel Care服务经纪人接受处理事故现场前，由其他机构相关人士或第三方参与其事故处理业务时，会对Easy Travel Care服务中心协助处理事故过程产生负面影响。
      <br />
      <br />
      <h6>第四条 服务开始及终止</h6>
      Easy Travel Care服务的用户，至签订服务合同当天开始接受服务，截止到最后一天或出境当天终止。原则上使用服务天数，按24小时计算。
      <br />
      <br />
      <h6>第五条 条款的效力</h6>
      1. 该条款由Easy Travel Care中心在网页或手机APP等平台上发布或告知用户的同时，产生效力。
      <br />
      2. Easy Travel Care服务中心修改该条款的内容时，需在适用日期一周前至适用日期的前一天，根据第一项条款，以发布或告示的方法，准确明示适用日期及修改理由。此时，修改后的条款内容只适用于服务起始日在其适用日期以后的服务合同，适用日期以前开始的服务合同，则根据修改前的条款处理。
      <br />
      3. 用户如不同意修改后的条款内容，可申请取消合同。
      <br />
      4. 如用户未申请取消合同，正常使用服务时，则被视为同意修改后的条款内容。
      <br />
      <br />
      <h6>第六条 条款适用优先顺序</h6>
      1. 如Easy Travel Care服务与保险公司的保险产品有相互关系时，关于保险的所有事项，均适用于该保险产品的普通条款及特别条款。
      <br />
      2. 如Easy Travel Care服务的普通条款与特别条款有冲突时，特别条款的效力优先适用。
      <br />
      <br />
      <h6>第七条 会员及用户的咨格得失</h6>
      1. 所有个人及团体以申请签订服务合同为目的，通过Easy Travel Care服务中心提供的媒体，输入或提供Easy Travel Care服务所要求的个人识别信息（姓名、出生年月日、性别、国籍、电话号码、电子邮箱等）及旅游日程信息，而获取会员资格。但，为享受Easy Travel Care服务，用户必须提供护照信息。
      <br />
      2. Easy Travel Care服务所要求的个人信息及旅行日程信息，如会员虚假提供或提供的个人识别信息及旅行日期信息不完整，12小时以上未进行申请签订合同的阶段时，Easy Travel Care服务中心有权删除会员输入的个人识别信息及旅行日程信息，并驳回会员资格。
      <br />
      3. 为进行签订服务合同流程，Easy Travel Care服务中心可以利用会员的个人识别信息及旅行日程信息。
      <br />
      4. 根据第一项条款，获得会员资格的会员，通过签订合同获取签订证明代码，从而获得用户资格。
      <br />
      <br />
      <h6>第八条 Easy Travel Care有务的义务和权利</h6>
      1. Easy Travel Care服务中心为提供稳定服务而持续努力，如收到会员或用户的投诉时，诚恳回应。但，根据事宜的必要性和适当性，可以选择由合作机构代替回应。
      <br />
      2. 符合以下Easy Travel Care服务的各项中的一项或几项，部份或全部服务会被受限制或被中止。
      <br />
      ① 系统及设备保修期间
      <br />
      ② 提供基础服务的基干通信公司终止网络服务时
      <br />
      ③ 国家非常状态、停电、信息网连接暴增等其他不可抗力的情况
      <br />
      3. 如Easy Travel Care服务中心限制或中止服务，根据第5条第1项的方法，将其理由及限制时间等立即通知会员或用户。
      <br />
      4. 签订服务合同之前已有的既往症，即签订服务合同前既有的疾病或因既有事故或疾病引起的症状及并发症，签订合同资格可能会收限制或免除Easy Travel Care服务中心支付保险金的义务。
      <br />
      5. 为预防提供服务国及纠纷，Easy Travel Care服务中心有权保存和监视通信上的对话内容。
      <br />
      6. 因用户对该流程及提供的服务，发生纠纷时，用户明确同意使用已保存的证明资料。
      <br />
      7. Easy Travel Care服务中心，为提供稳定及正确的服务要求用户提供医疗记录（包括旅行前发生的记录、Easy Travel Care服务中心为提供服务而判断有必要的所有记录）时，用户必须将医疗记录提供给Easy Travel Care服务中心，如用户拒绝提供而无法顺利、准确地提供旅行服务的，将免除Easy Travel Care服务中心的责任。
      <br />
      8. 因发生在用户本身的事故或疾病等事件，与提供至用户本人的服务相关，并包括个人、法律个体、公共机关等针对第三方的所有权利和行动，Easy Travel Care服务有权自动行驶代理权，用户应转让该权利及行动，不得取消该内容，并且遵循对其有必要的所有法律程序，对用户要求的一项事项进行协助。
      <br />
      9. Easy Travel Care服务项目中，关于旅行者安心保险，将根据“PREMIUMPASS”的合作保险公司的规定及条款支付。
      <br />
      10. Easy Travel Care基于安心旅游礼宾服务，因此相关旅行者保险金的保障问题，可以通过“PREMIUMPASS”的合作保险公司得到保障，根据礼宾服务项目的实际产生费用由会员承担。
      <br />
      <br />
      <h6>第九条 会员及用户的义务和权利</h6>
      1. 会员及用户应遵守该条款规定的事项和服务使用指南、以及注意事项等Easy Travel Care服务所告知的事项。
      <br />
      2. 会员及用户应注意个人识别信息通过不正当的方法被使用，如发现被不正当使用，应立即通知Easy Travel Care服务中心，协助防止损失发生。
      <br />
      3. 会员及用户不得将服务相关的部份或全部权利及义务，转让或以担保提供给第三方。
      <br />
      4. 会员及用户除法律规定的内容以外，有权拒绝未经本人同意将本人的个人信息提供至第三方使用。
      <br />
      <br />
      <h6>第十条 取消合同及中途解除</h6>
      1. Easy Travel Care服务签订证明书上记载的开始日期之前，随时可以解除（取消）合同，已缴纳的服务费将全额退还。
      <br />
      2. 服务期间的变更或取消，必须在开始日期前进行。
      <br />
      3. 即使是服务进行期间，如果“PREMIUMPASS”判断为有可能发生个人识别信息的不正当及恶意、故意的事故，可以中途解除服务合同。
      <br />
      4. 签订证明书上注明的服务开始日期之后，解除服务合同（中途解除）时，减去已使用的服务期间的手续费后，全额退还，取消手续费的计算法根据保险短期费率及服务费费率计算。服务费返还请求权有效期为自用户资格丧失日之起3年。但保险费返还请求权的规定，根据保险业法进行。
      <br />
      5. 取消及中途解除所需资料：
      <br />
      ① 服务开始前取消：取消申请书（或通过电子邮件申请取消）、存折复印件
      <br />
      ② 因单纯变心而中途解除：解除申请书、护照复印件、存折复印件（根据解除申请书的发送日期进行退款）
      <br />
      ③ 因提前出境而中途解除：护照复印件、存折复印件、回国机票 E-TICKET复印件或出入境实事证明书（提出E-TICKET时，机票信息内容，不得包含服务合同期间再次入境的信息；如果提供出入境事实证明书时，服务合同期间内，以最终回国日期为基准，进行退款）
      <br />
      <br />
      <h6>第十一条 延长Easy Travel Care服务</h6>
      1. 签订服务合同后，在韩国境内滞留期间，因合同期限到期需要重新签订合同或延长合同时，用户需在合同到期前，通过SOS聊天窗口或电话、电子邮件等途径，申请重新签订或延长合同。
      <br />
      2. 无论Easy Travel Care服务中心是否通知，之前签订的合同有效期已过，或者之前签订的合同到期之前，尚未进行重新签订流程时，被视为没有重新签订的意向，并限制重新签订资格。
      <br />
      3. 签订服务合同之前已有的既往症，即签订服务合同前既有的疾病或因既有事故或疾病引起的症状及并发症，签订合同资格可能会受限制或免除Easy Travel Care服务中心支付保险金的义务。
      <br />
      <br />
      <h6>第十二条 申请签订团体保险</h6>
      1. Easy Travel Care服务为会员及用户，以签约人的身份与保险公司签订团体保险合同，提供给会员。
      <br />
      2. 如果用户签订签约人是Easy Travel Care服务的团体保险合同时，希望签为被保险人，可以通过相应流程，向包括该保险的平台申请签订保险契约，完成投保流程的用户即可成为投保人。
      <br />
      3. 预期在保险平台申请签订保险合同的会员，应先充分阅读及了解Easy Travel Care服务中心合作保险公司的签订指南及保险条款后，提出投保申请，并认真回答保险公司与Easy Travel Care服务告知的邀请事项。
      <br />
      4. 在保险平台签订保险合同时，会员签订的保险收益人是投保人（被保险人）本人及法定继承人。
      <br />
      5. 相关Easy Travel Care服务签订的团体保险，其他该条款未规定的事项，将根据Easy Travel Care服务与保险公司的规定。
      <br />
      <br />
      <h6>第十三条 旅游保险的便利支援</h6>
      Easy Travel Care服务项目中，旅行者保险的签约人是“PREMIUMPASS”，该保险为团体保险，适用团体折扣，因此可以以最低的保险费用，提供最佳的保险保障内容。另外，提供保险金代理理赔、比例理赔、代理申请保险金以及最终通知等服务。
      <br />
      <br />
      <h6>第十四条 Easy Travel Care服务 保险支援服务的限制</h6>
      Easy Travel Care服务中心，无论其理由是直接或简接，如符合以下陈述的事项为理由发生的服务项目，不予提供服务为原则。因用户要求提供服务时，可能会产生额外费用。
      <br />
      ① 签订的服务合同，未记载的服务内容
      <br />
      ② 用户及保护人（包括所属机构）的故意或重大过失
      <br />
      ③ 因用户的疾病或伤害发生的诊疗费、治疗费、医药费等所有医疗费用
      <br />
      ④ 死刑或用户的自害、自杀、自杀未遂、刑法上的犯罪行为或暴力行为（但，被确认为刑法上的正当行为、紧急避难及正当防卫时，提供支援）
      <br />
      ⑤ 用户的身心丧失或精神疾病
      <br />
      ⑥ 未经Easy Travel Care服务或合作机关的批准，因用户在其他医疗机关接受治疗的结果造成的伤害、疾病或者并发症
      <br />
      ⑦ 非Easy Travel Care的用户，或用户的同伴旅行者在旅行中发生的一切费用
      <br />
      ⑧ 用户未遵守政府对发生传染病的国家禁止旅行或接受预防疫苗的方针，回国后被确认传染病
      <br />
      ⑨ 用户自发前往发生战争、外国的武力行为、革命、内乱、事变、暴动、劳动争议及其他类似事件的地区，发生的服务内容
      <br />
      ⑩ 因核燃料物质（包括已使用完的燃料，以下皆是）或被核燃料物质污染的物质（包括原子核分裂生成物）的放射性、爆炸性或其他有害特性而发生的事故（此外包括被暴露在放射线或放射能污染）
      <br />
      ⑪ 非偶然、急剧的事故或疾病导致的怀孕、分娩、流产或外科手术、其他医疗措施或者旅行的目的本身在于治疗之前现有的伤害或疾病等医疗行为时
      <br />
      ⑫ 地震、喷发、海啸或类似的自然灾害
      <br />
      ⑬ 其他该条款内容所述的免责理由
      <br />
      <br />
      <h6>第十五条 解决纠纷</h6>
      1. 关于服务方面发生纠纷时，Easy Travel Care服务与用户，应为妥善解决而努力。
      <br />
      2. 即使根据第1项的规定努力协调后，因当事者间的纠纷进行诉讼时，关于用户与Easy Travel Care服务之间签订的合同的相关法律事项，将根据大韩民国法院指定为协议管辖法院。
      <br />
      3. 关于合同内容的纠纷，依据大韩民国的法律规定；关于保险的纠纷，依据合作保险公司的普通条款及特别条款的规定。
      <br />
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 首尔市江西区麻谷中央4路22（麻谷洞）PINESQUARE A栋 501号
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 服务中心 +82-2-2063-3544
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 服务中心 传真 +82-2-2123-0164
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 服务中心 电子邮箱 care@tothepp.com
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 服务中心 KAKAO TALK ID（@Easy Travel Care CENTER）
      <br />
      PRMIUMPAS INTERNATIONAL Corp. 注册咨询 1644-1493
      <br />
    </>
  )
}

export default EasyTravelCareTermCNView
