function EmailCollectionJPView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>本、サイトに掲示された、電子メールアドレスが電子メール収集プログラムや、その他の技術的装置を利用して、無断で収集されることを拒否し、これに違反した場合、情報通信網法により、刑事処罰されることを ご留意ください。</li>
            <li>情報通信網の利用及び、情報保護に関する法律（一部改正2002.12.18 法律　第０６９７号）</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">第５０条の２（電子メールアドレスの無断収集行為などの禁止）</h6>
              <ul className="list secondary">
                <li>誰でも、電子メールアドレスの収集を拒否する意思が明示された、 インターネットホームページで、自動的に電子メールアドレスを 収集するプログラム、その他の技術的装置を利用して電子メール アドレスを収集してはいけません。</li>
                <li>誰でも、第１項の規定を違反して、収集された電子メール アドレスを販売、流通してはいけません。</li>
                <li>誰でも、第１項および、第２項の規定によって、収集∙販売及び、流通が禁止された、電子メールアドレスであることを知り、これを 情報伝送に利用してはいけません。</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">第６５条の２（罰則）次の各号の１に該当するものは、１千万ウォン 以下の罰金に処されます。</h6>
              <ul className="list secondary">
                <li>第５０条の第4項の規定を違反して、技術的な措置をした者。</li>
                <li>第５０条の第6項の規定を違反して、営利目的の広告性情報を伝送 した者。</li>
                <li>第５０条の第2項の規定を違反して、電子メールアドレスを収集.販売. 流通または、情報伝送に利用した者。</li>
              </ul>
            </div>
          </div>
          掲示日：2021-01-04
        </div>
      </div>
    </section>
  )
}

export default EmailCollectionJPView
