import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { KeyValueType } from '../../../utils/types'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import TabView from '../../common/TabView'

function TermsPage() {
  const language = useSelector((state: any) => state.language.value)
  const tabItems = [
    {
      link: '/terms',
      title: language['이용약관'],
    },
    {
      link: '/terms/privacy',
      title: language['개인정보처리방침'],
    },
    {
      link: '/terms/email-collection',
      title: language['이메일무단수집거부'],
    },
  ]
  const termsName: KeyValueType = {
    '/terms': language['이용약관'],
    '/terms/privacy': language['개인정보처리방침'],
    '/terms/email-collection': language['이메일무단수집거부'],
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub2.jpg" title={language['서비스정책']} />
      <TabView tabItems={tabItems} />
      <BreadcrumbView title={language['서비스정책']} link="/terms" names={termsName} />
      <Outlet />
      <div className="divide" />
    </>
  )
}

export default TermsPage
