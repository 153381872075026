import Modal from 'react-bootstrap/Modal'

function AlertModal(props: any) {
  const { icon, body, open, close, footer } = props

  return (
    <Modal show={open} onHide={close} centered>
      <Modal.Header>
        <button type="button" className="close" onClick={close}>
          <img src={icon} alt="" />
        </button>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  )
}

export default AlertModal
