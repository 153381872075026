import { Outlet, Navigate } from 'react-router-dom'
import { KeyValueType } from '../../../utils/types'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import TabView from '../../common/TabView'
import { useSelector } from 'react-redux'

function MyPage() {
  const loginUser = useSelector((state: any) => state.user.value)
  const language = useSelector((state: any) => state.language.value)
  if (loginUser === null || loginUser.memberSid === null || loginUser.memberSid === '') {
    return <Navigate to="/login?redirect-page=my-page" />
  }

  const tabItems = [
    {
      link: '/my-page',
      title: language['내 회원정보'],
    },
    {
      link: '/my-page/purchase',
      title: language['구매내역'],
    },
    {
      link: '/my-page/withdrawal',
      title: language['회원탈퇴'],
    },
  ]
  const myPageName: KeyValueType = {
    '/my-page': language['내 회원정보'],
    '/my-page/purchase': language['구매내역'],
    '/my-page/withdrawal': language['회원탈퇴'],
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub2.jpg" title={language['마이페이지']} />
      <TabView tabItems={tabItems} />
      <BreadcrumbView title={language['마이페이지']} link="/my-page" names={myPageName} />
      <Outlet />
      <div className="divide" />
    </>
  )
}

export default MyPage
