import { createSlice } from '@reduxjs/toolkit'
import axiosConnect from '../network/http-request'

const initialState = {
  value: [],
}

export const boardCategorySlice = createSlice({
  name: 'boardCategory',
  initialState,
  reducers: {
    boardCategory: (state, action) => {
      state.value = action.payload
    },
  },
})

export const fetchBoardCategory = () => (dispatch) => {
  axiosConnect('/api/faqcategory/list', 'GET').then((result) => {
    dispatch(boardCategory(result.data))
  })
}

export const { boardCategory } = boardCategorySlice.actions
export default boardCategorySlice.reducer
