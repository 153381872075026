import { useSelector } from 'react-redux'

function B2BTermCNView() {
  const language = useSelector((state: any) => state.language.value)
  return (
    <>
      <>
        <h6>{language['이지트래블케어 이용 약관']}</h6>
        从今天开始，请安心享受大韩民国旅行吧！
        <br />
        在大韩民国旅行途中需要帮助时，请随时联系Easy Travel Care中心。Easy Travel Care中心一年365天，全年无休。
        <br />
        Easy Travel Care中心，通过手机聊天、有线通话、电子邮件等方式，为访问韩国旅游的游客提供安心旅游保险及管理服务。 <br />
        无论何时，都会帮助游客在大韩民国享受安全舒适的旅行，不但为海外游客提供量身定制的礼宾服务，而且还提供舒适、安全以及有品位的价值观光、优质礼宾服务，最终提高旅游的品质。
        <br />
        <h6>Easy Travel Care 介绍</h6>
        Easy Travel Care服务是种安心旅游平台服务，为访问韩国的外国游客在韩国享受安心、舒适、愉快旅游而提供集外国人专用旅行者保险服务及定制型礼宾服务于一体的优质服务。
        <br />
        主要服务包括外国人专用旅行者保险和(株)PREMIUMPASS INTERNATIONAL提供的医疗安心服务、旅游安心服务、机场安心服务、商务安心服务、旅行者安心保险及高端会员特别优惠。
        <br />
        服务提供公司(株)PREMIUMPASS INTERNATIONAL，2007年成立礼宾服务专门企业，目前迅速成长为全球性旅游服务（Hospitality）专门企业。
        <br />
        (株)PREMIUMPASS INTERNATIONAL是旅游综合服务专门企业, 运营MICE事业、礼宾及车辆事业、机场相关服务事业、旅游事业、礼宾服务事业等，且在旅游景点及交通的主要据点运营旅游中心及休息室。
        <br />
        <br />
        <h6>Easy Travel Care 服务的概要</h6>
        1. Easy Travel Care 服务 注册会员 <br />
        为注册Easy Travel Care服务，需要填写所属国家名称、姓名、出生年月日、性别、手机号码、电子邮箱等信息，在提供服务的过程中，根据该信息进行本人确认。如信息不一致，服务会受限制。 <br />
        注册Easy Travel Care服务时，通过平台提供注册确认书以及服务指南。如申请要求以电子邮件的方式接收注册确认书时，另外发送至该用户的电子邮箱。 <br />
        注册会员时，必须确认填写的本人信息是否正确。尤其要特别确认，在紧急情况时，可联络的手机号码、电子邮箱等是否正确填写，如有错误，请立即通过Easy Travel Care服务中心或聊天服务进行修改。
        <br />
        在旅行中发生紧急情况或应急状况时，需告知护照号码方可接受服务，应急处理完毕后，通过护照信息进行本人确认。
        <br />
        2. Easy Travel Care服务联系方式
        <br />
        需要帮肋时或发生紧急情况时，可通过Easy Travel Care的联络服务平台SOS申请或Easy Travel Care服务中心（1644-1493，82-2-2063-3544）联系我们。
        <br />
        可通过电话、电子邮箱、平台的聊天服务申请SOS，或通过KAKAO TALK联系24小时服务中心，如需要有线通话时，请告知可联络的手机号码，负责人会拨打电话给您。未使用韩国USIM卡的电话号码，可能会被限制联系。
        <br />
        ※电子邮件 或 KAKAO TALK
        <br />
        可通过以下联络方式申请服务：
        <br />
        Easy Travel Care服务中心的代表电子邮箱（care@tothepp.com）
        <br />
        KAKAO TALK 添加朋友（@Easy Travel Care CENTER）
        <br />
        <br />
        <h6>Easy Travel Care服务的普通条款</h6>
        <h6>第一条 目的</h6>
        本条款的目的在于明确规定在使用(株)PREMIUMPASS INTERNATIONAL（以下称“PREMIUMPASS”）提供的安心旅游平台：Easy Travel Care服务（即：Easy Travel Care）时，会员及用户之间的权利及义务。
        <br />
        <br />
        <h6>第二条 用语的定义</h6>
        1. 会员
        <br />
        以申请签订Easy Travel Care服务中心的安心旅游平台服务合同为目的，通过电话、电子邮箱、网页、手机APP等方式，或者通过Easy Travel Care服务的广告代理合作企业，为了顺利使用服务，提供Easy Travel Care服务平台所要求的个人识别信息及旅游日程信息的个人及团体。
        <br />
        2. 用户
        <br />
        同意Easy Travel Care服务条款中明示的权利和义务，缴纳Easy Travel Care服务指定的服务费，并获取签订证明书编号的会员。
        <br />
        3. Easy Travel Care服务中心
        <br />
        针对会员在旅行中遇到的困难，提供便利、保险等服务，以非面对面的方式进行基本服务接待的综合服务中心。
        <br />
        4. 签订证明书
        <br />
        Easy Travel Care服务中心提供给用户的证明书，包括签订证明编号、个人识别信息及主要签订条件等内容。
        <br />
        5. 管理服务经理
        <br />
        根据条款，对用户申请的医疗、现场服务及保险服务接待，赋予直接或间接的干涉权及决定权，进行评价、调整、监督的专门服务经理。
        <br />
        6. 会员特惠
        <br />
        以正常途径获得Easy Travel Care服务资格的会员，可以享受的特惠，其决定权在 Easy Travel Care服务运营商，即PREMIUMPASS。
        <br />
        <br />
        <h6>第三条 服务内容</h6>
        Easy Travel Care服务提供的安心旅游平台服务，如下：
        <br />
        1. 医疗安心服务
        <br />
        为在大韩民国旅行中可能发生的各种交通事故、安全事故等定制型服务，提供医疗翻译，医疗费用解决方案，协助处理旅行者保险以及医疗支援等服务。
        <br />
        2. 旅游安心服务
        <br />
        在大韩民国旅行中发生紧急事项时，提供各种旅行管理服务。
        <br />
        - 遗失钱包、护照等重要证件时，提供支援服务
        <br />
        - 旅行途中发生的紧急事件时，提供跟踪管理服务
        <br />
        - 提供各种交通工具预订出票服务及车辆安排服务
        <br />
        - 提供紧急住宿咨询及预订服务
        <br />
        3. 机场安心服务
        <br />
        在国际机场特别区域，为顾客提供安心管理服务。
        <br />
        - 为老弱者提供机场支援及协助解决不便事项
        <br />
        - 提供国际机场咨询中心服务
        <br />
        - 签证及紧急机票的管理服务
        <br />
        4. 商务安心服务
        <br />
        为舒适安全的旅游及成功的商机而特化的支援服务。
        <br />
        - 紧急咨询及安排专业翻译服务
        <br />
        - 会议设施及小规模洽谈场地的咨询及预约服务
        <br />
        - KTX、机票等咨询及预约服务
        <br />
        5. 旅行者安心保险
        <br />
        旅行者安心保险由“PREMIUMPASS”的合作保险公司提供，因此提供的安心保险服务根据合作保险公司的规定及保险条款。
        <br />
        伤害死亡（限在韩旅游过程中）：1亿韩元(未满15岁除外)
        <br />
        伤害后遗障碍（限在韩旅游过程中）：1亿韩元
        <br />
        疾病死亡/80%高度后遗障碍：2千万韩元（未满15岁及80岁以上均除外）
        <br />
        赔偿责任（限在韩旅游过程中）：5百万韩元 （个人负担1万韩元）
        <br />
        携带物品损毁（遗失除外）：20万韩元 （个人负担1万韩元）
        <br />
        国内旅游伤害补助_医疗实际费用保障：1千万韩元（每次就诊以10万韩元为限）
        <br />
        国内旅游伤害非补助_医疗实际费用保障：1千万韩元（每次就诊以10万韩元为限, 年就诊次数仅限100次）
        <br />
        6. 会员特惠
        <br />
        为使用Easy Travel Care服务的会员提供的会员特色服务，提供机场及旅游、商务等多种礼宾服务，同时可享受折扣优惠。会员特惠服务，随时由Easy Travel Care服务的提供方“PREMIUMPASS”变更其优惠内容，会员可这网页或手机APP等程序确认变更事项。
        <br />
        7. 提供服务其他相关注意事项
        <br />
        - 用户在大韩民国旅行途中遇到交通事故及安全事故时，提供紧急应急方法、协助联系有关机构及派遣翻译人员、现场出动等服务。
        <br />
        - 该支援服务可对大韩民国境内发生的事故进行处理，对出发国家或换乘国家发生的事故，服务会受限制或无法提供服务。
        <br />
        - 该服务建议用户在发生事故后，立即联系Easy Travel Care服务中心申请服务， Easy Travel Care服务中心尚未接受该事故的委托业务，或Easy Travel Care服务经纪人接受处理事故现场前，由其他机构相关人士或第三方参与其事故处理业务时，会对Easy Travel Care服务中心协助处理事故过程产生负面影响。
        <br />
        <br />
        <h6>第四条 服务开始及终止</h6>
        Easy Travel Care服务的用户，至签订服务合同当天开始接受服务，截止到最后一天或出境当天终止。原则上使用服务天数，按24小时计算。
        <br />
        <br />
        <h6>第五条 条款的效力</h6>
        1. 该条款由Easy Travel Care中心在网页或手机APP等平台上发布或告知用户的同时，产生效力。
        <br />
        2. Easy Travel Care服务中心修改该条款的内容时，需在适用日期一周前至适用日期的前一天，根据第一项条款，以发布或告示的方法，准确明示适用日期及修改理由。此时，修改后的条款内容只适用于服务起始日在其适用日期以后的服务合同，适用日期以前开始的服务合同，则根据修改前的条款处理。
        <br />
        3. 用户如不同意修改后的条款内容，可申请取消合同。
        <br />
        4. 如用户未申请取消合同，正常使用服务时，则被视为同意修改后的条款内容。
        <br />
        <br />
        <h6>第六条 条款适用优先顺序</h6>
        1. 如Easy Travel Care服务与保险公司的保险产品有相互关系时，关于保险的所有事项，均适用于该保险产品的普通条款及特别条款。
        <br />
        2. 如Easy Travel Care服务的普通条款与特别条款有冲突时，特别条款的效力优先适用。
        <br />
        <br />
        <h6>第七条 会员及用户的咨格得失</h6>
        1. 所有个人及团体以申请签订服务合同为目的，通过Easy Travel Care服务中心提供的媒体，输入或提供Easy Travel Care服务所要求的个人识别信息（姓名、出生年月日、性别、国籍、电话号码、电子邮箱等）及旅游日程信息，而获取会员资格。但，为享受Easy Travel Care服务，用户必须提供护照信息。
        <br />
        2. Easy Travel Care服务所要求的个人信息及旅行日程信息，如会员虚假提供或提供的个人识别信息及旅行日期信息不完整，12小时以上未进行申请签订合同的阶段时，Easy Travel Care服务中心有权删除会员输入的个人识别信息及旅行日程信息，并驳回会员资格。
        <br />
        3. 为进行签订服务合同流程，Easy Travel Care服务中心可以利用会员的个人识别信息及旅行日程信息。
        <br />
        4. 根据第一项条款，获得会员资格的会员，通过签订合同获取签订证明代码，从而获得用户资格。
        <br />
        <br />
        <h6>第八条 Easy Travel Care有务的义务和权利</h6>
        1. Easy Travel Care服务中心为提供稳定服务而持续努力，如收到会员或用户的投诉时，诚恳回应。但，根据事宜的必要性和适当性，可以选择由合作机构代替回应。
        <br />
        2. 符合以下Easy Travel Care服务的各项中的一项或几项，部份或全部服务会被受限制或被中止。
        <br />
        ① 系统及设备保修期间
        <br />
        ② 提供基础服务的基干通信公司终止网络服务时
        <br />
        ③ 国家非常状态、停电、信息网连接暴增等其他不可抗力的情况
        <br />
        3. 如Easy Travel Care服务中心限制或中止服务，根据第5条第1项的方法，将其理由及限制时间等立即通知会员或用户。
        <br />
        4. 签订服务合同之前已有的既往症，即签订服务合同前既有的疾病或因既有事故或疾病引起的症状及并发症，签订合同资格可能会收限制或免除Easy Travel Care服务中心支付保险金的义务。
        <br />
        5. 为预防提供服务国及纠纷，Easy Travel Care服务中心有权保存和监视通信上的对话内容。
        <br />
        6. 因用户对该流程及提供的服务，发生纠纷时，用户明确同意使用已保存的证明资料。
        <br />
        7. Easy Travel Care服务中心，为提供稳定及正确的服务要求用户提供医疗记录（包括旅行前发生的记录、Easy Travel Care服务中心为提供服务而判断有必要的所有记录）时，用户必须将医疗记录提供给Easy Travel Care服务中心，如用户拒绝提供而无法顺利、准确地提供旅行服务的，将免除Easy Travel Care服务中心的责任。
        <br />
        8. 因发生在用户本身的事故或疾病等事件，与提供至用户本人的服务相关，并包括个人、法律个体、公共机关等针对第三方的所有权利和行动，Easy Travel Care服务有权自动行驶代理权，用户应转让该权利及行动，不得取消该内容，并且遵循对其有必要的所有法律程序，对用户要求的一项事项进行协助。
        <br />
        9. Easy Travel Care服务项目中，关于旅行者安心保险，将根据“PREMIUMPASS”的合作保险公司的规定及条款支付。
        <br />
        10. Easy Travel Care基于安心旅游礼宾服务，因此相关旅行者保险金的保障问题，可以通过“PREMIUMPASS”的合作保险公司得到保障，根据礼宾服务项目的实际产生费用由会员承担。
        <br />
        <br />
        <h6>第九条 会员及用户的义务和权利</h6>
        1. 会员及用户应遵守该条款规定的事项和服务使用指南、以及注意事项等Easy Travel Care服务所告知的事项。
        <br />
        2. 会员及用户应注意个人识别信息通过不正当的方法被使用，如发现被不正当使用，应立即通知Easy Travel Care服务中心，协助防止损失发生。
        <br />
        3. 会员及用户不得将服务相关的部份或全部权利及义务，转让或以担保提供给第三方。
        <br />
        4. 会员及用户除法律规定的内容以外，有权拒绝未经本人同意将本人的个人信息提供至第三方使用。
        <br />
        <br />
        <h6>第十条 取消合同及中途解除</h6>
        1. Easy Travel Care服务签订证明书上记载的开始日期之前，随时可以解除（取消）合同，已缴纳的服务费将全额退还。
        <br />
        2. 服务期间的变更或取消，必须在开始日期前进行。
        <br />
        3. 即使是服务进行期间，如果“PREMIUMPASS”判断为有可能发生个人识别信息的不正当及恶意、故意的事故，可以中途解除服务合同。
        <br />
        4. 签订证明书上注明的服务开始日期之后，解除服务合同（中途解除）时，减去已使用的服务期间的手续费后，全额退还，取消手续费的计算法根据保险短期费率及服务费费率计算。服务费返还请求权有效期为自用户资格丧失日之起3年。但保险费返还请求权的规定，根据保险业法进行。
        <br />
        5. 取消及中途解除所需资料：
        <br />
        ① 服务开始前取消：取消申请书（或通过电子邮件申请取消）、存折复印件
        <br />
        ② 因单纯变心而中途解除：解除申请书、护照复印件、存折复印件（根据解除申请书的发送日期进行退款）
        <br />
        ③ 因提前出境而中途解除：护照复印件、存折复印件、回国机票 E-TICKET复印件或出入境实事证明书（提出E-TICKET时，机票信息内容，不得包含服务合同期间再次入境的信息；如果提供出入境事实证明书时，服务合同期间内，以最终回国日期为基准，进行退款）
        <br />
        <br />
        <h6>第十一条 延长Easy Travel Care服务</h6>
        1. 签订服务合同后，在韩国境内滞留期间，因合同期限到期需要重新签订合同或延长合同时，用户需在合同到期前，通过SOS聊天窗口或电话、电子邮件等途径，申请重新签订或延长合同。
        <br />
        2. 无论Easy Travel Care服务中心是否通知，之前签订的合同有效期已过，或者之前签订的合同到期之前，尚未进行重新签订流程时，被视为没有重新签订的意向，并限制重新签订资格。
        <br />
        3. 签订服务合同之前已有的既往症，即签订服务合同前既有的疾病或因既有事故或疾病引起的症状及并发症，签订合同资格可能会受限制或免除Easy Travel Care服务中心支付保险金的义务。
        <br />
        <br />
        <h6>第十二条 申请签订团体保险</h6>
        1. Easy Travel Care服务为会员及用户，以签约人的身份与保险公司签订团体保险合同，提供给会员。
        <br />
        2. 如果用户签订签约人是Easy Travel Care服务的团体保险合同时，希望签为被保险人，可以通过相应流程，向包括该保险的平台申请签订保险契约，完成投保流程的用户即可成为投保人。
        <br />
        3. 预期在保险平台申请签订保险合同的会员，应先充分阅读及了解Easy Travel Care服务中心合作保险公司的签订指南及保险条款后，提出投保申请，并认真回答保险公司与Easy Travel Care服务告知的邀请事项。
        <br />
        4. 在保险平台签订保险合同时，会员签订的保险收益人是投保人（被保险人）本人及法定继承人。
        <br />
        5. 相关Easy Travel Care服务签订的团体保险，其他该条款未规定的事项，将根据Easy Travel Care服务与保险公司的规定。
        <br />
        <br />
        <h6>第十三条 旅游保险的便利支援</h6>
        Easy Travel Care服务项目中，旅行者保险的签约人是“PREMIUMPASS”，该保险为团体保险，适用团体折扣，因此可以以最低的保险费用，提供最佳的保险保障内容。另外，提供保险金代理理赔、比例理赔、代理申请保险金以及最终通知等服务。
        <br />
        <br />
        <h6>第十四条 Easy Travel Care服务 保险支援服务的限制</h6>
        Easy Travel Care服务中心，无论其理由是直接或简接，如符合以下陈述的事项为理由发生的服务项目，不予提供服务为原则。因用户要求提供服务时，可能会产生额外费用。
        <br />
        ① 签订的服务合同，未记载的服务内容
        <br />
        ② 用户及保护人（包括所属机构）的故意或重大过失
        <br />
        ③ 因用户的疾病或伤害发生的诊疗费、治疗费、医药费等所有医疗费用
        <br />
        ④ 死刑或用户的自害、自杀、自杀未遂、刑法上的犯罪行为或暴力行为（但，被确认为刑法上的正当行为、紧急避难及正当防卫时，提供支援）
        <br />
        ⑤ 用户的身心丧失或精神疾病
        <br />
        ⑥ 未经Easy Travel Care服务或合作机关的批准，因用户在其他医疗机关接受治疗的结果造成的伤害、疾病或者并发症
        <br />
        ⑦ 非Easy Travel Care的用户，或用户的同伴旅行者在旅行中发生的一切费用
        <br />
        ⑧ 用户未遵守政府对发生传染病的国家禁止旅行或接受预防疫苗的方针，回国后被确认传染病
        <br />
        ⑨ 用户自发前往发生战争、外国的武力行为、革命、内乱、事变、暴动、劳动争议及其他类似事件的地区，发生的服务内容
        <br />
        ⑩ 因核燃料物质（包括已使用完的燃料，以下皆是）或被核燃料物质污染的物质（包括原子核分裂生成物）的放射性、爆炸性或其他有害特性而发生的事故（此外包括被暴露在放射线或放射能污染）
        <br />
        ⑪ 非偶然、急剧的事故或疾病导致的怀孕、分娩、流产或外科手术、其他医疗措施或者旅行的目的本身在于治疗之前现有的伤害或疾病等医疗行为时
        <br />
        ⑫ 地震、喷发、海啸或类似的自然灾害
        <br />
        ⑬ 其他该条款内容所述的免责理由
        <br />
        <br />
        <h6>第十五条 解决纠纷</h6>
        1. 关于服务方面发生纠纷时，Easy Travel Care服务与用户，应为妥善解决而努力。
        <br />
        2. 即使根据第1项的规定努力协调后，因当事者间的纠纷进行诉讼时，关于用户与Easy Travel Care服务之间签订的合同的相关法律事项，将根据大韩民国法院指定为协议管辖法院。
        <br />
        3. 关于合同内容的纠纷，依据大韩民国的法律规定；关于保险的纠纷，依据合作保险公司的普通条款及特别条款的规定。
        <br />
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 首尔市江西区麻谷中央4路22（麻谷洞）PINESQUARE A栋 501号
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 服务中心 +82-2-2063-3544
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 服务中心 传真 +82-2-2123-0164
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 服务中心 电子邮箱 care@tothepp.com
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 服务中心 KAKAO TALK ID（@Easy Travel Care CENTER）
        <br />
        PRMIUMPAS INTERNATIONAL Corp. 注册咨询 1644-1493
        <br />
        <br />
      </>
      <h6>{language['현대해상 화재보험 약관']}</h6>
      <>
        <h6>（必填）个人（信用）信息处理同意事项</h6>
        <h6>个人(信用)信息的收集、使用相关事项</h6>
        个人信用信息的收集、使用目的
        <br />
        - 保险合同的核保、签订与维持管理(包括复效及更新) <br />
        - 保险金等的支付审查
        <br />
        - 保险净费率的计算验证
        <br />
        - 信访处理及纠纷应对
        <br />
        - 合法性及事故调查(包括保险欺诈调查)
        <br />
        - 保险募集秩序的维持
        <br />
        - 金融交易相关业务（金融交易申请、自动转账等）
        <br />
        <br />
        收集、使用的个人（信用）信息内容
        <br />
        - 个人识别信息（姓名、身份证号、外国人登陆证号、护照号码、地址、性别、职业、电话号码、电子邮件）
        <br />
        - 金融交易业务（保险费及保险金等的收支）相关信息
        <br />
        <br />
        个人（信用）信息使用期限
        <br />
        - 自同意收集、使用之日起至（金融）交易结束后5年
        <br />
        <br />
        <h6>个人（信用）信息查询相关事项</h6>
        个人信用信息的查询目的 <br />
        - 保险合同的核保、签订与维持管理（包括复效及更新）、保险金等的支付与审查、 保险事故调查（包括保险欺诈调查） <br />
        <br />
        需要查询的个人（信用）信息 <br />
        - 信用信息集中机构（生命保险协会、损害保险协会、韩国信用信息院）及保险费率 <br />
        计算机构收集管理的保险合同信息、保险金支付相关信息（包括事故信息）、被保险人的疾病及伤害相关信息 <br />
        <br />
        同意查询的有效期及个人（信用）信息的持有使用期限 <br />
        - 自同意收集使用之日起至交易结束后5年（但超过交易结束后5年的，限于保险金支付、金融事故调查、保险欺诈行为的预防与揭发、信访处理、法律义务履行等事项,将保留使用并另行保管） <br />
        <h6>（必填）有关签订保险合同的同意事项</h6>
        <h6>集体保险规程同意事项</h6>
        第一条（目的）
        <br />
        本规程旨在规定为（株）PREMIUMPASS INTERNATIONAL会员提供的服务使用相关协议事项。
        <br />
        <br />
        第二条 (集体投保认购等)
        <br />
        1.（株）PREMIUMPASS INTERNATIONAL可以作为投保人为会员与保险公司签订集体保险合同。
        <br />
        2. 希望以被保险人身份签订（株）PREMIUMPASS INTERNATIONAL作为投保人的集体保险合同的会员，可以按规定程序提出参保申请。
        <br />
        3. 拟申请参保的会员，应如实回答保险公司和（株）PREMIUMPASS INTERNATIONAL要求告知的事项。
        <br />
        <br />
        <h6>基于保险合同签订、履行等目的的个人（信用）信息处理 </h6>
        个人信用信息收集、使用相关事项 <br />
        根据个人信息保护法、信用信息使用及保护相关法律, 本合同所规定的个人(信用)信息收集、使用内容具体如下。 是否同意？ <br />
        <br />
        个人信用信息的收集、使用目的
        <br />
        - 保险合同的核保、签订与维持管理(包括复效及更新)
        <br />
        - 保险金等的支付审查
        <br />
        - 保险净费率的计算验证
        <br />
        - 信访处理及纠纷应对
        <br />
        - 合法性及事故调查(包括保险欺诈调查)
        <br />
        - 保险募集秩序的维持
        <br />
        - 金融交易相关业务（金融交易申请、自动转账等）
        <br />
        <br />
        收集、使用的个人（信用）信息内容
        <br />
        - 个人识别信息（姓名、身份证号、外国人登陆证号、护照号码、地址、性别、职业、电话号码、电子邮件）
        <br />
        - 金融交易业务（保险费及保险金等的收支）相关信息 <br />
        <br />
        个人（信用）信息使用期限
        <br />- 自同意收集、使用之日起至（金融）交易结束后5年
        <br />
        <br />
      </>
      <h6>{language['이용약관']}</h6>
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
                <h6>第一条（目的）</h6>
                该条款是以会员在PREMIUMPASS INTERNATIONAL（以下称“公司”）运营的 EASY TRAVERL CARE网站，在使用公司为会员提供的电商交易服务（以下称“服务”）时，规定全员的权利、义务及责任事项为目的。 该条款在不违反其性质的情况下，均适用于利用PC通讯、智能手机（Android手机、iPhone等）应用程序，无线等的电商交易。
                <br />
                <br />
                <h6>第二条（用语定义）</h6>
                条款中使用的用语定议如下。
                <br />
                1. “网站”是指公司为使注册会员的用户享受多种信息及服务，而制作、运营的网页。目前“公司”运营的“网站”（2021.01起）如下。
                <br />
                www.easytravelcare.co.kr
                <br />
                - 今后，“公司”公告及提供的其他网站
                <br />
                <br />
                2. “销售者”指为了商品或服务的顺利销售，从“公司”获取销售代理服务的人。但其方式为开放式销售模式的情况时，是指对“会员”购买的商品或服务等交易信息负直接责任的人。
                <br />
                <br />
                3. “会员”是指向“网站”（以下称公司）提供个人信息进行会员注册的用户，使用注册会员时设置的用户名（ID），自由登录网站并持续获取公司提供的信息或使用公司提供的服务的用户。
                <br />
                <br />
                4. “商品”是指“公司”通过网站向会员销售的商品或优惠券。
                <br />
                <br />
                5. “用户名（ID）”是指为识别“会员”以及使用服务，由“会员”选定，并经“公司”批准注册的文字或数字的组合。
                <br />
                <br />
                6. “密码（Password）是指为确认“会员”的同一性、保护“会员”的权益及秘密，“会员”自行设定并注册在“网站”上的文字和数字的组合。
                <br />
                <br />
                7. 预约确认服务券：可以以购买价格使用的商品和服务（以下称“商品”）的数码证券。
                <br />
                <br />
                8. 留言板：指“会员”在使用“服务”时，在“网站“上发表的符号、文字、语音形式的文章、图片、视频及各种文件和连接等。
                <br />
                <br />
                9. “聊天”是指购买商品后，需要进行紧急咨询时，使用的聊天服务。
                <br />
                <br />
                10. 该条款未定义的术语遵照相关法律规定。
                <br />
                <br />
                <h6>第3条（条款明示、说明及修改）</h6>
                1. 为了方便会员确认，“公司”将该条款内容及公司名、营业所在地、法人代表姓名、营业证号码、联系方式（电话号码、传真号码、电子邮箱等）、通信销售业申报号码、个人信息管理负责人等信息，在“网站”的初期“服务”页面显示。但，条款的具体内容，会员可通过连接画面查看。
                <br />
                <br />
                2. “公司”在不违背条款限制相关法律、电商交易等消费者保护相关法律、电子文件及电子交易基本法、电子签名法、促进利用信息通信网等相关法律、消费者基本法、电子金融交易法等相关法令的范围内，可修改该条款内容。
                <br />
                <br />
                3. “公司”修改条款时，应明示适用日期及修改理由，从适用日期7日之前至适用日期前一天，在现行条款和“网站”的公告栏或首页予以公告。但如修改内容不利于“会员”，且产生重大影响时，需在30日前公告。
                <br />
                <br />
                4. 根据第3条第3款，修改条款只适用于适用日期以后签订的合同，如没有法律的特别规定或其他不得已的理由，不适用修改适用日期之前的条款。
                <br />
                <br />
                5. 根据第3条第3款，公告修改适用日期之后，会员继续使用“公司”的“服务”时，视为同意修改后的条款。如会员不同意修改后的条款，可随时解除使用合同。
                <br />
                <br />
                6. 关于该条款没有明示的事项及此条款的解释，依照电商交易等消费者保护相关法律、条款限制相关法律，公平交易会员会规定的电商交易等消费者保护方针和相关法律的规定及一般惯例。
                <br />
                <br />
                <h6>第4条 (个别服务使用条款及使用条款）</h6>
                1. “公司”有权在所提供的“服务”范围内的各个“服务”项目，另设“个别服务”使用条款及使用条件，对“个别服务”单独适用的条款，会员首次使用“相关服务”时，经过单独条款同意流程后，视为同意使用“服务”内容，此时“个别服务”的使用条款优先于总条款。
                <br />
                <br />
                2. 当天18:00之前购买Easy Travel Care服务时，从购买时刻3小时之后，开始提供服务；当天18:00之后购买时，将在此日中午12:00开始提供服务。
                <br />
                <br />
                <h6>第5条 (停止提供服务）</h6>
                1. 如符合以下事项，“公司”可以停止提供“服务”
                <br />
                ① 服务使用设备的维修或检查、更换及故障等因保修工程不得已的情况
                <br />
                ② 《电信事业法》规定的基干通信事业者终止电子通信服务时
                <br />
                ③ 有其他不可抗拒的原因时
                <br />
                <br />
                2. “公司”因国家紧急状态、停电、服务设备故障或服务使用暴增等影响无法正常使用服务时，可限制或停止部分或全部“服务”。
                <br />
                <br />
                <h6>第6条（使用合同的成立）</h6>
                1. 使用合同将于“公司”批准“会员”的条款同意、使用申请后成立。
                <br />
                <br />
                2. 使用合同的成立始点为“公司”通过申请程序批准或标明注册完成提示之时。
                <br />
                <br />
                <h6>第7条（使用申请及批准）</h6>
                1. “公司”对相应于以下各号的使用申请，可以拒绝或保留批准、也可以事后解除使用合同。
                <br />
                ① 姓名及电子邮箱，与已经加入的“会员”一致的。
                <br />
                ② 盗用他人的名义、虚假记载或遗漏记载等。
                <br />
                ③ 注册内容有虚假信息或有错误记载的。
                <br />
                ④ 使用申请人根据该条款内容，此前有丧失会员资格的。
                <br />
                ⑤ 因“公司”规定丧失使用资格，经过3个月后，从“公司”获取重新注册许可的情况除外。
                <br />
                ⑥ 以不正当的用途或追求盈利为目的申请使用“服务”的（但使用“公司”许可范围内的服务时，除外）。
                <br />
                ⑦ 运营“服务”的相关设备不充裕，或技术上或业务上出现障碍时，解除障碍为止，可保留许可。
                <br />
                ⑧ 为申请服务违反相关法律、美风良俗、社会安定秩序及其他诸多事项，或者因会员的归责理由无法批准的。
                <br />
                ⑨ 其他违背该条款以及被确认为违法或使用不正当的方法申请服务时，“公司”根据合理的理由判断为有必要的。
                <br />
                <br />
                2. 根据第1项的使用申请，“公司”可通过专业机构申请实名确认及本人认证。
                <br />
                <br />
                <h6>第8条（服务使用时间）</h6>
                原则上“公司”的业务或技术上无特殊障碍时，“服务”的使用时间是365天（1天24小时）。但，由于定期检查等事由，“公司”公告的日期或时间除外。
                <br />
                <br />
                <h6>第9条（关于会员的用户名及密码的义务）</h6>
                1. 关于“用户名”和“密码”的管理责任全全归于“会员”本人，因疏忽其事项导致的所有民、刑事责任，全部在于“会员”本人。
                <br />
                <br />
                2. “会员”有义务保护自己的“用户名”及“密码”不被第三方租赁或使用。
                <br />
                <br />
                3. “会员”如发现自己的“用户名”及“密码”被盗或被第三方使用时，应立即通知“公司”，如“公司”对其有措施，则应遵从。
                <br />
                <br />
                4. 根据前项所述，如“会员”没有通知公司或没有遵从“公司“的措施，而发生的不利后果，其责任全全在于“会员”本人。
                <br />
                <br />
                <h6>第10条（使用合同解除及终止）</h6>
                1. “会员”的解除
                <br />
                ① “会员”可随时联系“公司”通知解除意项，并解除使用合同（申请注销会员），并可要求删除“会员”提供的注册账户信息资料。
                <br />
                ② 使用合同将在“会员”向公司提供解除意项同时终止。
                <br />
                ③ 根据前项解除合同的“会员（注销的会员）”，可根据“公司”指定的规定，通过注册会员程序，重新注册会员。但解除（注销）前，注册会员时所适用的信息将全部被删除，同时也不可申请退款。
                <br />
                <br />
                2. “公司”的解除
                <br />
                如有以下所列理由，“公司”可解除使用合同，该“会员”的信息将根据隐私政策处理。
                <br />
                ① 根据第7条第一项规定，确认为使用合同有拒绝许可理由时。
                <br />
                ② “会员”侵害“公司”或其他“会员”等他人的权利、名誉、信誉等正当利益时，以及违背善良风俗、其他社会秩序、大韩民国法律的行为。
                <br />
                ③ 用未经确认的事实或内容，对“公司”销售的“商品”或“服务”制造不利于公司的造谣时。
                <br />
                ④ 以扰乱交易秩序为目的，试图进行重复购买等妨碍网站程序的行为时。
                <br />
                ⑤ “公司”通过合理的判断，确认“会员”有其他违背该条款的行为时。
                <br />
                ⑥ “公司”通过电子邮件，电话，传真或其他方法，表明终止合同的意向，通知该会员解除合同的理由。
                <br />
                但，公司给予该会员事前对解除事由进行意见陈述的机会。
                <br />
                <br />
                3. 根据本条规定，使用合同终止时，“会员”将丧失使用服务的权限，会员的个人信息及记录等信息会全部被删除，关于事前购买的商品的退款等事宜，根据公司的退款规定进行处理。
                <br />
                <br />
                4. 终止使用合同所造成的损失，由解除合同的该“会员”承担，“公司”不承担一切责任。
                <br />
                <br />
                <h6>第11条（会员的义务）</h6>
                1. “会员”应遵守相关法律、该条款规定、使用指南等“公司”公告的内容，不得有其他妨碍“公司”业务的行为。
                <br />
                <br />
                2. “会员”在使用“服务”时，不得有以下各号的行为。
                <br />
                ① 申请或变更“服务”时，填写虚假内容。
                <br />
                ② 盗用他人信息。
                <br />
                ③ 未根据“公司”公告的内容，变更信息。
                <br />
                ④ 发送或发布“公司”指定信息以外的信息（电脑程序等）。
                <br />
                ⑤ 侵害“公司”及其他第三方的制作权、肖像权等知识财产权。
                <br />
                ⑥ 损害“公司”及其他第三方的名誉或妨碍业务的行为。
                <br />
                ⑦ 公开或发布违背公共秩序及美风良俗的猥亵、暴力情报、视频、音频等信息。
                <br />
                ⑧ 其他违背相关法律或“公司”指定规定的行为。
                <br />
                <br />
                <h6>第12条（公司的义务）</h6>
                1. “公司”不得实施相关法律或该条款禁止的行为或违反公序良俗的行为，根据该条款的规定，为提供持续、稳定的“服务”而竭尽全力。
                <br />
                <br />
                2. 为“会员”可安全利用“服务”，“公司”应具备为保护“会员”个人信息（包括信用信息）的系统，公示及遵守隐私政策。
                <br />
                <br />
                <h6>第13条（购买申请及同意提供个人信息等）</h6>
                1. “公员”通过“网站”，按照以下或类似于以下方法申请购买，用户申请购买时，“公司”应提供以下内容。
                <br />
                ① 搜索及选择商品。
                <br />
                ② 填写相关使用商品的预约信息（日期等）。
                <br />
                ③ 填写预约者与接受服务之人的姓名、国籍、护照码号、身份证号码、电子邮箱（或手机号）等信息
                <br />
                ④ 确认每个商品的构成、包含事项及受限于条款内容的服务等关于承担费用的内容。
                <br />
                ⑤ 同意该条款，标识接受或拒绝（第13条1-④）条款内容（例：用鼠标点击）。
                <br />
                ⑥ 同意申请购买商品等以及对其确认或“公司（网站）”的确认。
                <br />
                ⑦ 选择结算方式。
                <br />
                <br />
                2. 公司收到会员的购买申请时，向该会员发送购买确认通知，该通知抵达至会员时，购买合同开始成立。
                <br />
                <br />
                3. “公司”向第三方提供或有必要提供购买者的个人信息时，需要提前通知购买者关于公司所提供的个人信息接收者、接收个人信息的当事者使用个人信息的目的、被提供的个人信息项目、接收个人信息的当事者对个人信息的存档及使用期限等信息，并征得购买者的同意（经同意的内容有变更时，也同样适用）。
                <br />
                <br />
                4. “公司”委托第三方处理购买者的个人信息取缔业务时，必须向购买者告知个人信息处理委托方、委托处理个人信息的业务内容，并征得购买者的同意（经同意的内容有变更时，也同样适用）。但，如果有必要提供相关服务合同的履行，而且与增进购买者的便利有关时，通过促进信息通信网利用及信息保护等规定的方法，根据个人信息收集方针进行通知，无需经过通知程序及同意程序。
                <br />
                <br />
                <h6>第14条（合同成立）</h6>
                1. 相关预约及确认各商品所需要时间的内容，“公司”与“会员”双方皆同意遵守各商品页面所记载的内容。
                <br />
                <br />
                2. 第13条条款所列的“会员”提出购买申请时，如符合以下各号的内容，“公司“可决绝不予批准。
                <br />
                ① 申请内容包含虚假、遗漏记载、错误记载等。
                <br />
                ② 判断为购买申请对“公司”现存的技术有明显障碍时，因误报信息、信息遗漏、系统障碍、停止销售等理由，无法提供申请购买的“商品”和“服务”时（但“公司”事后必须通知“会员”并采取必要措施）。
                <br />
                ③ 以盗用第三方的用户名、名义或者结算方式等途径申请购买的。
                <br />
                ④ 以二次销售为目的，或者被怀疑以不正当的目的购买“商品“的。
                <br />
                ⑤ 无意购买商品，盲目重复购买与退款，导致其他用户丧失购买机会。
                <br />
                ⑥ 以违背相关法律、抵害社会秩序或美风良俗的目的，申请购买的。
                <br />
                ⑦ 其他违背该条款或被确认为以恶意利用为目的不正当申请，以及“公司” 根据合理的判断认为有必要的。
                <br />
                <br />
                3. “公司”的许可，根据第19条第1项的规定，以收信确认通知形式抵达“会员”时，合同视为成效。
                <br />
                <br />
                4. “公司”的许可意向表示，需要包含对“会员”购买申请的确认及可否销售与否、购买申请的变更、取消等相关信息。
                <br />
                <br />
                <h6>第15条（结算方式）</h6>
                1. 在“网站”购买的相关商品或人力的款项的支付方式，可在以下各号所列的方法中选择。
                <br />
                ① 信用卡
                <br />
                ② 各种PAY
                <br />
                ③ 其他“公司”指定的结算方式及通过电子支付方式支付货款
                <br />
                2. “会员”在结算过程中，输入的信息及因此信息发生的责任与不利，由会员全全负责。
                <br />
                <br />
                3. “公司”有权对“会员”结算货款时使用的结算方式确认本人信息或确认是否具有正当使用权，对此，在确认结束之前，可中断交易，无法确认时，可以取消该交易或保留退款。
                <br />
                <br />
                4. 如“会员”要求购买商品的购买确认（现金收据等）时，“公司”以实际销售金额发放。
                <br />
                <br />
                <h6>第16条（预约确认件或订单号）</h6>
                1. “会员”在“网站”上购买的商品，“公司”通过SMS或电子邮件等方式， 将其的详细内容发送给“会员”。
                <br />
                <br />
                2. “预约确认件或订单号”传送业务，因公休日、休息日、“公司”的营业时间或处理过程等状况，会有所变更，其内容遵循“网站”提示的相送内容。
                <br />
                <br />
                <h6>第17条（收信确认通知，变更或取消购买申请）</h6>
                1. “网站”收到“会员”的购买申请时，发送收信确认通知给“会员”。
                <br />
                <br />
                2. 收到收信确认通知的“会员”，如发现意见不一致的内容，请在收到确认通知之后，立即要求变更或取消购买申请，在确定预约前，如有“会员”的变更或取消要求，“网站”必须立即按照其要求进行处理。但，如果已经支付货款时，依照第20条取消订单的规定执行处理。
                <br />
                <br />
                <h6>第18条（取消订单等）</h6>
                1. “会员”的取消购买及取消订单的规定，遵守电商交易等消费者保护相关法令。
                <br />
                <br />
                2. “会员”纯粹变心导致的取消或退款，对处理其事项产生的手续费由“会员”承担。
                <br />
                <br />
                3. 如“会员”购买的“商品”的内容与标示、广告内容或合同内容不符时，至收到“商品”之日起30日内，可以撤回其订单。
                <br />
                <br />
                4. 使用信用卡或电子货币结算的“会员”，可立即向相应结算平台提出停止或取消支付货款。
                <br />
                <br />
                5. 取消购买时，取消已经结算的明细，并根据取消时间，重新测定并收取预约手续费。根据取消时间和相应信用卡公司的退款规定，取消金额的退款方式和退款日期会有所差异，信用卡的退款政策，需向相应信用卡公司直接咨询。
                <br />
                <br />
                6. 如“会员“盗用他人的个人信息，通过该条款禁止的不正当途径进行预约，并被怀疑骗取不正当利益时，“公司”有权取消或限制“会员”的购买申请，在“会员”提交辩明资料前，“公司”有权保留退款处理。
                <br />
                <br />
                7. 其他此条款及“网站”的使用指南尚无规定的取消及退款事项，按照消费者损失赔偿规定执行。
                <br />
                <br />
                <h6>第19条（商品的退款特约）</h6>
                1. “会员”购买服务后，如符合以下各号内容，则无法申请取消或退款。
                <br />
                ① 开始提供服务后，“会员”纯粹变心导致的取消邀请，将不被列为退款对象，其内容根据“卖方”的销售规定处理。
                <br />
                ② 该商品的使用期限和时间，因用户的事先选择而受到限制时。
                <br />
                2. “公司”按照退款程序进行退款时，需提前与“卖方”或“保险公司”确认协商后，进行退款。
                <br />
                <br />
                <h6>第20条（保护个人信息）</h6>
                1. 为提供相关服务，“网站”在收集“会员”个人信息时，在所需范围内收集最低限度的个人信息。
                <br />
                <br />
                2. 注册会员时，“网站”不可提前收集履行购买合同所需的信息。但，履行相关法律所规定的义务，签订购买合同需提前进行本人确认后收集最基本个人信息的情况除外。
                <br />
                <br />
                3. “网站”在收集、使用“会员”的个人信息时，应向该使用者提前告知其目的，并征得同意。
                <br />
                <br />
                4. “网站”不能将收集的个人信息，用于其目的以外的用途，如有新增的使用目的或需要提供至第三方时，在提供阶段，必须提前告知该“会员”，并征得通意。但，如果相关法律有其他规定时除外。
                <br />
                <br />
                5. 根据第2项与第3项，“网站”需要提前征得用户同意的情况 个人信息管理负责人的身份（所属机构、姓名及电话号码、其他联系方式），信息的收集及使用目的，第三方提供信息的相关事项（信息接收方、提供目的及提供的信息内容）等信息通信网促进使用及信息保护等相关法律第22条第2项规定的事项，必须提前告知“会员”，并征得同意，“会员”可随时撤回该同意内容。
                <br />
                <br />
                6. “会员“可随时要求查看“公司”保存的个人信息并更正错误信息，“公司”对此有义务立即采取必要措施。“会员”要求更正错误信息时，在其错误信息更正之前，“公司”则不使用该个人信息。但，阅览及错误更正的程序，按照“公司“的规定执行。
                <br />
                <br />
                7. 为保护个人信息，“公司”应尽量限制经营“会员”个人信息的人员，对包括信用卡、银行账号等“会员”的个人信息，如因丢失、被盗、流失、伪造、未经同意提供至第三方等原因对“会员”造成损失，“公司”承担全部责任。
                <br />
                <br />
                8. 第三方通过“公司”或其处接收个人信息后，达到收集或提供个人信息的目的时，应立即销毁该个人信息。
                <br />
                <br />
                9. “公司”不提前设置收集、使用、提供个人信息的同意栏。另外，必须提前告知“会员”，如拒绝同意提供、收集、使用个人息时，受限服务的具体内容。不得因“会员”拒绝提供非必须项目的个人信息为理由，而对该会员限制或拒绝提供服务。
                <br />
                <br />
                <h6>第21条（制作权的归属及使用限制）</h6>
                1. “网站”制作的作品的制作权和其他知识产权归“网站”所有。
                <br />
                <br />
                2. “会员”通过使用“网站”时获取的信息、图片、数据中，归属于“网站”的知识产权信息，未经“网站”的同意，不得通过复制、发送、出版、传播、广播等方式，将其用于盈利目的或提供至第三方。
                <br />
                <br />
                3. “会员”通过“网站”购买的商品的数据图片，未经“网站”同意，不得擅自通过加工、复制、发送、出版、传播、广播等方式，将其用于盈利目的或提供至第三方。
                <br />
                <br />
                <h6>第22条（会员资格限制或停止）</h6>
                1. “会员”随时可以向“公司”申请注销会员，“公司”需立即处理会员注销。
                <br />
                <br />
                2. “会员”如符合以下各号内容，“公司”有权立即限制或停止会员资格。
                <br />
                ① 注册会员或变更时，填写虚假内容的。
                <br />
                ② “会员”没有按时缴纳通过“网站”购买商品等货款，或使用“网站”的其他服务而需要支付相应费用的。
                <br />
                ③ 妨碍其他用户使用“网站”或盗用其信息等扰乱电商交易秩序的。
                <br />
                ④ 通过使用“网站”，违反法律或该条款禁止或公署约定的行为。
                <br />
                ⑤ 妨碍“网站”的健康运营或妨碍网站的业务的。
                <br />
                I. 相关“网站”的运营，散步毫无根据的事实和虚假事实，损害“公司”的信誉度的行为。
                <br />
                II. 在使用“网站”的过程中，对“公司”职员以粗暴、威胁或淫乱的言行妨碍网站正常运营的行为。
                <br />
                III. 购买商品之后，无正当理由，经常或反复取消或退货，妨碍公司正常业务的行为。
                <br />
                IV. 通过“网站”购买商品，使用部份服务之后，在商品没有特别缺陷的情况下，经常取消、部份或全部退款的行为。
                <br />
                V. 通过“网站”购买商品后，在商品没有特别缺陷的情况下，纯粹因为个人变心或没有根据的不满而妨碍“公司”正常业务的行为。
                <br />
                <br />
                <h6>第23条（免责条款）</h6>
                1. “公司”因天灾或类似不可抗力的原因，不能提供“服务”时，可免除提供“服务”的责任。
                <br />
                <br />
                2. 因“会员”自身事由导致的“服务”使用障碍，“公司”不对其负任何责任。
                <br />
                <br />
                3. 对“会员”填写的信息、资料等内容的事实可信度和准确度，“公司” 不负任何责任。
                <br />
                <br />
                4. “会员”之间或“会员”与第三方之间达成的“服务”协议，“公司”不负任何责任。
                <br />
                <br />
                5. “会员”丧失通过使用“服务”获得的预期收益，或者因通过“服务”获取的资料而受损失，“公司”对其不负任何责任。
                <br />
                <br />
                <h6>第24条（解决纠纷）</h6>
                1. 本“网站”反映“会员”提供的正当意见或不满，为赔偿或处理其损失，设立运营赔偿处理机构。
                <br />
                <br />
                2. “网站”首先处理用户提出的不满事项及意见。但，如无法迅速处理时，应立即通知用户该理由和处理进度。
                <br />
                <br />
                <h6>第25条（审判权及准据法）</h6>
                1. 关于“网站”与“会员”之间发生的电商交易纠纷的诉讼，根据诉讼当时“会员”的地址。如没有地址时，由该用户暂时居住的住所管辖内地方法院专属管理。但，起诉当时，用户的地址或住所不明确或者是外国居住者时，会向民事诉讼法的管辖法院提出诉讼。
                <br />
                <br />
                2. “网站”与“会员”之间提出的电商交易诉讼，适用韩国法律。
                <br />
                <br />
                附：该条款于2021年1月1日起实行。
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <br />
        <br />
        <h6>【隐私政策】个人信息收集及使用条款</h6>
        PREMIUMPASS INTERNATIONAL（以下简称"本公司"）非常重视您的个人信息保护，遵守《个人信息保护法》。 本公司通过个人信息处理方针，告知您所提供的个人信息以何种用途和方式被利用，为了保护个人信息正在采取何种措施。
        <br />
        <br />
        <h6>1. 个人信息处理项目</h6>本公司仅为提供安心支援服务及保险服务收集最基本的个人信息，选择项目可以忽略，并不因此而受到使用服务的限制。
        <br />
        <br />
        <h6>注册会员时，收集的项目有：</h6>
        - 必填项目：姓名、电子邮箱、生年月日、性别、身份证号码（韩国人）、护照号码（外国人）、手机号
        <br />
        - 在服务使用过程或提供服务业务处理过程中，有可能自动生成并收集以下信息（服务使用记录、登录日志、残留数据(Cookies) 、登录IP信息）
        <br />
        <br />
        <h6>2. 个人信息处理目的</h6>
        本公司收集的个人信息将用于以下目的。用户提供的所有信息，不会使用于以下所记述的目的之外的用途，如使用目的有变更，将事先征得用户同意。
        <br />
        - 主页注册会员及管理，本人确认，个人识别，防止不正当使用，防止非认可使用
        <br />
        - 确认用户身份，金融交易及服务业务，本人确认，个人识别，防止不正当使用，防止非认可使用
        <br />
        - 信访处理：
        <br />
        - 入现代海上保险，提供安心支援服务，金融交易及服务业务，收取费用
        <br />
        - 信访处理
        <br />
        - 服务宣传及营销业务
        <br />
        <br />
        <h6>3. 个人信息处理及保存期限</h6>
        个人信息的收集目的或获取目的达成时，将立即销毁用户的个人信息。
        <br />
        - 会员信息：保留至注销会员及除名为止
        <br />
        - 合同或取消订单等相关记录, 货款结算及财物供应等相关记录: 保留5年
        <br />
        - 消费者投诉, 纠纷处理: 保留3年
        <br />
        - 服务宣传及营销业务相关信息: 保留3年
        <br />
        但以下内容除外：
        <br />
        - 因费用结算问题尚未完成结算时
        <br />
        - 需要保护其他会员，因此本公司有必要保存时
        <br />
        - 依据商法等其他法令的规定，有必要保存时
        <br />
        - 因诉讼或纠纷，有必要保存为根据资料时
        <br />
        - 个别得到用户同意时
        <br />
        - 根据本公司的内部规定，有必要保存一定期间时
        <br />
        但上述情况，也要正确告知正当依据，并且事前通知保存个信息的正确期限，问题解决后，立即完全销毁。
        <br />
        <br />
        <h6>4. 个人信息销毁相关事项</h6>
        本公司达成《个人信息处理目的》或经过《处理及保存期限》时，将立即销毁该个人信息。
        <br />
        - 以电子文件形式保存的个人信息，使用无法修复记录的技术方法删除
        <br />
        - 以印刷方式打印出来的个人信息，用粉碎机粉碎并销毁
        <br />
        <br />
        <h6>5. 个人信息提供至第三方相关事项</h6>
        本公司除已得到用户同意或依据相关法令的规定之外，绝不向第三方提供用户的个人信息。
        <br />
        <br />
        - 第三者提供项目及提供目的（结算公司PG社, 另行通知）
        <br />
        - 提供至（PG社）的信息: 姓名、手机号码、电子邮箱、产品名称
        <br />
        提供目的：电子结算
        <br />
        - 提供至现代海上保险公司的信息：姓名、身份证号码、护照号码（外国人）、性别、手机号码、电子邮箱、产品名称
        <br />
        提供目的：入保险
        <br />
        - 个人信息保存期限: 根据使用目的提供个人信息起, 至提供目的达成时（或第3条明示的期限为止）。
        <br />
        <br />
        <h6>6. 信息主体的权力、义务及其运用方法相关事项</h6>
        您可以随时撤回为注册Easy Travel Care服务而同意收集、使用及提供个人信息的内容。您可以提出查阅、更正、停止、删除个人信息的要求, 以书面、电话或传真等方式联系个人信息保护负责人, 本公司将立即采取销毁该个人信息等措施。但, 如有法律规定的义务事项时, 部份权力将会受限制。
        <br />
        <br />
        <h6>7. 个人信息保护负责人</h6>为了保护用户的个人信息，处理有关个人信息的投诉，本公司设有个人信息保护负责人。
        <br />
        - 姓名: 韩真熙
        <br />
        - 职责: 代理
        <br />
        - 部门: Easy Travel Care服务 专属负责人
        <br />
        - 电话: +82-2-1644-1493
        <br />
        <br />
        <h6>8. 个人信息的安全性确保措施</h6>
        用户所提供的个人信息，全部使用防火墙等安全系统进行管理。另外，本公司为安全保护用户的个人信息，分级赋予对用户个人信息的接触权限，限制对管理用户个人信息人员的配置，持续进行保安教育。并且，指定个人信息处理系统的专属人员，保存登录记录。
        <br />
        <br />
        <h6>9. 个人信息处理方针变更相关事项</h6>
        个人信息处理方针的制定日期为2021年1月1日, 根据法令、政策或保安技术的变更, 如有追加、删除及修改内容时, 将在实行更新后的个人信息处理方针7日前, 通过本网站通知变更事由及内容。
        <br />
        <br />
        公告日期: 2020年1月1日 / 实行日期: 2021年1月1日
        <br />
        <br />
      </>
      <h6>{language['이메일무단수집거부']}</h6>
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>拒绝利用电子邮件收集程序或其他技术设备擅自收集本站公布的电子邮箱地址，如有违返，请留意根据信息通信网法进行刑事处罚。</li>
            <li>信息通信网利用及信息保护等相关法律（部分修订2002.12.18 法律第0697号）</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">第50条第2款（禁址擅自收集电子邮箱地址等）</h6>
              <ul className="list secondary">
                <li>任何人不得在明确标示拒绝收集电子邮箱地址的网站上，利用电子邮件收集程序或其他技术设备擅自收集电子邮箱地址。</li>
                <li>依据第一款规定，任何人不得擅自销售、流通已收集的电子邮箱地址。</li>
                <li>依据第一款及第二款规定，任何人不得在明知是禁止收集、销售及流通的电子邮箱的情况下，仍将其用于信息传送。</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">第65条第2款 （处罚）符合下列内容者，处以1千万韩元以下的罚款。</h6>
              <ul className="list secondary">
                <li>采取违反第50条第4款所规定的技术性措施之人</li>
                <li>传送违反第50条第6款所规定的具有盈利为目的的广告信息之人</li>
                <li>违反第50条第2款规定，将电子邮箱用于收集、销售、流通或者信息传送之人。</li>
              </ul>
            </div>
          </div>
          发布日期：2021-01-04
        </div>
      </div>
    </>
  )
}

export default B2BTermCNView
