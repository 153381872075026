function PrivacyCNView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
            <h3>【隐私政策】个人信息收集及使用条款</h3>
            PREMIUMPASS INTERNATIONAL（以下简称"本公司"）非常重视您的个人信息保护，遵守《个人信息保护法》。 本公司通过个人信息处理方针，告知您所提供的个人信息以何种用途和方式被利用，为了保护个人信息正在采取何种措施。
            <br />
            <br />
            <h5>1. 个人信息处理项目</h5>本公司仅为提供安心支援服务及保险服务收集最基本的个人信息，选择项目可以忽略，并不因此而受到使用服务的限制。
            <br />
            <br />
            <h6>注册会员时，收集的项目有：</h6>
            - 必填项目：姓名、电子邮箱、生年月日、性别、身份证号码（韩国人）、护照号码（外国人）、手机号
            <br />
            - 在服务使用过程或提供服务业务处理过程中，有可能自动生成并收集以下信息（服务使用记录、登录日志、残留数据(Cookies) 、登录IP信息）
            <br />
            <br />
            <h5>2. 个人信息处理目的</h5>
            本公司收集的个人信息将用于以下目的。用户提供的所有信息，不会使用于以下所记述的目的之外的用途，如使用目的有变更，将事先征得用户同意。
            <br />
            - 主页注册会员及管理，本人确认，个人识别，防止不正当使用，防止非认可使用
            <br />
            - 确认用户身份，金融交易及服务业务，本人确认，个人识别，防止不正当使用，防止非认可使用
            <br />
            - 信访处理：
            <br />
            - 入现代海上保险，提供安心支援服务，金融交易及服务业务，收取费用
            <br />
            - 信访处理
            <br />
            - 服务宣传及营销业务
            <br />
            <br />
            <h5>3. 个人信息处理及保存期限</h5>
            个人信息的收集目的或获取目的达成时，将立即销毁用户的个人信息。
            <br />
            - 会员信息：保留至注销会员及除名为止
            <br />
            - 合同或取消订单等相关记录, 货款结算及财物供应等相关记录: 保留5年
            <br />
            - 消费者投诉, 纠纷处理: 保留3年
            <br />
            - 服务宣传及营销业务相关信息: 保留3年
            <br />
            但以下内容除外：
            <br />
            - 因费用结算问题尚未完成结算时
            <br />
            - 需要保护其他会员，因此本公司有必要保存时
            <br />
            - 依据商法等其他法令的规定，有必要保存时
            <br />
            - 因诉讼或纠纷，有必要保存为根据资料时
            <br />
            - 个别得到用户同意时
            <br />
            - 根据本公司的内部规定，有必要保存一定期间时
            <br />
            但上述情况，也要正确告知正当依据，并且事前通知保存个信息的正确期限，问题解决后，立即完全销毁。
            <br />
            <br />
            <h5>4. 个人信息销毁相关事项</h5>
            本公司达成《个人信息处理目的》或经过《处理及保存期限》时，将立即销毁该个人信息。
            <br />
            - 以电子文件形式保存的个人信息，使用无法修复记录的技术方法删除
            <br />
            - 以印刷方式打印出来的个人信息，用粉碎机粉碎并销毁
            <br />
            <br />
            <h5>5. 个人信息提供至第三方相关事项</h5>
            本公司除已得到用户同意或依据相关法令的规定之外，绝不向第三方提供用户的个人信息。
            <br />
            <br />
            - 第三者提供项目及提供目的（结算公司PG社, 另行通知）
            <br />
            - 提供至（PG社）的信息: 姓名、手机号码、电子邮箱、产品名称
            <br />
            提供目的：电子结算
            <br />
            - 提供至现代海上保险公司的信息：姓名、身份证号码、护照号码（外国人）、性别、手机号码、电子邮箱、产品名称
            <br />
            提供目的：入保险
            <br />
            - 个人信息保存期限: 根据使用目的提供个人信息起, 至提供目的达成时（或第3条明示的期限为止）。
            <br />
            <br />
            <h5>6. 信息主体的权力、义务及其运用方法相关事项</h5>
            您可以随时撤回为注册Easy Travel Care服务而同意收集、使用及提供个人信息的内容。您可以提出查阅、更正、停止、删除个人信息的要求, 以书面、电话或传真等方式联系个人信息保护负责人, 本公司将立即采取销毁该个人信息等措施。但, 如有法律规定的义务事项时, 部份权力将会受限制。
            <br />
            <br />
            <h5>7. 个人信息保护负责人</h5>为了保护用户的个人信息，处理有关个人信息的投诉，本公司设有个人信息保护负责人。
            <br />
            - 姓名: 韩真熙
            <br />
            - 职责: 代理
            <br />
            - 部门: Easy Travel Care服务 专属负责人
            <br />
            - 电话: +82-2-1644-1493
            <br />
            <br />
            <h5>8. 个人信息的安全性确保措施</h5>
            用户所提供的个人信息，全部使用防火墙等安全系统进行管理。另外，本公司为安全保护用户的个人信息，分级赋予对用户个人信息的接触权限，限制对管理用户个人信息人员的配置，持续进行保安教育。并且，指定个人信息处理系统的专属人员，保存登录记录。
            <br />
            <br />
            <h5>9. 个人信息处理方针变更相关事项</h5>
            个人信息处理方针的制定日期为2021年1月1日, 根据法令、政策或保安技术的变更, 如有追加、删除及修改内容时, 将在实行更新后的个人信息处理方针7日前, 通过本网站通知变更事由及内容。
            <br />
            <br />
            公告日期: 2020年1月1日 / 实行日期: 2021年1月1日
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyCNView
