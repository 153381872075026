import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)
  const possibleChat = useSelector((state: any) => state.possibleChat.value)
  const clickChat = () => {
    if (possibleChat === 'needlogin') {
      navigate('/login')
    } else if (possibleChat === 'usable') {
      window.open('/sos/chat/room', '_blank')
    } else if (possibleChat === 'needAgree') {
      navigate('/sos/terms')
    } else if (possibleChat === 'needPurchase') {
      navigate('/purchase/information')
    } else if (possibleChat === 'agreeButYet') {
      alert('관리자 승인전입니다.')
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <div className="footerwrap">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 mb-3">
              <ul className="footer-link mb-3">
                <li>
                  <Link to="/terms">
                    <span>{language['이용약관']}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/terms/privacy">
                    <span>{language['개인정보처리방침']}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/terms/email-collection">
                    <span>{language['이메일무단수집거부']}</span>
                  </Link>
                </li>
              </ul>
              <div className="footer-text">
                {language['상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542']}
                <br />
                {language['통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 |']}{' '}
                <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1098606310" style={{ color: '#777' }}>
                  {language['사업자 정보확인']}
                </a>
                <br />
                {language['소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호']}
                <br />
                {language['개인정보 처리 책임자 : 임상엽 (care@tothepp.com)']}
              </div>
              <ul className="footer-link mt-2 mb-4">
                <li>
                  <a href="https://www.facebook.com/premiumpassintl" target="_blank" rel="noopener noreferrer">
                    <img src="/resources/home/img/svg/sns-facebook.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/premium_pass_/" target="_blank" rel="noopener noreferrer">
                    <img src="/resources/home/img/svg/sns-instagram.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://blog.naver.com/totheppi" target="_blank" rel="noopener noreferrer">
                    <img src="/resources/home/img/svg/sns-naverblog.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <h4>
                <small>{language['고객센터']}</small> 1644-1493
              </h4>
              <div className="footer-text">{language['월-금 09:00-18:00 (점심시간 12:00-13:00), 한국시간 기준']}</div>
              <div className="footer-text">{language['한국시간 기준']}</div>
              <Link to="/">
                <img src="/resources/home/img/svg/logo.svg" alt="" className="img-fluid mt-3" />
              </Link>
              <br />
            </div>
          </div>
        </div>
        <div className="footer-copy">© Easy Travel Care</div>
      </div>
      <div id="sos-call" style={{ cursor: 'pointer' }}>
        <a onClick={clickChat} className="sos-call">
          <img src="/resources/home/img/svg/sos-btn.svg" alt="" />
        </a>
      </div>
      <div id="pagetop" className="hide">
        <a href="#">
          <img src="/resources/home/img/svg/arrow-top.svg" alt="" />
        </a>
      </div>
    </>
  )
}

export default Footer
