import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

function FindResultLoginIdView() {
  const { state } = useLocation()
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-5">
        <div className="nav-tabs-box">
          <ul className="nav nav-tabs nav-fill">
            <li className="nav-item">
              <Link to="/find/login-id" className="nav-link active">
                {language['아이디 찾기']}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/find/password" className="nav-link ">
                {language['비밀번호 찾기']}
              </Link>
            </li>
          </ul>
        </div>

        <div className="loginwrap">
          <div className="row no-gutters">
            <div className="col-12">
              <form>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group mt-5 mb-5">
                  <h4 className="text-space-lg">
                    {language['고객님의 아이디는']}
                    <br />
                    <span className="text-primary">{state.loginId}</span>
                    <br />
                    {language['입니다.']}
                  </h4>
                </div>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group">
                  <Link to="/login" className="btn btn-primary btn-block btn-lg">
                    {language['로그인']}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FindResultLoginIdView
