import { createSlice } from '@reduxjs/toolkit'
import { languageSet } from './languageSet'

const initalStateValue = () => {
  const language = localStorage.getItem('language') || 'kr'
  if (language === 'en') {
    return languageSet.english
  } else if (language === 'cn') {
    return languageSet.chinese
  } else if (language === 'jp') {
    return languageSet.japanese
  } else {
    return languageSet.korean
  }
}

export const languageSlice = createSlice({
  name: 'language',
  initialState: { value: initalStateValue() },
  reducers: {
    changeLanguage: (state, action) => {
      if (action.payload === 'en') {
        state.value = languageSet.english
      } else if (action.payload === 'cn') {
        state.value = languageSet.chinese
      } else if (action.payload === 'jp') {
        state.value = languageSet.japanese
      } else {
        state.value = languageSet.korean
      }
      localStorage.setItem('language', action.payload)
    },
  },
})

export const { changeLanguage } = languageSlice.actions
export default languageSlice.reducer
