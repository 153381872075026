import { Navigate, Outlet } from 'react-router-dom'
import PurchaseHeaderView from './view/PurchaseHeaderView'
import PurchaseStatusView from './view/PurchaseStatusView'
import { useSelector } from 'react-redux'

function PurchasePage() {
  const loginUser = useSelector((state: any) => state.user.value)
  if (loginUser === null || loginUser.memberSid === null || loginUser.memberSid === '') {
    return <Navigate to="/login?redirect-page=purchase/information" />
  }

  return (
    <>
      <PurchaseHeaderView />
      <PurchaseStatusView />
      <Outlet />
      <div className="divide"></div>
    </>
  )
}

export default PurchasePage
