/* eslint-disable */
let websoket = null

export function createWebSocket(message, onopen) {
  websoket = new SockJS('/echo')
  websoket.onopen = onopen
  websoket.onmessage = message

  websoket.onerror = (error) => {
    console.error('WebSocket 에러 발생: ', error)
  }
  websoket.onclose = () => {
    console.log('WebSocket 연결 종료')
  }
  return websoket
}

export function sendChatMessage(user, type, message, chatRoomName, geo) {
  websoket.send(
    JSON.stringify({
      msgStatus: type,
      msgContent: message,
      msgSendId: user.memberId,
      msgSendName: user.memberName,
      msgGeo: geo,
      chatRoomName: chatRoomName,
      msgSendTime: new Date(),
    })
  )
}
