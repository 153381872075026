import { createGlobalStyle } from 'styled-components'

const FontJP = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;800&display=swap');

body {
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans JP", Roboto, "NSimsun", "MS PGothic", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Noto Sans JP', sans-serif !important;
}
.carousel-caption {
	font-family: 'Noto Sans JP', sans-serif !important;
}
.page-header h2 {
	font-family: 'Noto Sans JP', sans-serif !important;
}
`

export default FontJP
