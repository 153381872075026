import { Navigate, Outlet, useLocation } from 'react-router-dom'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import { useSelector } from 'react-redux'

function B2BPage() {
  const language = useSelector((state: any) => state.language.value)

  const location = useLocation()
  const loginUser = useSelector((state: any) => state.user.value)
  if (loginUser === null || loginUser.memberSid === null || loginUser.memberSid === '' || loginUser.memberAuth !== 6) {
    return <Navigate to="/login?redirect-page=b2b/service_purchase" />
  }

  const getTitle = (path: string) => {
    if (path === '/b2b/service_purchase') {
      return language['서비스 구매']
    }
    return language['회원 관리']
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub2.jpg" title={getTitle(location.pathname)} />
      <BreadcrumbView title={getTitle(location.pathname)} link={location.pathname} />
      <Outlet />
    </>
  )
}

export default B2BPage
