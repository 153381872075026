import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function MainServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="section bg-light main_service">
      <div className="container">
        <h2 className="en text-center">{language['서비스 소개']}</h2>
        <div className="row mt-5">
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['의료 안심케어']}</h5>
                <p>
                  {language['여행 중 발생할 수 있는']} <br />
                  {language['여러 사고에 준비되어 있는']} <br />
                  {language['의료 지원 서비스']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_medical.jpg" className="img-fluid" alt="" />
                <Link to="/information/medical" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/medical" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['공항 안심케어']}</h5>
                <p>
                  {language['국제공항이라는 특별한']}
                  <br />
                  {language['공간에서의 고객을 위한']}
                  <br />
                  {language['안심 케어 서비스']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_airport.jpg" className="img-fluid" alt="" />
                <Link to="/information/airport" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/airport" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['트래블 안심케어']}</h5>
                <p>
                  {language['여행 중 긴급사항 발생시']}
                  <br />
                  {language['제공되는 다양한']}
                  <br />
                  {language['트래블 컨시어지 서비스']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_travel.jpg" className="img-fluid" alt="" />
                <Link to="/information/travel" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/travel" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['비즈니스 안심케어']}</h5>
                <p>
                  {language['편안하고 안전한 여행과']}
                  <br />
                  {language['성공적인 비즈니스를 위한']}
                  <br />
                  {language['특화 컨시어지 서비스']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_business.jpg" className="img-fluid" alt="" />
                <Link to="/information/business" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/business" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['여행자 안심케어']}</h5>
                <p>
                  {language['여행 중 발생할 수 있는 A']}
                  <br />
                  {language['휴대품 손해, 배상책임에서 A']}
                  <br />
                  {language['사망까지']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_traveler.jpg" className="img-fluid" alt="" />
                <Link to="/information/traveler" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/traveler" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{language['특별 혜택']}</h5>
                <p>
                  {language['20여 가지의']}
                  <br />
                  {language['멤버십 특별혜택 제공']}
                </p>
              </div>
              <div className="card-img-middle d-flex align-items-center justify-content-center">
                <img src="/resources/home/img/service_special.jpg" className="img-fluid" alt="" />
                <Link to="/information/special" className="btn btn-primary btn-round">
                  {language['Details']}
                </Link>
              </div>
              <div className="card-footer text-muted text-right">
                <Link to="/information/special" className="btn btn-outline-dark btn-round btn-sm en">
                  {language['More']} <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainServiceView
