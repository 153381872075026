import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'
import { paymentRequest } from '../../../../network/payment-request'
import { addCommasToNumber } from '../../../../utils/number-format'

function PurchasePaymentView() {
  const { state } = useLocation()
  const language = useSelector((state: any) => state.language.value)

  const [timeLeft, setTimeLeft] = useState(20 * 60)
  const [paymentData, setPaymentData] = useState<any[]>()

  const navigate = useNavigate()
  const paymentInformation = (currency: string) => {
    axiosConnect(
      '/rest/purchase/purchaseSummary',
      'GET',
      {},
      {
        term: state.day,
        startDate: state.startDay,
        currency: currency,
      }
    )
      .then((result) => {
        let data: any = []
        Object.entries(result.data).forEach(([key, value]) => {
          data.push({ key: key, value: value })
        })
        setPaymentData(data)
        setTimeout(() => paymentRequest('SendPayForm_id'), 2000)
      })
      .catch((error) => {
        alert(error)
      })

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }

  useEffect(() => {
    if (timeLeft <= 0) {
      navigate(-1)
    }
  }, [timeLeft])

  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft % 60

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <form className="form-add">
              <section>
                <h5>{language['서비스 안내']}</h5>
                <hr className="dark mt-0" />
                <h6>{language['컨시어지 및 채팅 서비스']}</h6>
                <ul className="list">
                  <li>{language['원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.']}</li>
                  <li>{language['상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.']}</li>
                </ul>
                <h6 className="mt-4">{language['서비스 이용 권장 사항']}</h6>
                <ul className="list">
                  <li>{language['이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.']}</li>
                </ul>
                <h6 className="mt-4">{language['이용권 구매 및 환불규정']}</h6>
                <ul className="list">
                  <li>{language['모든 이용권은 부가세(VAT) 포함 가격입니다.']}</li>
                  <li>{language['출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.']}</li>
                  <li>{language['서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.']}</li>
                  <li>{language['이용권 사용 시작 전에는 100% 환불이 가능합니다.']}</li>
                </ul>
                <hr className="dark" />
              </section>
              <br />

              <section>
                <h5>{language['서비스 결제 정보 확인']}</h5>
                <div className="boxwrap">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>{language['서비스명']}</th>
                        <td className="text-left">{language['EasyTraverCare']}</td>
                      </tr>
                      <tr>
                        <th>{language['가입기간']}</th>
                        <td className="text-left">{state.day + ' ' + language['일']}</td>
                      </tr>
                      <tr>
                        <th>{language['서비스 기간']}</th>
                        <td className="text-left">
                          {state.startDay} ~ {state.endDay}
                        </td>
                      </tr>
                      <tr>
                        <th>{language['이용금액']}</th>
                        <td className="text-left">
                          ${state.priceEn} (₩{addCommasToNumber(state.priceKr)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section>
                <div className="greybox mt-0">
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <h6>
                        <strong className="text-lg">{state.day + language['일']}</strong> {language['이용기간에 대한 서비스 총 결제 금액']}{' '}
                        <strong className="text-lg">
                          ${state.priceEn} (₩{addCommasToNumber(state.priceKr)})
                        </strong>{' '}
                        {language['입니다.']}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-left">{language['위의 금액을 결제하시겠습니까?']}</div>
                  </div>
                  <hr className="dark" />
                  <div className="p-3 mb-4 bg-danger text-white text-center">
                    {language['사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.']} <br />
                    <strong className="text-md">{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</strong>
                  </div>
                </div>
              </section>
            </form>

            <form id="SendPayForm_id" method="post">
              {paymentData && paymentData.map((item, index) => <input key={index} type="hidden" name={item.key} value={item.value}></input>)}
            </form>

            <div className="row">
              <div className="col-4">
                <a onClick={() => navigate(-1)} className="btn btn-outline-dark btn-lg">
                  {language['이전화면']}
                </a>
              </div>
              <div className="col-4 text-right">
                <a onClick={() => paymentInformation('WON')} className="btn btn-primary btn-lg">
                  {language['국내카드 결제']}
                </a>
              </div>
              <div className="col-4 text-right">
                <a onClick={() => paymentInformation('USD')} className="btn btn-primary btn-lg">
                  {language['해외카드 결제']}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchasePaymentView
