import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosConnect from '../../../../network/http-request'
import PagenationView from '../../../common/PaginationView'
import { DateUtil } from '../../../../utils/date-format'

function B2BMember() {
  const language = useSelector((state: any) => state.language.value)

  const [pageNo, setPageNo] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [tempSearchKeyword, setTempSearchKeyword] = useState('')
  const [memberList, setMemberList] = useState<any>({
    totalPages: 1,
    content: [],
  })

  useEffect(() => {
    getMemberList()
  }, [pageNo, keyword])

  const getMemberList = () => {
    if (keyword === '') {
      fetchMemberList(`${pageNo}`)
    } else {
      fetchMemberList(`${pageNo}/${keyword}`)
    }
  }

  const fetchMemberList = (params: string) => {
    axiosConnect(`/api/purchase/myMemberList/${params}`, 'GET')
      .then((result) => {
        let resultData = result.data
        if (resultData.totalPages === 0) {
          resultData.totalPages = 1
        } else {
          if (resultData.numberOfElements < resultData.pageable.pageSize) {
            resultData.totalPages = resultData.totalPages - 1
          }
        }
        setMemberList(resultData)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.nativeEvent.isComposing === false && tempSearchKeyword !== '') {
      searchMemberList(tempSearchKeyword)
    }
  }

  const searchMemberList = (value: string) => {
    setPageNo(1)
    setKeyword(value)
    getMemberList()
  }

  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row mb-3">
          <div className="col-md-4">
            {/* <select className="form-control mt-2">
              <option>{language['서비스 제목']}</option>
            </select> */}
          </div>
          <div className="col-md-8 form-inline justify-content-end">
            <div className="input-group mt-2">
              {/* <select className="form-control col-4 mr-1">
                <option>{language['제목']}</option>
                <option>{language['내용']}</option>
                <option>{language['제목+내용']}</option>
                <option>{language['작성자']}</option>
              </select> */}
              <input
                type="text"
                className="form-control col-12 mr-2"
                value={tempSearchKeyword}
                onChange={(e) => {
                  setTempSearchKeyword(e.target.value)
                }}
                placeholder={language['검색어를 입력해주세요.']}
                onKeyDown={handleKeyDown}
              />
              <a
                onClick={(e) => {
                  searchMemberList(tempSearchKeyword)
                }}
                className="btn btn-outline-dark"
              >
                <i className="bi bi-search"></i>
              </a>
            </div>
            <a
              onClick={() => {
                setTempSearchKeyword('')
                searchMemberList('')
              }}
              className="btn btn-primary ml-1 mt-2"
            >
              {language['전체보기']}
            </a>
          </div>
        </div>

        <div className="boxwrap">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col">{language['번호']}</th>
                <th scope="col">{language['여권번호']}</th>
                <th scope="col">{language['아이디']}</th>
                <th scope="col">{language['이름']}</th>
                <th scope="col">{language['성별']}</th>
                <th scope="col">{language['생년월일']}</th>
                <th scope="col">{language['이메일']}</th>
                <th scope="col">{language['서비스 이용기간']}</th>
                <th scope="col">{language['구매 연동']}</th>
                <th scope="col">{language['일수']}</th>
              </tr>
            </thead>
            <tbody>
              {memberList.content &&
                memberList.content.map((member: any, index: number) => (
                  <tr>
                    <td>{member.seq}</td>
                    <td>{member.memberPassportNum}</td>
                    <td>{member.memberId}</td>
                    <td>{member.memberName}</td>
                    <td>{member.memberGender === 'Male' ? language['남성'] : language['여성']}</td>
                    <td>{member.memberBirthday}</td>
                    <td>{member.memberEmail}</td>
                    <td>
                      {DateUtil.convertDate(member.memberStartDate)}~{DateUtil.convertDate(member.memberEndDate)}
                    </td>
                    <td>{member.title}</td>
                    <td>{member.memberSelectTerm}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="text-center">
            <PagenationView
              currentPage={pageNo}
              totalPages={memberList.totalPages}
              updatePage={(index: number) => {
                setPageNo(index)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default B2BMember
