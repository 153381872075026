import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api'
import { DateUtil } from '../../../../utils/date-format'

function ChatMapView(props: any) {
  const { isLoginUser, chat } = props
  function isUserMessageClass() {
    return isLoginUser ? 'message-me' : 'message'
  }
  const location = {
    lat: Number(chat.msgContent.split('|')[0]),
    lng: Number(chat.msgContent.split('|')[1]),
  }
  const containerStyle = {
    width: '400px',
    height: '400px',
  }

  return (
    <div className={isUserMessageClass()}>
      <div className="message-body">
        <LoadScriptNext googleMapsApiKey={`AIzaSyDRiQ0wIhtX9mM9kcMMlAjkZXr9jkLOh7s`}>
          <GoogleMap zoom={15} center={location} mapContainerStyle={containerStyle}>
            <MarkerF position={location} />
          </GoogleMap>
        </LoadScriptNext>

        <div className="message-date">{DateUtil.getFormatDate(chat.msgSendTime)}</div>
      </div>
    </div>
  )
}

export default ChatMapView
