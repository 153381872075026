import { useSelector } from 'react-redux'

function ConciergeSeoulView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="page-header">
              <h2>
                {language['프리미엄라운지']} <br />
                {language['서울역점']}
              </h2>
            </div>
            <hr className="blue" />
            <ul className="list">
              <li>
                <h6>{language['위치: 서울역 도심공항터미널 지하 2층']}</h6>
              </li>
            </ul>
            <hr className="blue" />
          </div>
          <div className="col-12 col-md-7">
            <img src="/resources/home/img/desk_seoul.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light-blue mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="col-12 offset-md-1 col-md-10 mt-5">
                <p className="text-center">
                  <img src="/resources/home/img/svg/desk_seoul_map1_en.svg" alt="" className="d-block w-100" />
                </p>
                <p className="pt-4"></p>
                <hr className="mt-5 mb-5 blue" />
                <p className="mt-0 mb-0 p-1"></p>
              </div>
              <div className="col-12 offset-md-1 col-md-10 mt-3">
                <p className="text-center">
                  <img src="/resources/home/img/svg/desk_seoul_map2_en.svg" alt="" className="d-block w-100" />
                </p>
                <p className="pt-5"></p>
                <hr className="mt-1 mb-3 blue" />
              </div>
              <div className="col-12 offset-md-1 col-md-10 mt-3 section2St">
                <div className="titSt">
                  <span></span>
                  <h3></h3>
                </div>
                <ul>
                  <li>
                    <h4>{language['카페']}</h4>
                    <p></p>
                    <span className="iconSt"></span>
                  </li>
                  <li>
                    <h4>{language['사무용품&스낵']}</h4>
                    <p></p>
                    <span className="iconSt"></span>
                  </li>
                  <li>
                    <h4>{language['여행 편의 서비스']}</h4>
                    <p></p>
                    <span className="iconSt"></span>
                  </li>
                  <li>
                    <h4>{language['비즈니스 업무']}</h4>
                    <p></p>
                    <span className="iconSt"></span>
                  </li>
                  <li>
                    <h4>{language['소규모 회의실 대관']}</h4>
                    <p></p>
                    <span className="iconSt"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConciergeSeoulView
