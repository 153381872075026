import { Link, useLocation } from 'react-router-dom'

function BreadcrumbView(props: any) {
  const location = useLocation()
  const { title, link, names } = props

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb d-flex justify-content-end">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="bi bi-house"></i>
                </Link>
              </li>
              {names ? (
                <li className="breadcrumb-item">
                  <Link to={link}>{title}</Link>
                </li>
              ) : (
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              )}
              {names && (
                <li className="breadcrumb-item active" aria-current="page">
                  {names[location.pathname]}
                </li>
              )}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default BreadcrumbView
