import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosConnect, { axiosConnectJson, axiosConnectFileUpload } from '../../../network/http-request'
import { useSelector } from 'react-redux'
import ChatTextView from './view/ChatTextView'
import ChatBotView from './view/ChatBotView'
import ChatBotAnswerView from './view/ChatBotAnswerView'
import useWatchLocation from '../../../utils/useWatchLocation'
import ChatMapView from './view/ChatMapView'
import ChatImageView from './view/ChatImageView'
import { createWebSocket, sendChatMessage } from '../../../network/chat-socket'
import ChatJoinView from './view/ChatJoinView'

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
}
let chatMessage: any[] = []
let chatRoomName = ''

function SOSCallChatRoom() {
  const { state } = useLocation()
  const loginUser = useSelector((state: any) => state.user.value)
  const language = useSelector((state: any) => state.language.value)

  const navigate = useNavigate()
  const messageListView = useRef() as React.MutableRefObject<HTMLDivElement>
  const [chatList, setChatList] = useState<any[]>([])
  const [message, setMessage] = useState('')
  const [roomName, setRoomName] = useState('')
  const [chatRoomConnect, setChatRoomConnect] = useState(false)
  const { location } = useWatchLocation(geolocationOptions)

  useEffect(() => {
    chatMessage = []
    if (state.roomId !== null && state.roomId !== '') {
      setRoomName(state.roomName)
      chatRoomName = state.roomName
      createWebSocket(onMessage, onConnect)
      getChatLog(state.roomId, '')
    } else {
      getChatBot()
    }

    if (messageListView && messageListView.current) {
      const handleScrollDown = (event: any) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      }
      messageListView?.current?.addEventListener('DOMNodeInserted', handleScrollDown)
      return () => {
        messageListView?.current?.removeEventListener('DOMNodeInserted', handleScrollDown)
      }
    }
  }, [])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.nativeEvent.isComposing === false) {
      sendMessage('TEXT', message)
    }
  }

  const onMessage = (event: any) => {
    console.log(`onMessage: ${event}`)
    chatMessage.push(JSON.parse(event.data))
    setChatList([...chatMessage])
  }

  const onConnect = () => {
    if (state.roomName === '') {
      sendMessage('JOIN', language['담당매니저연결중입니다'] + '<br />' + language['빠른시간내에연락드리겠습니다'])
    }
    setChatRoomConnect(true)
  }

  const createChatRoom = (parent: string) => {
    setRoomName(`${loginUser.memberSid} ${parent}`)
    chatRoomName = `${loginUser.memberSid} ${parent}`
    createWebSocket(onMessage, onConnect)
  }

  const clickMessage = () => {
    if (chatRoomConnect) {
      sendMessage('TEXT', message)
    }
  }

  const clickMap = () => {
    if (chatRoomConnect) {
      sendMessage('MAP', location.latitude + '|' + location.longitude)
    }
  }

  const sendMessage = (status: string, message: string) => {
    sendChatMessage(loginUser, status, message, roomName ? roomName : chatRoomName, location.latitude + '|' + location.longitude)
    saveChatLog({
      msgStatus: status,
      msgContent: message,
      msgSendId: loginUser.memberId,
      msgSendName: loginUser.memberName,
      msgGeo: location.latitude + '|' + location.longitude,
      chatRoomName: roomName ? roomName : chatRoomName,
      msgSendTime: new Date(),
    })
    setMessage('')
  }

  function isUserMessageClass(loginId: string) {
    return loginUser.memberId === loginId
  }

  function chatBotClick(chat: any) {
    axiosConnectJson(
      '/rest/chat/getChatBotNextStep',
      'GET',
      {},
      {
        answer: chat.chatBotId,
        parent: chat.chatBotParent,
      }
    )
      .then((result) => {
        if (result.data.length > 0) {
          chatMessage.push({
            msgSendId: loginUser.memberId,
            msgSendName: loginUser.memberName,
            msgSendSid: loginUser.memberSid,
            msgContent: chat.chatBotContent,
            msgStatus: 'TEXT',
            msgSendTime: new Date(),
          })
          chatMessage.push(result.data)
          setChatList([...chatMessage])
        } else {
          createChatRoom(chat.chatBotParent)
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  function saveChatLog(message: any) {
    axiosConnectJson('/rest/chat/saveChatLog', 'POST', message)
      .then((result) => {
        console.log(result.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function getChatLog(roomId: string, roomName: string) {
    axiosConnect(
      '/rest/chat/getChatLog',
      'GET',
      {},
      {
        roomId: roomId,
        roomName: roomName,
      }
    )
      .then((result) => {
        chatMessage = result.data
        setChatList([...chatMessage])
      })
      .catch((error) => {
        alert(error)
      })
  }

  function getChatBot() {
    axiosConnect('/rest/chat/getChatBot', 'GET')
      .then((result) => {
        chatMessage.push(result.data)
        setChatList([...chatMessage])
      })
      .catch((error) => {
        alert(error)
      })
  }

  const handleFileUpload = (event: any) => {
    if (!chatRoomConnect) {
      return
    }

    const formData = new FormData()
    formData.append('fileUpload', event.target.files[0])

    axiosConnectFileUpload('/rest/chat/fileUpload', 'POST', formData)
      .then((result) => {
        let replacePath = result.data.filePath.replace(/\\/g, '/').split('webapps')
        sendMessage('IMG', replacePath[1])
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <body className="chat">
      <div>
        <nav className="navbar fixed-top mnavbar navchat">
          <a onClick={() => navigate('/')} className="nav-chat" title="이지트래블케어">
            <img src="/resources/home/img/svg/logo-w.svg" alt="이지트래블케어" />
          </a>
          <a className="navbar-brand">
            <span className="chat-info">{language['수십 분 내에 응답합니다.']}</span>
          </a>
          <a onClick={() => navigate(-1)} className="nav-expander" title="메뉴보기">
            <i className="bi bi-x bi-lg"></i>
          </a>
        </nav>
      </div>

      <div className="talkwrap" ref={messageListView}>
        {chatList.map((chat: any, index: number) => {
          if (chat.type && chat.type === 'Q') {
            return <ChatBotView chat={chat} chatBotClick={chatBotClick} key={`chat_room_${index}`} isEnable={index === chatList.length - 1} createChatRoom={createChatRoom} />
          } else if (chat.msgStatus && chat.msgStatus === 'TEXT') {
            return <ChatTextView isLoginUser={isUserMessageClass(chat.msgSendId)} chat={chat} key={`chat_room_${index}`} />
          } else if (chat.msgStatus && chat.msgStatus === 'IMG') {
            return <ChatImageView isLoginUser={isUserMessageClass(chat.msgSendId)} chat={chat} key={`chat_room_${index}`} />
          } else if (chat.msgStatus && chat.msgStatus === 'MAP') {
            return <ChatMapView isLoginUser={isUserMessageClass(chat.msgSendId)} chat={chat} key={`chat_room_${index}`} />
          } else if (chat.msgStatus && chat.msgStatus === 'JOIN') {
            return <ChatJoinView chat={chat} key={`chat_room_${index}`} />
          } else {
            return <ChatBotAnswerView chat={chat} chatBotClick={chatBotClick} key={`chat_room_${index}`} isEnable={index === chatList.length - 1} createChatRoom={createChatRoom} />
          }
        })}

        <div className="tab_barwrap fixed-bottom">
          <div className="container nopadding">
            <div className="form-line" style={{ marginTop: '1px' }}>
              <div className="form-group row">
                <div className="col-10">
                  <span className="float-photo">
                    <a onClick={clickMap}>
                      <i className="bi bi-geo-alt"></i>
                    </a>
                    <a href="#none">
                      <input id="fileInput" type="file" onChange={handleFileUpload} style={{ display: 'none' }} disabled={!chatRoomConnect} />
                      <label htmlFor="fileInput">
                        <i className="bi bi-camera"></i>
                      </label>
                    </a>
                  </span>
                  <input type="text" className="form-control no-line" placeholder="내용을 입력해주세요." value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyDown} disabled={!chatRoomConnect} />
                </div>
                <div className="col-2 text-right">
                  <a className="btn btn-primary btn-sm-nav-tab" onClick={clickMessage}>
                    <i className="bi bi-send-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default SOSCallChatRoom
