import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'
import { useState, useEffect } from 'react'

function QnAEditView() {
  const { id } = useParams()

  const language = useSelector((state: any) => state.language.value)
  const categoryList = useSelector((state: any) => state.boardCategory.value)
  const loginUser = useSelector((state: any) => state.user.value)

  const [qna, setQna] = useState<any>({})
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [category, setCategory] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (categoryList.length > 0) {
      setCategory(categoryList[0].sq)
    }

    axiosConnect(`/api/faqboard/readView/${id}`, 'GET')
      .then((result) => {
        setQna(result.data)
        setTitle(result.data.faqContent)
        setContent(result.data.faqContent)
        setCategory(result.data.faqCategory)
      })
      .catch((error) => {
        alert(error)
      })
  }, [])

  if (loginUser === null || loginUser.memberSid === null || loginUser.memberSid === '') {
    return <Navigate to="/login?redirect-page=qna/create" />
  }

  const validate = () => {
    if (title.length <= 0) {
      alert(language['제목을 입력해주세요'])
      return false
    }

    if (content.length <= 0) {
      alert(language['문의할 내용을 입력해 주세요.'])
      return false
    }
    return true
  }

  const updateQnAApi = () => {
    validate() &&
      axiosConnect(`/rest/faqboard/update`, 'POST', {
        faqNo: id,
        faqTitle: title,
        faqContent: content,
        faqCategory: category,
        faqWriter: loginUser.memberName,
        faqLang: loginUser.memberLang,
      })
        .then((result) => {
          navigate('/help/qna')
        })
        .catch((error) => {
          alert(error)
        })
  }

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-12">
          <form className="form-add">
            <hr className="dark" />
            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['문의유형']}</label>
              <div className="col-md-4">
                <select className="form-control mr-1" value={category} onChange={(e) => setCategory(e.target.value)}>
                  {categoryList.map((category: any, index: number) => {
                    return (
                      <option key={`category_${index}`} value={category.categoryNo}>
                        {category.categoryName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <hr />
            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['작성자']}</label>
              <div className="col-md-4">
                <input type="text" className="form-control-plaintext" value={qna.faqWriter} placeholder="" />
              </div>
            </div>
            <hr />
            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['제목']}</label>
              <div className="col-md-8">
                <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="" />
              </div>
            </div>
            <hr />
            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['문의내용']}</label>
              <div className="col-md-9">
                <textarea className="form-control" id="exampleFormControlTextarea1" rows={7} value={content} onChange={(e) => setContent(e.target.value)} placeholder={language['문의할 내용을 입력해 주세요.']}></textarea>
              </div>
            </div>
            <hr className="dark" />
          </form>

          <div className="text-center">
            <a onClick={updateQnAApi} className="btn btn-primary btn-lg">
              {language['확인']}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QnAEditView
