import { useSelector } from 'react-redux'

function MainOurServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="container main_intro">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="card-title">
                {language['맞춤형 안심보험']} <br className="d-block d-md-none" />& {language['컨시어지 서비스']}
              </h5>
              <p className="card-text">
                <img src="/resources/home/img/svg/easytravelcare-icon1.svg" alt="" />
              </p>
              <ul className="list">
                <li>{language['여행 중 발생할 수 있는 안전사고 등 해결방안 모색']}</li>
                <li>{language['여행 안심보험은 물론 의료지원 및 케어 서비스']}</li>
                <li>{language['각종 도난사고 보험케어 및 트래블관련 케어 서비스']}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="card-title">{language['대한민국 안심관광을 즐기세요']}</h5>
              <p className="card-text">
                <img src="/resources/home/img/svg/easytravelcare-icon2.svg" alt="" />
              </p>
              <ul className="list">
                <li>{language['급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행']}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="card-title">{language['체계적인 서비스 운영 시스템']}</h5>
              <p className="card-text">
                <img src="/resources/home/img/svg/easytravelcare-icon3.svg" alt="" />
              </p>
              <ul className="list">
                <li>{language['컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영']}</li>
                <li>{language['인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스']}</li>
                <li>{language['국내 최고의 전문의전요원에 의한 직접적인 서비스']}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="card-title">
                {language['특별혜택 및']}
                <br className="d-block d-md-none" />
                {language['모바일 컨시어지 운영']}
              </h5>
              <p className="card-text">
                <img src="/resources/home/img/svg/easytravelcare-icon4.svg" alt="" />
              </p>
              <ul className="list">
                <li>{language['가입자를 위한 20여가지 특별혜택']}</li>
                <li>{language['오프라인과 모바일 채팅 컨시어지 연계 서비스']}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainOurServiceView
