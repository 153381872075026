import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'

function FindPasswordView() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const [loginId, setLoginId] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const clickFindPassword = () => {
    axiosConnect(`/rest/member/findPw`, 'POST', {
      memberId: loginId,
      memberEmail: email,
      memberPhone: phoneNumber,
    })
      .then((result) => {
        if (result.data != null && result.data.memberId != null) {
          navigate('/find/result-password', { state: { loginId: result.data.memberId, phoneNumber: result.memberPhone } })
        } else {
          alert(language['아이디가 존재하지 않습니다.'])
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <div className="container mt-5">
        <div className="nav-tabs-box">
          <ul className="nav nav-tabs nav-fill">
            <li className="nav-item">
              <Link to="/find/login-id" className="nav-link">
                {language['아이디 찾기']}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/find/password" className="nav-link active">
                {language['비밀번호 찾기']}
              </Link>
            </li>
          </ul>
        </div>

        <div className="loginwrap">
          <div className="row no-gutters">
            <div className="col-12">
              <h4 className="text-left">{language['비밀번호 찾기']}</h4>
              <hr className="dark" />
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="inputID" className="sr-only">
                    {language['아이디']}
                  </label>
                  <input type="text" className="form-control" id="inputID" placeholder={language['아이디']} value={loginId} onChange={(e) => setLoginId(e.target.value)} />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="inputEmail" className="sr-only">
                    {language['이메일']}
                  </label>
                  <input type="email" className="form-control" id="inputEmail" placeholder={language['이메일']} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="inputPhone" className="sr-only">
                    {language['휴대폰번호']}
                  </label>
                  <input type="text" className="form-control" id="inputPhone" placeholder={language['휴대폰번호( 하이폰 - 포함해서 작성해주세요)']} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group">
                  <a onClick={clickFindPassword} className="btn btn-primary btn-block btn-lg">
                    {language['비밀번호 찾기']}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FindPasswordView
