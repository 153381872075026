import { DateUtil } from '../../../../utils/date-format'

function ChatJoinView(props: any) {
  const { chat } = props
  function isUserMessageClass() {
    return 'message'
  }

  return (
    <div className={isUserMessageClass()}>
      {!isUserMessageClass() ? (
        <a>
          <img src="/resources/home/img/svg/soschat.svg" alt="soschat" className="message-profile" />
        </a>
      ) : null}

      <div className="message-body">
        {!isUserMessageClass() ? <h5>{chat.msgSendName}</h5> : null}
        <div className="message-box" dangerouslySetInnerHTML={{ __html: chat.msgContent }} />
        <div className="message-date">{DateUtil.getFormatDate(chat.msgSendTime)}</div>
      </div>
    </div>
  )
}

export default ChatJoinView
