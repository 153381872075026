import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import ScrollToTop from './component/common/ScrollToTop'
import Footer from './component/Footer'
import Header from './component/Header'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBoardCategory } from './store/boardCategory'
import { fetchPossibleChat } from './store/possibleChat'
import axiosConnect from './network/http-request'
import { logout } from './store/user'
import FontJP from './theme/FontJP'
import FontCN from './theme/FontCN'

function App() {
  const dispatch = useDispatch()
  const loginUser = useSelector((state) => state.user.value)
  const language = useSelector((state) => state.language.value)

  useEffect(() => {
    dispatch(fetchBoardCategory())
    dispatch(fetchPossibleChat())

    sessionCheck()
  }, [loginUser])

  function sessionCheck() {
    if (!(loginUser === null || loginUser.memberSid === null || loginUser.memberSid === '')) {
      axiosConnect(`/api/member/${loginUser.memberId}`, 'GET')
        .then((result) => {})
        .catch((error) => {
          dispatch(logout())
        })
    }
  }

  return (
    <div>
      {language.code === 'jp' ? <FontJP /> : null}
      {language.code === 'cn' ? <FontCN /> : null}
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default App
