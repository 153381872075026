import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { KeyValueType } from '../../../utils/types'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import TabView from '../../common/TabView'

function InformationPage() {
  const language = useSelector((state: any) => state.language.value)

  const tabItems = [
    {
      link: '/information/medical',
      title: language['의료 안심케어'],
    },
    {
      link: '/information/airport',
      title: language['공항 안심케어'],
    },
    {
      link: '/information/travel',
      title: language['트래블 안심케어'],
    },
    {
      link: '/information/business',
      title: language['비즈니스 안심케어'],
    },
    {
      link: '/information/traveler',
      title: language['여행자 안심케어'],
    },
    {
      link: '/information/special',
      title: language['특별 혜택'],
    },
  ]
  const informationName: KeyValueType = {
    '/information/medical': language['의료 안심케어'],
    '/information/airport': language['공항 안심케어'],
    '/information/travel': language['트래블 안심케어'],
    '/information/business': language['비즈니스 안심케어'],
    '/information/traveler': language['여행자 안심케어'],
    '/information/special': language['특별 혜택'],
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub_service.jpg" title={language['서비스 소개']} />
      <TabView tabItems={tabItems} />
      <BreadcrumbView title={language['서비스 소개']} link="/information/medical" names={informationName} />
      <Outlet />
    </>
  )
}

export default InformationPage
