import { useSelector } from 'react-redux'

function ConciergeJeonbukView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="page-header">
              <h2>{language['전북쇼핑트래블라운지']}</h2>
            </div>
            <hr className="blue" />
            <ul className="list">
              <li>
                <h6>{language['위치: 전주시 완산구 충경로 1층']}</h6>
              </li>
            </ul>
            <hr className="blue" />
          </div>
          <div className="col-12 col-md-7">
            <img src="/resources/home/img/desk_jeonbuk.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light-blue mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="col-12 offset-md-1 col-md-10 mt-5">
                <p className="text-center">
                  <img src="/resources/home/img/jeonbuk_map.jpg" alt="" className="d-block w-100" />
                </p>
                <p className="pt-3"></p>
                <hr className="mt-5 blue" />
              </div>
              <div className="col-12 offset-md-1 col-md-10 section2St">
                <div className="titStl">
                  <span></span>
                  <h3> </h3>
                </div>
                <ul>
                  <li>
                    <h4>{language['무인 카페 라운지']}</h4>
                    <p></p>
                    <span className="iconStl iconStl1"></span>
                  </li>
                  <li>
                    <h4>{language['전북관광 안내 서비스']}</h4>
                    <p></p>
                    <span className="iconStl iconStl2"></span>
                  </li>
                  <li>
                    <h4>{language['관광기념품 판매 및 굿즈 판매']}</h4>
                    <p></p>
                    <span className="iconStl iconStl3"></span>
                  </li>
                  <li>
                    <h4>{language['수하물 보관 서비스']}</h4>
                    <p></p>
                    <span className="iconStl iconStl4"></span>
                  </li>
                  <li>
                    <h4>{language['소규모 미팅룸 대관']}</h4>
                    <p></p>
                    <span className="iconStl iconStl5"></span>
                  </li>
                  <li>
                    <h4>{language['이벤트 체험존']}</h4>
                    <p></p>
                    <span className="iconStl iconStl6"></span>
                  </li>
                  <li>
                    <h4>
                      {language['여행 편의 서비스']} <small>({language['우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등']})</small>
                    </h4>
                    <p></p>
                    <span className="iconStl iconStl7"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConciergeJeonbukView
