import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'

function SOSCallPage() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub2.jpg" title={language['SOS 콜 채팅']} />
      <BreadcrumbView title={language['SOS 콜 채팅']} link="/sos-call/terms" />
      <Outlet />
    </>
  )
}

export default SOSCallPage
