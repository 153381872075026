import { Link, useLocation } from 'react-router-dom'

function InnerPathTabView(props: any) {
  const { tabItems } = props
  const location = useLocation()

  const isActive = (url: string) => {
    return location.pathname.includes(url) ? 'active' : ''
  }

  return (
    <div className="fulltab">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="nav nav-pills nav-justified">
              {tabItems.map((tab: any, index: number) => (
                <li className="nav-item" key={`tab_${index}`}>
                  <Link className={'nav-link ' + isActive(`${tab.link}`)} to={tab.link}>
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InnerPathTabView
