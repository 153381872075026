import { useSelector } from 'react-redux'

function BusinessServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['비즈니스 안심케어']}</h2>
            </div>
            <h5>
              {language['편안하고 안전한 여행과']}
              <br />
              {language['성공적인 비즈니스를 위한']}
              <br />
              {language['특화 컨시어지 서비스']}
            </h5>
            <ul className="list">
              <li>{language['긴급 통·번역 인력 수배 서비스']}</li>
              <li>{language['회의시설 및 미팅 장소 예약 지원 서비스']}</li>
              <li>{language['KTX, 항공권 등 예약 지원 서비스']}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_business.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service_business.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['통·번역 인력 수배 서비스']}</h5>
                  <ul className="list secondary">
                    <li>{language['동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['회의시설 및 미팅 장소 예약 지원']}</h5>
                  <ul className="list secondary">
                    <li>{language['회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스']}</li>
                    <li>{language['연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['KTX · 항공권 등 예약 지원']}</h5>
                  <ul className="list secondary">
                    <li>{language['KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessServiceView
