import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'
import AlertModal from '../../../common/AlertModal'
import { useState } from 'react'
import { useSelector } from 'react-redux'

function FindResultPasswordView() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const sendResetPassword = () => {
    axiosConnect(`/rest/member/sendSMS`, 'POST', {
      id: state.loginId,
      phone: state.phoneNumber,
    })
      .then(() => {
        openModal()
      })
      .catch((error) => {
        alert(error)
      })
  }

  const sendHome = () => {
    closeModal()
    navigate('/login')
  }

  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div className="container mt-5">
        <div className="nav-tabs-box">
          <ul className="nav nav-tabs nav-fill">
            <li className="nav-item">
              <Link to="/find/login-id" className="nav-link">
                {language['아이디 찾기']}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/find/password" className="nav-link active">
                {language['비밀번호 찾기']}
              </Link>
            </li>
          </ul>
        </div>

        <div className="loginwrap">
          <div className="row no-gutters">
            <div className="col-12">
              <form>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group mt-5 mb-5">
                  <h4 className="text-space-lg">
                    {language['계정정보가 일치합니다.']}
                    <br />
                    {language['계정의 비밀번호를']}
                    <br />
                    <span className="text-primary">{language['임시 비밀번호로 초기화']}</span>
                    {language['하시겠습니까?']}
                  </h4>
                </div>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group row">
                  <div className="col-6">
                    <a onClick={sendResetPassword} className="btn btn-primary btn-block btn-lg">
                      {language['예']}
                    </a>
                  </div>
                  <div className="col-6">
                    <Link to="/" className="btn btn-outline-dark btn-block btn-lg">
                      {language['아니요, 처음으로']}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <AlertModal
          open={modalOpen}
          close={closeModal}
          icon="/resources/home/img/svg/icon-close.svg"
          body={
            <div className="modal-body d-flex justify-content-center align-items-center">
              <p className="font-weight-bold text-center">{language['휴대폰으로 임시비밀번호를 보내드렸습니다.']}</p>
            </div>
          }
          footer={
            <button type="button" className="btn btn-primary" onClick={sendHome}>
              {language['로그인']}
            </button>
          }
        />
      </div>
    </>
  )
}

export default FindResultPasswordView
