function TermsJPView() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
              <h5>第１条（目的）</h5>
              この規約は、プレミアムパスインターナショナル（以下「会社」といいます）の運営するイージートラベルケア（easytravelcare）インターネットサイト（以下「サイト」といいます）を通じ、
              <br />
              提供する電子コマースのサービス（以下「サービス」といいます）を利用することにとって、会員の権利と義務及び責任事項を
              <br />
              規定することを目的にします。
              <br />
              <br />
              この規約は、PC通信、スマホ（Androidフォン、iPhoneなど）アプリ、無線などを利用する電子コマースについても、その
              <br />
              性質に反しない限り、準用されます。
              <br />
              <br />
              <h5>第２条（用語の正義）</h5>
              規約から使用する用語の定義は、次のようであります。
              <br />
              1.「サイト」とは、会社に会員として登録した利用者が
              <br />
              様々な情報とサービスを提供されるように会社が製作、運営するインターネットウェブサイトを意味します。
              <br />
              現在、会社の運営する「サイト」(2020.01 現在)は、
              <br />
              次のようであります。
              <br />
              *www.easytravelcare.co.kr
              <br />
              - 後ほど、会社からお知らせし、提供する他のウェブサイト。
              <br />
              <br />
              2.販売者とは、商品または、サービスのなだらかな販売のために、「会社」から販売代行サービスを提供される者を
              <br />
              いいます。但し、オープンマーケットのサービスの場合は、「会員」が購買した商品及びサービスなどの取引情報についての <br />
              <br />
              直接的な責任を負う者をいいます。
              <br />
              <br />
              3.「会員」というのは、「サイト」（以下「会社」）に、
              <br />
              個人情報を提供し、会員登録をした使用者として、
              <br />
              会員登録の時、設定したIDで、「サイト」に自由に
              <br />
              接続し、会社が提供する情報を持続的に提供されるにしろ、
              <br />
              「会社」のサービスを、頻りに利用できる使用者のことを
              <br />
              いいます。
              <br />
              <br />
              4.「商品」というのは、「会社」が 「サイト」を通じ、
              <br />
              会員に販売する商品または、クーポンなどをいいます。
              <br />
              <br />
              5.「ID」というのは、「会員」の識別とサービスの利用のために、
              <br />
              「会員」が選定し、会社が承認して、登録した文字または、数字の組合をいいます。
              <br />
              <br />
              6.「パスワード」というのは、「会員」の同一性の確認と「会員」 の権益及び、秘密保護のために、「会員」自らが設定し、
              <br />
              「サイト」に登録した文字と数字の組合をいいます。
              <br />
              <br />
              7.予約の確認バウチャー：商品とサービス（以下「商品 など」といいます）を購買した価格で利用できるデジタル証券と
              <br />
              いいます。
              <br />
              <br />
              8.掲示板：「会員」が「サービス」を利用することにおいて、
              <br />
              「サイト」上に、掲示した符号、文字、音声の形の字、写真、動画及び各種のファイルとリンクなどを意味します。
              <br />
              <br />
              9.「チャット」というのは、商品などを購買の後、緊急サービス
              <br />
              のご相談をご希望の時、使用できるチャットサービスといいます。
              <br />
              <br />
              10.この規約から正義されていない用語は、関連法令が伝える
              <br />
              ことに従います。
              <br />
              <br />
              <h5>第３条（規約などの明示と説明及び改正）</h5>
              1.「会社」は、この規約の内容と会社の「商号」、営業所の所在地、代表者の氏名、事業者登録番号、連絡先（電話番号、ファックス番号、電子メールアドレスなど）、通信販売業の 申告番号、個人情報管理責任者などと共に、会員が簡単に 確認できるように、「サイト」の初期「サービス」の画面（前面）に掲示する。但し、規約の具体的な内容は、会員が連結 画面を通じて見られます。
              <br />
              <br />
              2.「会社」は、規約の規制に関する法律、電子コマースなどの 消費者保護に関する法律、電子文書及び、電子取引の基本法、 電子署名法、情報通信網の利用促進などに関する法律、消費者基本法、電子金融取引法などの関連法令を反しない限りの 範囲からこの規約を改正できます。
              <br />
              <br />
              3.「会社」が規約を改正する場合においては、適用日付及び、改正理由を明示し、現行規約と共に「サイト」の公知事項または、初期の画面にその適用日付の7日以前から適用日付の前日までお知らせする。但し、「会員」に重大な影響を与える不利な内容に変更する場合は、30日以前からお知らせします。
              <br />
              <br />
              4.前項により、改正規約は、適用日付の以降に締結された 契約だけ適用され、法令に特別な規制または、他のやむを得ない事由がない限り改正規約の適用日付の以前に遡及し、 適用されません。
              <br />
              <br />
              5.前項によって、公知された適用日付の以降に、会員が 「会社」の「サービス」を、頻りに利用する場合においては、 改正された規約に同意することにする。 改正された規約に同意しない「会員」は、いつでも自由に 「サービス」の利用契約が解約できます。
              <br />
              <br />
              6.この規約に明示しない事項と、この規約の解釈に関しては、 電子コマースなどでの消費者保護に関する法律、規約の規制 などに関する法律、公正取引委員会が定める電子コマース などでの消費者保護指針と関連法令の規正と一般の商慣習に 従います。
              <br />
              <br />
              <h5>第4条（個別サービス利用規約及び、利用条件）</h5>
              「会社」は提供する「サービス」内の「個別サービス」に 関する別の利用契約及び、利用条件を各の「サービス」別に し、「個別サービス」から別途に適用される規約においての 同意は、「回当サービス」を最初に利用する場合、別途の同意手続きを経った後、利用し、「サービス」の内容を同意したことにし、この場合、「個別サービス」においての利用規約などは、本、規約に 優先します。
              <br />
              <br />
              <h5>第5条（サービス提供の中止）</h5>
              1.「会社」は、次の各号に当てはまる場合、「サービス」の提供が 中止できます。
              <br />
              1)「サービス」利用設備の保守点検、交替及び、故障などの工事に よるやむを得ない場合。
              <br />
              2)電気通信事業法に規定された基幹通信事業者が電気通信「サービス」 を中止した場合。
              <br />
              3)他の不可抗力的な事由がある場合。 <br />
              <br />
              2.「会社」は、国家非常事態、停電、「サービス」設備の障害または、
              <br />
              「サービス」利用の暴注など、正常的な「サービス」の利用に
              <br />
              支障がある時は、「サービス」の全部または、一部を制限するにしろ、停止できます。
              <br />
              <br />
              <h5>第6条（利用契約の成立）</h5>
              1. 利用契約は、「会員」の規約同意、利用のお申し込みに対する 「会社」の承認後、成り立ちます。
              <br />
              <br />
              2. 利用契約の成立は、「会社」が登録完了の表示を、お申し込み 手続きの上から承認または、表した起点にします。
              <br />
              <br />
              <h5>第7条（利用お申し込み及び、承諾）</h5>
              1.「会社」は、次の各号に当てはまる利用のお申し込みに対しては、 承認を拒否したり、留保及び、事後、利用契約が解約できます。
              <br />
              1) 既に登録された「会員」の氏名及び、電子メールアドレスが 同一な場合。
              <br />
              2) 他人の名義を盗用したり、虚偽表示、記載漏れなどがある場合。
              <br />
              3) 登録内容に虚偽の情報を記載したり、瘦せ我慢がある場合。
              <br />
              4) 利用申請書が、この規約によって、以前に会員の資格を喪失した ことがある場合。
              <br />
              5)「会社」により、利用資格の喪失後、、三ヶ月が経った後、「会社」 の会員再登録の承諾を得る場合にあたっては、例外にする。
              <br />
              6)否定した用途または、営利を追求する目的として、「サービス」を 利用しようとする場合（但し、「会社」が承認した範囲内での サービス利用は例外にする。）
              <br />
              7)「サービス」の運営によっての関連設備の余裕がなかったり、 技術上または、業務上の支障または、問題がある場合、但し、 その事由が解決されるまで承諾が留保できる。
              <br />
              8)関連法令、美風良俗または、社会の安寧秩序及び、他の諸般事項を 違反し、申し込んだり、会員の帰責事由に応じて、承認が不可能な 場合。
              <br />
              9)他のこの規約に違反されたり、違法または、不当な利用申請が ご確認された場合、「会社」が合理的な判断によって、必要だと 認める場合。
              <br />
              <br />
              3. 第1項による利用申請にあたって、「会社」は専門機関を通じ、実名確認及び、本人認証が要請できます。
              <br />
              <br />
              4. 「会社」は「会員」に対する利用回数、「サービス」のメニューなどを細分し、会社の政策に従って、等級別に分けてあります。
              <br />
              <br />
              5.「会社」とは、「会員」について青少年保護法及び、ビデオ物の 振興に関する法律などによる年齢遵守及び、等級によって、利用 制限や等級別制限することができます。
              <br />
              <br />
              <h5>第8条（サービスの利用時間）</h5>
              「サービス」の利用は、「会社」の業務または、技術上に特別な支障がない場合、365日1日24時間を原則的にします。但し、定期点検の事由で、「会社」がお知らせした日または、時間は除外されます。
              <br />
              <br />
              <h5>第9条（会員のID及び、パスワードについての義務）</h5>
              1.「ID」と「パスワード」に関する管理の責任は、全的に「会員」に あり、これをおろそかにして、発生した全ての民.刑事上の責任は「会員」の自らにあります。
              <br />
              <br />
              2.「会員」は、自らの「ID」及び、「パスワード」を第3者に貸与 及び、利用させてはいけない。
              <br />
              <br />
              3.「会員」は、自らの「ID」及び、「パスワード」を盗難または、第3者が使用しているのを認知した場合、直ちに、「会社」に通知し、 「会社」の措置がある場合にあたって、それに従わなければなりません。
              <br />
              <br />
              4.「会員」が、前項による通知をしなかったり、「会社」の措置に 応じなくて、発生する全ての不利益についての責任は、全的に 「会員」にあります。
              <br />
              <br />
              <h5>第10条（利用契約の解約及び、終了）</h5>
              1.「会員」の解約
              <br />
              1)「会員」は、いつでも「会社」に連絡し、解約の意思を通知するこ とにとって、利用契約を解約（会員脱退を要請）できるので、「会 員」の勘定登録資料の削除が要請できます。
              <br />
              2) 利用契約は、「会員」の解約意思が「会社」に到達した時に終了されます。
              <br />
              3)前項により、解約をした「会員」（脱退した会員）は、「会社」が 定めた規正規約により、会員登録手続きを通し、会員として、再び 登録できますが、解約（脱退）以前の会員登録の時、適用された 等級、積金、ポイントなどの保有条件は、消滅され、払い戻しなど 適用されません。 但し、再登録を日程期間の間は際限できます。
              <br />
              <br />
              2.会社の解約 「会社」は、次のような事由がある場合、利用契約が解約できるし、 該当「会員」に対する保有情報は、個人情報取扱い方針に従います。
              <br />
              1) 第7条の第1項から定めている利用契約の承諾拒否事由があるのが 確認された場合。
              <br />
              2)「会員」が「会社」または、他の「会員」、その他の他人の権利 や名誉、信用以外の正当な利益を侵害したり、善良な風俗、他の 社会秩序、大韓民国の法令に違反される行為をした場合。
              <br />
              3)「会社」が販売する「商品」または、「サービス」について、確 認されていない事実と内容に不信を助長する行為をした場合。
              <br />
              4) 取引秩序を妨害する目的で、重複購買など、サイトのなだらかな 進行を妨害する行為を試みた場合。
              <br />
              5) 他の「会社」が合理的な判断による「会員」がこの規約に違反される行為をした場合。
              <br />
              6)「会社」は、電子メール、電話、ファックス他の方法を通じ、解約の意思を明らかにして、会員に解約の事由をお知らせします。 但し、会社は、該当の会員に前もって解約の事由についての意見陳述の機会を付与します。
              <br />
              <br />
              3.本条項により、利用契約が終了される場合、「会員」は、サービスを 利用する権限がなくなり、会員の個人情報及び、記録などは全部削除 され、前もって購買する商品などの払い戻し処理に関しては、会社の 払い戻しの規正に依拠します。
              <br />
              <br />
              4.利用契約の終了と関連し、発生した損害は、利用契約が終了された 該当「会員」が責任を負うべきであり、「会社」は一体の責任を負いません。
              <br />
              <br />
              <h5>第11条（会員の義務）</h5>
              1.「会員」は、関連法令、本の規約規定、利用案内など「会社」が告知 する内容を遵守しなければならず、他の「会社」の業務に妨害する行為をしてはいけません。
              <br />
              <br />
              2.「会員」は、「サービス」の利用と関連して、つぎの各号の行為を してはいけません。
              <br />
              1)「サービス」のお申し込みまたは、変更時、虚偽の内容の登録。
              <br />
              2) 他人の情報盗用など。
              <br />
              3)「会社」が掲示した情報の許可を受けていない変更。
              <br />
              4)「会社」が定めた情報以外の情報（コンピュータープログラムなど） の送信または、掲示。
              <br />
              5) 「会社」及び、他の第3者の著作権、肖像権など、知識の財産権に 対する侵害。
              <br />
              6) 「会社」及び、他の第3者の名誉を損傷させたり、業務を妨害する 行為。
              <br />
              7) わいせつまたは、暴力的なメッセージ、動画、音声他の公共秩序、 美風良俗に反する情報を「サイト」に公開または、掲示する行為。
              <br />
              8) 他の関連法令や「会社」で、定めた規定に違反される行為。
              <br />
              <br />
              <h5>第12条（会社の義務）</h5>
              1.「会社」は、法令と、この規約を禁止したり、公序良俗に反する 行為をせずに、この規約が定めることによって、持続的で、安定的な「サービス」を提供するために、最善を尽くさなければなりません。
              <br />
              <br />
              2.「会社」は、会員が安全に「サービス」が利用できるように、「会員」の個人情報（信用情報含め）保護のためのセキュリティシステムを備えなければならず、個人情報取り扱い方針を公示して、 遵守するのである。
              <br />
              <br />
              <h5>第13条（購買申請及び、個人情報提供の同意など）</h5>
              1.「会員」は、「サイト」上から、次のような、これに類似の方法によって、購買を申請し、「会社」は利用者が購買申請をするにあたって、次の各内容を分かりやすく提供しなければなりません。
              <br />
              1) 商品などの検索及び、選択
              <br />
              2) 商品利用に関する予約情報の入力（日付）
              <br />
              3) 予約者とサービスを受ける人の氏名、国籍、パスポート番号、 住民登録の個人別固有番号、電子メールアドレス（又は、携帯電話）などの入力。
              <br />
              4)各、商品別の構成、包含事項及び、規約内容、請約撤回権が 際 限されるサービスなどの費用負担と関する内容についての確認。
              <br />
              5)この規約に同意し、上4号の事項を確認したり、拒否する表示。 （例：マウス クリックなど）
              <br />
              6)商品などの購買申請及び、これに関する確認又は、「会社（サイト）」確認についての同意。
              <br />
              7)決済方法の選択
              <br />
              <br />
              2.会社は会員の購買申請がある場合、会員に購買確認通知をし、こ の通知が到達した視点に購買契約が成立します。
              <br />
              <br />
              3.「会社」が第3者に購買者の個人情報を提供、提供する必要がある場合、提供される個人情報を提供受ける者、個人情報を提供される者の個人情報の利用目的、提供する個人情報の項目、個人情報を提供受ける者の個人情報保有及び、利用期間を購買者に知らせ、 同意を受けなければなりません。（同意を受ける事項が変更される場合にも同じである。）
              <br />
              <br />
              4.「会社」が第3者に、購買者の個人情報を扱えるように、業務を委託する場合は、個人情報の扱い委託を受ける者、個人情報の扱い 委託をする義務の内容を購買者に知らせ、同意を受けなければなりません。（同意を受ける事項が変更される場合にも同じである。）但し、サービスの提供に関する契約移行のため、必要だし、 購買者の便宜増進と関連する場合は、情報通信網利用促進及び、 情報保護などに定めている方法で、個人情報の扱い方針を通じて、 知らせることにとって、告知手続き、同意手続きを踏まなくても いいです。
              <br />
              <br />
              <h5>第14条（契約の成立）</h5>
              1.「会社」と「会員」は、各購買商品の予約確定まで、かかる時間について、各商品ページへの記載内容を遵守するのに同意します。
              <br />
              <br />
              2.「会社」は、第13条のような、「会員」の購買申請について、次の
              <br />
              各号に当てはまると、承諾しない場合があります。
              <br />
              1) 申請内容に虚偽、記載漏れ、意地のある場合。
              <br />
              2) 他の購買申請に承諾することが「会社」の技術上に、著しく支障があると判断する場合と、情報誤記載、情報漏れ、システムミス、 品切れなどの事由で、購買申請した「商品」と「サービス」提供不可の場合、但し、この場合は、「会社」は「会員」に、これを 事後 知らせ、必要な措置を移行しなければならない。
              <br />
              3) 第3者のID、名義又は、決済手段を盗用し、購買申請する場合。
              <br />
              4) 再販売の目的又は、「商品など」を購買申請の状況上、不当な目的に購買したことが疑われる場合。
              <br />
              5) 購買意思なしに、商品の購買と返品を無分別に繰り返し、他の利用者の購買機会を剝奪する場合。
              <br />
              6) 「会社」が提供する割引クーポン、イベントなどを利用して、非常的な取引をした場合。
              <br />
              7) 関連法令に違反されたり、社会の安寧秩序あるいは、美風良俗を 阻害するおそれのある目的に申し込んだ場合。
              <br />
              8) 他のこの規約に違反されたり、悪用使用とする不当な利用申請であることが確認された場合及び、「会社」が合理的な判断により、 必要があると認める場合。
              <br />
              <br />
              3.「会社」の承諾が、第19条 第1項の規定による、受信確認通知の形で、「会員」に到達した時点で、契約が成立したと思います。
              <br />
              <br />
              4.「会社」の承諾の意思表示には、「会員」の購買申請に対する確認 及び、販売可能の可否、購買申請の訂正取り消しなどに関する情報などを含める必要があります。
              <br />
              <br />
              <h5>第15条（決済方法）</h5>
              1.「サイト」で購買した商品または、サービスに対する代金の支払い方法は、次の各号の方法中、利用可能な方法で行うことができます。 但し、「サイト」は、利用者の支払方法について、財貨サービスなどの代金にいかなる名目の手数料も追加して、徴収することはできません。
              <br />
              1) 現金、クレジットカードなど。
              <br />
              2) 各種のPay
              <br />
              3) 他の「会社」が追加指定する決済手段及び、電子的支払方法による代金支払など。
              <br />
              2.「会員」が購買代金の決済と関して、入力した情報及び、その情報 と関して、発生した責任と不利益は全的に会員負担となります。
              <br />
              <br />
              3.「会社」は、「会員」が購買代金の決済時、使用した決済手段に 対する、本人情報の確認又は、正当な使用を勧める有無について確認でき、これに対する確認が完了するまで、取引を中断したり、確認が不可能な場合、該当取引は、取引又は、払い戻し保留などができる。
              <br />
              <br />
              4.「会社」は、「会員」が購買した商品の購買した商品の購買証明書 （現金領収証など）を要請すれば、実購買額で発行します。
              <br />
              <br />
              <h5>第16条（予約確認証または、バウチャー転送）</h5>
              1.「会社」は「会員」に購買した「サイト」の商品の詳細内容をSNS 又は、電子めーるなどの方式で発送します。
              <br />
              <br />
              2.「予約確認証または、バウチャー」伝送作業は、祝日と「会社」の 勤務時間と休業日または、処理過程の状況によって、変更される場合があり、これは、サイト内の該当コンテンツに明示された内容に従います。
              <br />
              <br />
              <h5>第17条（受信確認通知、購買申請変更及び、取り消し）</h5>
              1.「サイト」は「会員」の購買申請がある場合、「会員」に受信確認通知をします。
              <br />
              <br />
              2. 受信確認通知を受けた「会員」は、意思表示の不一致などがある場合は、受信確認通知を受けた後、直ちに、購買申請変更及び、 キャンセルを要請することができ、「サイト」は予約確定の前に、 「会員」の要請がある場合は、遅滞なくその要請に応じて、処理しなければなりません。但し、既に代金を支払った場合は、第20条の申し込みの撤回などに関する規定に従います。
              <br />
              <br />
              <h5>第18条（請約撤回など）</h5>
              1.「会社」の購買取り消し及び、請約撤回の規定は、電子商取引など における消費者保護に関する法律など、関連法令を遵守します。
              <br />
              <br />
              2.「会員」は商品を引き渡された（アップロード含め）場合、次の 各号の1に該当する場合は、返品及び、交換できません。
              <br />
              1) 引き渡された（アップロード含め）データを「会員」の責任ある 事由により、滅失または、毀損された場合。
              <br />
              2) 引き渡された（アップロード含め）データを一定期間（販売者設定期間）が経過した場合。
              <br />
              3) 引き渡された（アップロード含め）データを複製可能な場合。
              <br />
              <br />
              3.「会員」の単純な心変わりによるキャンセル及び、払い戻しの場合、 異議処理に発生する手数料は、会員負担となります。
              <br />
              <br />
              4.「会員」は「商品など」の内容が表示、広告内容と異なる場合や契 約内容と異なる場合は、その商品などを供給受けた日から30日以内 に、その事実の請約を撤回することができます。
              <br />
              <br />
              5.クレジットカード又は、電子マネーで決済した「会員」の場合、 遅滞なく、当該決済手段を提供した事業者に対し、代金の請求を 停止または、取り消すよう要請します。
              <br />
              <br />
              6.購買をキャンセルした場合、既に決済していた内訳をキャンセルし、 キャンセル時点によって、予約のキャンセル料を再承認する。 この場合、購買取り消し時点と該当カード会社の払い戻し処理基準によって、キャンセル金額の払い戻し方法と払い戻し日は、多少異なる 場合があり、使用済みクレジットカードの払い戻しポリシーについては、クレジットカード会社に直接お問い合わせください。
              <br />
              <br />
              7.「会員」が他人の個人情報を盗用し、本規約で禁止する不正な方法に より、予約後不当な利益詐取と疑われる場合、「会社」は「会員」のチケットの購買をキャンセル処理または、制限することができ、 「会員」が十分な釈明資料を提出するまで、払い戻しを保留することができます。
              <br />
              <br />
              8.その他、この規約及び、「サイト」の利用案内に規定されていない キャンセル及び、払い戻しに関する事項については、消費者被害補償 規定で定めるところに従います。
              <br />
              <br />
              <h5>第19条（商品の払い戻し特約）</h5>
              1.「会員」はサービス購買後、次の各号に該当する場合、払い戻し または、交換を要請することはできません。
              <br />
              1) サービス開始後の単純な心変わりは払い戻し対象から除外され、 各「販売者」の販売規定に従います。
              <br />
              2) 本製品の使用期間と、前もって時間がお客様の選択によって制限された場合。
              <br />
              <br />
              2.「会社」が商品の返品手続きに従って、返金する場合、「販売者」 又は、「保険会社」との協議を終えたことを確認した後、 払い戻しが行われます。
              <br />
              <br />
              <h5>第20条（個人情報保護）</h5>
              1.「サイト」は「会員」の個人情報収集時に、サービス提供のために、 必要な範囲で最小限の個人情報を収集します。
              <br />
              <br />
              2.「サイト」は会員登録の際、購買契約の履行に必要な情報を事前に 収集しません。但し、関連法令上の義務付けのために、購買契約の 以前に本人確認が必要な場合として、最小限の特定個人情報を 収集する場合はこの限りではありません。
              <br />
              <br />
              3.「サイト」は「会員」の個人情報を収集、利用する時には、当該 利用者にその目的を告知し、同意を得ます。
              <br />
              <br />
              4.「サイト」は収集された個人情報を目的以外の用途で利用すること ができず、新たな利用目的が発生した場合または、第3者に提供す る場合には、利用提供段階で、当該「会員」に、その目的を告知し、 同意を得ます。但し、関連法令に異なる定めがある場合は、例外に します。
              <br />
              <br />
              5.「サイト」は、第2項と第3項により、利用者の同意を得なければ ならない場合。 個人情報管理責任者の身元（所属、氏名または、電話番号、その他の連絡先）、情報の収集目的及び、利用目的、第3者に対する情報提供関連事項（提供された者、提供目的及び、提供する情報の内容）など、情報通信網の利用促進及び、情報保護などに関する、法律 第２２条第2項が規定する事項をあらかじめ明示し、又は、告知しなければならず、「会員」は、いつでも移動意思を撤回することが できます。
              <br />
              <br />
              6.「会員」は、いつでも「会社」が持っている自分の個人情報に対し て、閲覧及び、エラー訂正を要求することができ、「会社」は これに対して、遅滞なく、必要な措置を取る義務を負います。 「会社」がエラーの訂正を要求した場合は、「会社」はそのエラー を訂正するまで、当該個人情報を利用しません。 但し、閲覧及び、エラー訂正の手続きは、「会社」の定めるところに従います。
              <br />
              <br />
              7.「会社」は、個人情報保護のために、「会員」の個人情報を取り扱う者を最小限に制限しなければならず、クレジットカード、銀行口座 などを含む会員の個人情報の紛失、盗難、流出、同意のない第3者 提供、変造などによる、「会員」の損害に対して、全ての責任を負います。
              <br />
              <br />
              8.「会社」または、それから個人情報の提供を受けた第3者は、個人情報の収集目的又は、提供を受けた目的を達成した時は、当該個人情報を遅滞なく、破棄します。
              <br />
              <br />
              9.「会社」は、個人情報の収集.利用.提供に関する同意欄を、あらかじめ選択したものに設定しておきません。又は、個人情報の収集. 利用.提供に関する、「会員」の同意拒否時に、制限されるサービスを具体的に明示し、必修収集項目ではなく、個人情報の収集.利用. 提供に関する「会員」の同意拒否を理由に会員登録などのサービス 提供を制限したり、拒否したりしません。
              <br />
              <br />
              <h5>第21条（著作権の帰属及び、利用制限）</h5>
              1.「サイト」が作成した著作物に対する、著作権その他の知的財産権は「サイト」に帰属します。
              <br />
              <br />
              2.「会員」は「サイト」を利用することで得た情報、イメージ、データの内、「サイト」に知的財産権が帰属する情報を「サイト」の事前承諾なしに、複製、送信、出版、配布、放送、その他の方法により、営利目的で利用し、又は、第3者に利用させてはなりません。
              <br />
              <br />
              3.「会員」は「サイト」で購買した商品のデータ画像を「サイト」の 事前承諾なしに、任意で加工、複製、送信、出版、配布、放送その他の方法により、営利目的で利用したり、第3者に利用させては なりません。
              <br />
              <br />
              <h5>第22条（会員資格の制限又は、停止）</h5>
              1.「会員」は「会社」に、いつでも脱退を要請することができ、「会社」は、直ちに会員脱退を処理します。
              <br />
              <br />
              2.「会員」が次の各号の事由に該当する場合、「会社」は、会員資格を直ちに、制限または、停止させることができます。
              <br />
              1) 会員登録申請または、変更時に、虚偽の内容を登録した場合。
              <br />
              2) 「サイト」を利用して購買した商品などの代金、その他「サイト」 利用に関して、会員が負担する債務を期日に履行しない場合。
              <br />
              3) 他人のサイト利用を妨害したり、その情報を盗用するなど、電子商取引秩序を脅かす場合。
              <br />
              4) 「サイト」を利用して法令、この規約が禁止したり、公序良俗に反する行為をした場合。
              <br />
              5) 「サイト」の健全な運営を害したり、サイトの業務を妨害したりする場合。
              <br />
              A.「サイト」の運営に関して、根拠のない事実と虚偽の事実を 明示したり、流布して「会社」の信頼性を害する場合。
              <br />
              B.「サイト」の利用過程で、「会社」の職員に暴言、脅迫または、 わいせつな言動などで、サイトの運営を妨害する場合。
              <br />
              C.商品などを購買した後、正当な理由なしに、常習的または、 繰り返しにキャンセル.返品して、会社の業務を妨害する場合。
              <br />
              D.「サイト」を通じて、購買の後、商品などに特別な欠陥が ないにもかかわらず、一部使用後に常習的なキャンセル.全部 または、一部返品などで「会社」の業務を妨害する場合。
              <br />
              E.「サイト」を通じて、購買した商品などに、特別な欠陥が ないにもかかわらず、個人的な単純な心変わり、根拠のない 不満などで、「会社」の業務を妨害する場合。
              <br />
              <br />
              3.会社が会員資格を制限.停止させた後、同じ行為が2回以上繰り返されたり、30日以内に、その理由が是正されない場合、「会社」は利用契約を解約することができます。
              <br />
              <br />
              <h5>第23条（免責条項）</h5>
              1.「会社」は天災地変または、これに準ずる不可抗力により、 「サービス」を提供できない場合は、「サービス」提供に対する、 責任が免除されます。
              <br />
              <br />
              2.「会社」は「会員」の帰責事由による、「サービス」利用の障害に ついては、責任を負いません。
              <br />
              <br />
              3.「会社」は「会員」が掲載した情報、資料など、事実の信頼度と 正確性などの内容については、責任を負いません。
              <br />
              <br />
              4.「会社」は「会員」の間、または、「会員」と第３者間で行われた「サービス」に関しては、いかなる責任も負いません。
              <br />
              <br />
              5.「会社」は「会員」が「サービス」を利用して得た、期待収益の 喪失と「サービス」を通じて、得た資料に対する損害に対しては、 いかなる責任も負いません。
              <br />
              <br />
              <h5>第24条（紛争解決）</h5>
              1.「サイト」は「会員」が提起する、正当な意見や不満を反映し、 その被害を補償処理するために、被害補償処理機構を設置、運営 します。
              <br />
              <br />
              2.「サイト」は、利用者から提出される苦情及び、意見は優先的に 処理します。但し、迅速な処理が困難な場合は、利用者に、その 理由と処理日程を、直ちに、通知します。
              <br />
              <br />
              <h5>第25条（裁判権および、準拠法）</h5>
              1.「サイト」は「会員」との間で、電子商取引紛争に関する訴訟は、 提訴当時の「会員」の住所により、住所がない場合、居所を管轄 する地方法院の専属管轄とします。 但し、提訴当時の利用者の住所または、居所が明確じゃなかったり、 外国居住者の場合は、民事訴訟法上の管轄裁判所に提起します。
              <br />
              <br />
              2.「サイト」は「会員」の間に、提起された電子商取引訴訟には、 韓国法が適用されます。
              <br />
              <br />
              付則
              <br />
              1. この規約は、２０２１年1月1日から施行されます。
              <br />
              <br />
              2. この改正規約は、施行日以後に、締結される契約にのみ適用され、 それ以前に、締結された契約に対しては、改正前の規約条項を そのまま適用します。
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsJPView
