import { useSelector } from 'react-redux'
import PrivacyKRView from './PrivacyKRView'
import PrivacyENView from './PrivacyENView'
import PrivacyJPView from './PrivacyJPView'
import PrivacyCNView from './PrivacyCNView'

function PrivacyView() {
  const language = useSelector((state: any) => state.language.value)
  const privacyView = () => {
    if (language.code === 'en') {
      return <PrivacyENView />
    } else if (language.code === 'jp') {
      return <PrivacyJPView />
    } else if (language.code === 'cn') {
      return <PrivacyCNView />
    } else {
      return <PrivacyKRView />
    }
  }
  return privacyView()
}

export default PrivacyView
