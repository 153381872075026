import { useSelector } from 'react-redux'
import TermsKRView from './TermsKRView'
import TermsENView from './TermsENView'
import TermsJPView from './TermsJPView'
import TermsCNView from './TermsCNView'

function TermsView() {
  const language = useSelector((state: any) => state.language.value)

  const termsView = () => {
    if (language.code === 'en') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>Terms of Use</h2>
                </div>
              </div>
            </div>
          </div>
          <TermsENView />
        </>
      )
    } else if (language.code === 'jp') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>利用約款</h2>
                </div>
              </div>
            </div>
          </div>
          <TermsJPView />
        </>
      )
    } else if (language.code === 'cn') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>使用条款</h2>
                </div>
              </div>
            </div>
          </div>
          <TermsCNView />
        </>
      )
    } else {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>이용약관</h2>
                </div>
              </div>
            </div>
          </div>
          <TermsKRView />
        </>
      )
    }
  }
  return termsView()
}

export default TermsView
