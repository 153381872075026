import { useSelector } from 'react-redux'

function TravelServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['트래블 안심케어']}</h2>
            </div>
            <h5>
              {language['여행 중 긴급사항 발생시 제공되는']}
              <br />
              {language['다양한 트래블 컨시어지 서비스']}
            </h5>
            <ul className="list">
              <li>{language['지갑, 여권 등 분실 시 지원 케어 서비스']}</li>
              <li>{language['여행 도중 긴급사항 발생 시 케어 서비스']}</li>
              <li>{language['교통편 예약 발권 및 차량 수배 서비스']}</li>
              <li>{language['긴급 숙박 예약 지원 서비스']}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_travel.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service_travel.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['지갑, 여권 등 분실 시 케어 서비스']}</h5>
                  <ul className="list secondary">
                    <li>{language['여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)']}</li>
                    <li>{language['보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['긴급상황 발생 시 케어서비스']}</h5>
                  <ul className="list secondary">
                    <li>{language['긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.']}</li>
                    <li>{language['보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['교통편 예약 발권 / 차량 수배 서비스']}</h5>
                  <ul className="list secondary">
                    <li>{language['대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.']}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TravelServiceView
