import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function AirportServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['공항 안심케어']}</h2>
            </div>
            <h5>
              {language['국제공항이라는 특별한 공간에서의']} <br />
              {language['고객을 위한 안심 케어 서비스']}
            </h5>
            <ul className="list">
              <li>{language['노약자 등 공항에서 지원 및 불편사항 케어']}</li>
              <li>{language['인천공항에서의 컨시어지 서비스 제공']}</li>
              <li>{language['비자 및 긴급 항공권 케어 서비스']}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_airport.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service-airport.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['공항 불편 사항 케어']}</h5>
                  <ul className="list secondary">
                    <li>{language['공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.']}</li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['공항 내 컨시어지 서비스 제공']}</h5>
                  <ul className="list secondary">
                    <li>{language['인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.']}</li>
                    <li>
                      {language['공항 트래블센터']}{' '}
                      <strong>
                        <Link to="/concierge/incheon-t1" className="link">
                          {language['약도']}
                        </Link>
                      </strong>{' '}
                      {language['확인 부탁드립니다.']}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="cardline">
                <div className="card-body">
                  <h5 className="card-title">{language['공항 긴급 서비스 제공']}</h5>
                  <ul className="list secondary">
                    <li>{language['긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공']}</li>
                    <li>{language['긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공']}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AirportServiceView
