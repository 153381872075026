export const languageSet = {
    korean: {
        "code": "kr",
        "언어": "한국어",
        // 서비스 소개
        "서비스 소개": "서비스 소개",
        "의료 안심케어": "의료 안심케어",
        "공항 안심케어": "공항 안심케어",
        "트래블 안심케어": "트래블 안심케어",
        "비즈니스 안심케어": "비즈니스 안심케어",
        "여행자 안심케어": "여행자 안심케어",
        "특별 혜택": "특별 혜택",
        "SOS 콜 채팅": "SOS 콜 채팅",
        "서비스 구매": "서비스 구매",
        "자주하는 질문": "자주하는 질문",
        "문의": "문의",
        "마이페이지": "마이페이지",
        "내 회원정보": "내 회원정보",
        "구매내역": "구매내역",
        "회원탈퇴": "회원탈퇴",
        "님 반갑습니다.": "님 반갑습니다.",
        "정보수정": "정보수정",
        "로그인": "로그인",
        "회원가입": "회원가입",
        "로그아웃": "로그아웃",
        "메뉴닫기": "메뉴닫기",
        "고객센터": "고객센터",
        // Footer
        "상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542": "상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542",
        "통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 |": "통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 | ",
        "사업자 정보확인": "사업자 정보확인",
        "소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호": "소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호",
        "개인정보 처리 책임자 : 임상엽 (care@tothepp.com)": "개인정보 처리 책임자 : 임상엽 (care@tothepp.com)",
        "월-금 09:00-18:00 (점심시간 12:00-13:00), 한국시간 기준": "월-금 09:00-18:00 (점심시간 12:00-13:00)",
        "한국시간 기준": "한국시간 기준",
        // Main
        "즐겁고 안전한 관광": "즐겁고 안전한 관광",
        "안심보험에서 안심여행까지!": "안심보험에서 안심여행까지!",
        "외국인 관광객 니즈에 맞는": "외국인 관광객 니즈에 맞는",
        "맞춤형 안심케어 서비스": "맞춤형 안심케어 서비스",
        "안심보험&컨시어지 서비스": "안심보험&컨시어지 서비스",
        "K-Travel 안심케어": "K-Travel 안심케어",
        "편안하고": "편안하고",
        "안전하고": "안전하고",
        "품위있는 가치관광": "품위있는 가치관광",
        "안전하고 즐거운 한국여행": "안전하고 즐거운 한국여행",
        "이지트래블케어 안심보험과 함께하세요!": "이지트래블케어 안심보험과 함께하세요!",
        "이전": "이전",
        "다음": "다음",
        // Our Service
        "맞춤형 안심보험": "맞춤형 안심보험",
        "컨시어지 서비스": "컨시어지 서비스",
        "여행 중 발생할 수 있는 안전사고 등 해결방안 모색": "여행 중 발생할 수 있는 안전사고 등 해결방안 모색",
        "여행 안심보험은 물론 의료지원 및 케어 서비스": "여행 안심보험은 물론 의료지원 및 케어 서비스",
        "각종 도난사고 보험케어 및 트래블관련 케어 서비스": "각종 도난사고 보험케어 및 트래블관련 케어 서비스",
        "대한민국 안심관광을 즐기세요": "대한민국 안심관광을 즐기세요",
        "급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행": "급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행",
        "체계적인 서비스 운영 시스템": "체계적인 서비스 운영 시스템",
        "컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영": "컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영",
        "인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스": "인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스",
        "국내 최고의 전문의전요원에 의한 직접적인 서비스": "국내 최고의 전문의전요원에 의한 직접적인 서비스",
        "특별혜택 및": "특별혜택 및",
        "모바일 컨시어지 운영": "모바일 컨시어지 운영",
        "가입자를 위한 20여가지 특별혜택": "가입자를 위한 20여가지 특별혜택",
        "오프라인과 모바일 채팅 컨시어지 연계 서비스": "오프라인과 모바일 채팅 컨시어지 연계 서비스",
        // Main Infor
        "대한민국을 여행할 때는": "대한민국을 여행할 때는",
        "언제나 이지트래블케어": "언제나 이지트래블케어",
        "안심보험으로 즐겁고 안전한 관광을": "안심보험으로 즐겁고 안전한 관광을",
        "하실 수 있습니다.": "하실 수 있습니다.",
        // 서비스 소개
        "여행 중 발생할 수 있는": "여행 중 발생할 수 있는",
        "여행 중 발생할 수 있는 A": "여행 중 발생할 수 있는",
        "여러 사고에 준비되어 있는": "여러 사고에 준비되어 있는",
        "의료 지원 서비스": "의료 지원 서비스",
        "Details": "Details",
        "More": "More",
        "국제공항이라는 특별한": "국제공항이라는 특별한",
        "공간에서의 고객을 위한": "공간에서의 고객을 위한",
        "안심 케어 서비스": "안심 케어 서비스",
        "여행 중 긴급사항 발생시": "여행 중 긴급사항 발생시",
        "제공되는 다양한": "제공되는 다양한",
        "트래블 컨시어지 서비스": "트래블 컨시어지 서비스",
        "편안하고 안전한 여행과": "편안하고 안전한 여행과",
        "성공적인 비즈니스를 위한": "성공적인 비즈니스를 위한",
        "특화 컨시어지 서비스": "특화 컨시어지 서비스",
        "휴대품 손해, 배상책임에서": "휴대품 손해, 배상책임에서",
        "휴대품 손해, 배상책임에서 A": "휴대품 손해, 배상책임에서",
        "사망까지": "사망까지",
        "20여 가지의": "20여 가지의",
        "멤버십 특별혜택 제공": "멤버십 특별혜택 제공",
        // Price
        "Easy Travel Care Prices": "Easy Travel Care Prices",
        "Shorts": "Shorts",
        "Middle": "Middle",
        "Long": "Long",
        "Days": "Days",
        "days": "days",
        "Normally": "Normally",
        "별도 문의": "별도 문의",
        "31일 이상의 서비스 이용을 원하시면": "31일 이상의 서비스 이용을 원하시면",
        "별도 문의하여 주시기 바랍니다.": "별도 문의하여 주시기 바랍니다.",
        // SOS Call
        "여행 중 곤란한 상황이": "여행 중 곤란한 상황이",
        "발생하셨나요?": "발생하셨나요?",
        "SOS 콜 채팅 바로가기": "SOS 콜 채팅 바로가기",
        // Concierge
        "지역별 컨시어지 데스크": "지역별 컨시어지 데스크",
        "대한민국 관광 주요거점에": "대한민국 관광 주요거점에",
        "위치한 각 트래블센터에서": "위치한 각 트래블센터에서",
        "맞춤형 컨시어지 서비스를 제공합니다.": "맞춤형 컨시어지 서비스를 제공합니다.",
        "이지트래블 케어 서비스는": "이지트래블 케어 서비스는",
        "여행 중 각 컨시어지데스크에": "여행 중 각 컨시어지데스크에",
        "방문하셔서 문의주시면 안심관광을": "방문하셔서 문의주시면 안심관광을",
        "위한 도움을 드리고 있습니다.": "위한 도움을 드리고 있습니다.",
        "인천공항 T1": "인천공항 T1",
        "인천공항 T2": "인천공항 T2",
        "서울역 라운지": "서울역 라운지",
        "마포관광 정보센터": "마포관광 정보센터",
        "송도 컨벤시아": "송도 컨벤시아",
        "인천국제공항 제1터미널": "인천국제공항 제1터미널",
        "컨시어지데스크": "컨시어지데스크",
        "부스명: MICE Concierge Desk(40번 데스크)": "부스명: MICE Concierge Desk(40번 데스크)",
        "위치: 제1터미널 8번과 9번 게이트 사이": "위치: 제1터미널 8번과 9번 게이트 사이",
        "인천국제공항 제2터미널": "인천국제공항 제2터미널",
        "부스명: MICE Concierge Desk": "부스명: MICE Concierge Desk",
        "위치: 제2터미널 4번게이트": "위치: 제2터미널 4번게이트",
        "프리미엄라운지": "프리미엄라운지",
        "서울역점": "서울역점",
        "위치: 서울역 도심공항터미널 지하 2층": "위치: 서울역 도심공항터미널 지하 2층",
        "위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리": "위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리",
        "송도컨벤시아점": "송도컨벤시아점",
        "위치: 인천 송도컨벤시아 내(Gate1)": "위치: 인천 송도컨벤시아 내(Gate1)",
        "이지트래블케어 서비스 외에 컨시어지 서비스": "이지트래블케어 서비스 외에 컨시어지 서비스",
        "이용 시 별도의 비용이 발생됩니다.": "이용 시 별도의 비용이 발생됩니다.",
        "비용은 각 컨시어지데스크 요금정책에 따릅니다.": "비용은 각 컨시어지데스크 요금정책에 따릅니다.",
        "컨시어지 데스크 이용방법": "컨시어지 데스크 이용방법",
        "컨시어지 데스크 방문": "컨시어지 데스크 방문",
        "이지트래블케어 바우처 제시": "이지트래블케어 바우처 제시",
        "바우처의 내용 확인": "바우처의 내용 확인",
        "서비스 시작": "서비스 시작",
        "Terminal": "Terminal",
        "카페": "카페",
        "사무용품&스낵": "사무용품&스낵",
        "여행 편의 서비스": "여행 편의 서비스",
        "비즈니스 업무": "비즈니스 업무",
        "소규모 회의실 대관": "소규모 회의실 대관",

        // 공항 서비스 
        "국제공항이라는 특별한 공간에서의": "국제공항이라는 특별한 공간에서의",
        "고객을 위한 안심 케어 서비스": "고객을 위한 안심 케어 서비스",
        "노약자 등 공항에서 지원 및 불편사항 케어": "노약자 등 공항에서 지원 및 불편사항 케어",
        "인천공항에서의 컨시어지 서비스 제공": "인천공항에서의 컨시어지 서비스 제공",
        "비자 및 긴급 항공권 케어 서비스": "비자 및 긴급 항공권 케어 서비스",
        "INFORMATION": "INFORMATION",
        "공항 불편 사항 케어": "공항 불편 사항 케어",
        "공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.",
        "공항 내 컨시어지 서비스 제공": "공항 내 컨시어지 서비스 제공",
        "인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.": "인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.",
        "트래블센터": "트래블센터",
        "약도": "약도",
        "확인 부탁드립니다.": "확인 부탁드립니다.",
        "공항 긴급 서비스 제공": "공항 긴급 서비스 제공",
        "긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공": "긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공",
        "긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공": "긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공",
        // 비즈니스 서비스 
        "편안하고 안전한 여행과": "편안하고 안전한 여행과",
        "성공적인 비즈니스를 위한": "성공적인 비즈니스를 위한",
        "특화 컨시어지 서비스": "특화 컨시어지 서비스",
        "긴급 통·번역 인력 수배 서비스": "긴급 통·번역 인력 수배 서비스",
        "회의시설 및 미팅 장소 예약 지원 서비스": "회의시설 및 미팅 장소 예약 지원 서비스",
        "KTX, 항공권 등 예약 지원 서비스": "KTX, 항공권 등 예약 지원 서비스",
        "통·번역 인력 수배 서비스": "통·번역 인력 수배 서비스",
        "동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.": "동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.",
        "회의시설 및 미팅 장소 예약 지원": "회의시설 및 미팅 장소 예약 지원",
        "회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스": "회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스",
        "연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.": "연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.",
        "KTX · 항공권 등 예약 지원": "KTX · 항공권 등 예약 지원",
        "KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.": "KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.",
        // 의료 서비스 
        "교통사고, 안전사고 등 맞춤형 케어 서비스": "교통사고, 안전사고 등 맞춤형 케어 서비스",
        "의료통역사 수배, 의료비 해결방안 모색,": "의료통역사 수배, 의료비 해결방안 모색,",
        "여행자보험 & 의료지원 등 케어": "여행자보험 & 의료지원 등 케어",
        "교통사고 시 이용 안내": "교통사고 시 이용 안내",
        "여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.": "여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.",
        "안전사고 시 이용 안내": "안전사고 시 이용 안내",
        "여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.",
        "맞춤형 케어 이용 안내": "맞춤형 케어 이용 안내",
        "안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.": "안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.",
        // 특별 혜택
        "컨시어지 데스크 무료이용": "컨시어지 데스크 무료이용",
        "공항 픽업 및 의전 서비스 할인": "공항 픽업 및 의전 서비스 할인",
        "교통 예약 및 발권": "교통 예약 및 발권",
        "멤버십 특별혜택 서비스": "멤버십 특별혜택 서비스",
        "인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용": "인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용",
        "공항 픽업/샌딩 서비스 20% 할인": "공항 픽업/샌딩 서비스 20% 할인",
        "공항 의전 및 영접 서비스 30% 할인": "공항 의전 및 영접 서비스 30% 할인",
        "수하물 딜리버리 서비스 20% 할인": "수하물 딜리버리 서비스 20% 할인",
        "대중교통 예약 및 발권 등 탑승 지원 서비스": "대중교통 예약 및 발권 등 탑승 지원 서비스",
        "항공, KTX, 고속버스 등 교통 예약 및 발권": "항공, KTX, 고속버스 등 교통 예약 및 발권",
        // 여행자 안심케어
        "상해 및 질병": "상해 및 질병",
        "국내여행 중 배상책임": "국내여행 중 배상책임",
        "국내여행 중 휴대품 손해": "국내여행 중 휴대품 손해",
        "여행자 안심 보험 서비스": "여행자 안심 보험 서비스",
        "국내여행중 상해사망": "국내여행중 상해사망",
        "국내여행중 상해후유장해": "국내여행중 상해후유장해",
        "1억원": "1억원",
        "15세 미만 제외": "15세 미만 제외",
        "국내여행중 질병사망/ 80%고도후유장해": "국내여행중 질병사망/ 80%고도후유장해",
        "2천만원": "2천만원",
        "15세 미만/ 80세이상 제외": "15세 미만/ 80세이상 제외",
        "국내여행중 일괄배상": "국내여행중 일괄배상",
        "5백만원": "5백만원",
        "국내여행": "국내여행",
        "국내여행중 휴대품손해": "국내여행중 휴대품손해",
        "20만원": "20만원",
        "분실제외": "분실제외",
        "국내여행 상해급여_의료실비보장": "국내여행 상해급여_의료실비보장",
        "1천만원": "1천만원",
        "통원 1회당 10만원 한도": "통원 1회당 10만원 한도",
        "국내여행 상해비급여_의료실비보장": "국내여행 상해비급여_의료실비보장",
        // 트래블 안심케어
        "여행 중 긴급사항 발생시 제공되는": "여행 중 긴급사항 발생시 제공되는",
        "다양한 트래블 컨시어지 서비스": "다양한 트래블 컨시어지 서비스",
        "지갑, 여권 등 분실 시 지원 케어 서비스": "지갑, 여권 등 분실 시 지원 케어 서비스",
        "여행 도중 긴급사항 발생 시 케어 서비스": "여행 도중 긴급사항 발생 시 케어 서비스",
        "교통편 예약 발권 및 차량 수배 서비스": "교통편 예약 발권 및 차량 수배 서비스",
        "긴급 숙박 예약 지원 서비스": "긴급 숙박 예약 지원 서비스",
        "지갑, 여권 등 분실 시 케어 서비스": "지갑, 여권 등 분실 시 케어 서비스",
        "여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)": "여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)",
        "보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)": "보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)",
        "긴급상황 발생 시 케어서비스": "긴급상황 발생 시 케어서비스",
        "긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.",
        "보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)": "보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)",
        "교통편 예약 발권 / 차량 수배 서비스": "교통편 예약 발권 / 차량 수배 서비스",
        "대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.": "대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.",
        // FAQ
        "가입시 연령제한이 있나요?": "가입시 연령제한이 있나요?",
        "연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.": "연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.",
        "가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?": "가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?",
        "업무일 기준, 개시일 24시간 전까지입니다.": "업무일 기준, 개시일 24시간 전까지입니다.",
        "가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?": "가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?",
        "이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.": "이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.",
        "이지트래블케어 가입시 달러로만 결제하나요?": "이지트래블케어 가입시 달러로만 결제하나요?",
        "달러로만 결제 가능합니다.": "달러로만 결제 가능합니다.",
        "카드 결제시 오류가 나면 어떻게 하나요?": "카드 결제시 오류가 나면 어떻게 하나요?",
        "고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.": "고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.",
        "서비스에 가입했다가 다시 취소할 수 있나요?": "서비스에 가입했다가 다시 취소할 수 있나요?",
        "서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.": "서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.",
        "상해발생 지역이 해외여도 가능한가요?": "상해발생 지역이 해외여도 가능한가요?",
        "한국 국내 상황에만 적용됩니다.": "한국 국내 상황에만 적용됩니다.",
        "특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?": "특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?",
        "특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.": "특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.",
        "멤버십 증서는 어디서 확인하나요?": "멤버십 증서는 어디서 확인하나요?",
        "서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.": "서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.",
        "컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?": "컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?",
        "멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.": "멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.",
        "이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.": "이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.",

        // 채팅 
        "상담원 연결이 필요해요!": "상담원 연결이 필요해요!",
        "ChatBot": "ChatBot",
        "서비스 이용안내": "서비스 이용안내",
        "약관동의": "약관동의",
        "이용안내": "이용안내",
        "수십 분 내에 응답합니다.": "수십 분 내에 응답합니다.",
        "담당매니저연결중입니다": "담당매니저연결중입니다",
        "빠른시간내에연락드리겠습니다": "빠른시간내에연락드리겠습니다",
        "WELCOME!": "WELCOME!",
        "How can I Help today?": "How can I Help today?",
        "New Chat": "New Chat",
        "운영시간: 00:00 ~ 24:00": "운영시간: 00:00 ~ 24:00",
        "정말 삭제하시겠습니까?": "정말 삭제하시겠습니까?",
        "확인": "확인",

        // 채팅 이용약관 
        "이제 이지트래블케어 서비스를 이용할 수 있습니다.": "이제 이지트래블케어 서비스를 이용할 수 있습니다.",
        "맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!": "맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!",
        "우측 하단의 채팅 아이콘": "우측 하단의 채팅 아이콘",
        "을 통해 온라인 채팅 상담이 가능합니다.": "을 통해 온라인 채팅 상담이 가능합니다.",
        "서비스 이용 권장사양": "서비스 이용 권장사양",
        "HTML5 지원 브라우저,": "HTML5 지원 브라우저,",
        "Chrome 14 버전 이상,": "Chrome 14 버전 이상,",
        "Internet Explorer 10 버전 이상,": "Internet Explorer 10 버전 이상,",
        "Safari 6 버전 이상,": "Safari 6 버전 이상,",
        "Opera 12.10 버전 이상,": "Opera 12.10 버전 이상,",
        "Firefox 11 버전 이상,": "Firefox 11 버전 이상,",
        "안드로이드 브라우저 4.4 이상": "안드로이드 브라우저 4.4 이상",
        "이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.": "이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.",
        "위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.": "위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.",
        "약관에 동의해주시기 바랍니다.": "약관에 동의해주시기 바랍니다.",
        "서비스 약관 동의": "서비스 약관 동의",
        "이지트래블케어 서비스에 가입해주셔서 감사합니다.": "이지트래블케어 서비스에 가입해주셔서 감사합니다.",
        "본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.": "본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.",
        "이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.": "이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.",
        "약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.": "약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.",
        "지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.": "지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.",
        "이지트래블케어 이용 약관에 동의합니다.": "이지트래블케어 이용 약관에 동의합니다.",
        "이지트래블케어 이용 약관": "이지트래블케어 이용 약관",
        "아래의 약관에 모두 동의합니다.": "아래의 약관에 모두 동의합니다.",
        "현대해상 화재보험 약관": "현대해상 화재보험 약관",
        "현대해상 화재보험 약관에 동의합니다.": "현대해상 화재보험 약관에 동의합니다.",
        
        // QnA
        "게시글을 삭제하시겠습니까?": "게시글을 삭제하시겠습니까?",
        "삭제하신 후에는 복구가 되지 않습니다.": "삭제하신 후에는 복구가 되지 않습니다.",
        "삭제": "삭제",
        "삭제 되었습니다.": "삭제 되었습니다.",
        "제목을 입력해주세요": "제목을 입력해주세요",
        "문의유형": "문의유형",
        "작성자": "작성자",
        "제목": "제목",
        "문의내용": "문의내용",
        "문의할 내용을 입력해 주세요.": "문의할 내용을 입력해 주세요.",
        "답변완료": "답변완료",
        "수정": "수정",
        "목록": "목록",
        "답변대기": "답변대기",
        "궁금한 내용을 작성하시면 댓글로 답변해드립니다.": "궁금한 내용을 작성하시면 댓글로 답변해드립니다.",
        "광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.": "광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.",
        "작성하기": "작성하기",
        "카테고리": "카테고리",
        "작성일": "작성일",
        "상태": "상태",

        // 마이페이지 
        "비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.": "비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.",
        "비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.": "비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.",
        "이메일을 형식에 맞게 작성해주세요.": "이메일을 형식에 맞게 작성해주세요.",
        "사용언어": "사용언어",
        "하이픈(-)을 포함해서 작성해주세요.": "하이픈(-)을 포함해서 작성해주세요.",
        "휴대폰 번호": "휴대폰 번호",
        "이메일": "이메일",
        "새 비밀번호": "새 비밀번호",
        "새 비밀번호 확인": "새 비밀번호 확인",
        "영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.": "영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.",
        "생년월일": "생년월일",
        "여권번호": "여권번호",
        "외국인등록번호": "외국인등록번호",
        "이름": "이름",
        "성별": "성별",
        "국적": "국적",
        "아이디": "아이디",
        // 구매이력 
        "사용가능": "사용가능",
        "결제취소": "결제취소",
        "서비스만료": "서비스만료",
        "나의 구매 내역을 확인할 수 있습니다.": "나의 구매 내역을 확인할 수 있습니다.",
        "고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)": "고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)",
        "컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.": "컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.",
        "결제일": "결제일",
        "유효기간": "유효기간",
        "금액": "금액",
        "결제수단": "결제수단",
        "상태": "상태",
        "가입증서": "가입증서",
        "가입증서 보기": "가입증서 보기",
        // 회원 탈퇴
        "탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.": "탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.",
        "서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.": "서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.",
        "단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.": "단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.",
        "유의사항을 모두 확인하였으며, 이에 동의합니다.": "유의사항을 모두 확인하였으며, 이에 동의합니다.",
        "비밀번호": "비밀번호",
        "비밀번호를 입력하세요.": "비밀번호를 입력하세요.",
        "탈퇴사유": "탈퇴사유",
        "회원탈퇴 후 재가입": "회원탈퇴 후 재가입",
        "서비스를 더 이상 이용하지 않음": "서비스를 더 이상 이용하지 않음",
        "이용이 불편하고 장애가 많음": "이용이 불편하고 장애가 많음",
        "콘텐츠의 유용성 부족": "콘텐츠의 유용성 부족",
        "고객센터 및 서비스 불만": "고객센터 및 서비스 불만",
        "기타": "기타",
        "기타의견": "기타의견",
        "탈퇴하기": "탈퇴하기",
        // 아이디 찾기
        "아이디가 존재하지 않습니다.": "아이디가 존재하지 않습니다.",
        "아이디 찾기": "아이디 찾기",
        "비밀번호 찾기": "비밀번호 찾기",
        "여권번호 또는 외국인등록번호": "여권번호 또는 외국인등록번호",
        "휴대폰번호": "휴대폰번호",
        "B2B회원은 아이디 찾기 및": "B2B회원은 아이디 찾기 및",
        "비밀번호 찾기를 지원하지 않습니다.": "비밀번호 찾기를 지원하지 않습니다.",
        "1644-1493로 문의해주시기 바랍니다.": "1644-1493로 문의해주시기 바랍니다.",
        "휴대폰번호( 하이폰 - 포함해서 작성해주세요)": "휴대폰번호( 하이폰 - 포함해서 작성해주세요)",
        "고객님의 아이디는": "고객님의 아이디는",
        "입니다.": "입니다.",
        "계정정보가 일치합니다.": "계정정보가 일치합니다.",
        "계정의 비밀번호를": "계정의 비밀번호를",
        "임시 비밀번호로 초기화": "임시 비밀번호로 초기화",
        "하시겠습니까?": "하시겠습니까?",
        "예": "예",
        "아니요, 처음으로": "아니요, 처음으로",
        "휴대폰으로 임시비밀번호를 보내드렸습니다.": "휴대폰으로 임시비밀번호를 보내드렸습니다.",
        // 로그인
        "아이디를 입력하세요.": "아이디를 입력하세요.",
        "아이디/비밀번호 찾기": "아이디/비밀번호 찾기",
        "비밀번호": "비밀번호",
        "비밀번호 조건에 맞지 않습니다.": "비밀번호 조건에 맞지 않습니다.",
        "아이디 저장": "아이디 저장",
        "개인정보 보호를 위해 본인 기기에서만 사용해주세요.": "개인정보 보호를 위해 본인 기기에서만 사용해주세요.",
        // 회원가입
        "이미 가입된 아이디 입니다.": "이미 가입된 아이디 입니다.",
        "사용 가능한 아이디 입니다.": "사용 가능한 아이디 입니다.",
        "사용할 수 없는 아이디 입니다.": "사용할 수 없는 아이디 입니다.",
        "회원가입": "회원가입",
        "중복체크": "중복체크",
        "비밀번호 확인": "비밀번호 확인",
        "비밀번호 조건에 맞지 않습니다.": "비밀번호 조건에 맞지 않습니다.",
        "회원가입 완료": "회원가입 완료",
        "이지트래블케어 회원가입이 완료되었습니다.": "이지트래블케어 회원가입이 완료되었습니다.",
        "회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.": "회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.",
        "로그인 후 이용가능합니다.": "로그인 후 이용가능합니다.",
        "비밀번호 : 영문/숫자/특수문자, 숫자 6~16자": "비밀번호 : 영문/숫자/특수문자, 숫자 6~16자",
        "남성": "남성",
        "여성": "여성",
        "여권번호(외국인등록번호)": "여권번호(외국인등록번호)",
        "올바른 형식의 이메일 주소가 아닙니다.": "올바른 형식의 이메일 주소가 아닙니다.",
        "필수항목 체크": "필수항목 체크",
        "개인정보처리방침": "개인정보처리방침",
        "동의": "동의",
        "필수": "필수",
        "이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.": "이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.",
        "동의하고 가입하기": "동의하고 가입하기",
        // 구매
        "컨시어지 서비스 구매를": "컨시어지 서비스 구매를",
        "완료했습니다.": "완료했습니다.",
        "이지트래블케어와 함께 즐거운 여행되세요.": "이지트래블케어와 함께 즐거운 여행되세요.",
        "홈으로 가기": "홈으로 가기",
        "구매정보 확인": "구매정보 확인",
        "정보입력": "정보입력",
        "결제하기": "결제하기",
        "구매완료": "구매완료",
        "서비스 제공 시간 안내": "서비스 제공 시간 안내",
        "서비스 개시일 24시간(업무일 기준) 전까지 구매 가능": "서비스 개시일 24시간(업무일 기준) 전까지 구매 가능",
        "서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지": "서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지",
        "31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)": "31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)",
        "서비스 이용 기간 설정": "서비스 이용 기간 설정",
        "가입기간": "가입기간",
        "일": "일",
        "이용금액": "이용금액",
        "시작일": "시작일",
        "종료일": "종료일",
        "아래의 약관을 모두 동의합니다.": "아래의 약관을 모두 동의합니다.",
        "서비스 이용료": "서비스 이용료",
        "사용기간": "사용기간",
        "금액": "금액",
        "사용기간": "사용기간",
        "서비스 안내": "서비스 안내",
        "컨시어지 및 채팅 서비스": "컨시어지 및 채팅 서비스",
        "원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.": "원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.",
        '상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.': '상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.',
        "서비스 이용 권장 사항": "서비스 이용 권장 사항",
        "이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.": "이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.",
        "이용권 구매 및 환불규정": "이용권 구매 및 환불규정",
        "모든 이용권은 부가세(VAT) 포함 가격입니다.": "모든 이용권은 부가세(VAT) 포함 가격입니다.",
        "출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.": "출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.",
        "서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.": "서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.",
        "이용권 사용 시작 전에는 100% 환불이 가능합니다.": "이용권 사용 시작 전에는 100% 환불이 가능합니다.",
        "서비스 결제 정보 확인": "서비스 결제 정보 확인",
        "서비스명": "서비스명",
        "EasyTraverCare": "EasyTraverCare",
        "가입기간": "가입기간",
        "서비스 기간": "서비스 기간",
        "이용기간에 대한 서비스 총 결제 금액": "이용기간에 대한 서비스 총 결제 금액",
        "입니다.": "입니다.",
        "위의 금액을 결제하시겠습니까?": "위의 금액을 결제하시겠습니까?",
        "사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.": "사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.",
        "이전화면": "이전화면",
        "결제하기": "결제하기",
        "제1조(목적)": "제1조(목적)",
        '이 약관은 회사가 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠"라고 한다) 및 제반서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.': '이 약관은 회사가 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠"라고 한다) 및 제반서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.',
        "제2조(정의)": "제2조(정의)",
        '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.': '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.',
        '2. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.': '2. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.',
        '3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.': '3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.',

        // 약관 
        "이용약관": "이용약관",
        "이메일무단수집거부": "이메일무단수집거부",
        "서비스정책": "서비스정책",
        
        // B2B
        "서비스 구매 엑셀 업로드": "서비스 구매 엑셀 업로드",
        "파일첨부": "파일첨부",
        "You can upload the list only if you agree to the terms and conditions below": "You can upload the list only if you agree to the terms and conditions below",
        "취소": "취소",
        "엑셀 업로드": "엑셀 업로드",
        "We agree to the regulations and signing up for Easy Care Service.": "We agree to the regulations and signing up for Easy Care Service.",
        "내용": "내용",
        "제목+내용": "제목+내용",
        "작성자": "작성자",
        "검색어를 입력해주세요.": "검색어를 입력해주세요.",
        "전체보기": "전체보기",
        "서비스 이용기간": "서비스 이용기간",
        "구매 연동": "구매 연동",
        "서비스 정보": "서비스 정보",
        "첨부파일명": "첨부파일명",
        "승인": "승인",
        "요청": "요청",
        "번호": "번호",
        "회원 관리": "회원 관리",
        "로그인 정보를 다시 확인해주세요.": "로그인 정보를 다시 확인해주세요.",
        // 2023.05.11 추가된 언어
        "전북쇼핑트래블라운지": "전북쇼핑트래블라운지",
        "위치: 전주시 완산구 충경로 1층": "위치: 전주시 완산구 충경로 1층",
        "무인 카페 라운지": "무인 카페 라운지",
        "전북관광 안내 서비스": "전북관광 안내 서비스",
        "관광기념품 판매 및 굿즈 판매": "관광기념품 판매 및 굿즈 판매",
        "수하물 보관 서비스": "수하물 보관 서비스",
        "소규모 미팅룸 대관": "소규모 미팅룸 대관",
        "이벤트 체험존": "이벤트 체험존",
        "여행 편의 서비스": "여행 편의 서비스",
        "우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등": "우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등",
        "일수": "일수",
        "구매자": "구매자",
        "명": "명",
        "대기": "대기",
        "파일을 등록해주세요.": "파일을 등록해주세요.",
        "국내카드 결제": "국내카드 결제",
        "해외카드 결제": "해외카드 결제"
    },
    english: {
        "code": "en",
        "언어": "Language",
        "서비스 소개": "Service Introduction",
        "의료 안심케어": "Medical worry-free care",
        "공항 안심케어": "Airport worry-free care",
        "트래블 안심케어": "Travel worry-free care",
        "비즈니스 안심케어": "Business worry-free care",
        "여행자 안심케어": "Traveler's worry-free care",
        "특별 혜택": "Special Benefits",
        "SOS 콜 채팅": "SOS Call-Chat",
        "서비스 구매": "Purchase the Service",
        "자주하는 질문": "FAQ",
        "문의": "Inquiry",
        "마이페이지": "My page",
        "내 회원정보": "My membership information",
        "구매내역": "Payment history",
        "회원탈퇴": "Withdrawal",
        "님 반갑습니다.": "Welcome,________!",
        "정보수정": "Change my information",
        "로그인": "Log in",
        "회원가입": "Join us",
        "로그아웃": "Log out",
        "메뉴닫기": "Close Menu",
        "고객센터": "Customer Center",
        "상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542": "Company name: PREMIUMPASS INTERNATIONAL Corp. | CEO: Kim, Eung su | Tel: 1644-1493 | Fax: 02-2063-3542",
        "통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 |": "Mail-order business report number: 2008-Seoul Gangseo-0702 | Business registration number: 109-86-06310 |",
        "사업자 정보확인": "Business information",
        "소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호": "Location : #501, Building A, Pine Square, 22, Magokjungang 4-ro, Gangseo-gu, Seoul",
        "개인정보 처리 책임자 : 임상엽 (care@tothepp.com)": "Person in charge of handling personal information: Sang Yeop, Lim(care@tothepp.com)",
        "월-금 09:00-18:00 (점심시간 12:00-13:00), 한국시간 기준": "Mon-Fri 09:00-18:00 (Lunch: 12:00-13:00)",
        "한국시간 기준": "in Korea time",
        "즐겁고 안전한 관광": "Enjoyable and Safe trip",
        "안심보험에서 안심여행까지!": "From worry-free insurance to worry-free trip!",
        "외국인 관광객 니즈에 맞는": "Customized worry-free care service,",
        "맞춤형 안심케어 서비스": "Meet the needs of foreign tourists.",
        "안심보험&컨시어지 서비스": "Worry-free insurance & Concierge service",
        "K-Travel 안심케어": "K-Travel worry-free care",
        "편안하고": "Comfortable,",
        "안전하고": "Safe,",
        "품위있는 가치관광": "Elegant and valuable tourism",
        "안전하고 즐거운 한국여행": "Comfortable and Enjoyable Trip to Korea",
        "이지트래블케어 안심보험과 함께하세요!": "Travel with Easy Travel Care!",
        "이전": "previous",
        "다음": "Next",
        "맞춤형 안심보험": "Customized worry-free care insurance",
        "컨시어지 서비스": "concierge service",
        "여행 중 발생할 수 있는 안전사고 등 해결방안 모색": "Solution arranging such as safety accidents that may occur during travel",
        "여행 안심보험은 물론 의료지원 및 케어 서비스": "Care service for not only traveler's worry free insurance but also Medical supports",
        "각종 도난사고 보험케어 및 트래블관련 케어 서비스": "Various theft case insurance care and travel related care service",
        "대한민국 안심관광을 즐기세요": "Enjoy a worry-free tour in Korea",
        "급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행": "Comfortable and safe travel by providing specialized worry-free care services as well as travel safety insurance for FIT and business tourists, which are rapidly increasing",
        "체계적인 서비스 운영 시스템": "Systematic service operation system",
        "컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영": "Concierge service/Customer service operation by foreign language CSR",
        "인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스": "Systematic service linked to Incheon Airport - Travel Center - Customer service",
        "국내 최고의 전문의전요원에 의한 직접적인 서비스": "Direct service by the best professional protocol specialist in Korea",
        "특별혜택 및": "Special benefits",
        "모바일 컨시어지 운영": " & mobile concierge operation",
        "가입자를 위한 20여가지 특별혜택": "About 20 special benefits for subscribers",
        "오프라인과 모바일 채팅 컨시어지 연계 서비스": "Offline and mobile chat concierge linked service",
        "대한민국을 여행할 때는": "Travel to Korea?",
        "언제나 이지트래블케어": "Be always with Easy Travel Care!",
        "안심보험으로 즐겁고 안전한 관광을": "You can have fun and safe sightseeing",
        "하실 수 있습니다.": "with Worry-free insurance.",
        "여행 중 발생할 수 있는": "Medical support service",
        "여행 중 발생할 수 있는 A": "Various accidents that might occur during travel",
        "여러 사고에 준비되어 있는": "prepared for various accidents",
        "의료 지원 서비스": "",
        "Details": "Details",
        "More": "More",
        "국제공항이라는 특별한": "In a special space, International Airport",
        "공간에서의 고객을 위한": "Worry-free care service for customers",
        "안심 케어 서비스": "",
        "여행 중 긴급사항 발생시": "Worry-free travel concierge service Provided in case of emergency during travel",
        "제공되는 다양한": "",
        "트래블 컨시어지 서비스": "",
        "편안하고 안전한 여행과": "For comfortable, safe travel",
        "성공적인 비즈니스를 위한": "and successful business",
        "특화 컨시어지 서비스": "Specialized concierge service",
        "휴대품 손해, 배상책임에서": "travel from the damage to belongings,",
        "휴대품 손해, 배상책임에서 A": "from the damage to belongings,",
        "사망까지": "and liability to death",
        "20여 가지의": "About 20",
        "멤버십 특별혜택 제공": "membership benefits",
        "Easy Travel Care Prices": "Easy Travel Care Prices",
        "Shorts": "Shorts",
        "Middle": "Middle",
        "Long": "Long",
        "Days": "Days",
        "days": "days",
        "Normally": "Normally",
        "별도 문의": "Separe inquiry",
        "31일 이상의 서비스 이용을 원하시면": "If you want to subscribe to the service for more than 31 days,",
        "별도 문의하여 주시기 바랍니다.": "please contact us separately.",
        "여행 중 곤란한 상황이": "Are you in panic while traveling?",
        "발생하셨나요?": "",
        "SOS 콜 채팅 바로가기": "Go to SOS Call-chat",
        "지역별 컨시어지 데스크": "Regional concierge desk",
        "대한민국 관광 주요거점에": "Each travel center is located in Korea’s major tourism hubs.",
        "위치한 각 트래블센터에서": "",
        "맞춤형 컨시어지 서비스를 제공합니다.": "We provide concierge services at..",
        "이지트래블 케어 서비스는": "Easy Travel Care Service helps you get lots of information and travel worry-free by visiting any of our concierge desks.",
        "여행 중 각 컨시어지데스크에": "",
        "방문하셔서 문의주시면 안심관광을": "",
        "위한 도움을 드리고 있습니다.": "",
        "인천공항 T1": "Incheon International Airport T1",
        "인천공항 T2": "Incheon International Airport T2",
        "서울역 라운지": "Seoul Station Lounge",
        "마포관광 정보센터": "Mapo Tourist Information Center",
        "송도 컨벤시아": "Songdo Convensia",
        "인천국제공항 제1터미널": "Incheon International Airport Terminal 1",
        "컨시어지데스크": "Concierge desk",
        "부스명: MICE Concierge Desk(40번 데스크)": "Booth name: MICE Concierge desk (Desk40)",
        "위치: 제1터미널 8번과 9번 게이트 사이": "Location: Between Gate 8 and 9 in Terminal 1",
        "인천국제공항 제2터미널": "Incheon International Airport Terminal 2",
        "부스명: MICE Concierge Desk": "Booth Name: MICE Concierge Desk",
        "위치: 제2터미널 4번게이트": "Location: Gate 4, Terminal 2",
        "프리미엄라운지": "Premium lounge",
        "서울역점": "Seoul Station Branch",
        "위치: 서울역 도심공항터미널 지하 2층": "Location: Seoul Station City Airport Terminal B2",
        "위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리": "Location: 3-minute walk from Exit 8 of Hongik University Station on Subway Line 2",
        "송도컨벤시아점": "Sondo Convensia branch",
        "위치: 인천 송도컨벤시아 내(Gate1)": "Location : in Incheon Songdo convensia (Gate1)",
        "이지트래블케어 서비스 외에 컨시어지 서비스": "In addition to the easy travel care service, a separate fee is incurred when using the concierge service.",
        "이용 시 별도의 비용이 발생됩니다.": "",
        "비용은 각 컨시어지데스크 요금정책에 따릅니다.": "The Fee is subject to each concierge desk rate policy.",
        "컨시어지 데스크 이용방법": "How to use the concierge desk",
        "컨시어지 데스크 방문": "Visit the concierge desk",
        "이지트래블케어 바우처 제시": "Submit a Easy Travel Care voucher",
        "바우처의 내용 확인": "Check the contents of the voucher",
        "서비스 시작": "Start the service",
        "Terminal": "Terminal",
        "카페": "Café",
        "사무용품&스낵": "Office supplies & Snacks",
        "여행 편의 서비스": "Travel Convenience Services",
        "비즈니스 업무": "Business work",
        "소규모 회의실 대관": "Small meeting room rental",
        "국제공항이라는 특별한 공간에서의": "Worry-free service for customers in a special space, International Airport",
        "고객을 위한 안심 케어 서비스": "",
        "노약자 등 공항에서 지원 및 불편사항 케어": "Support and care for inconveniences at the airport for people who need our help such as the elderly",
        "인천공항에서의 컨시어지 서비스 제공": "Concierge service provided at Incheon International Airport",
        "비자 및 긴급 항공권 케어 서비스": "Visa and emergency ticket care service",
        "INFORMATION": "INFORMATION",
        "공항 불편 사항 케어": "Airport inconvenience care",
        "공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "In the event of an accident at the airport, if you inform us of the current location and current station of the accident through an SOS call, we will provide various countermeasures and dispatch services.",
        "공항 내 컨시어지 서비스 제공": "Concierge service in the Airport",
        "인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.": "When visiting Incheon International Airport Terminal 1 or Incheon International Airport Terminal 2 Travel Center, we will help resolve accidents within the airport",
        "트래블센터": "Travel Center",
        "약도": "Map",
        "확인 부탁드립니다.": "Please click it.",
        "공항 긴급 서비스 제공": "Providing airport emergency services",
        "긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공": "Emergency tickets: Emergency replacement ticket reissuance and refund procedure care service in case of missed tickets",
        "긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공": "Emergency Visa: Provides issuance procedure care service after confirmation by related authorities",
        "긴급 통·번역 인력 수배 서비스": "Urgent interpretation and translation arragement service",
        "회의시설 및 미팅 장소 예약 지원 서비스": "Conference facility and meeting place reservation support service",
        "KTX, 항공권 등 예약 지원 서비스": "Reservation support service for KTX, flight tickets, etc.",
        "통·번역 인력 수배 서비스": "Interpretation and translation arragement service",
        "동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.": "We provide services such as simultaneous interpretation, sequential interpretation, attendant interpretation, telephone interpretation, etc., assistance in hiring professional translations, reservation support for specialized translations by field, information on rental of interpretation equipment, and reservation support services.",
        "회의시설 및 미팅 장소 예약 지원": "Conference facility and meeting place reservation support",
        "회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스": "Reservation of conference/meeting venues: information and reservation support service for meeting venues located in major business and transportation hubs",
        "연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.": "Banquet Venue Reservation: Customized space reservation support service according to purpose of use and size",
        "KTX · 항공권 등 예약 지원": "Banquet Venue Reservation: Customized space reservation support service according to purpose of use and size",
        "KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.": "KTX online/offline ticket issuance and reservation support service, domestic/international ticket issuance and reservation support service",
        "교통사고, 안전사고 등 맞춤형 케어 서비스": "Customized care services such as traffic accidents and safety accidents",
        "의료통역사 수배, 의료비 해결방안 모색,": "arrangement of medical interpreters, finding solutions to medical expenses",
        "여행자보험 & 의료지원 등 케어": "travel insurance & medical support, etc.",
        "교통사고 시 이용 안내": "User guide in case of traffic accident",
        "여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.": "In the event of a traffic accident while traveling, if you inform us of your current location and accident status through an SOS call, we will provide medical support.",
        "안전사고 시 이용 안내": "Guidelines for Safety Accidents",
        "여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "If various safety accidents occur during travel, if you inform us of your current location and accident status through an SOS call, we will provide various countermeasures and dispatch services.",
        "맞춤형 케어 이용 안내": "Guide to using customized care",
        "안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.": "In the event of a safety accident, we provide medical care services such as receiving reports from related agencies, issuing documents, arranging companies specializing in medical interpretation and translation, and arranging international medical tourism caregivers.",
        "컨시어지 데스크 무료이용": "Complimentary use of the Concierge desk",
        "공항 픽업 및 의전 서비스 할인": "Airport pickup and protocol service discount",
        "교통 예약 및 발권": "Transportation reservation and ticketing",
        "멤버십 특별혜택 서비스": "Membership special benefit service",
        "인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용": "Complimentary use of Incheon International Airport Terminal 1 & Terminal 2 concierge desk",
        "공항 픽업/샌딩 서비스 20% 할인": "20% discount on Airport pickup/sending service",
        "공항 의전 및 영접 서비스 30% 할인": "30% discount on Airport protocol and reception services",
        "수하물 딜리버리 서비스 20% 할인": "20% discount on Luggage delivery service",
        "대중교통 예약 및 발권 등 탑승 지원 서비스": "Boarding support services such as public transportation reservation and ticketing",
        "항공, KTX, 고속버스 등 교통 예약 및 발권": "Transportation reservation and ticketing such as as Air ticket , KTX, and express bus",
        "상해 및 질병": "Injury and illness",
        "국내여행 중 배상책임": "Liability for compensation during domestic travel",
        "국내여행 중 휴대품 손해": "Damage to belongings during domestic travel",
        "여행자 안심 보험 서비스": "Traveler's worry-free insurance",
        "국내여행중 상해사망": "Accidental Death (During Domestic Trip)",
        "국내여행중 상해후유장해": "Accident Disability (During Domestic Trip)",
        "1억원": "KRW 100,000,000",
        "15세 미만 제외": "Excluding under age 15",
        "국내여행중 질병사망/ 80%고도후유장해": "Death by Illness or 80% Severe Disability by Illness (Domestic Trip)",
        "2천만원": "KRW 20,000,000",
        "15세 미만/ 80세이상 제외": "Excluding under age 15, age 80 or more",
        "국내여행중 일괄배상": "Lump Sum Compensation (Domestic Trip)",
        "5백만원": "KRW 5,000,000",
        "국내여행": "Domestic travel",
        "국내여행중 휴대품손해": "Personal Belongings Damage (Domestic Trip)",
        "20만원": "KRW 200,000",
        "분실제외": "Except Loss",
        "국내여행 상해급여_의료실비보장": "Domestic Travel Injury Insurance_Medical Expense Coverage",
        "1천만원": "KRW 10,000,000",
        "통원 1회당 10만원 한도": "Limit of KRW100,000 per Outpatient",
        "국내여행 상해비급여_의료실비보장": "(Non-covered) Domestic Travel Injury Insurance_Medical Expense Coverage",
        "여행 중 긴급사항 발생시 제공되는": "Various travel concierge services provided in case of emergency during travel",
        "다양한 트래블 컨시어지 서비스": "",
        "지갑, 여권 등 분실 시 지원 케어 서비스": "Support care service in case of loss of wallet, passport, etc.",
        "여행 도중 긴급사항 발생 시 케어 서비스": "Care service in case of emergency during travel",
        "교통편 예약 발권 및 차량 수배 서비스": "Transportation reservation & ticketing and car arrangement service",
        "긴급 숙박 예약 지원 서비스": "Urgent Accommodation Reservation Support Service",
        "지갑, 여권 등 분실 시 케어 서비스": "Care service in case of loss of wallet, passport, etc.",
        "여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)": "We provide support for issuance of all documents required for passport reissuance (theft certificate, police confirmation, etc.) care service, confirmation of the lost location of theft, and connection to the lost and found center (accompanying the police station if necessary).",
        "보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)": "Insurance service - Loss of belongings during domestic travel: KRW 200,000 (in case of accidental damage such as theft or damage)",
        "긴급상황 발생 시 케어서비스": "Care service in case of emergency",
        "긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "In the event of an emergency, if you inform us of your current location and accident station through an SOS call, we will provide various response plans and dispatch services.",
        "보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)": "Insurance service - Compensation liability during domestic travel: KRW 5,000,000 (compensation for bodily injury to others and damage to property)",
        "교통편 예약 발권 / 차량 수배 서비스": "Transportation Reservation &Ticketing / Car Arrangement Service",
        "대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.": "We provide public transportation (bus / KTX / taxi) ticketing and reservation support services, emergency replacement ticket reissuance and refund procedure care services in case you miss a bus or train, customized vehicle arrangement and reservation support services.",
        "가입시 연령제한이 있나요?": "Is there an age limitation?",
        "연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.": "*There's no age limitation. But if you're less than 15 years old and greater than or equal to 80 years old, the insurance gurantee details could be different.",
        "가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?": "By when do I should sign up?",
        "업무일 기준, 개시일 24시간 전까지입니다.": "*Based on working days, you should apply for the service until 24 hours before the date you want your service to get started.",
        "가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?": "Can I use all EASY TRAVEL CARE Service for free just by signing up?",
        "이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.": "When you sign up for Easy Travel Care, you can enjoy benefits within the service category. However, please check the separate cost part that occurs at the customer's expense.",
        "이지트래블케어 가입시 달러로만 결제하나요?": "When signing up for Easy Travel Care, do I pay only in US dollars?",
        "달러로만 결제 가능합니다.": "*Payment is accepted in USD only.",
        "카드 결제시 오류가 나면 어떻게 하나요?": "What if there is an error when paying by card?",
        "고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.": "If you contact the customer center (1644-1493), we will help you pay in cash.",
        "서비스에 가입했다가 다시 취소할 수 있나요?": "Can I sign up for a service and then cancel it again?",
        "서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.": "Cancellation is possible up to 24 hours prior to the service commencement date.",
        "상해발생 지역이 해외여도 가능한가요?": "Is it possible even if the area where the injury occurred is overseas?",
        "한국 국내 상황에만 적용됩니다.": "Applies only to Korean domestic Statuss.",
        "특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?": "Can I use the special benefit discount even after the service period ends?",
        "특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.": "The special benefit discount is only available for a limited time during the service use period.",
        "멤버십 증서는 어디서 확인하나요?": "Where can I check my membership certificate?",
        "서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.": "You can check and print it on My Page during the service period.",
        "컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?": "How do I present my voucher when using the concierge desk?",
        "멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.": "You can use it by downloading the membership certificate and submitting it to the desk.",
        "이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.": "You can see all details of FAQ about EASY TRAVEL CARE at once.",
        "상담원 연결이 필요해요!": "I need an agent connection to Customer center",
        "ChatBot": "ChatBot",
        "서비스 이용안내": "Service usage guide",
        "약관동의": "Agree to terms and conditions",
        "이용안내": "Usage guide",
        "수십 분 내에 응답합니다.": "We will respond within minutes",
        "담당매니저연결중입니다": "Now you connecting with the manager in charge",
        "빠른시간내에연락드리겠습니다": "We will contact you as soon as possible.",
        "WELCOME!": "WELCOME!",
        "How can I Help today?": "How can I Help today?",
        "New Chat": "New Chat",
        "운영시간: 00:00 ~ 24:00": "Operating time: 00:00~24:00",
        "정말 삭제하시겠습니까?": "Are you sure you want to delete?",
        "확인": "Confirm",
        "이제 이지트래블케어 서비스를 이용할 수 있습니다.": "Easy Travel Care service is now available.",
        "맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!": "Enjoy safe tourism through customized safety insurance, concierge service, and mobile SOS chat service!",
        "우측 하단의 채팅 아이콘": "Online is available through the chat icon at the bottom right",
        "을 통해 온라인 채팅 상담이 가능합니다.": "",
        "서비스 이용 권장사양": "Recommended specifications for using the service",
        "HTML5 지원 브라우저,": "HTML5 supported browsers",
        "Chrome 14 버전 이상,": "Chrome 14 or higher,",
        "Internet Explorer 10 버전 이상,": "Internet Explorer 10 or higher,",
        "Safari 6 버전 이상,": "Safari version 6 or higher,",
        "Opera 12.10 버전 이상,": "Opera 12.10 or higher,",
        "Firefox 11 버전 이상,": "Firefox version 11 or higher,",
        "안드로이드 브라우저 4.4 이상": "Android browser 4.4 or higher",
        "이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.": "The chat service provided by EASY TRAVE CARE is optimized for the above version.",
        "위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.": "For devices that do not meet the above version, the screen may not operate normally.",
        "약관에 동의해주시기 바랍니다.": "Please agree to Terms and Conditions",
        "서비스 약관 동의": "Agree to Terms of Service",
        "이지트래블케어 서비스에 가입해주셔서 감사합니다.": "Thank you for subscribing to the Easy Travel Care service.",
        "본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.": "This course is the last step to use the Easy Travel chat service (SOS Call Service).",
        "이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.": "After agreeing to the terms and conditions of Easy Travel Care and Hyundai Marine & Fire Insurance, click the [Next] button to start the Easy Travel Care chat service.",
        "약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.": "Members who do not agree to the terms and conditions cannot use the chat service.",
        "지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.": "Even if you do not consent now, you can come back to this screen at any time through the SOS Call icon at the bottom right of the homepage's first screen.",
        "이지트래블케어 이용 약관에 동의합니다.": "I agree to the Easy Travel Care terms and conditions.",
        "이지트래블케어 이용 약관": "Easy Travel Care Terms and Conditions",
        "아래의 약관에 모두 동의합니다.": "I agree to all of the terms below.",
        "현대해상 화재보험 약관": "Hyundai Marine & Fire Insurance Terms and Conditions",
        "현대해상 화재보험 약관에 동의합니다.": "I agree to the terms and conditions of Hyundai Marine & Fire Insurance.",
        "게시글을 삭제하시겠습니까?": "Are you sure you want to delete the post?",
        "삭제하신 후에는 복구가 되지 않습니다.": "Once deleted, it cannot be recovered.",
        "삭제": "Delete",
        "삭제 되었습니다.": "Deleted",
        "제목을 입력해주세요": "Please type the title.",
        "문의유형": "Inquiry Type",
        "작성자": "Writer",
        "제목": "Title",
        "문의내용": "Detail",
        "문의할 내용을 입력해 주세요.": "Please type the detail.",
        "답변완료": "Answer complete",
        "수정": "Edit",
        "목록": "List",
        "답변대기": "waiting for answer",
        "궁금한 내용을 작성하시면 댓글로 답변해드립니다.": "If you have any questions and inquiries, we will respond in the comments.",
        "광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.": "Posts of an advertisement or commercial could be modified or deleted by the administrator.",
        "작성하기": "Write",
        "카테고리": "Category",
        "작성일": "Date of Writing",
        "상태": "Status",
        "비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.": "Password must be a minimum of 6 characters and a maximum of 16 characters including English, numbers and special characters.",
        "비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.": "The password and password verification line do not match. Please check again.",
        "이메일을 형식에 맞게 작성해주세요.": "Please fill in the email format.",
        "사용언어": "Language",
        "하이픈(-)을 포함해서 작성해주세요.": "Please include a hyphen (-)",
        "휴대폰 번호": "Mobile phone Number",
        "이메일": "Email",
        "새 비밀번호": "New password",
        "새 비밀번호 확인": "Verify new password",
        "영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.": "6 to 16 characters using all alphabets, numbers, and special characters",
        "생년월일": "Date of birth",
        "여권번호": "Passport number",
        "외국인등록번호": "Foreigner registration number",
        "이름": "Name",
        "성별": "Gender",
        "국적": "Nationality",
        "아이디": "ID",
        "사용가능": "Available",
        "결제취소": "Cancel payment",
        "서비스만료": "Service expiration",
        "나의 구매 내역을 확인할 수 있습니다.": "You can check my payment history.",
        "고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)": "This is the history of your Easy Travel Care service purchase. (Purchase details are displayed in order of latest.)",
        "컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.": "When you use the concierge desk, you can use it as a voucher if you present your subscription certificate.",
        "결제일": "Date of payment",
        "유효기간": "Expiration date",
        "금액": "Amount",
        "결제수단": "Payment method",
        "가입증서": "Subsciption Certificate",
        "가입증서 보기": "See my subsciption certificate",
        "탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.": "A deleted ID cannot be recovered, and you cannot re-register with the same ID or nickname in the future.",
        "서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.": "Information registered for using the service is deleted, and deleted data cannot be recovered.",
        "단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.": "However, registered posts including comments will not be deleted.",
        "유의사항을 모두 확인하였으며, 이에 동의합니다.": "I have read all notes and agree to them.",
        "비밀번호": "Password",
        "비밀번호를 입력하세요.": "Please enter your password",
        "탈퇴사유": "reason for withdrawal",
        "회원탈퇴 후 재가입": "Re-registration after membership withdrawal",
        "서비스를 더 이상 이용하지 않음": "no longer use the service",
        "이용이 불편하고 장애가 많음": "Inconvenient to use and many obstacles",
        "콘텐츠의 유용성 부족": "Lack of usefulness of content",
        "고객센터 및 서비스 불만": "Customer center and service complaints",
        "기타": "etc",
        "기타의견": "other opinions",
        "탈퇴하기": "withdraw",
        "아이디가 존재하지 않습니다.": "This ID is not exist.",
        "아이디 찾기": "Find my ID",
        "비밀번호 찾기": "Find my password",
        "여권번호 또는 외국인등록번호": "Passport number or Foreign registration number.",
        "휴대폰번호": "Mobile phone Number",
        "B2B회원은 아이디 찾기 및": "ID and password search are not supported for B2B members.",
        "비밀번호 찾기를 지원하지 않습니다.": "",
        "1644-1493로 문의해주시기 바랍니다.": "For inquiries, please contact 1644-1493 (Customer Center).",
        "휴대폰번호( 하이폰 - 포함해서 작성해주세요)": "Mobile phone number (please include Hyphen (-) .)",
        "고객님의 아이디는": "Your ID is .",
        "입니다.": "",
        "계정정보가 일치합니다.": "Account information matches.",
        "계정의 비밀번호를": "Are you sure you want to reset the password for your account to a temporary password?",
        "임시 비밀번호로 초기화": "",
        "하시겠습니까?": "",
        "예": "Yes",
        "아니요, 처음으로": "No, back to start",
        "휴대폰으로 임시비밀번호를 보내드렸습니다.": "A temporary password has been sent to your mobile phone.",
        "아이디를 입력하세요.": "Please enter your ID",
        "아이디/비밀번호 찾기": "Find my ID/password",
        "비밀번호 조건에 맞지 않습니다.": "The password conditions are not met.",
        "아이디 저장": "Save the ID",
        "개인정보 보호를 위해 본인 기기에서만 사용해주세요.": "To protect your privacy, please use it only on your own device.",
        "이미 가입된 아이디 입니다.": "This ID is already registered.",
        "사용 가능한 아이디 입니다.": "This ID is available.",
        "사용할 수 없는 아이디 입니다.": "This ID isn't available.",
        "중복체크": "duplicate check",
        "비밀번호 확인": "Verify password",
        "회원가입 완료": "Member registration complete",
        "이지트래블케어 회원가입이 완료되었습니다.": "Easy Travel Care membership registration is complete.",
        "회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.": "You can now use all functions of the Easy Travel Care site.",
        "로그인 후 이용가능합니다.": "The service is Available after login.",
        "비밀번호 : 영문/숫자/특수문자, 숫자 6~16자": "Password: English/number/special character, 6~16 numbers",
        "남성": "Male",
        "여성": "Female",
        "여권번호(외국인등록번호)": "Passport number (Foreigner Registration Number)",
        "올바른 형식의 이메일 주소가 아닙니다.": "This is not a well-formed email address.",
        "필수항목 체크": "Check Required Items",
        "개인정보처리방침": "Privacy Policy",
        "동의": "Agree",
        "필수": "Essential",
        "이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.": "Agreeing to the Terms of Use and Privacy Policy is a mandatory check item.",
        "동의하고 가입하기": "Agree and sign up",
        "컨시어지 서비스 구매를": "You have completed the purchase of concierge services.",
        "완료했습니다.": "",
        "이지트래블케어와 함께 즐거운 여행되세요.": "Have a pleasant trip with Easy Travel Care.",
        "홈으로 가기": "Go HOME",
        "구매정보 확인": "Check purchase information",
        "정보입력": "Fill in a form",
        "결제하기": "Pay",
        "구매완료": "Complete the payment",
        "서비스 제공 시간 안내": "Information on service provision time",
        "서비스 개시일 24시간(업무일 기준) 전까지 구매 가능": "Can be purchased up to 24 hours (business days) before the service start date",
        "서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지": "Service provision period: from 00:00 on the start date to 23:59 on the end date",
        "31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)": "If you want to use the service for more than 31 days, please contact us separately. (1644-1493)",
        "서비스 이용 기간 설정": "Set service usage period",
        "가입기간": "Subscription period",
        "일": "Days",
        "이용금액": "Usage amount",
        "시작일": "Date of start",
        "종료일": "Date of end",
        "아래의 약관을 모두 동의합니다.": "I agree to all terms and conditions below.",
        "서비스 이용료": "Service fee",
        "사용기간": "Period of use",
        "서비스 안내": "Service information",
        "컨시어지 및 채팅 서비스": "Concierge and chat service",
        "원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.": "If you purchase a pass by setting a desired period, you can use the concierge service and real-time chatting service provided by Easy Travel Care according to the terms and conditions.",
        '상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.': 'For details, please refer to the "Easy Travel Care Terms and Conditions".',
        "서비스 이용 권장 사항": "Recommendations for using the service",
        "이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.": "EASY TRAVEL CARE's chat service is optimized for Google Chrome and supports browsers that are HTML5 compliant.",
        "이용권 구매 및 환불규정": "Ticket Purchase and Refund Policy",
        "모든 이용권은 부가세(VAT) 포함 가격입니다.": "All passes are inclusive of VAT.",
        "출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.": "Charges incurred after dispatch service are subject to each concierge desk charge policy.",
        "서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.": "From the time the service starts, it is impossible to request cancellation of the subscription payment.",
        "이용권 사용 시작 전에는 100% 환불이 가능합니다.": "100% refund is possible up to 24 hours (business days) before the start of using the pass.",
        "서비스 결제 정보 확인": "Check service payment information",
        "서비스명": "Service name",
        "EasyTraverCare": "Easy Travel Care",
        "서비스 기간": "Service period",
        "이용기간에 대한 서비스 총 결제 금액": "Total service payment amount for the period of use",
        "위의 금액을 결제하시겠습니까?": "Would you like to pay the above amount?",
        "사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.": "For safe financial transactions of users, please proceed with payment within the time limit.",
        "이전화면": "Previous screen",
        "제1조(목적)": "Article 1 [Purpose)",
        '이 약관은 회사가 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠"라고 한다) 및 제반서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.': 'The purpose of these terms and conditions is to stipulate the rights, obligations, and responsibilities between the company and users in relation to the use of digital content (hereinafter referred to as "content") and services provided online by the company.',
        "제2조(정의)": "Article 2 (Definition)",
        '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.': 'The definitions of terms used in these terms and conditions are as follows. 1. "Company" refers to a person who engages in economic activities related to the "contents" industry and provides content and services.',
        '2. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.': '2. "User" refers to members and non-members who access the site of the "company" and use the "contents" and services provided by the "company" in accordance with these terms and conditions.',
        '3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.': '3. "Member" means a "user" who has entered into a use contract with the "company" and has been given a "user" ID, and is continuously provided with information from the "company" and continues to use the services provided by the "company". refers to those who can use',
        "이용약관": "Terms of Use",
        "이메일무단수집거부": "Rejection of Unauthorized Email Collection",
        "서비스정책": "Service Policy",
        "서비스 구매 엑셀 업로드": "Service purchase excel upload",
        "파일첨부": "Attachments",
        "You can upload the list only if you agree to the terms and conditions below": "You can upload the list only if you agree to the terms and conditions below",
        "취소": "Cancel",
        "엑셀 업로드": "Excel upload",
        "We agree to the regulations and signing up for Easy Care Service.": "We agree to the regulations and signing up for Easy Care Service.",
        "내용": "detail",
        "제목+내용": "Title + content",
        "작성자": "Writer",
        "검색어를 입력해주세요.": "Please enter a search term.",
        "전체보기": "View all",
        "서비스 이용기간": "Service period",
        "구매 연동": "P.name",
        "서비스 정보": "Service information",
        "첨부파일명": "Attached file name",
        "승인": "Approval",
        "요청": "Request",
        "번호": "Number",
        "회원 관리": "Member management",
        "로그인 정보를 다시 확인해주세요.": "Please check your login information",
        // 2023.05.11 추가된 언어
        "전북쇼핑트래블라운지": "Jeonbuk Shopping Travel Lounge",
        "위치: 전주시 완산구 충경로 1층": "Location : 1st floor, 25 Chunggyeong-ro, Wansan-gu, Jeonju-si, Jeollabuk-do",
        "무인 카페 라운지": "Unmanned cafe·Lounge",
        "전북관광 안내 서비스": "Tourist Information Service",
        "관광기념품 판매 및 굿즈 판매": "Sales of tourist souvenirs and merchandise",
        "수하물 보관 서비스": "Luggage Storage",
        "소규모 미팅룸 대관": "Meeting room rental",
        "이벤트 체험존": "Event zone (Photo zone)",
        "여행 편의 서비스": "Travel convenience services",
        "우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등": "umbrella rental, phone charging, power bank rental, Wi-Fi, etc.",
        "일수": "Days",
        "구매자": "Buyer",
        "명": "Pax",
        "대기": "Wating",
        "파일을 등록해주세요.": "Please upload the file.",
        "국내카드 결제": "Domestic card (KRW only)",
        "해외카드 결제": "Overseas card (USD only)"
    },
    chinese: {
        "code": "cn",
        "언어": "语言",
        "서비스 소개": "服务介绍",
        "의료 안심케어": "医疗安心服务",
        "공항 안심케어": "机场安心服务",
        "트래블 안심케어": "旅游安心服务",
        "비즈니스 안심케어": "商务安心服务",
        "여행자 안심케어": "旅行者安心服务",
        "특별 혜택": "特别优惠",
        "SOS 콜 채팅": "SOS呼叫联络",
        "서비스 구매": "购买服务",
        "자주하는 질문": "常见问题",
        "문의": "咨询",
        "마이페이지": "我的主页",
        "내 회원정보": "我的会员信息",
        "구매내역": "购买明细",
        "회원탈퇴": "会员注销",
        "님 반갑습니다.": "先生或女士，您好！",
        "정보수정": "修改信息",
        "로그인": "登录",
        "회원가입": "注册会员",
        "로그아웃": "退出",
        "메뉴닫기": "关闭菜单",
        "고객센터": "客服中心",
        "상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542": "商号名称: (株)PREMIUMPASS INTERNATIONAL | 代表 : 金应洙 | 代表电话 : 1644-1493 | 传真 : 02-2063-3542",
        "통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 |": "通信销售业申报编号 : 2008-首尔江西-0702 | 营业执照注册号 : 109-86-06310 |",
        "사업자 정보확인": "营业者信息确认",
        "소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호": "所在地 : 首尔特别市江西区麻谷中央4路22(麻谷洞)Pine Square A栋501号",
        "개인정보 처리 책임자 : 임상엽 (care@tothepp.com)": "个人信息处理负责人 : 林相烨 (care@tothepp.com)",
        "월-금 09:00-18:00 (점심시간 12:00-13:00), 한국시간 기준": "周一~周五 09:00-18:00 (午休时间 12:00-13:00)",
        "한국시간 기준": "以韩国时间为准",
        "즐겁고 안전한 관광": "既愉快又安全",
        "안심보험에서 안심여행까지!": "从安心保险到安心旅游！",
        "외국인 관광객 니즈에 맞는": "符合外国游客需求的",
        "맞춤형 안심케어 서비스": "定制型安心服务",
        "안심보험&컨시어지 서비스": "安心保险＆礼宾服务",
        "K-Travel 안심케어": "K-Travel安心服务",
        "편안하고": "安全舒适之旅",
        "안전하고": "有品位有价值",
        "품위있는 가치관광": "",
        "안전하고 즐거운 한국여행": "安全愉快的韩国之旅",
        "이지트래블케어 안심보험과 함께하세요!": "Easy Travel Care和您同行！",
        "이전": "前一页",
        "다음": "下一页",
        "맞춤형 안심보험": "定制型安心保险",
        "컨시어지 서비스": "礼宾服务",
        "여행 중 발생할 수 있는 안전사고 등 해결방안 모색": "提供旅行途中可能发生的安全事故等解决方案",
        "여행 안심보험은 물론 의료지원 및 케어 서비스": "提供安心保险、医疗支援及旅游支援服务",
        "각종 도난사고 보험케어 및 트래블관련 케어 서비스": "各种失窃保险及旅行相关的支援服务",
        "대한민국 안심관광을 즐기세요": "安心享受韩国之旅吧！",
        "급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행": "为激增的自由行客户(FIT)及商务差旅客户提供安心保险及定制式安心支援服务，使其享受舒适且安全的旅行体验",
        "체계적인 서비스 운영 시스템": "系统化的服务运营体系",
        "컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영": "提供礼宾服务并运营由外国语专业咨询员组成的信息咨询中心",
        "인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스": "仁川机场-旅游中心-信息咨询中心，三条龙联网服务",
        "국내 최고의 전문의전요원에 의한 직접적인 서비스": "提供迅速直接的韩国顶级礼宾服务",
        "특별혜택 및": "特别优惠及",
        "모바일 컨시어지 운영": "手机咨询服务",
        "가입자를 위한 20여가지 특별혜택": "用户可享受20余种特别优惠项目",
        "오프라인과 모바일 채팅 컨시어지 연계 서비스": "用户在外旅游时，提供手机聊天咨询窗口连接服务",
        "대한민국을 여행할 때는": "韩国之旅的不二选择",
        "언제나 이지트래블케어": "Easy Travel Care",
        "안심보험으로 즐겁고 안전한 관광을": "通过安心保险，",
        "하실 수 있습니다.": "享受既愉快又安全的旅游。",
        "여행 중 발생할 수 있는": "为处理旅行中可能发生的",
        "여행 중 발생할 수 있는 A": "为处理旅行中可能发生的",
        "여러 사고에 준비되어 있는": "各种事故而准备的",
        "의료 지원 서비스": "医疗支援服务",
        "Details": "Details",
        "More": "More",
        "국제공항이라는 특별한": "在国际机场特别区域，",
        "공간에서의 고객을 위한": "为顾客提供",
        "안심 케어 서비스": "安心支援服务",
        "여행 중 긴급사항 발생시": "旅行中发生紧急事项时，",
        "제공되는 다양한": "提供各种旅游礼宾服务",
        "트래블 컨시어지 서비스": "",
        "편안하고 안전한 여행과": "为舒适安全的旅游及成功的商机",
        "성공적인 비즈니스를 위한": "提供个性化礼宾服务",
        "특화 컨시어지 서비스": "",
        "휴대품 손해, 배상책임에서": "携带品毁损、赔偿责任、死亡等相关的保险",
        "휴대품 손해, 배상책임에서 A": "携带品毁损、赔偿责任、死亡等相关的保险",
        "사망까지": "",
        "20여 가지의": "提供20余种会员制特别优惠",
        "멤버십 특별혜택 제공": "",
        "Easy Travel Care Prices": "Easy Travel Care Prices",
        "Shorts": "Shorts",
        "Middle": "Middle",
        "Long": "Long",
        "Days": "Days",
        "days": "days",
        "Normally": "Normally",
        "별도 문의": "另行咨询",
        "31일 이상의 서비스 이용을 원하시면": "使用服务日期超过31天的，",
        "별도 문의하여 주시기 바랍니다.": "请另行咨询",
        "여행 중 곤란한 상황이": "旅游中遇到困难了吗？",
        "발생하셨나요?": "",
        "SOS 콜 채팅 바로가기": "即刻连接SOS呼叫联络",
        "지역별 컨시어지 데스크": "各地区礼宾服务台",
        "대한민국 관광 주요거점에": "韩国主要旅游据点设有旅游中心，",
        "위치한 각 트래블센터에서": "",
        "맞춤형 컨시어지 서비스를 제공합니다.": "提供量身定制的礼宾服务。",
        "이지트래블 케어 서비스는": "Easy Travel Care服务为旅行途中访问各礼宾服务台进行咨询的游客",
        "여행 중 각 컨시어지데스크에": "",
        "방문하셔서 문의주시면 안심관광을": "提供安心旅游服务。",
        "위한 도움을 드리고 있습니다.": "",
        "인천공항 T1": "仁川机场 第一航站楼",
        "인천공항 T2": "仁川机场 第二航站楼",
        "서울역 라운지": "首尔站休息室",
        "마포관광 정보센터": "麻浦旅游信息中心",
        "송도 컨벤시아": "仁川松岛国际会展中心",
        "인천국제공항 제1터미널": "仁川国际机场第一航站楼",
        "컨시어지데스크": "礼宾服务台",
        "부스명: MICE Concierge Desk(40번 데스크)": "服务台名称: MICE Concierge Desk(40号前台)",
        "위치: 제1터미널 8번과 9번 게이트 사이": "位置：第一航站楼Gate8与GATE9之间",
        "인천국제공항 제2터미널": "仁川国际机场第二航站楼",
        "부스명: MICE Concierge Desk": "服务台名称: MICE Concierge Desk",
        "위치: 제2터미널 4번게이트": "位置：第二航站楼GATE4",
        "프리미엄라운지": "豪华休息室",
        "서울역점": "首尔站服务台",
        "위치: 서울역 도심공항터미널 지하 2층": "位置：首尔站都心机场客运站 地下2层",
        "위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리": "位置：地铁2号线弘大入口站8号出口步行3分钟",
        "송도컨벤시아점": "仁川松岛国际会展中心",
        "위치: 인천 송도컨벤시아 내(Gate1)": "位置：仁川松岛国际会展中心(Gate1)",
        "이지트래블케어 서비스 외에 컨시어지 서비스": "Easy Travel Care用户使用Easy Travel Care所含服务以外的礼宾服务，",
        "이용 시 별도의 비용이 발생됩니다.": "将产生额外费用。",
        "비용은 각 컨시어지데스크 요금정책에 따릅니다.": "其费用遵循各礼宾服务台收费政策。",
        "컨시어지 데스크 이용방법": "礼宾服务台使用指南",
        "컨시어지 데스크 방문": "访问礼宾服务台",
        "이지트래블케어 바우처 제시": "出示Easy Travel Care证书",
        "바우처의 내용 확인": "确认证书",
        "서비스 시작": "提供服务",
        "Terminal": "",
        "카페": "咖啡店",
        "사무용품&스낵": "办公用品&零食",
        "여행 편의 서비스": "旅游便利服务",
        "비즈니스 업무": "商务",
        "소규모 회의실 대관": "小规模会议室租赁",
        "국제공항이라는 특별한 공간에서의": "在国际机场特别区域，",
        "고객을 위한 안심 케어 서비스": "为顾客提供安心支援服务",
        "노약자 등 공항에서 지원 및 불편사항 케어": "为老弱者提供机场支援服务并协助解决不便事宜",
        "인천공항에서의 컨시어지 서비스 제공": "提供仁川机场礼宾服务",
        "비자 및 긴급 항공권 케어 서비스": "签证及紧急机票相关的支援服务",
        "INFORMATION": "INFORMATION",
        "공항 불편 사항 케어": "机场内不便事宜协理服务",
        "공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "在机场发生各种事故时，通过SOS呼叫，告知当前位置及事故现状，即可提供各种应对方案并出动服务人员。",
        "공항 내 컨시어지 서비스 제공": "提供机场内礼宾服务",
        "인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.": "访问仁川机场第一航站楼、仁川机场第二航站楼内的旅游中心，工作人员会协助解决在机场内发生的各种事故。",
        "트래블센터": "旅游中心",
        "약도": "请参考礼宾服务台示意图。",
        "확인 부탁드립니다.": "",
        "공항 긴급 서비스 제공": "提供机场紧急服务",
        "긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공": "紧急机票：错过航班时间时，协助预订可替代机票，并提供退票退款支援服务。",
        "긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공": "紧急签证：提供相关部门确认后的签发流程跟踪支援服务。",
        "긴급 통·번역 인력 수배 서비스": "专业翻译人员紧急支援服务",
        "회의시설 및 미팅 장소 예약 지원 서비스": "会议设施及小规模洽谈场地的咨询及预约服务",
        "KTX, 항공권 등 예약 지원 서비스": "KTX、机票等咨询及预订服务",
        "통·번역 인력 수배 서비스": "翻译人员支援服务",
        "동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.": "提供同声传译、交替传译、随行口译、电话口译等口译服务、各领域专业笔译预约服务、口译设备租赁咨询及预约支援服务。",
        "회의시설 및 미팅 장소 예약 지원": "会议设施及小规模洽谈场地预约支援服务",
        "회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스": "会议及小规模洽谈场地预约：咨询并预订位于商业、交通主要据点的洽谈场所。",
        "연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.": "宴会场地预约：根据顾客的使用目的、所需规模等条件，协助咨询并预订符合要求的空间场地。",
        "KTX · 항공권 등 예약 지원": "KTX、机票等的预订服务",
        "KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.": "提供KTX线上/线下预订及出票服务、韩国国内/国际航线机票预订及出票服务。",
        "교통사고, 안전사고 등 맞춤형 케어 서비스": "针对交通事故、安全事故等采取的支援服务",
        "의료통역사 수배, 의료비 해결방안 모색,": "提供医疗翻译、医疗费用解决方案、",
        "여행자보험 & 의료지원 등 케어": "旅行者保险以及医疗支援等服务",
        "교통사고 시 이용 안내": "发生交通事故时",
        "여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.": "旅游途中发生交通事故时，通过SOS呼叫，告知当前位置及事故现状，即可提供医疗支援服务。",
        "안전사고 시 이용 안내": "发生安全事故时",
        "여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "旅游途中发生各种安全事故时，通过SOS呼叫，告知当前位置及事故现状，即可提供各种应对方案并出动服务人员。",
        "맞춤형 케어 이용 안내": "定制式服务使用指南",
        "안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.": "旅游途中发生安全事故时，协助向相关机构报案、准备各种所需文件、安排医疗翻译机构、安排医疗旅游国际护工等提供医疗服务。",
        "컨시어지 데스크 무료이용": "可免费使用礼宾服务台",
        "공항 픽업 및 의전 서비스 할인": "机场接送及礼宾服务优惠",
        "교통 예약 및 발권": "交通预订及出票",
        "멤버십 특별혜택 서비스": "会员特别优惠服务",
        "인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용": "可免费使用仁川机场第一航站楼&第二航站楼礼宾服务台",
        "공항 픽업/샌딩 서비스 20% 할인": "机场接机/送机服务优惠20%",
        "공항 의전 및 영접 서비스 30% 할인": "机场礼宾及迎接服务优惠30%",
        "수하물 딜리버리 서비스 20% 할인": "行李配送服务优惠20%",
        "대중교통 예약 및 발권 등 탑승 지원 서비스": "大众交通预订及出票等乘车支援服务",
        "항공, KTX, 고속버스 등 교통 예약 및 발권": "机票、KTX、高速巴士等交通预订及出票",
        "상해 및 질병": "伤害及疾病",
        "국내여행 중 배상책임": "在韩旅游途中赔偿责任",
        "국내여행 중 휴대품 손해": "在韩旅游途中携带物品损毁",
        "여행자 안심 보험 서비스": "旅行者安心保险服务",
        "국내여행중 상해사망": "伤害死亡（限在韩旅游）",
        "국내여행중 상해후유장해": "伤害后遗障碍（限在韩旅游）",
        "1억원": "1亿韩元",
        "15세 미만 제외": "未满15岁除外",
        "국내여행중 질병사망/ 80%고도후유장해": "疾病死亡/80%高度后遗障碍（限在韩旅游）",
        "2천만원": "2千万韩元",
        "15세 미만/ 80세이상 제외": "未满15岁及80岁以上均除外",
        "국내여행중 일괄배상": "赔偿责任（限在韩旅游）",
        "5백만원": "5百万韩元",
        "국내여행": "在韩旅游",
        "국내여행중 휴대품손해": "携带物品损毁（限在韩旅游）",
        "20만원": "20万韩元",
        "분실제외": "遗失除外",
        "국내여행 상해급여_의료실비보장": "在韩旅游伤害补助_医疗实际费用保障",
        "1천만원": "1千万韩元",
        "통원 1회당 10만원 한도": "每次就诊以10万韩元为限",
        "국내여행 상해비급여_의료실비보장": "在韩旅游伤害非补助_医疗实际费用保障",
        "여행 중 긴급사항 발생시 제공되는": "旅游途中发生紧急事项时，",
        "다양한 트래블 컨시어지 서비스": "提供各种旅游礼宾服务",
        "지갑, 여권 등 분실 시 지원 케어 서비스": "遗失钱包、护照等重要证件时，提供支援服务",
        "여행 도중 긴급사항 발생 시 케어 서비스": "旅游途中发生紧急事项时，提供跟踪管理服务",
        "교통편 예약 발권 및 차량 수배 서비스": "提供订票出票服务及车辆安排服务",
        "긴급 숙박 예약 지원 서비스": "提供紧急住宿咨询及预订服务",
        "지갑, 여권 등 분실 시 케어 서비스": "遗失钱包、护照等证件时",
        "여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)": "协助顾客办理申请临时护照时所需各类文件（失窃证明、警察确认书等），确认遗失地点，联系遗失物品中心（必要时，安排翻译人员陪同前往警察局）。",
        "보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)": "保险服务 - 韩国境内旅游途中携带物品损毁(丢失除外）：20万韩元",
        "긴급상황 발생 시 케어서비스": "发生紧急状况时",
        "긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "旅游途中发生紧急状况时，通过SOS呼叫，告知当前位置及事故现状，即可提供各种应对方案并出动服务人员。",
        "보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)": "保险服务 - 统一赔偿（韩国境内旅游）：500万韩元 (导致他人身体残疾及财物损失的相关赔偿)",
        "교통편 예약 발권 / 차량 수배 서비스": "交通订票出票/车辆安排服务",
        "대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.": "提供大众交通（巴士/KTX/出租车等）预订及出票服务。错过巴士及KTX时间时，协助预订可替代车票及出票，并提供退票退款支援服务。按照顾客需求提供车辆安排服务及预订支援服务。",
        "가입시 연령제한이 있나요?": "注册服务有年龄限制吗?",
        "연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.": "没有年龄限制。但未满15岁、80岁以上的，保险保障内容可能会有所不同。",
        "가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?": "最晚注册日期应截至服务起始前的何时？",
        "업무일 기준, 개시일 24시간 전까지입니다.": "以工作日为准，截至起始日24小时之前。",
        "가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?": "只要注册成功，均可免费使用Easy Travel Care服务吗？",
        "이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.": "注册Easy Travel Care,只可享受相应服务范畴内的优惠。 实际发生的额外费用由顾客自行承担。<br /><br />例如: 使用紧急机票协助服务时，除预约支援外的购票费用作为额外费用应由顾客承担。",
        "이지트래블케어 가입시 달러로만 결제하나요?": "注册Easy Travel Care时只能用美元结算吗?",
        "달러로만 결제 가능합니다.": "只能用美元结算。",
        "카드 결제시 오류가 나면 어떻게 하나요?": "用卡结算时显示结算失败怎么办?",
        "고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.": "请与客服中心（1644-1493）联系，会帮您转为现金结算。",
        "서비스에 가입했다가 다시 취소할 수 있나요?": "注册后可以取消吗？",
        "서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.": "以服务起始日前的工作日为准，24小时之前便可取消。",
        "상해발생 지역이 해외여도 가능한가요?": "伤害发生地区在海外也可以吗?",
        "한국 국내 상황에만 적용됩니다.": "只适用于韩国境内的情况。",
        "특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?": "在服务期满后还能继续享受特别优惠吗？",
        "특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.": "特别优惠仅限于服务使用期间。",
        "멤버십 증서는 어디서 확인하나요?": "在哪里确认会员资格证？",
        "서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.": "服务使用期间可在我的主页上确认并打印。",
        "컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?": "使用礼宾服务台需要出示何种凭证？",
        "멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.": "下载会员证书后提交到服务台即可。",
        "이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.": "可以确认对Easy Travel Care的疑问内容。",
        "상담원 연결이 필요해요!": "需要连接咨询员",
        "ChatBot": "ChatBot",
        "서비스 이용안내": "服务使用介绍",
        "약관동의": "同意条款",
        "이용안내": "使用指南",
        "수십 분 내에 응답합니다.": "几十分钟之内答复。",
        "담당매니저연결중입니다": "正在连接负责人。",
        "빠른시간내에연락드리겠습니다": "我们会尽快联系您。",
        "WELCOME!": "WELCOME!",
        "How can I Help today?": "How can I Help today?",
        "New Chat": "New Chat",
        "운영시간: 00:00 ~ 24:00": "运营时间：00:00~24:00",
        "정말 삭제하시겠습니까?": "确定要删除吗？",
        "확인": "确认",
        "이제 이지트래블케어 서비스를 이용할 수 있습니다.": "现在可以使用Easy Travel Care服务。",
        "맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!": "通过定制型安心保险、礼宾服务、手机SOS联络服务享受安心旅游吧！",
        "우측 하단의 채팅 아이콘": "通过右下角的聊天图标，",
        "을 통해 온라인 채팅 상담이 가능합니다.": "可以进行在线联络咨询。",
        "서비스 이용 권장사양": "使用服务推荐规格",
        "HTML5 지원 브라우저,": "Easy Travel Care所提供的联络服务优于",
        "Chrome 14 버전 이상,": "HTML5 支持浏览器",
        "Internet Explorer 10 버전 이상,": "Chrome 14 版本以上",
        "Safari 6 버전 이상,": "Internet Explorer 10 版本以上",
        "Opera 12.10 버전 이상,": "Safari 6 版本以上",
        "Firefox 11 버전 이상,": "Opera 12.10 版本以上",
        "안드로이드 브라우저 4.4 이상": "Firefox 11 版本以上",
        "이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.": "Android浏览器 4.4 以上",
        "위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.": "*若使用上述版本以下设备，有可能无法正常显示画面。",
        "약관에 동의해주시기 바랍니다.": "希望您能同意条款。",
        "서비스 약관 동의": "服务条款同意书",
        "이지트래블케어 서비스에 가입해주셔서 감사합니다.": "感谢您使用 Easy Travel Care 服务。",
        "본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.": "本过程是使用Easy Travel Care 联络服务 (SOS Call Service) 的最后申请阶段。",
        "이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.": "同意 Easy Travel Care 条款及现代海上火灾保险条款后，点击确认按钮即可开始享受 Easy Travel Care 服务。",
        "약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.": "不同意条款的会员无法享受聊天服务。",
        "지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.": "即使现在不同意，随时都能通过主页右下角的SOS Call图标进入该画面。",
        "이지트래블케어 이용 약관에 동의합니다.": "同意Easy Travel Care服务使用条款",
        "이지트래블케어 이용 약관": "Easy Travel Care服务使用条款",
        "아래의 약관에 모두 동의합니다.": "同意以下所有服务条款",
        "현대해상 화재보험 약관": "现代海上火灾保险条款(必填)",
        "현대해상 화재보험 약관에 동의합니다.": "同意现代海上火灾保险条款(必填)",
        "게시글을 삭제하시겠습니까?": "要删除帖子吗?",
        "삭제하신 후에는 복구가 되지 않습니다.": "删除后无法恢复",
        "삭제": "删除",
        "삭제 되었습니다.": "已删除",
        "제목을 입력해주세요": "请输入题目",
        "문의유형": "咨询分类",
        "작성자": "填写人",
        "제목": "题目",
        "문의내용": "咨询内容",
        "문의할 내용을 입력해 주세요.": "请输入咨询内容",
        "답변완료": "回复完毕",
        "수정": "修改",
        "목록": "列表",
        "답변대기": "待回复",
        "궁금한 내용을 작성하시면 댓글로 답변해드립니다.": "上传疑问内容，我们会留言答复。",
        "광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.": "广告、商业性质的文字会被管理者修改或删除。",
        "작성하기": "填写",
        "카테고리": "分类",
        "작성일": "填写日期",
        "상태": "状态",
        "비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.": "密码设置包括英文、数字、特殊符号，总长度6～16位",
        "비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.": "密码和密码确认栏不一致。 请再确认一次。",
        "이메일을 형식에 맞게 작성해주세요.": "请按照正确格式填写电子邮箱地址。",
        "사용언어": "语言",
        "하이픈(-)을 포함해서 작성해주세요.": "填写时包含“-”",
        "휴대폰 번호": "手机号码",
        "이메일": "邮箱",
        "새 비밀번호": "新密码",
        "새 비밀번호 확인": "确认新密码",
        "영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.": "填写包括英文、数字、特殊符号，总长度6～16位",
        "생년월일": "出生日期",
        "여권번호": "护照号码",
        "외국인등록번호": "外国人登录号码",
        "이름": "姓名",
        "성별": "性别",
        "국적": "国籍",
        "아이디": "用户名",
        "사용가능": "可使用",
        "결제취소": "取消结算",
        "서비스만료": "服务过期",
        "나의 구매 내역을 확인할 수 있습니다.": "可确认我的购买清单",
        "고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)": "这是您的Easy Travel Care服务购买明细。（购买明细按照最近时间顺序显示。）",
        "컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.": "在柜台处提供注册证书，便可将其作为票券使用。",
        "결제일": "结算日期",
        "유효기간": "有效期",
        "금액": "金额",
        "결제수단": "结算方式",
        "가입증서": "注册证书",
        "가입증서 보기": "查看注册证书",
        "탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.": "用户名注销后无法恢复,且不能用同一用户名或昵称重新注册。",
        "서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.": "为使用服务而填写的信息将被删除，已删除的数据无法恢复。",
        "단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.": "但,留言等已上传的帖子不会被删除。",
        "유의사항을 모두 확인하였으며, 이에 동의합니다.": "注意事项已全部确认并同意。",
        "비밀번호": "密码",
        "비밀번호를 입력하세요.": "请输入密码",
        "탈퇴사유": "注销理由",
        "회원탈퇴 후 재가입": "注销会员后重新注册",
        "서비스를 더 이상 이용하지 않음": "不再使用服务",
        "이용이 불편하고 장애가 많음": "使用不便且故障较多",
        "콘텐츠의 유용성 부족": "内容实用性欠缺",
        "고객센터 및 서비스 불만": "对客服中心及其服务不满意",
        "기타": "其他",
        "기타의견": "其他意见",
        "탈퇴하기": "注销",
        "아이디가 존재하지 않습니다.": "用户名不存在",
        "아이디 찾기": "找用户名",
        "비밀번호 찾기": "找密码",
        "여권번호 또는 외국인등록번호": "护照号码或外国人登录号码",
        "휴대폰번호": "手机号码",
        "B2B회원은 아이디 찾기 및": "B2B会员不支持找用户名及找密码功能。",
        "비밀번호 찾기를 지원하지 않습니다.": "",
        "1644-1493로 문의해주시기 바랍니다.": "请拨打1644-1493（客服中心）。",
        "휴대폰번호( 하이폰 - 포함해서 작성해주세요)": "手机号码（填写时包含“-”）",
        "고객님의 아이디는": "您的用户名为",
        "입니다.": "",
        "계정정보가 일치합니다.": "账号信息一致",
        "계정의 비밀번호를": "您确定要将账号密码初始化为临时密码吗？",
        "임시 비밀번호로 초기화": "",
        "하시겠습니까?": "",
        "예": "是",
        "아니요, 처음으로": "不是， 返回首页",
        "휴대폰으로 임시비밀번호를 보내드렸습니다.": "临时密码已发送至手机",
        "아이디를 입력하세요.": "请输入用户名",
        "아이디/비밀번호 찾기": "找用户名及密码",
        "비밀번호 조건에 맞지 않습니다.": "不符合密码条件",
        "아이디 저장": "保存用户名",
        "개인정보 보호를 위해 본인 기기에서만 사용해주세요.": "为了保护个人信息安全，请仅在本人设备上使用。",
        "이미 가입된 아이디 입니다.": "该用户名已被注册",
        "사용 가능한 아이디 입니다.": "用户名可使用",
        "사용할 수 없는 아이디 입니다.": "用户名不可用",
        "중복체크": "重复核对",
        "비밀번호 확인": "确认密码",
        "회원가입 완료": "会员注册完毕",
        "이지트래블케어 회원가입이 완료되었습니다.": "Easy Travel Care会员注册已完成。",
        "회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.": "您可以开始使用Easy Travel Care网站的所有功能。",
        "로그인 후 이용가능합니다.": "登录即可使用",
        "비밀번호 : 영문/숫자/특수문자, 숫자 6~16자": "密码设置包括英文、数字、特殊符号，总长度6～16位",
        "남성": "男",
        "여성": "女",
        "여권번호(외국인등록번호)": "护照号码（外国人登录号码）",
        "올바른 형식의 이메일 주소가 아닙니다.": "邮箱格式不正确",
        "필수항목 체크": "必须确认项",
        "개인정보처리방침": "个人信息处理方针",
        "동의": "同意",
        "필수": "必填",
        "이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.": "同意使用条款及个人信息处理方针是必须确认项。",
        "동의하고 가입하기": "同意后注册",
        "컨시어지 서비스 구매를": "礼宾服务购买完成",
        "완료했습니다.": "",
        "이지트래블케어와 함께 즐거운 여행되세요.": "祝您与Easy Travel Care共度舒心惬意之旅",
        "홈으로 가기": "返回主页",
        "구매정보 확인": "确认购买信息",
        "정보입력": "输入信息",
        "결제하기": "结算",
        "구매완료": "购买完毕",
        "서비스 제공 시간 안내": "服务提供时间",
        "서비스 개시일 24시간(업무일 기준) 전까지 구매 가능": "购买截至服务起始日24小时（以工作日为准）之前",
        "서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지": "服务提供期间：起始日00点至终止日23:59",
        "31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)": "使用服务日期超过31天的，请另行咨询（1644-1493）。",
        "서비스 이용 기간 설정": "服务使用期间设定",
        "가입기간": "使用期间",
        "일": "天",
        "이용금액": "使用金额",
        "시작일": "起始日",
        "종료일": "终止日",
        "아래의 약관을 모두 동의합니다.": "同意以下所有条款",
        "서비스 이용료": "服务使用费",
        "사용기간": "使用期间",
        "서비스 안내": "服务指南",
        "컨시어지 및 채팅 서비스": "礼宾服务及联络咨询服务",
        "원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.": "设定所需使用期间后购买使用券,即可根据条款使用Easy Travel Care提供的礼宾服务和实时聊天咨询服务等。",
        '상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.': "详情请参考《Easy Travel Care使用条款》。",
        "서비스 이용 권장 사항": "服务使用推荐规格",
        "이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.": "Easy Travel Care所提供的联络咨询服务是HTML5支持浏览器， 优于谷歌Chrome。",
        "이용권 구매 및 환불규정": "购买使用券及退款规定",
        "모든 이용권은 부가세(VAT) 포함 가격입니다.": "所有使用券均包含增值税（VAT）。",
        "출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.": "出动服务人员所产生的费用，遵循各礼宾服务台的收费政策。",
        "서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.": "服务开始后，无法申请取消相应使用券结算。",
        "이용권 사용 시작 전에는 100% 환불이 가능합니다.": "使用券使用起始日24小时（以工作日为准）之前可100%退款。",
        "서비스 결제 정보 확인": "确认服务结算信息",
        "서비스명": "服务名称",
        "EasyTraverCare": "Easy Travel Care",
        "서비스 기간": "服务期间",
        "이용기간에 대한 서비스 총 결제 금액": "使用 服务的总费用为",
        "위의 금액을 결제하시겠습니까?": "确定要结算上述金额吗？",
        "사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.": "为确保用户的金融交易安全，请在规定时间内完成结算。",
        "이전화면": "前一页",
        "제1조(목적)": "第一条(目的）",
        '이 약관은 회사가 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠"라고 한다) 및 제반서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.': "",
        "제2조(정의)": "第二条(定义）",
        '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.': "",
        '2. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.': "",
        '3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.': "",
        "이용약관": "使用条款",
        "이메일무단수집거부": "拒绝擅自收集电子邮件",
        "서비스정책": "服务政策",
        "서비스 구매 엑셀 업로드": "上传服务购买Ecxel",
        "파일첨부": "附件",
        "You can upload the list only if you agree to the terms and conditions below": "只有同意以下条款，您才能上传名单。",
        "취소": "取消",
        "엑셀 업로드": "上传Ecxel",
        "We agree to the regulations and signing up for Easy Care Service.": "我们同意相关规定并使用Easy Travel Care服务。",
        "내용": "内容",
        "제목+내용": "题目+内容",
        "작성자": "填写人",
        "검색어를 입력해주세요.": "请输入搜索词",
        "전체보기": "查看全部",
        "서비스 이용기간": "服务使用期间",
        "구매 연동": "购买联动",
        "서비스 정보": "服务信息",
        "첨부파일명": "附件名称",
        "승인": "批准",
        "요청": "申请",
        "번호": "号码",
        "회원 관리": "会员管理",
        "로그인 정보를 다시 확인해주세요.": "请确认登录信息是否正确",
        // 2023.05.11 추가된 언어
        "전북쇼핑트래블라운지": "全北购物旅游休息室",
        "위치: 전주시 완산구 충경로 1층": "位置：全州市完山区忠景路25 1楼",
        "무인 카페 라운지": "无人咖啡店·休息室",
        "전북관광 안내 서비스": "全北旅游咨询服务",
        "관광기념품 판매 및 굿즈 판매": "旅游纪念品及周边商品销售",
        "수하물 보관 서비스": "行李寄存服务",
        "소규모 미팅룸 대관": "小规模会议室租赁",
        "이벤트 체험존": "体验活动区",
        "여행 편의 서비스": "旅游便利服务",
        "우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등": "雨伞租借、手机充电、充电宝租借、 Wi-Fi等",
        "일수": "天数",
        "구매자": "购买人",
        "명": "名",
        "대기": "Wating",
        "파일을 등록해주세요.": "请上传文件。",
        "국내카드 결제": "韩国发行卡结算(KRW)",
        "해외카드 결제": "海外发行卡结算(USD)"
    },
    japanese: {
        "code": "jp",
        "언어": "言語",
        "서비스 소개": "サービス紹介",
        "의료 안심케어": "医療安心ケア",
        "공항 안심케어": "空港安心ケア",
        "트래블 안심케어": "トラベル安心ケア",
        "비즈니스 안심케어": "ビジネス安心ケア",
        "여행자 안심케어": "旅行者安心ケア",
        "특별 혜택": "特典",
        "SOS 콜 채팅": "SOSコールチャット",
        "서비스 구매": "サービス購買",
        "자주하는 질문": "良くある質問",
        "문의": "お問合せ",
        "마이페이지": "マイページ",
        "내 회원정보": "会員情報",
        "구매내역": "購入内容",
        "회원탈퇴": "退会",
        "님 반갑습니다.": "～様 ようこそ",
        "정보수정": "情報修正",
        "로그인": "ログイン",
        "회원가입": "会員登録",
        "로그아웃": "ログアウト",
        "메뉴닫기": "メニューを閉じる",
        "고객센터": "顧客センター",
        "상호명 : (주)프리미엄패스 인터내셔널 | 대표 : 김응수 | 대표전화 : 1644-1493 | 팩스 : 02-2063-3542": "商号：(株)プレミアムパスインターナショナル／代表：キムウンス／TEL : 1644-1493 , FAX : 02-2063-3542",
        "통신판매업 신고번호 : 제2008-서울강서-0702호 | 사업자 등록번호 : 109-86-06310 |": "通信販売業申告番号：第2008-江西-0702号／事業者登録番号：109-86-06310 |",
        "사업자 정보확인": "事業者情報確認",
        "소재지 : 서울특별시 강서구 마곡중앙4로 22 (마곡동) 파인스퀘어 A동 501호": "所在地 : ソウル特別市江西区麻谷中央４路２２(麻谷洞)パインスクエアA棟501号",
        "개인정보 처리 책임자 : 임상엽 (care@tothepp.com)": "個人情報処理責任者：イム・サンヨプ (care@tothepp.com)",
        "월-금 09:00-18:00 (점심시간 12:00-13:00), 한국시간 기준": "月～金 09:00-18:00 (お昼休憩 12:00-13:00)",
        "한국시간 기준": "韓国時刻基準",
        "즐겁고 안전한 관광": "楽しく安全な旅行",
        "안심보험에서 안심여행까지!": "安心保険から安心旅行まで！",
        "외국인 관광객 니즈에 맞는": "外国人観光客のニーズに合わせた",
        "맞춤형 안심케어 서비스": "オーダーメイド式の安心ケアサービス",
        "안심보험&컨시어지 서비스": "安心保険＆コンシェルジュサービス",
        "K-Travel 안심케어": "K-Travel安心ケア",
        "편안하고": "楽に安全に",
        "안전하고": "上品で価値ある観光",
        "품위있는 가치관광": "",
        "안전하고 즐거운 한국여행": "安全で楽しい韓国旅行",
        "이지트래블케어 안심보험과 함께하세요!": "イージートラベルケアと共に！",
        "이전": "以前",
        "다음": "次",
        "맞춤형 안심보험": "オーダーメイド安心保険",
        "컨시어지 서비스": "コンシェルジュサービス",
        "여행 중 발생할 수 있는 안전사고 등 해결방안 모색": "旅行中に発生しうる事故などの解決方法を模索",
        "여행 안심보험은 물론 의료지원 및 케어 서비스": "旅行安心保険はもちろん医療サポート及びケアサービス",
        "각종 도난사고 보험케어 및 트래블관련 케어 서비스": "各種盗難事故の保険ケア及びトラベル関連のケアサービス",
        "대한민국 안심관광을 즐기세요": "韓国の観光を安心して楽しみましょう",
        "급증하고 있는 FIT 및 비즈니스 관광객에 대한 여행 안심보험은 물론 특화 안심케어 서비스 제공으로 편안하고 안전한 여행": "急増しているFIT及びビジネス観光客に対する旅行安心保険はもちろん、特化安心ケアサービス提供で心地よく安全な旅行",
        "체계적인 서비스 운영 시스템": "体系的なサービス運営システム",
        "컨시어지 서비스/외국어 전문 상담원에 의한 콜센터 운영": "コンシェルジュサービス／外国語専門相談員によるコールセンター運営",
        "인천공항‒트래블센터-콜센터와 연계된 체계적인 서비스": "仁川国際空港‒トラベルセンター‒コールセンターと連携している体系的なサービス",
        "국내 최고의 전문의전요원에 의한 직접적인 서비스": "国内最高の専門儀典要員による直接的なサービス",
        "특별혜택 및": "特典および",
        "모바일 컨시어지 운영": "モバイルコンシェルジュサービス運営",
        "가입자를 위한 20여가지 특별혜택": "加入者のための２０余りの特典",
        "오프라인과 모바일 채팅 컨시어지 연계 서비스": "オフラインとモバイルチャットコンシェルジュの連携サービス",
        "대한민국을 여행할 때는": "韓国を旅行する際には",
        "언제나 이지트래블케어": "いつでもイージートラベルケア",
        "안심보험으로 즐겁고 안전한 관광을": "安心保険で楽しく安全な観光が",
        "하실 수 있습니다.": "できます",
        "여행 중 발생할 수 있는": "旅行中に発生しうる",
        "여행 중 발생할 수 있는 A": "旅行中に発生しうる",
        "여러 사고에 준비되어 있는": "様々な事故に備えている",
        "의료 지원 서비스": "医療支援サービスです",
        "Details": "Details",
        "More": "More",
        "국제공항이라는 특별한": "国際空港という特別な",
        "공간에서의 고객을 위한": "空間での顧客のための",
        "안심 케어 서비스": "安心ケアサービス",
        "여행 중 긴급사항 발생시": "旅行中緊急事項が発生時",
        "제공되는 다양한": "提供される様々な",
        "트래블 컨시어지 서비스": "トラベルコンシェルジュサービス",
        "편안하고 안전한 여행과": "心地よく安全な旅行と",
        "성공적인 비즈니스를 위한": "完璧なビジネスのための",
        "특화 컨시어지 서비스": "特化コンシェルジュサービス",
        "휴대품 손해, 배상책임에서": "携帯品の損害、賠償責任から死亡まで",
        "휴대품 손해, 배상책임에서 A": "携帯品の損害、賠償責任から死亡まで",
        "사망까지": "",
        "20여 가지의": "２０余りの",
        "멤버십 특별혜택 제공": "メンバーシップ特典提供",
        "Easy Travel Care Prices": "Easy Travel Care Prices",
        "Shorts": "Shorts",
        "Middle": "Middle",
        "Long": "Long",
        "Days": "Days",
        "Normally": "Normally",
        "별도 문의": "別途問い合わせ",
        "31일 이상의 서비스 이용을 원하시면": "３１日以上のサービス利用をご希望される場合",
        "별도 문의하여 주시기 바랍니다.": "別途お問い合わせください",
        "여행 중 곤란한 상황이": "旅行中混乱した状況が",
        "발생하셨나요?": "発生しましたか。",
        "SOS 콜 채팅 바로가기": "SOSコールチャットにすぐ",
        "지역별 컨시어지 데스크": "地域別コンシェルジュデスク",
        "대한민국 관광 주요거점에": "韓国観光の主要拠点に",
        "위치한 각 트래블센터에서": "位置する各トラベルセンターで",
        "맞춤형 컨시어지 서비스를 제공합니다.": "オーダーメイド式のコンシェルジュサービスを提供します。",
        "이지트래블 케어 서비스는": "イージートラベルケアサービスは",
        "여행 중 각 컨시어지데스크에": "旅行中各コンシェルジュデスクに",
        "방문하셔서 문의주시면 안심관광을": "訪問しお問い合わせ下さったら安心観光の",
        "위한 도움을 드리고 있습니다.": "ためのお手伝いを致します。",
        "인천공항 T1": "仁川国際空港 T1",
        "인천공항 T2": "仁川国際空港 T2",
        "서울역 라운지": "ソウル駅のラウンジ",
        "마포관광 정보센터": "麻浦観光情報センター",
        "송도 컨벤시아": "松島コンベンシア",
        "인천국제공항 제1터미널": "仁川国際空港第１ターミナル",
        "컨시어지데스크": "コンシェルジュデスク",
        "부스명: MICE Concierge Desk(40번 데스크)": "ブース名： MICE Concierge Desk(40番デスク)",
        "위치: 제1터미널 8번과 9번 게이트 사이": "位置：第１ターミナル8番と9番ゲートの間",
        "인천국제공항 제2터미널": "仁川国際空港第2ターミナル",
        "부스명: MICE Concierge Desk": "ブース名： MICE Concierge Desk",
        "위치: 제2터미널 4번게이트": "位置：第２ターミナル4番ゲート",
        "프리미엄라운지": "プレミアムラウンジ",
        "서울역점": "ソウル駅店",
        "위치: 서울역 도심공항터미널 지하 2층": "位置：ソウル駅都心空港ターミナル地下２階",
        "위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리": "位置：地下鉄２号線弘大入口駅8番出口から徒歩3分",
        "송도컨벤시아점": "松島コンベンシア",
        "위치: 인천 송도컨벤시아 내(Gate1)": "位置：仁川松島コンベンシア内(Gate1)",
        "이지트래블케어 서비스 외에 컨시어지 서비스": "イージートラベルケアサービス以外にコンシェルジュサービス",
        "이용 시 별도의 비용이 발생됩니다.": "利用時、別途費用が発生します。",
        "비용은 각 컨시어지데스크 요금정책에 따릅니다.": "費用は各コンシェルジュデスクの料金設定によります。",
        "컨시어지 데스크 이용방법": "コンシェルジュデスク利用方法",
        "컨시어지 데스크 방문": "コンシェルジュデスクを訪問",
        "이지트래블케어 바우처 제시": "イージートラベルケアバウチャーを提示",
        "바우처의 내용 확인": "バウチャーの内容を確認",
        "서비스 시작": "サービス開始",
        "Terminal": "Terminal",
        "카페": "カフェ",
        "사무용품&스낵": "事務用品＆スナック",
        "여행 편의 서비스": "旅行便宜サービス",
        "비즈니스 업무": "ビジネス業務",
        "소규모 회의실 대관": "小規模の会議室を貸与",
        "국제공항이라는 특별한 공간에서의": "国際空港という特別な空間での",
        "고객을 위한 안심 케어 서비스": "顧客のための安心ケアサービス",
        "노약자 등 공항에서 지원 및 불편사항 케어": "高齢者などを空港でサポート及び不便な事をケア",
        "인천공항에서의 컨시어지 서비스 제공": "仁川国際空港でのコンシェルジュサービス提供",
        "비자 및 긴급 항공권 케어 서비스": "ビザ及び緊急航空券のケアサービス",
        "INFORMATION": "INFORMATION",
        "공항 불편 사항 케어": "空港での不便事項のケア",
        "공항 내 각종 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "空港内各種事故発生時SOSコールを通じて現在地と事故状況をお知らせ下さったら、各種対応及び出頭サービスを提供します。",
        "공항 내 컨시어지 서비스 제공": "空港内コンシェルジュサービス提供",
        "인천국제공항 제1터미널, 인천국제공항 제2터미널 트래블센터 방문시, 공항 내 사고 해결을 도와드립니다.": "仁川国際空港第1ターミナル、仁川国際空港第2ターミナルのトラベルセンター訪問の際、空港内事故の解決をお手伝い致します。",
        "트래블센터": "トラベルセンター",
        "약도": "略図",
        "확인 부탁드립니다.": "ご確認お願い致します。",
        "공항 긴급 서비스 제공": "空港緊急サービス提供",
        "긴급 항공권 : 항공권 놓친 경우 긴급 대체 항공권 재발권 및 환불 절차 케어 서비스 제공": "緊急航空券：飛行機の乗り遅れた場合、緊急代替航空券を再発券及び払い戻しの手続きケアサービス",
        "긴급 비자 : 관계 기관 확인 후 발급 절차 케어 서비스 제공": "緊急ビザ：関係機関確認後、発給手続きケアサービスを提供",
        "긴급 통·번역 인력 수배 서비스": "緊急通訳・翻訳者の手配サービス",
        "회의시설 및 미팅 장소 예약 지원 서비스": "会議施設及びミーティング場所の予約サポートサービス",
        "KTX, 항공권 등 예약 지원 서비스": "KTX,航空券などの予約サポートサービス",
        "통·번역 인력 수배 서비스": "通訳・翻訳者の手配サービス",
        "동시통역, 순차통역, 수행 통역, 전화 통역 등 전문 인력 수배, 분야별 전문 번역 예약 지원, 통역 장비 대여 안내 및 예약 지원 서비스를 제공해 드립니다.": "当時通訳、逐次通訳、遂行通訳、電話通訳など専門人材を手配、分野ごとに専門翻訳予約サポート、通訳装備レンタル及び予約サポートサービスを提供致します。",
        "회의시설 및 미팅 장소 예약 지원": "会議施設及びミーティング場所の予約サポートサービス",
        "회의·미팅장소 예약 : 비즈니스, 교통 주요 거점에 위치한 미팅 장소 안내 및 예약 지원 서비스": "会議・ミーティング場所の予約：ビジネス、交通主要拠点に位置するミーティング場所の案内及び予約サポートサービス",
        "연회 장소 예약 : 사용 목적, 규모에 따른 맞춤형 공간 예약 지원 서비스를 제공해 드립니다.": "宴会場所の予約：使用目的、規模によってオーダーメイド式の空間予約サポートサービスを提供致します。",
        "KTX · 항공권 등 예약 지원": "KTX,航空券などの予約サポート",
        "KTX 온/오프라인 티켓 발권 및 예약 지원 서비스, 국내/국제선 항공권 발권 및 예약 지원 서비스를 제공해 드립니다.": "KTXのオンライン・オフラインでのチケット発券及び予約サポートサービス、国内／国際線航空券の発券及び予約サポートサービスを提供致します。",
        "교통사고, 안전사고 등 맞춤형 케어 서비스": "交通事故、安全事故などのオーダーメイドケアサービス",
        "의료통역사 수배, 의료비 해결방안 모색,": "医療通訳者の手配、医療費解決方法を模索",
        "여행자보험 & 의료지원 등 케어": "旅行者保険＆医療支援などをケア",
        "교통사고 시 이용 안내": "交通事故時の利用案内",
        "여행 중 교통 사고 시 SOS 콜을 통해 현재 위치와 사고현황을 알려주시면 의료 지원 서비스를 제공해 드립니다.": "旅行中交通事故の際、SOSコールを通じて現在地と事故状況をお知らせ下さったら、医療支援サービスを提供致します。",
        "안전사고 시 이용 안내": "安全事故時の利用案内",
        "여행 중 각종 안전 사고 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "旅行中各種事故が発生時SOSコールを通じて現在地と事故状況をお知らせ下さったら、各種対応及び出頭サービスを提供します。",
        "맞춤형 케어 이용 안내": "オーダーメイド式ケアの利用案内",
        "안전사고 발생시 관련 기관 신고 접수, 제반 서류 발급, 의료 통·번역 전문 업체 수배, 의료 관광 국제 간병사 수배 등 의료 케어 서비스를 제공해 드립니다.": "事故発生時関連機関への申告受付、諸般書類発給、医療通訳・翻訳専門業者手配、医療観光国際看護人の手配など医療手配サービスを提供致します。",
        "컨시어지 데스크 무료이용": "コンシェルジュデスク無料利用",
        "공항 픽업 및 의전 서비스 할인": "空港ピックアップサービス及び儀典サービス割引",
        "교통 예약 및 발권": "交通予約及び発券",
        "멤버십 특별혜택 서비스": "メンバーシップ特典サービス",
        "인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용": "仁川空港第１ターミナル＆第２ターミナルのコンシェルジュデスクの無料利用",
        "공항 픽업/샌딩 서비스 20% 할인": "空港ピックアップ／送迎サービス20％割引",
        "공항 의전 및 영접 서비스 30% 할인": "空港儀典及び迎接サービス30％割引",
        "수하물 딜리버리 서비스 20% 할인": "手荷物デリバリーサービス20％割引",
        "대중교통 예약 및 발권 등 탑승 지원 서비스": "大衆交通予約及び発券など搭乗支援サービス",
        "항공, KTX, 고속버스 등 교통 예약 및 발권": "空港、KTX、高速バスなど交通予約及び発券",
        "상해 및 질병": "傷害及び疾病",
        "국내여행 중 배상책임": "国内旅行中の賠償責任",
        "국내여행 중 휴대품 손해": "国内旅行中の携帯品損害",
        "여행자 안심 보험 서비스": "旅行者安心保険サービス",
        "국내여행중 상해사망": "国内旅行中傷害死亡",
        "국내여행중 상해후유장해": "国内旅行中傷害後遺障害",
        "1억원": "１億ウォン",
        "15세 미만 제외": "15歳未満除外",
        "국내여행중 질병사망/ 80%고도후유장해": "国内旅行中の疾病死亡／80％高度後遺障害",
        "2천만원": "２千万ウォン",
        "15세 미만/ 80세이상 제외": "15歳未満／80歳以上 除外",
        "국내여행중 일괄배상": "国内旅行中 一括賠償",
        "5백만원": "５百万ウォン",
        "국내여행": "国内旅行",
        "국내여행중 휴대품손해": "国内旅行中携帯品損害",
        "20만원": "20万ウォン",
        "분실제외": "紛失除外",
        "국내여행 상해급여_의료실비보장": "国内旅行 傷害給与_医療実費保障",
        "1천만원": "1千万ウォン",
        "통원 1회당 10만원 한도": "通院１回につき10万ウォンまで",
        "국내여행 상해비급여_의료실비보장": "国内旅行 傷害非給与_医療実費保障",
        "여행 중 긴급사항 발생시 제공되는": "旅行中緊急事項発生時に提供される",
        "다양한 트래블 컨시어지 서비스": "様々なトラベルコンシェルジュサービス",
        "지갑, 여권 등 분실 시 지원 케어 서비스": "財布、パスポートなど紛失時の支援ケアサービス",
        "여행 도중 긴급사항 발생 시 케어 서비스": "旅行の際発生する緊急事項のケアサービス",
        "교통편 예약 발권 및 차량 수배 서비스": "交通手段の予約発券及び車両手配サービス",
        "긴급 숙박 예약 지원 서비스": "緊急宿泊予約サポートサービス",
        "지갑, 여권 등 분실 시 케어 서비스": "財布、パスポートなど紛失時のケアサービス",
        "여권 재발급에 필요한 제반 서류(도난 증명서, 경찰 확인서 등) 발급 지원 케어 서비스, 도난 분실 장소 확인, 유실물 센터 연결( 필요 시 경찰서 동행 서비스 지원)": "パスポートの再発給に必要な諸般書類（盗難証明証、警察確認証など）の発給サポートケアサービス、盗難紛失場所の確認、遺失物センターへの連絡（必要な際、警察署同行サービス支援）サービスなどを致します。",
        "보험 서비스 - 국내여행 중 휴대품 손해 : 20만원 (도난, 파손 등 사고로 손해발생 시)": "保険サービス－国内旅行中の携帯品損害：20万ウォン",
        "긴급상황 발생 시 케어서비스": "緊急状況発生時のケアサービス",
        "긴급 상황 발생 시 SOS콜을 통해 현재 위치와 사고 현황을 알려주시면 각종 대응 방안 및 출동 서비스를 제공합니다.": "緊急状況発生時にSOSコールを通じて、現在地と事故状況をお知らせ下されば、それに合わせた対応及び出動サービスを提供します。",
        "보험 서비스 - 국내여행 중 배상책임 : 500만원 (타인 신체의 장해 및 재물의 손해에 대한 배상)": "保険サービスー国内旅行中の賠償責任：500万ウォン(他人の身体障害及び財物の損害に対する賠償)",
        "교통편 예약 발권 / 차량 수배 서비스": "交通手段の予約発券／車両手配サービス",
        "대중교통 (버스 / KTX / 택시 ) 발권 및 예약 지원 서비스, 버스나 기차 놓친 경우 긴급 대체 티켓 재 발권 및 환불 절차 케어 서비스, 맞춤 차량 수배 및 예약 지원 서비스를 제공해 드립니다.": "大衆交通(バス／KTX／タクシー)の発券及び予約サポートサービス、バスや列車などに乗り遅れた場合、緊急代替チケットの再発券及び、払い戻しの手続きケアサービス、お客様のニーズに合わせた車両の手配及び予約サポートサービスを提供致します。",
        "가입시 연령제한이 있나요?": "加入時、年齢制限はございますか。",
        "연령 제한은 없습니다만 15세 미만, 80세 이상의 경우 보험 보장 내용이 다를 수 있습니다.": "年齢制限はございませんが、15歳未満、そして80歳以上の場合は保険保障内容が変わることがございます。",
        "가입하려면 서비스 시작 전에 언제까지 가입해야 하나요?": "加入したい場合は、サービス開始前いつまでに加入しなければいけませんか。",
        "업무일 기준, 개시일 24시간 전까지입니다.": "営業日基準で、開始日24時間前までです。",
        "가입만 하면 이지트래블케어 서비스 모두 무료로 이용 가능한가요?": "加入だけで、イージートラベルケアサービスが全て無料で利用可能ですか。",
        "이지트래블케어 가입시 해당 서비스 범주 안의 혜택을 누리실 수 있습니다. 단, 실비가 발생되는 별도 비용 부분은 고객부담으로 확인바랍니다.<br /><br />예: 긴급 항공권 케어 서비스시, 예약 지원 외의 항공권 구매 비용은 실비로 고객 부담입니다.": "イージートラベルケア加入の際、該当サービス範囲内の特典をご利用できます。但し、実費が発生する別途費用の部分はお客様負担になる点、ご確認お願い致します。<br /><br />に緊急航空券ケアサービスの際、予約サポート以外の航空券購入費用は実費でお客様のご負担です。",
        "이지트래블케어 가입시 달러로만 결제하나요?": "イージートラベルケア加入の際、ドルのみの決済ですか。",
        "달러로만 결제 가능합니다.": "ドルのみ決済可能です。",
        "카드 결제시 오류가 나면 어떻게 하나요?": "カード決済時、エラーが発生したらどうしますか。",
        "고객센터(1644-1493)로 연락주시면 현금결제를 도와드리겠습니다.": "顧客センター(1644-1493)に連絡下さったら、現金決済をお手伝い致します。",
        "서비스에 가입했다가 다시 취소할 수 있나요?": "サービスに加入して再度キャンセルできますか。",
        "서비스 개시일 전 업무일 기준 24시간 이전까지는 취소가 가능합니다.": "サービス開始日前、営業日基準で24時間前まではキャンセルが可能です。",
        "상해발생 지역이 해외여도 가능한가요?": "傷害発生地域が海外でも可能ですか。",
        "한국 국내 상황에만 적용됩니다.": "韓国国内の状況のみに適用されます。",
        "특별혜택할인은 서비스 기간이 끝나도 이용할 수 있나요?": "特典割引はサービス期間が終わっても利用できますか。",
        "특별혜택 할인은 서비스 이용 기간에만 한정적으로 이용하실 수 있습니다.": "特典割引はサービス利用期間内のみ限定でご利用できます。",
        "멤버십 증서는 어디서 확인하나요?": "メンバーシップ証書はどこで確認できますか。",
        "서비스 이용 기간동안 마이페이지에서 확인 및 출력이 가능합니다.": "サービス利用期間中、マイページで確認及び出力可能です。",
        "컨시어지 데스크 이용시 바우처는 무엇으로 제시하나요?": "コンシェルジュデスクを利用時、バウチャーは何で提示しますか。",
        "멤버십 증서를 다운 받은 후 데스크에 제출하시면 이용 가능합니다.": "メンバーシップ証書をダウンロードしてデスクに提出すればご利用可能です。",
        "이지트래블케어에 대해 궁금해하는 내용들을 한번에 확인하실 수 있습니다.": "イージートラベルケアに関して気になる内容をすべてご確認できます。",
        "상담원 연결이 필요해요!": "相談員の連絡が必要です！",
        "ChatBot": "ChatBot",
        "서비스 이용안내": "サービス利用案内",
        "약관동의": "約款同意",
        "이용안내": "利用案内",
        "수십 분 내에 응답합니다.": "数十分以内にご回答します。",
        "담당매니저연결중입니다": "担当マネージャーに連絡しています。",
        "빠른시간내에연락드리겠습니다": "速やかに連絡致します。",
        "WELCOME!": "WELCOME!",
        "How can I Help today?": "How can I Help today?",
        "New Chat": "New Chat",
        "운영시간: 00:00 ~ 24:00": "営業時間：00:00～24:00",
        "정말 삭제하시겠습니까?": "本当に削除しますか。",
        "확인": "確認",
        "이제 이지트래블케어 서비스를 이용할 수 있습니다.": "今からイージートラベルケアサービスをご利用できます。",
        "맞춤형 안심보험, 컨시어지 서비스, 모바일 SOS 채팅 서비스를 통해 안심관광을 즐기세요!": "オーダーメイド式安心保険、コンシェルジュサービス、モバイルSOSチャットサービスを通じて観光を安心して楽しみましょう！",
        "우측 하단의 채팅 아이콘": "右下のチャットアイコン",
        "을 통해 온라인 채팅 상담이 가능합니다.": "を通してオンラインチャット相談が可能です。",
        "서비스 이용 권장사양": "サービス利用推奨スペック",
        "HTML5 지원 브라우저,": "HTML5支援ブラウザ",
        "Chrome 14 버전 이상,": "Chrome 14バージョン以上",
        "Internet Explorer 10 버전 이상,": "Internet Explorer 10バージョン以上",
        "Safari 6 버전 이상,": "Safari 6バージョン以上",
        "Opera 12.10 버전 이상,": "Opera 12.10 バージョン以上",
        "Firefox 11 버전 이상,": "Firefox 11 バージョン以上",
        "안드로이드 브라우저 4.4 이상": "アンドロイドブラウザ4.4以上",
        "이지트래블케어가 제공하는 채팅 서비스는 위 버전에 최적화 되어 있습니다.": "イージートラベルケアが提供するチャットサービスは上記のバージョンに最適化されています。",
        "위 버전을 충족하지 않는 디바이스의 경우 화면이 정상적으로 동작하지 않을 수 있습니다.": "上記のバージョンを満たしていないデバイスの場合、画面が正常に動かない場合がございます。",
        "약관에 동의해주시기 바랍니다.": "約款に同意してください。",
        "서비스 약관 동의": "サービス約款同意",
        "이지트래블케어 서비스에 가입해주셔서 감사합니다.": "イージートラベルケアサービスにご加入いただき感謝申し上げます。",
        "본 과정은 이지트래블 채팅서비스 (SOS Call Service)를 이용하기 위한 마지막 단계입니다.": "次の過程はイージートラベルチャットサービス (SOS Call Service)をご利用するための最後のステップです。",
        "이지트래블케어 약관과 현대해상 화재보험 약관에 동의한 후 [다음] 버튼을 누르시면 이지트래블케어 채팅 서비스를 시작할 수 있습니다.": "イージートラベルケア約款と現代海上火災保険約款に同意した後、〔次〕ボタンを押したらイージートラベルケアチャットサービスがスタートできます。",
        "약관에 동의하지 않은 회원은 채팅 서비스를 제공받을 수 없습니다.": "約款に同意していない会員はチャットサービスの提供ができません。",
        "지금 동의 처리를 하지 않아도, 언제든지 홈페이지 첫 화면의 우측 하단의 SOS Call 아이콘을 통해 다시 이 화면으로 올 수 있습니다.": "今同意手続きをしなくても、いつでもホームページのメイン画面の右下のSOS Callアイコンを通じて再度この画面に戻れます。",
        "이지트래블케어 이용 약관에 동의합니다.": "イージートラベルケアの利用約款に同意します。",
        "이지트래블케어 이용 약관": "イージートラベルケア約款",
        "아래의 약관에 모두 동의합니다.": "下記の約款に全て同意します。",
        "현대해상 화재보험 약관": "現代海上火災保険約款",
        "현대해상 화재보험 약관에 동의합니다.": "現代海上火災保険約款に同意します。",
        "게시글을 삭제하시겠습니까?": "投稿を削除しますか。",
        "삭제하신 후에는 복구가 되지 않습니다.": "削除した後は元に戻せません。",
        "삭제": "削除",
        "삭제 되었습니다.": "削除しました。",
        "제목을 입력해주세요": "タイトルを入力してください。",
        "문의유형": "お問い合わせフォーム",
        "작성자": "作成者",
        "제목": "タイトルを入力してください。",
        "문의내용": "お問い合わせ内容",
        "문의할 내용을 입력해 주세요.": "問い合わせする内容を入力してください。",
        "답변완료": "回答完了",
        "수정": "修正",
        "목록": "項目",
        "답변대기": "回答待機",
        "궁금한 내용을 작성하시면 댓글로 답변해드립니다.": "気になる内容を作成していただいたらコメントで回答致します。",
        "광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.": "広告、商業的な投稿は管理者によって任意で修正または削除されることがあります。",
        "작성하기": "作成する",
        "카테고리": "カテゴリー",
        "작성일": "作成日",
        "상태": "状態",
        "비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.": "パスワードは英文、数字、特殊文字を全て含めて最小6文字、最大16文字までです。",
        "비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.": "パスワードとバスワード確認欄が一致しません。再度ご確認ください。",
        "이메일을 형식에 맞게 작성해주세요.": "Eメールを形式に合わせて作成してください。",
        "사용언어": "使用言語",
        "하이픈(-)을 포함해서 작성해주세요.": "ハイフン(-)を含めて作成してください",
        "휴대폰 번호": "携帯番号",
        "이메일": "Eメール",
        "새 비밀번호": "新しいパスワード",
        "새 비밀번호 확인": "新しいパスワード確認",
        "영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.": "英文、数字、特殊文字を全て利用して６～16文字で作成してください。",
        "생년월일": "生年月日",
        "여권번호": "パスポート番号",
        "외국인등록번호": "外国人登録番号",
        "이름": "名前",
        "성별": "性別",
        "국적": "国籍",
        "아이디": "ID",
        "사용가능": "使用可能",
        "결제취소": "決済取り消し",
        "서비스만료": "サービス満了",
        "나의 구매 내역을 확인할 수 있습니다.": "購入内容が確認できます。",
        "고객님의 이지트래블케어 서비스 구매 내역입니다. (구매내역은 최신순으로 보여집니다.)": "お客様のイージートラベルケアサービス購入内容です。(購入内容は最新順で見られます。)",
        "컨시어지 데스크 이용 시 가입증서를 제시하시면 바우처로 사용하실 수 있습니다.": "コンシェルジュデスク利用時、加入証書を提示するとバウチャーで使用できます。",
        "결제일": "決済日",
        "유효기간": "有効期間",
        "금액": "金額",
        "결제수단": "決済手段",
        "가입증서": "加入証書",
        "가입증서 보기": "加入証書を見る",
        "탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.": "脱退したIDは再度ご利用できません。その後同じIDやニックネームで再加入もできません。",
        "서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.": "サービス利用のために登録した情報は削除され、削除されたデータは元に戻せません。",
        "단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.": "但し、コメントを含めて登録された投稿は削除されません。",
        "유의사항을 모두 확인하였으며, 이에 동의합니다.": "注意事項を全て確認し、これに同意します。",
        "비밀번호": "パスワード",
        "비밀번호를 입력하세요.": "パスワードを入力してください。",
        "탈퇴사유": "脱退理由",
        "회원탈퇴 후 재가입": "会員脱退後、再加入",
        "서비스를 더 이상 이용하지 않음": "サービスをこれ以上利用しません",
        "이용이 불편하고 장애가 많음": "利用が不便で面倒",
        "콘텐츠의 유용성 부족": "コンテンツの有用性不足",
        "고객센터 및 서비스 불만": "顧客センター及びサービスの不満",
        "기타": "その他",
        "기타의견": "その他の意見",
        "탈퇴하기": "脱退する",
        "아이디가 존재하지 않습니다.": "IDが存在しません。",
        "아이디 찾기": "ID検索",
        "비밀번호 찾기": "パスワード検索",
        "여권번호 또는 외국인등록번호": "パスポートまたは外国人登録番号",
        "휴대폰번호": "携帯番号",
        "B2B회원은 아이디 찾기 및": "B2B会員はID検索及び",
        "비밀번호 찾기를 지원하지 않습니다.": "パスワード検索をサポートしません。",
        "1644-1493로 문의해주시기 바랍니다.": "1644-1493へお問い合わせください。",
        "휴대폰번호( 하이폰 - 포함해서 작성해주세요)": "携帯番号(ハイフン -を含めて作成してください)",
        "고객님의 아이디는": "お客様のIDは",
        "입니다.": "です。",
        "계정정보가 일치합니다.": "アカウントが一致しません。",
        "계정의 비밀번호를": "アカウントのパスワードを",
        "임시 비밀번호로 초기화": "臨時パスワードに初期化",
        "하시겠습니까?": "しますか。",
        "예": "はい",
        "아니요, 처음으로": "いいえ。初めに",
        "휴대폰으로 임시비밀번호를 보내드렸습니다.": "携帯に臨時パスワードを送りました。",
        "아이디를 입력하세요.": "IDを入力してください。",
        "아이디/비밀번호 찾기": "ID／パスワード 検索",
        "비밀번호 조건에 맞지 않습니다.": "パスワードの条件に合わないです。",
        "아이디 저장": "ID保存",
        "개인정보 보호를 위해 본인 기기에서만 사용해주세요.": "個人情報保護のためご本人のデバイスをご利用くだいさい。",
        "이미 가입된 아이디 입니다.": "既に加入されているIDです。",
        "사용 가능한 아이디 입니다.": "使用可能なIDです。",
        "사용할 수 없는 아이디 입니다.": "使用できないIDです。",
        "중복체크": "ダブルチェック",
        "비밀번호 확인": "パスワード確認",
        "회원가입 완료": "会員加入完了",
        "이지트래블케어 회원가입이 완료되었습니다.": "イージートラベルケアの会員加入が完了しました。",
        "회원님은 이지트래블케어 사이트의 모든 기능을 시작하실 수 있습니다.": "お客様はイージートラベルケアサイトの全ての機能をご利用できます。",
        "로그인 후 이용가능합니다.": "ログイン後、ご利用可能です。",
        "비밀번호 : 영문/숫자/특수문자, 숫자 6~16자": "パスワード：英文／数字／特殊文字、６～16文字まで",
        "남성": "男性",
        "여성": "女性",
        "여권번호(외국인등록번호)": "パスポート番号(外国人登録番号)",
        "올바른 형식의 이메일 주소가 아닙니다.": "正しいフォームのEメールアドレスではございません。",
        "필수항목 체크": "必須項目チェック",
        "개인정보처리방침": "个人信息处理方针",
        "동의": "同意",
        "필수": "必須",
        "이용약관 및 개인정보처리방침 동의는 필수체크 항목입니다.": "利用約款及び個人情報処理方針の同意は必須チェック項目です。",
        "동의하고 가입하기": "同意して加入する",
        "컨시어지 서비스 구매를": "コンシェルジュサービス購入を",
        "완료했습니다.": "完了しました。",
        "이지트래블케어와 함께 즐거운 여행되세요.": "イージートラベルケアと共に楽しい旅行を",
        "홈으로 가기": "ホームに戻る",
        "구매정보 확인": "購入情報確認",
        "정보입력": "情報入力",
        "결제하기": "決済する",
        "구매완료": "購入完了",
        "서비스 제공 시간 안내": "サービス提供時間案内",
        "서비스 개시일 24시간(업무일 기준) 전까지 구매 가능": "サービス開始日24時間(営業日基準)前まで購入可能",
        "서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지": "サービス提供期間：開始日0時から終了日23：59まで",
        "31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)": "31日以上のサービスをご希望される場合、別途お問い合わせください。(1644-1493)",
        "서비스 이용 기간 설정": "サービス利用期間設定",
        "가입기간": "加入期間",
        "일": "日",
        "이용금액": "利用金額",
        "시작일": "開始日",
        "종료일": "終了日",
        "아래의 약관을 모두 동의합니다.": "下記の約款に全て同意します。",
        "서비스 이용료": "サービス利用料",
        "사용기간": "使用期間",
        "서비스 안내": "サービス案内",
        "컨시어지 및 채팅 서비스": "コンシェルジュ及びチャットサービス",
        "원하는 기간을 설정하여 이용권을 구매하면 약관에 따라 이지트래블케어가 제공하는 컨시어지 서비스와 실시간 채팅 서비스 등을 이용할 수 있습니다.": "ご希望の期間を設定し、利用券を購入したら約款に従ってイージートラベルケアが提供するコンシェルジュサービスとリアルタイムのチャットサービスなどがご利用できます。",
        '상세 내용은 "이지트래블케어 이용약관"을 참조해주시기 바랍니다.': '詳細内容は”イージートラベルケア利用約款”をご参考ください。',
        "서비스 이용 권장 사항": "サービス利用推奨スベック",
        "이지트래블케어가 제공하는 채팅서비스는 HTML5 지원 브라우저로 구글 크롬에 최적화 되어 있습니다.": "イージートラベルケアが提供するチャットサービスは、HTML5対応のブラウザに対応しており、特にGoogle Chromeに最適化されています。",
        "이용권 구매 및 환불규정": "利用券購入及び払い戻し規定",
        "모든 이용권은 부가세(VAT) 포함 가격입니다.": "全ての利用券は税込みの価格です。",
        "출동 서비스 이후 발생하는 요금은 각 컨시어지 테스크 요금 정책에 따릅니다.": "出動サービス後発生する料金はコンシェルジュデスク料金の設定に従います。",
        "서비스가 개시될 시각부터는 해당 이용권 결제 취소 요청이 불가능합니다.": "サービスが開始後、該当利用券の決済キャンセル要請は不可能です。",
        "이용권 사용 시작 전에는 100% 환불이 가능합니다.": "利用券を使用前は100％払い戻し可能です。",
        "서비스 결제 정보 확인": "サービス決済情報確認",
        "서비스명": "サービス名",
        "EasyTraverCare": "Easy Travel Care",
        "서비스 기간": "サービス期間",
        "이용기간에 대한 서비스 총 결제 금액": "利用期間に対してのトータルのサービス決済金額",
        "위의 금액을 결제하시겠습니까?": "上記の金額を決済しますか。",
        "사용 고객의 안전한 금융거래를 위하여 제한 시간 내에 결제를 진행해주시기 바랍니다.": "使用顧客の安全な金融取引のために制限時間内に決済を進めてください。",
        "이전화면": "前の画面",
        "제1조(목적)": "第１条(目的)",
        '이 약관은 회사가 온라인으로 제공하는 디지털콘텐츠(이하 "콘텐츠"라고 한다) 및 제반서비스의 이용과 관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.': "この約款は会社がオンラインで提供するデジタルコンテンツ(以下、“コンテンツ”と言う)及び諸般サービスの利用と関連して会社と利用者の権利、義務及び責任事項などを定める目的です。",
        "제2조(정의)": "",
        '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 1. "회사"라 함은 "콘텐츠" 산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반서비스를 제공하는 자를 말합니다.': "",
        '2. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가 제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.': "",
        '3. "회원"이라 함은 "회사"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "회사"의 정보를 지속적으로 제공받으며 "회사"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.': "",
        "이용약관": "利用約款",
        "이메일무단수집거부": "電子メールの無断収集の拒否",
        "서비스정책": "サービスポリシー",
        "서비스 구매 엑셀 업로드": "サービス購入エクセルアップロード",
        "파일첨부": "ファイル添付",
        "You can upload the list only if you agree to the terms and conditions below": "下記の約款に同意するとリストをアップロードできます。",
        "취소": "キャンセル",
        "엑셀 업로드": "エクセルアップロード",
        "We agree to the regulations and signing up for Easy Care Service.": "イージートラベルケア規定及び加入に同意します。",
        "내용": "内容",
        "제목+내용": "タイトル＋内容",
        "작성자": "作成者",
        "검색어를 입력해주세요.": "検索語を入力してくだい。",
        "전체보기": "一覧する",
        "서비스 이용기간": "サービス利用期間",
        "구매 연동": "購入名",
        "서비스 정보": "サービス情報",
        "첨부파일명": "添付ファイル名",
        "승인": "承認",
        "요청": "要請",
        "번호": "番号",
        "회원 관리": "会員管理",
        "로그인 정보를 다시 확인해주세요.": "请确认登录信息是否正确",
        // 2023.05.11 추가된 언어
        "전북쇼핑트래블라운지": "全北ショッピングトラベルラウンジ",
        "위치: 전주시 완산구 충경로 1층": "位置  :  全羅北道 全州市 完山区 忠景路 25, 1階",
        "무인 카페 라운지": "無人カフェラウンジ",
        "전북관광 안내 서비스": "全北観光案内サービス",
        "관광기념품 판매 및 굿즈 판매": "観光記念品やグッズの販売",
        "수하물 보관 서비스": "手荷物保管サービス",
        "소규모 미팅룸 대관": "小規模ミーティングルームレンタル",
        "이벤트 체험존": "イベント体験ゾーン",
        "여행 편의 서비스": "旅行利便サービス",
        "우산 대여, 휴대폰 충전, 보조배터리 대여, 와이파이 등": "傘レンタル、携帯充電、モバイルバッテリーのレンタル、Wi-Fiなど",
        "일수": "日数",
        "구매자": "買い手",
        "명": "人",
        "대기": "Wating",
        "파일을 등록해주세요.": "ファイルを登録してください。",
        "국내카드 결제": "韓国カードで決済(KRW)",
        "해외카드 결제": "海外カードで決済(USD)"
    }
}

