function EasyTravelCareTermENView() {
  return (
    <>
      Enjoy a safe & comfortable tour in Korea! <br />
      Please contact Easy Travel Care Center whenever you need help during your trip to Korea! Easy Travel Care Center is open 365 days, all year around.
      <br />
      <br />
      Easy Travel Care Center provides Worry-Free Insurance & Care services for travelers in Korea via mobile chat, phone calls, and e-mail.
      <br />
      <br />
      Your experience in Korea with Easy Travel Care provides luxurious and foreigner-exclusive customized concierge services with safety and comfort at all times.
      <br />
      <br />
      <h6>Introduction to Easy Travel Care Service</h6>
      Easy Travel Care is a tourism platform service that offers travel insurance and specialized concierge services to foreign tourists in Korea.
      <br />
      <br />
      The offered services are Medical Easy Care Service, Travel Easy Care Service, Airport Easy Care Service, Business Easy Care Service, Traveler’s Worry-Free care and special benefits for premium memberships provided by PREMIUMPASS INTERNATIONAL Corp..
      <br />
      <br />
      About PREMIUMPASS INTERNATIONAL Corp.
      <br />
      Established in 2007 originally as a protocol and concierge service company, PREMIUMPASS INTERNATIONAL has successfully expanded its business in diverse hospitality sectors. <br />
      <br />
      PREMIUMPASS INTERNATIONAL's services, representatively of the Easy Travel Care service, are now recognized globally. The company specializes and provides services specifically in MICE business, protocol/transport business, airport-related business, travel business, concierge business, and operates Travel Center & Lounge in major tourist destinations and transportation hubs.
      <br />
      <br />
      <h6>Outline of Easy Travel Care</h6>
      <br />
      1. Subscription to Easy Travel Care
      <br />
      To sign up for Easy Travel Care service, the following information is needed and shall be collected from you: Nationality, Name, Date of birth, Gender, Mobile number, E-mail address, and Identity verification will be proceeded.
      <br />
      <br />
      If registration is successful, the service manual will be sent to you via designated platform. If you request for special form such as a separate registration certificate will be processed and sent to you via e-mail. <br />
      <br />
      Service may be restricted if the information is incorrect.
      <br />
      Please make sure your contact number and e-mail address are corresponding upon sign-up. In case of an error, you can modify your information by contacting the Easy Travel Care Center or through our chat service.
      <br />
      <br />
      In case of any emergency during traveling, your passport number would be required for verification before and after the service.
      <br />
      <br />
      2. How to Contact Easy Travel Care Center
      <br />
      If you need any help or in case of any emergency, please contact us by SOS request via our chat service on Easy Travel Care platform, or call Easy Travel Care Center (1644-1493/ 82-2-2063-3544).
      <br />
      <br />
      The care center operates 24/7 and can be contacted via phone, e-mail, chat service, or Kakao Talk messenger. You can also leave your contact number if you need a call directly from the manager. Mobiles that do not use Korean SIM cards may have limited services.
      <br />
      <br />
      ※ E-mail or Kakao Talk.
      <br />
      You can request a service through e-mail (care@tothepp.com) or KakaoTalk Plus Friend (@ Easy Travel Care CENTER)
      <br />
      <br />
      <h6>Terms and conditions of Easy Travel Care Service</h6>
      <h6>Term 1. Purpose</h6>
      This agreement aims to clarify the rights and obligations of members and subscribers about the Easy Travel Care service provided by PREMIUMPASS INTERNATIONAL Corp..
      <br />
      <br />
      <h6>Term 2. Terminology</h6>
      1. Members
      <br />
      Individuals or groups that have provided personal verification and itinerary information by phone, e-mail, website, or mobile app to register for the safe tourism platform service, or through the advertisement agencies of Easy Travel Care.
      <br />
      2. Subscribers
      <br />
      Members who have agreed to the terms and conditions of Easy Travel Care service and received a certificate number for the payment made.
      <br />
      3. Easy Travel Care Center
      <br />
      The total service center for non-contact primary services providing convenience, and insurances for possible inconveniences during travel.
      <br />
      4. Subscription Certificate
      <br />
      A document provided by the Easy Travel Care Center to the subscribers as evidence of subscription stated with the certificate number, personal verification information, and major subscription conditions.
      <br />
      5. Care Manager
      <br />
      A professional service manager authorized to be involved and make decisions and also eligible for evaluating, modifying, and supervising every part of the service and insurances.
      <br />
      6. Special Membership Benefits
      <br />
      Special benefits decided by PREMIUMPASS INTERNATIONAL Corp., Ltd. for qualified members of Easy Travel Care service.
      <br />
      <h6>Term 3. Service Details</h6>
      Easy Travel Care provides the following safe tourism platform services:
      <br />
      1. Medical Easy Care
      <br />
      Customized services for any possible accidents that could occur during your travel in Korea including arrangements with a medical interpreter, resolutions for medical expenses, travel insurance, medical care, and etc. <br />
      2. Travel Easy Care
      <br />
      Various concierge services prepared for emergencies, such as:
      <br />
      - Support service for lost items (wallet, passport, etc.) <br />
      - Care service for emergencies during traveling
      <br />
      - Arrangement service for vehicles and transportations
      <br />
      - Immediate accommodation reservation service
      <br />
      3. Airport Easy Care
      <br />
      Specialized Easy Care service within the international airport <br />
      - Support service for the elderly <br />
      - Concierge services
      <br />
      - Immediate airline ticketing and visa support service
      <br />
      4. Business Easy Care
      <br />
      Specialized concierge services for convenient travel and successful business trips
      <br />
      - Immediate arrangements for interpreters or translators <br />
      - Arrangements and reservations for meeting rooms or places
      <br />
      - Ticketing and support system for KTX, Express Bus, and Airlines
      <br />
      5. Traveler’s Worry-Free care
      <br />
      Traveler’s Worry-Free care provides and follows the terms and conditions of the affiliated insurance company. <br />
      - Accidental Death (During Domestic Trip): KRW100,000,000(Excluding under age 15)
      <br />
      - Accident Disability (During Domestic Trip): KRW100,000,000
      <br />
      - Death by Illness or 80% Severe Disability by Illness(During Domestic Trip): KRW20,000,000 (Excluding under age 15, age 80 or more)
      <br />
      - Domestic Travel Injury Insurance_Medical Expense Coverage: KRW10,000,000 (limit of KRW100,000 per Outpatient)
      <br />
      - (Non-covered) Domestic Travel Injury Insurance_Medical Expense Coverage: KRW10,000,000 (KRW100,000 per Outpatient, limit of 100 visits per year)
      <br />
      - Lump Sum Compensation (Domestic Trip): KRW5,000,000, Self-payment KRW10,000
      <br />
      - Personal Belongings Damage (Except Loss): KRW200,000, Self-payment KRW10,000
      <br />
      6. Special Membership Benefits
      <br />
      Easy Travel Care is a member-exclusive service that provides diverse concierge services and discounts in terms of airport, travel, and business. Please note that the membership service can be modified by the parent company, PREMIUMPASS INTERNATIONAL Corp.. The changes will be updated accordingly on the website and mobile app for your reference. <br />
      7. Precautions for Other Services
      <br />
      - If the subscriber has been in an accident during traveling Korea, Information for Emergency Response Procedure, translation services, arrangements with the related agencies, along with on-site dispatch will be provided.
      <br />
      - This service is only effective for accidents that occurred in South Korea and is restricted in any other countries.
      <br />
      - It is recommended that the subscriber immediately reports to the Easy Travel Care Service Center in case of an accident. If a third party is engaged before the arrival of the Easy Travel Care manager, our service can be limited.
      <br />
      <br />
      <h6>Term 4. Effective Date and Termination of Service</h6>
      Easy Travel Care service is effective from the first day of registration and is immediately terminated on the last day or after departure from Korea. The number of days is counted by 24 hours. <br />
      <br />
      <h6>Term 5. Effect of the Contract</h6>
      1. These terms and conditions take effect when Easy Travel Care posts them on websites, mobile applications, or informs the subscribers.
      <br />
      2. If Easy Travel Care amends these terms and conditions, it will be posted or notified in the same way as in paragraph 1 from one week before the date of application to the day before the date of application. At this time, the amended terms and conditions apply only to contracts whose service date starts after the date of application, and contracts already started apply to the terms and conditions before the amendment.
      <br />
      3. Subscribers may request unsubscribing if they do not agree to the revised terms and conditions.
      <br />
      4. The subscriber is deemed to have agreed to the revised terms and conditions by using the service if not request unsubscribing.
      <br />
      <br />
      <h6>Term 6. Priority of Application of Terms and Conditions</h6>
      1. For the service associated with Easy Travel Care services and to the insurance company’s products, general terms and special terms of the insurance product apply to all matters related to insurance.
      <br />
      2. In case the contents of the general terms and the contents of special terms conflict, the effect of the special terms takes precedence over the general terms.
      <br />
      <br />
      <h6>Term 7. Qualification and Disqualification for Members and Subscribers</h6>
      1. All individuals and groups are eligible for membership by filling in or providing personal identification information (nationality, name, date of birth, gender, phone number, email address, etc.) and itinerary information required by Easy Travel Care. However, you must present your passport to receive Easy Travel Care service.
      <br />
      2. If false or incomplete information is updated or the registration is without progress up to 12 hours, Easy Travel Care shall be eligible of terminating or discarding all itinerary information of the member.
      <br />
      3. Easy Travel Care is eligible of using the member's personal / itinerary information for membership registration.
      <br />
      4. Members who have been successfully qualified (refer to term 7.1) shall receive a certificate number.
      <br />
      <br />
      <h6>Term 8. EASY TRAVEL CARE’s Rights and Obligations</h6>
      1. Easy Travel Care shall constantly make effort to provide stabilized services and customer service. However, affiliates could also proceed with customer service, depending on the needs and circumstances.
      <br />
      2. Easy Travel Care could restrict or discontinue the service, completely or partly, if applicable to any of the following cases: <br />
      A. System and equipment repair, etc.
      <br />
      B. No service provided from the telecommunication service provider
      <br />
      C. Other unavoidable cases, such as national emergencies, power outages, network congestion, etc.
      <br />
      3. Easy Travel Care should inform the members or subscribers in case of discontinuation or restriction of service via methods specified in term 5.1.
      <br />
      4. If a member or a subscriber has an existing disease or a symptom caused by an accident or disease in the past, registration and insurance could be limited and exempted.
      <br />
      5. Easy Travel Care has the right to store and monitor conversations to prevent any disorders.
      <br />
      6. It is considered that the subscriber has agreed to the use of stored information as evidence in case of any service-related disorders.
      <br />
      7. The subscriber shall fill in and submit any medical records to the Easy Travel Care Center, including the records that occurred before the trip if Easy Travel Care requests the information for better service. Easy Travel Care shall not be responsible for any disadvantages caused by the subscriber's refusal for submission.
      <br />
      8. In case of occurrence of accidents and diseases, Easy Travel Care is eligible to immediately exercise all rights on behalf of the subscriber, regarding the service-related third parties, such as individuals, legal entities, and public institutions. The subscriber cannot cancel the rights and activities and must comply with all legal procedures. <br />
      9. Among Easy Travel Care services, Traveler’s Worry-Free care will be paid following the insurance company's terms and conditions with which the "Premiumpass" is affiliated.
      <br />
      10. Guarantee for the travel insurance shall be provided with the company affiliated with "PREMIUMPASS INTERNATIONAL" as Easy Travel Care service is based on the Worry-Free Tourism Concierge service. The member is responsible for the concierge service payments.
      <br />
      <br />
      <h6>Term 9. Rights and Obligations of Members and Subscribers</h6>
      1. Members and subscribers shall agree and follow every policy and precaution stated in this contract by Easy Travel Care. <br />
      2. Members and subscribers shall be aware of information misuse and immediately report to Easy Travel Care if any misuse is detected.
      <br />
      3. Members and subscribers cannot transfer or assure the rights and obligations to a third party.
      <br />
      4. Members and subscribers have the right to reject the use of their personal information by a third party, excluding the cases designated by law.
      <br />
      <br />
      <h6>Term 10. Cancellation or Termination of Subscription</h6>
      1. Subscribers can always withdraw their subscription and receive a full refund before the service effective date stated on the certificate.
      <br />
      2. Modification of service period or cancellation is only available before the commencement date.
      <br />
      3. "PREMIUMPASS INTERNATIONAL Corp." is eligible to cancel the service if it is considered that there is a possibility of injustice, malice, or deliberate accidents.
      <br />
      4. If the subscriber requests service cancellation after the service commencement date, a full refund excluding the period of use shall be available. The deduction depends on the insurance short rate and service rate. If the subscriber does not exert the right to claim for a return until 3 years after the disqualification, it would be considered as an extinctive prescription. However, the right to claim for refund would follow the Insurance Business Act.
      <br />
      5. Submission of Documents for Cancellation and Termination
      <br />
      A. Cancellation before the commencement date: cancellation request letter or e-mail/bankbook copy
      <br />
      B. Termination due to simple change of mind: application for termination/passport copy/bankbook copy (refund as of the date of receipt of the application for termination)
      <br />
      C. Termination due to early return: passport copy/bankbook copy/ return E-TICKET copy or certificate of entry & exit (E-TICKET cannot include documents with re-entry schedules before the insurance expiry. Refunds for the certificate of entry and exit are based on the final date of exit within the term of insurance.)
      <br />
      <br />
      <h6>Term 11. Renewal of Easy Travel Care Subscription</h6>
      1. If the subscriber's duration of entitlement expires while staying in Korea, the subscriber shall inform the center via SOS calls or emails before the expiry date.
      <br />
      2. Renewal will be limited if the duration of entitlement has already expired or the subscriber does not complete the renewal process within the expiry date.
      <br />
      3. Renewal and insurance would be limited if the subscriber has diseases or symptoms caused by accidents or illness before the commencement of the renewed service and insurance payments of Easy Travel Care may be exempted.
      <br />
      <br />
      <h6>Term 12. Subscription for Group Insurance etc.</h6>
      1. Easy Travel Care, as a contractor for members and subscribers, can conclude a group insurance contract with the insurance company and provide the service.
      <br />
      2. Members and subscribers who wish to set Easy Travel Care as the insurant of the concluded group insurance contract could proceed by subscribing to a plan that includes the insurance service.
      <br />
      3. Members and subscribers who wish to subscribe to a plan that includes the insurance service shall thoroughly read the terms and conditions of the affiliated insurance company and follow the requirements of Easy Travel Care.
      <br />
      4. If subscribing to the plan that includes the insurance service, the beneficiary would be the insurant and the legal heir.
      <br />
      5. For group insurance policies that are not specified in this document, the terms, and conditions of the affiliation agreement will be applied.
      <br />
      <br />
      <h6>Term 13. Travel Insurance Convenience Support</h6>
      Travel insurance within the Easy Travel Care plan is group insurance of "PREMIUMPASS INTERNATIONAL Corp." that provides group discounts. Each plan provides optimal insurance coverage and a subscription fee. Insurance claims, proportional insurance coverage, and final guidance to the insurance company are also provided.
      <br />
      <br />
      <h6>Term 14. Restrictions on Easy Travel Care Insurance Service Support</h6>
      Easy Travel Care would not provide services occurred by the following reasons. If service is delivered due to the subscriber's request, additional costs may be incurred.
      <br />
      ① Services that are not listed in the subscription certificate
      <br />
      ② Intentional or grave negligence of the subscriber or guardian (including the affiliates)
      <br />
      ③ All medical costs of the subscriber's illness or casualties, such as diagnosis expenses, medical expenses, and medication costs
      <br />
      ④ Death penalty, self-harm, suicide, suicide attempt, and any crimes or violence designated by law. However, support is provided if admitted as self-defense or emergency evacuation
      <br />
      ⑤ Insanity or mental illness
      <br />
      ⑥ Injury, diseases, or complications resulting from the treatments from other medical institutions without the approval of Easy Travel Care and its affiliates <br />
      ⑦ All expenses incurred by a companion who is not a subscriber of Easy Travel Care.
      <br />
      ⑧ Infectious diseases picked up on the return from certain countries and uncooperative actions to government measures, such as vaccinations or travel restrictions.
      <br />
      ⑨ Services generated by the subscriber's voluntary entrance to war, overseas armed force, revolutions, civil war, riots, disturbances, labor disputes, and other similar incidents.
      <br />
      ⑩ Radioactive, explosive, and other accidents caused by nuclear fuel (Includes the used ones, same for below cases) or substances contaminated by nuclear fuel (includes nuclear fission products)
      <br />
      ⑪ If the purpose of the tour is revealed to be medical practice or pregnancy, childbirth, abortion, and other surgical operations that are uncaused by accidents or accidental illnesses
      <br />
      ⑫ Earthquake, eruption, tsunami, or similar natural disasters.
      <br />
      ⑬ Other reasons for exemption under this agreement.
      <br />
      <br />
      <h6>Term 15. Solution of Conflict</h6>
      1. Easy Travel Care and its subscribers should endeavor to resolve service-related disputes.
      <br />
      2. If a lawsuit is filed due to disputes between the two parties, notwithstanding the regulation above (15.1), the competent court for all legal matters concerning the contractual relationship will be in Republic of Korea. <br />
      3. All disputes over contracts would conform to the law of Republic of Korea. Insurance-related disputes would conform to the general and special terms of the affiliated insurance company.
      <br />
      <br />
      PREMIUMPASS INTERNATIONAL Corp. Unit 501, Bldg. A, 22 Magokjungang 4-ro, Gangseo-gu, Seoul, Republic of Korea
      <br />
      PREMIUMPASS INTERNATIONAL Corp. Care Center +82-2-2063-3544
      <br />
      PREMIUMPASS INTERNATIONAL Corp. Care Center’s E-mail care@tothepp.com <br />
      PREMIUMPASS INTERNATIONAL Corp. Care Center’s KakaoTalk ID (@Easy Travel Care CENTER) <br />
      PREMIUMPASS INTERNATIONAL Corp. Subscription Counseling 1644-1493
      <br />
      PREMIUMPASS INTERNATIONAL Corp. Fax FAX +82-2-2138-0164
      <br />
    </>
  )
}

export default EasyTravelCareTermENView
