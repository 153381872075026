import { createHashRouter } from 'react-router-dom'
import App from '../App'
import InformationPage from '../component/pages/information/InformationPage'
import AirportServiceView from '../component/pages/information/view/AirPortServiceView'
import BusinessServiceView from '../component/pages/information/view/BusinessServiceView'
import MedicalServiceView from '../component/pages/information/view/MedicalServiceView'
import SpecialServiceView from '../component/pages/information/view/SpecialServiceView'
import TravelerServiceView from '../component/pages/information/view/TravelerServiceView'
import TravelServiceView from '../component/pages/information/view/TravelServiceView'
import Main from '../component/pages/main/MainPage'
import MyPage from '../component/pages/my-page/MyPage'
import MyPageInformationView from '../component/pages/my-page/view/MyPageInformationView'
import PurchasePage from '../component/pages/purchase/PurchasePage'
import PurchaseInputView from '../component/pages/purchase/view/PurchaseInputView'
import LoginPage from '../component/pages/login/LoginPage'
import SignUpPage from '../component/pages/login/SignUpPage'
import FAQPage from '../component/pages/faq/FAQPage'
import FAQListView from '../component/pages/faq/view/FAQListView'
import QnAListView from '../component/pages/qna/view/QnAListView'
import QnADetailView from '../component/pages/qna/view/QnADetailView'
import QnACreateView from '../component/pages/qna/view/QnACreateView'
import MyPurchaseListView from '../component/pages/my-page/view/MyPurchaseListView'
import WithdrawalView from '../component/pages/my-page/view/WithdrawalView'
import TermsPage from '../component/pages/terms/TermsPage'
import TermsView from '../component/pages/terms/view/TermsView'
import PrivacyView from '../component/pages/terms/view/PrivacyView'
import FindLoginIdPasswordPage from '../component/pages/login/FindLoginIdPasswordPage'
import FindLoginIdView from '../component/pages/login/view/FindLoginIdView'
import FindPasswordView from '../component/pages/login/view/FindPasswordView'
import FindResultLoginIdView from '../component/pages/login/view/FindResultLoginIdView'
import FindResultPasswordView from '../component/pages/login/view/FindResultPasswordView'
import ConciergePage from '../component/pages/concierge/ConciergePage'
import ConciergeIncheonT1View from '../component/pages/concierge/view/ConciergeIncheonT1View'
import ConciergeIncheonT2View from '../component/pages/concierge/view/ConciergeIncheonT2View'
import ConciergeSongdoView from '../component/pages/concierge/view/ConciergeSongdoView'
import ConciergeMapoView from '../component/pages/concierge/view/ConciergeMapoView'
import ConciergeSeoulView from '../component/pages/concierge/view/ConciergeSeoulView'
import SOSCallPage from '../component/pages/sos/SOSCallPage'
import SOSCallTermsView from '../component/pages/sos/view/SOSCallTermsView'
import SOSCallInforationView from '../component/pages/sos/view/SOSCallInformationView'
import SOSCallChatRoom from '../component/pages/sos/SOSCallChatRoom'
import SOSCallChatRoomList from '../component/pages/sos/SOSCallChatRoomList'
import PurchasePaymentView from '../component/pages/purchase/view/PurchasePaymentView'
import PurchaseCompletedView from '../component/pages/purchase/view/PurchaseCompletedView'
import SignUpCompletedPage from '../component/pages/login/SignUpCompletedPage'
import QnAEditView from '../component/pages/qna/view/QnAEditView'
import B2BPage from '../component/pages/b2b/B2BPage'
import B2BServicePurchaseList from '../component/pages/b2b/view/B2BServicePurchaseList'
import B2BServicePurchase from '../component/pages/b2b/view/B2BServicePurchase'
import B2BMember from '../component/pages/b2b/view/B2BMember'
import EmailCollectionView from '../component/pages/terms/view/EmailCollectionView'
import ConciergeJeonbukView from '../component/pages/concierge/view/ConciergeJeonbukView'
import MemberShipView from '../component/pages/member-ship/MemberShipView'
import B2BPurchaseMemberShipView from '../component/pages/b2b/view/B2BPurchaseMemberShipView'

const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
      {
        path: '/information',
        element: <InformationPage />,
        children: [
          {
            path: '/information/medical',
            element: <MedicalServiceView />,
          },
          {
            path: '/information/airport',
            element: <AirportServiceView />,
          },
          {
            path: '/information/travel',
            element: <TravelServiceView />,
          },
          {
            path: '/information/business',
            element: <BusinessServiceView />,
          },
          {
            path: '/information/traveler',
            element: <TravelerServiceView />,
          },
          {
            path: '/information/special',
            element: <SpecialServiceView />,
          },
        ],
      },
      {
        path: '/concierge',
        element: <ConciergePage />,
        children: [
          {
            path: '/concierge/incheon-t1',
            element: <ConciergeIncheonT1View />,
          },
          {
            path: '/concierge/incheon-t2',
            element: <ConciergeIncheonT2View />,
          },
          {
            path: '/concierge/seoul',
            element: <ConciergeSeoulView />,
          },
          {
            path: '/concierge/mapo',
            element: <ConciergeMapoView />,
          },
          {
            path: '/concierge/songdo',
            element: <ConciergeSongdoView />,
          },
          {
            path: '/concierge/jeonbuk',
            element: <ConciergeJeonbukView />,
          },
        ],
      },
      {
        path: '/sos',
        element: <SOSCallPage />,
        children: [
          {
            path: '/sos/terms',
            element: <SOSCallTermsView />,
          },
          {
            path: '/sos/information',
            element: <SOSCallInforationView />,
          },
        ],
      },
      {
        path: '/purchase',
        element: <PurchasePage />,
        children: [
          {
            path: '/purchase/information',
            element: <PurchaseInputView />,
          },
          {
            path: '/purchase/payment',
            element: <PurchasePaymentView />,
          },
          {
            path: '/purchase/completed',
            element: <PurchaseCompletedView />,
          },
        ],
      },
      {
        path: '/help',
        element: <FAQPage />,
        children: [
          {
            path: '/help/faq',
            element: <FAQListView />,
          },
          {
            path: '/help/qna',
            element: <QnAListView />,
          },
          {
            path: '/help/qna/create',
            element: <QnACreateView />,
          },
          {
            path: '/help/qna/:id',
            element: <QnADetailView />,
          },
          {
            path: '/help/qna/edit/:id',
            element: <QnAEditView />,
          },
        ],
      },
      {
        path: '/my-page',
        element: <MyPage />,
        children: [
          {
            path: '/my-page',
            element: <MyPageInformationView />,
          },
          {
            path: '/my-page/purchase',
            element: <MyPurchaseListView />,
          },
          {
            path: '/my-page/withdrawal',
            element: <WithdrawalView />,
          },
        ],
      },
      {
        path: '/terms',
        element: <TermsPage />,
        children: [
          {
            path: '/terms',
            element: <TermsView />,
          },
          {
            path: '/terms/privacy',
            element: <PrivacyView />,
          },
          {
            path: '/terms/email-collection',
            element: <EmailCollectionView />,
          },
        ],
      },
      {
        path: '/find',
        element: <FindLoginIdPasswordPage />,
        children: [
          {
            path: '/find/login-id',
            element: <FindLoginIdView />,
          },
          {
            path: '/find/password',
            element: <FindPasswordView />,
          },
          {
            path: '/find/result-login-id',
            element: <FindResultLoginIdView />,
          },
          {
            path: '/find/result-password',
            element: <FindResultPasswordView />,
          },
        ],
      },
      {
        path: '/b2b',
        element: <B2BPage />,
        children: [
          {
            path: '/b2b/service_purchase',
            element: <B2BServicePurchaseList />,
          },
          {
            path: '/b2b/service_purchase/:id',
            element: <B2BServicePurchase />,
          },
          {
            path: '/b2b/member',
            element: <B2BMember />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
  {
    path: '/sign-up/completed',
    element: <SignUpCompletedPage />,
  },
  {
    path: '/sos/chat/room',
    element: <SOSCallChatRoomList />,
  },
  {
    path: '/sos/chat/room/:id',
    element: <SOSCallChatRoom />,
  },
  {
    path: '/purchase/member-ship',
    element: <MemberShipView />,
  },
  {
    path: '/b2b/purchase/member-ship',
    element: <B2BPurchaseMemberShipView />,
  },
])

export default router
