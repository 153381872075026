import _ from 'lodash'

interface PagenationProp {
  currentPage: number
  totalPages: number
  updatePage: Function
}

function PagenationView(prop: PagenationProp) {
  const itemSize = 10
  const clickFirst = () => {
    if (prop.currentPage != 0) {
      prop.updatePage(1)
    }
  }
  const clickPrevious = () => {
    if (prop.currentPage - 1 > 0) {
      prop.updatePage(prop.currentPage - 1)
    }
  }
  const clickNext = () => {
    if (prop.currentPage + 1 <= prop.totalPages) {
      prop.updatePage(prop.currentPage + 1)
    }
  }
  const clickLast = () => {
    if (prop.currentPage != prop.totalPages) {
      prop.updatePage(prop.totalPages)
    }
  }
  const clickPage = (index: number) => {
    if (prop.currentPage != index) {
      prop.updatePage(index)
    }
  }
  const getTotalPage = () => {
    return prop.totalPages >= itemSize ? itemSize : prop.totalPages
  }
  const getPageIndex = () => {
    return prop.currentPage + Math.ceil(itemSize / 2) - itemSize
  }
  const getMaxIndex = () => {
    return prop.totalPages - itemSize
  }
  const getCurrentIndex = (index: number) => {
    let pageIndex = getPageIndex()
    if (pageIndex <= 0 || getMaxIndex() <= 0) {
      return index + 1
    } else if (pageIndex >= getMaxIndex()) {
      return getMaxIndex() + index + 1
    } else {
      return pageIndex + index + 1
    }
  }

  return (
    <div className="text-center">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className="page-item icon">
            <a className="page-link" href="javascript:;" onClick={clickFirst}>
              <i className="bi bi-chevron-double-left"></i>
            </a>
          </li>
          <li className="page-item icon mr-2">
            <a className="page-link" href="javascript:;" onClick={clickPrevious}>
              <i className="bi bi-chevron-left"></i>
            </a>
          </li>
          {_.range(0, getTotalPage()).map((index) => {
            return (
              <li className={`page-item ${getCurrentIndex(index) === prop.currentPage ? 'active' : ''}`} key={`page_${index}`}>
                <a
                  className="page-link"
                  href="javascript:;"
                  onClick={(e) => {
                    clickPage(getCurrentIndex(index))
                  }}
                >
                  {getCurrentIndex(index)}
                </a>
              </li>
            )
          })}
          <li className="page-item icon ml-2">
            <a className="page-link" href="javascript:;" onClick={clickNext}>
              <i className="bi bi-chevron-right"></i>
            </a>
          </li>
          <li className="page-item icon">
            <a className="page-link" href="javascript:;" onClick={clickLast}>
              <i className="bi bi-chevron-double-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default PagenationView
