import { useState, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { axiosConnectFormUrlEncoded } from '../../../network/http-request'
import { login } from '../../../store/user'
import { validatePassword } from '../../../utils/checkValidity'
import { validateId } from '../../../utils/checkValidity'

function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const redirectPage = searchParams.get('redirect-page')
  const language = useSelector((state: any) => state.language.value)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loginId, setLoginId] = useState(localStorage.getItem('loginId') ?? '')
  const [password, setPasword] = useState('')
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('loginId') ? true : false)
  const [validFailLoginId, setValidFailLoginId] = useState(false)
  const [validFailPassword, setValidFailPassword] = useState(false)

  const validate = () => {
    let valid = true
    setValidFailLoginId(false)
    setValidFailPassword(false)

    if (!validateId(loginId)) {
      valid = false
      setValidFailLoginId(true)
    }

    if (!validatePassword(password)) {
      valid = false
      setValidFailPassword(true)
    }

    return valid
  }

  const handleLoginKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.nativeEvent.isComposing === false) {
      loginApi()
    }
  }

  const loginApi = () => {
    // validate() &&
    axiosConnectFormUrlEncoded('/api/main/login', 'POST', {
      memberId: loginId,
      memberPwd: password,
    })
      .then((result) => {
        if (result.data !== '') {
          dispatch(login(result.data))
          if (rememberMe) {
            localStorage.setItem('loginId', loginId)
          }

          if (result.data.memberAuth === 5) {
            // 관리자 로그인
            window.location.href = '/admin/adminIndex'
          } else if (result.data.memberAuth === 4) {
            // 메니저 로그인
            window.location.href = '/chatManager/managerIndex'
          } else if (result.data.memberAuth === 6) {
            // B2B 회원 로그인
            if (redirectPage !== null) {
              navigate(`/${redirectPage}`)
            } else {
              navigate('/b2b/service_purchase')
            }
          } else {
            if (redirectPage !== null) {
              navigate(`/${redirectPage}`)
            } else {
              navigate('/')
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert(language['로그인 정보를 다시 확인해주세요.'])
        } else {
          alert(error)
        }
      })
  }

  return (
    <div className="container loginwrap">
      <div className="row no-gutters">
        <div className="col-12">
          <Link to="/" className="login-brand">
            <img src="/resources/home/img/svg/logo.svg" alt="이지트래블케어" />
          </Link>
          <h2>{language['로그인']}</h2>

          <form>
            <div className="form-group row no-gutters">
              <label htmlFor="inputEmail" className="sr-only">
                {language['아이디']}
              </label>
              <input
                type="text"
                className={`form-control ${validFailLoginId ? 'valid is-invalid' : ''}`}
                id="inputEmail"
                placeholder={language['아이디']}
                value={loginId}
                onChange={(e) => {
                  setLoginId(e.target.value)
                }}
              />
              {validFailLoginId ? <p className="invalid-feedback p-2 text-left m-0">{language['아이디를 입력하세요.']}</p> : <></>}
            </div>
            <div className="form-group row no-gutters">
              <label htmlFor="inputPassword" className="sr-only">
                {language['비밀번호']}
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className={`form-control ${validFailPassword ? 'valid is-invalid' : ''}`}
                  id="inputPassword"
                  placeholder={language['비밀번호']}
                  onChange={(e) => {
                    setPasword(e.target.value)
                  }}
                  onKeyDown={handleLoginKeyDown}
                />
                {validFailPassword ? <p className="invalid-feedback p-2 text-left m-0">{language['비밀번호 조건에 맞지 않습니다.']}</p> : <></>}
              </div>
            </div>
            <div className="form-group row no-gutters text-left align-items-center relative custom-form">
              <div className="custom-control custom-checkbox col-6 relative">
                <input type="checkbox" className="custom-control-input" id="customCheck1" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                <label className="custom-control-label" htmlFor="customCheck1">
                  {language['아이디 저장']}
                </label>
              </div>
              {rememberMe ? (
                <div className="popover fade show bs-popover-bottom" role="tooltip" id="popover" style={{ top: '25px' }}>
                  <div className="arrow" style={{ left: '20px' }}></div>
                  <div className="popover-body">{language['개인정보 보호를 위해 본인 기기에서만 사용해주세요.']}</div>
                </div>
              ) : (
                <></>
              )}

              <div className="col-6 text-right ">
                <Link to="/find/login-id" className="text-primary text-lg">
                  {language['아이디']}
                </Link>{' '}
                ·{' '}
                <Link to="/find/password" className="text-primary text-lg">
                  {language['비밀번호 찾기']} &gt;
                </Link>
              </div>
            </div>
            <p className="clearfix">
              <br />
            </p>
            <div className="form-group row no-gutters">
              <a className="btn btn-primary btn-block btn-lg" onClick={(e) => loginApi()}>
                {language['로그인']}
              </a>
            </div>
            <div className="form-group row no-gutters">
              <Link to="/sign-up" className="btn btn-outline-primary btn-block btn-lg">
                {language['회원가입']}
              </Link>
            </div>

            <p className="text-copy mt-5">© Easy Travel Care</p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
