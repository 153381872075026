function PrivacyJPView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
            <h3>個人情報処理方針</h3>
            （株）プレミアムパス インターナショナル（以下「本社」といいます） は、貴下の個人情報保護を非常に重要視して、「個人情報保護法」を 遵守しております。「本社」は、個人情報処理方針を通じて、貴下が 提供する個人情報がどのような用途と方式で利用されており、個人情報保護のために、どのような措置が取られているかをお知らせします。
            <br />
            <br />
            <h5>1. 処理する個人情報の項目</h5>「本社」は、安心ケア、保険サービス提供のために、必要な 最小限の個人情報だけを収集します。選択項目は、入力しなくても、 サービス利用には制限がありません。
            <br />
            <br />
            <h6>【会員登録時の収集項目】</h6>
            ㅡ必修項目：氏名、イーメール、生年月日、性別、住民（登録の 個人別固有）番号（内国人）、パスポート番号（外国人）、電話番号
            <br />
            ㅡサービス利用過程や、サービス提供業務処理過程で、次のような 情報が自動的に生成され、収集されることがあります。 （サービス利用記録、接続ログ、クッキー、接続IP情報）
            <br />
            <br />
            <h5>2. 個人情報の処理目的</h5>
            「本社」は、収集した個人情報を次の目的のために、活用します。 お客様が提供した全ての情報は、次に記述した目的以外は使用されず、利用目的が変更された場合は、事前同意を求めるつもりです。
            <br />
            ㅡホームページの会員登録及び、管理、本人確認、個人識別、不正使用防止、費認可の使用防止
            <br />
            ㅡお客様の身元把握、金融取引及びサービス業務、本人確認、個人識別、不正使用防止、不認可の使用防止、苦情処理
            <br />
            ㅡ現代海上保険の登録、安心ケアサービス提供、金融取引及び、 サービス業務、料金の取立
            <br />
            ㅡ苦情処理
            <br />
            ㅡサービス広報及び、マーケティング業務
            <br />
            <br />
            <h5>3.個人情報の処理及び、保有期間</h5>
            個人情報の収集目的または、提供された目的が達成された時は、 お客様の個人情報を遅滞なく、破棄します。
            <br />
            ㅡ会員情報：会員脱退及び、除名時まで保有
            <br />
            ㅡ契約または、請約撤回などに関する記録、代金決済及び、財貨など の供給に関する記録: 5年間保有
            <br />
            ㅡ消費者不満、紛争処理: 3年間保有
            <br />
            ㅡサービス広報及び、マーケティング業務のための情報：３年 但し、次は、例外とします。
            <br />
            A.料金精算上の問題で、決済が完了していない場合
            <br />
            B.他の一般会員を保護する必要がありますので、当社が保存する 必要があると判断される場合
            <br />
            C.商法など、他の法令の規定により、保存する必要性がある場合
            <br />
            D.訴訟や、紛争において、根拠資料として、保存する必要性が ある場合
            <br />
            E.その他、個別に利用者の同意を得た場合
            <br />
            F.その他、当社の内部規定により、日程期間保存する必要性が ある場合
            <br />
            しかし、この場合にも正当な根拠と共に、事前に個人情報が保有 される期間を告知して、その後、完全に破棄します。
            <br />
            <br />
            <h5>4.個人情報の破棄に関する事項</h5>
            「本社」は、『個人情報処理目的』が達成されたり、『処理及び、保有期間』が経過した後は、当該個人情報を直ちに、破棄します。 <br />
            ㅡ電子ファイル形式で、保存されている個人情報は、記録を再生できない技術的方法を使用して作成
            <br />
            ㅡ紙に出力された個人情報は、粉砕機で粉砕または、焼却して破棄
            <br />
            <br />
            <h5>5.個人情報の第３者提供に関する事項</h5>
            「本社」は、貴下の同意があったり、関連法令の規定による場合を 除き、貴下の情報を第３者に提供しません。
            <br />
            <br />
            第３者 提供 項目 提供 目的 (ここに( )に書かなくてはならない。決済会社) 提供情報：氏名、電話番号、イーメール、商品名 提供目的：電子決済
            <br />
            現代海上提供情報：氏名、住民登録番号、パスポート番号 （外国人）、性別、電話番号、イーメール、、商品名 提供目的：保険の登録
            <br />
            ㅡ個人情報の保有期間：目的に応じた個人情報提供時から、提供目的 達成時まで、または、３条に明示された期限まで。
            <br />
            <br />
            <h5>6.情報主体の権利∙義務及び、その行事方法に関する事項</h5>
            貴下は、安心ケアサービスのために、個人情報の収集∙利用及び、 提供について、同意した内容をいつでも撤回できます。 貴下は、本人の個人情報の閲覧、訂正、処理停止、削除の要請が でき、個人情報保護責任者に書面、電話または、ファックスなどで 連結すれば、遅滞なく、破棄するなど、必要な措置を取ります。 但し、法律で定められた義務事項がある場合、権利行使が制限されることがあります。
            <br />
            <br />
            <h5>7.個人情報保護責任者</h5> 貴下の個人情報を保護し、個人情報に関する、苦情を処理するために、 下記の通り、個人情報保護責任者があります。
            <br />
            氏名: チェハンナ
            <br />
            役目: 職員
            <br />
            部署: イージートラベルケア 担当
            <br />
            連絡先: 02-1644-1493
            <br />
            <br />
            <h5>8.個人情報の安全性確保措置</h5>利用者に送ってくださる全ての情報は、防火壁など、セキュリティ システムによって、安全に管理されています。 又は、「本社」は、利用者の個人情報保護のための管理的対策として、 個人情報に対する、アクセス権限を差等付与していて、利用者の個人情報を扱う人数を最小限に制限して、持続的なセキュリティ教育を 行っています。 又は、個人情報を処理するシステムの使用者を指定して、ログ記録を 保管しています。
            <br />
            <br />
            <h5>9.個人情報処理方針の変更に関する事項</h5>この個人情報処理方針とは、2021年1月1日に制定されており、法令∙ 政策または、保安技術の変更により、内容の追加∙削除及び、修正が ある場合は、変更される個人情報処理方針を実施する少なくとも 7日の前に、「本社」のホームページを通じて、変更事由及び、内容 などをお知らせするようにします。
            <br />
            <br />
            ㅡ広告の日付: 2021年1月1日 / 施行日: 2021年1月1日
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyJPView
