import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'

function FindLoginIdView() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const [passportNumber, setPassportNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const clickFindLoginId = () => {
    axiosConnect(
      `/rest/member/findForeignerId`,
      'GET',
      {},
      {
        passportNum: passportNumber,
        phoneNum: phoneNumber,
      }
    )
      .then((result) => {
        if (result.data != null && result.data.memberId != null) {
          navigate('/find/result-login-id', { state: { loginId: result.data.memberId } })
        } else {
          alert(language['아이디가 존재하지 않습니다.'])
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <div className="container mt-5">
        <div className="nav-tabs-box">
          <ul className="nav nav-tabs nav-fill">
            <li className="nav-item">
              <Link to="/find/login-id" className="nav-link active">
                {language['아이디 찾기']}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/find/password" className="nav-link">
                {language['비밀번호 찾기']}
              </Link>
            </li>
          </ul>
        </div>

        <div className="loginwrap">
          <div className="row no-gutters">
            <div className="col-12">
              <h4 className="text-left">{language['아이디 찾기']}</h4>
              <hr className="dark" />
              <form>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <label htmlFor="inputPhone" className="sr-only">
                      {language['여권번호 또는 외국인등록번호']}
                    </label>
                    <input type="text" className="form-control" id="inputPhone" placeholder={language['여권번호 또는 외국인등록번호']} value={passportNumber} onChange={(e) => setPassportNumber(e.target.value)} />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="inputPhone" className="sr-only">
                    {language['휴대폰번호']}
                  </label>
                  <input type="text" className="form-control" id="inputPhone" placeholder={language['휴대폰번호( 하이폰 - 포함해서 작성해주세요)']} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <p className="clearfix">
                  <br />
                </p>
                <div className="form-group">
                  <a onClick={clickFindLoginId} className="btn btn-primary btn-block btn-lg">
                    {language['아이디 찾기']}
                  </a>
                </div>

                <p className="text-center mt-4 text-muted small">
                  <i className="bi bi-exclamation-triangle-fill mr-1"></i>
                  {language['B2B회원은 아이디 찾기 및']} <br className="d-block d-md-none" />
                  {language['비밀번호 찾기를 지원하지 않습니다.']}
                  <br /> {language['1644-1493로 문의해주시기 바랍니다.']}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FindLoginIdView
