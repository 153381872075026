import PagenationView from '../../../common/PaginationView'
import { useEffect, useState } from 'react'
import B2BPurchaseUploadModel from '../modal/B2BPurchaseUploadModal'
import { Link } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'
import { DateUtil } from '../../../../utils/date-format'
import { useSelector } from 'react-redux'

function B2BServicePurchaseList() {
  const language = useSelector((state: any) => state.language.value)

  const [pageNo, setPageNo] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [tempSearchKeyword, setTempSearchKeyword] = useState('')
  const [purchaseList, setPurchaseList] = useState<any>({
    totalPages: 1,
    content: [],
  })

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.nativeEvent.isComposing === false && tempSearchKeyword !== '') {
      searchPurchaseList(tempSearchKeyword)
    }
  }

  useEffect(() => {
    getPurchaseList()
  }, [pageNo, keyword])

  useEffect(() => {
    console.log(`===> modal: ${modalOpen}`)
  }, [modalOpen])

  const searchPurchaseList = (value: string) => {
    setPageNo(1)
    setKeyword(value)
    getPurchaseList()
  }

  const getPurchaseList = () => {
    if (keyword === '') {
      fetchPurchaseList(`${pageNo}`)
    } else {
      fetchPurchaseList(`${pageNo}/${keyword}`)
    }
  }

  const fetchPurchaseList = (params: string) => {
    axiosConnect(`/api/purchase/myList/${params}`, 'GET')
      .then((result) => {
        let resultData = result.data
        if (resultData.totalPages === 0) {
          resultData.totalPages = 1
        } else {
          if (resultData.numberOfElements < resultData.pageable.pageSize) {
            resultData.totalPages = resultData.totalPages - 1
          }
        }
        setPurchaseList(resultData)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const deletePurchase = (purchase: any) => {
    axiosConnect(`/api/purchase/${purchase.sq}`, 'DELETE')
      .then((result) => {
        getPurchaseList()
      })
      .catch((error) => {
        alert(error)
      })
  }

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
    setPageNo(1)
    setKeyword('')
    fetchPurchaseList(`${pageNo}`)
  }

  const openMemberShip = (purchase: any) => {
    const windowFeatures = 'width=800,height=1280,resizable,scrollbars=yes,toolbar=no'
    window.open(`/#/b2b/purchase/member-ship?file=${purchase.certificateOfApprovalTransferFileInfo}`, '_blank', windowFeatures)
  }

  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row mb-3">
          <div className="col-md-4">
            {/* <select className="form-control mt-2">
              <option>서비스 제목</option>
            </select> */}
          </div>
          <div className="col-md-8 form-inline justify-content-end">
            <div className="input-group mt-2">
              {/* <select className="form-control col-4 mr-1">
                <option>제목</option>
                <option>내용</option>
                <option>제목+내용</option>
                <option>작성자</option>
              </select> */}
              <input
                type="text"
                className="form-control col-12 mr-2"
                value={tempSearchKeyword}
                onChange={(e) => {
                  setTempSearchKeyword(e.target.value)
                }}
                placeholder={language['검색어를 입력해주세요.']}
                onKeyDown={handleKeyDown}
              />
              <a
                onClick={(e) => {
                  searchPurchaseList(tempSearchKeyword)
                }}
                className="btn btn-outline-dark"
              >
                <i className="bi bi-search"></i>
              </a>
            </div>
            <a
              onClick={() => {
                setTempSearchKeyword('')
                searchPurchaseList('')
              }}
              className="btn btn-primary ml-1 mt-2"
            >
              {language['전체보기']}
            </a>
            <a onClick={openModal} className="btn btn-success ml-1 mt-2">
              <i className="bi bi-file-earmark-excel"></i> {language['엑셀 업로드']}
            </a>
          </div>
        </div>

        <div className="boxwrap">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col">{language['작성일']}</th>
                <th scope="col">{language['제목']}</th>
                <th scope="col">{language['첨부파일명']}</th>
                <th scope="col">
                  {language['구매자']}
                  <small>({language['명']})</small>
                </th>
                <th scope="col">{language['승인']}</th>
                <th scope="col">{language['상태']}</th>
                <th scope="col">{language['가입증서']}</th>
              </tr>
            </thead>
            <tbody>
              {purchaseList.content &&
                purchaseList.content.map((purchase: any, index: number) => (
                  <tr>
                    <td>{DateUtil.toLocalDateString(purchase.createDatetime)}</td>
                    <td>
                      <Link to={`/b2b/service_purchase/${purchase.sq}`}>
                        <strong>{purchase.title}</strong>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/b2b/service_purchase/${purchase.sq}`}>{purchase.fileName}</Link>
                    </td>
                    <td>{purchase.requestCount}</td>
                    <td>{purchase.approvalCount}</td>
                    <td>{purchase.status}</td>
                    <td>
                      {purchase.status === '승인' ? (
                        <a onClick={() => openMemberShip(purchase)} className="btn btn-primary btn-sm">
                          {language['가입증서 보기']}
                        </a>
                      ) : purchase.requestCount === 0 && purchase.approvalCount == 0 ? (
                        <a onClick={() => deletePurchase(purchase)} className="btn btn-danger btn-sm">
                          {language['삭제']}
                        </a>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="text-center">
            <PagenationView
              currentPage={pageNo}
              totalPages={purchaseList.totalPages}
              updatePage={(index: number) => {
                setPageNo(index)
              }}
            />
          </div>
        </div>
        <B2BPurchaseUploadModel modalOpen={modalOpen} closeModal={closeModal} />
      </div>
    </>
  )
}

export default B2BServicePurchaseList
