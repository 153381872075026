function HyundaiKRView() {
  return (
    <>
      <h6>(필수) 개인(신용) 정보 처리동의</h6>
      <h6>개인(신용)정보의 수집 이용에 관한 사항</h6>
      개인신용정보의 수집 이용 목적
      <br />
      - 보험계약의 인수 심사 체결 유지 관리 (부활 및 갱신 포함)
      <br />
      - 보험금 등 지급 심사
      <br />
      - 순보험요율의 산출 검증
      <br />
      - 민원처리 및 분쟁대응
      <br />
      - 적부 및 사고조사 (보험사기 조사포함)
      <br />
      - 보험모집질서의 유지
      <br />
      - 금융거래 관련 업무 (금융거래 신청, 자동이체 등) 수집이용할 개인(신용) 정보의 내용
      <br />
      - 개인식별정보 (성명, 주민등록번호, 외국인등록번호, 여권번호, 주소, 성별, 직업, 전화번호, 전자우편주소)
      <br />
      - 금융거래 업무 (보험료 및 보험금 등 출수납 ) 관련 정보 개인(신용) 정보 보유 이용기간
      <br />
      - 수집 이용 동의일로부터 (금융)거래 종류 후 5년까지
      <br />
      <br />
      <h6>개인(신용)정보의 조회에 관한 사항</h6>
      개인신용정보의 조회목적
      <br />
      - 보험계약의 인수심사 체결 유지 관리 (부활 및 갱신 포함), 보험금 등 지급, 심사 보험사고조사 (보험사기조사 포함) 조회할 개인(신용) 정보
      <br />
      - 신용정보집중기관 (생명보험협회, 손해보험협회, 한국신용정보원) 및 보험요율 산출기관에서 수집 관리하는 보험계약정보, 보험금지급 관련 정보 (사고정보 포함), 피보험자의 질병 및 상해관련 정보 조회동의 유효기간 및 개인(신용)정보의 보유 이용 기간
      <br />
      - 수집 이용 동의일로부터 거래종류 후 5년까지 (단, 거래종료 후 5년이 경과한 후에는 보험금 지급, 금융사고조사, 보험사기 방지 적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유 이용하며 별도 보관)
      <br />
      <br />
      <h6>(필수) 보험계약 체결을 위한 동의</h6>
      <h6>단체보험 규약동의</h6>
      제1조 (목적)
      <br />
      이 규약은 (주)프리미엄패스인터내셔널 회원들을 위하여 제공하는 서비스 이용에 관하여 회원들 간 협약사항을 그 목적으로 합니다.
      <br />
      제2조 (단체보험가입 청약 등)
      <br />
      1. (주)프리미엄패스인터내셔널은 회원들을 위하여 계약자로서 보험사와 단체보험계약을 체결할 수 있습니다.
      <br />
      2. (주)프리미엄패스인터내셔널이 계약자인 단체보험계약에 피보험자로 가입하기를 희망하는 회원은 소정의 절차에 따라 보험가입신청을 합니다.
      <br />
      3. 보험가입신청을 하고자 하는 회원은 보험사와 (주)프리미엄패스인터내셔널이 고지를 요구하는 사항에 대하여 성실히 답합니다.
      <br />
      <br />
      <h6>보험계약의 체결, 이행 등을 위한 개인(신용)정보 처리동의</h6>
      개인신용정보의 수집 이용에 관한 사항 개인정보보호법 및 신용정보의 이용 및 보호에 관한 법률에 따라 본 계약과 관련 하여 귀하의 개인(신용)정보를 다음과 같이 수집 이용 하고자 합니다.
      <br />
      이에 대하여 동의하십니까? <br />
      <br />
      개인 신용정보의 수집 이용 목적
      <br />
      - 보험계약의 인수 심사 체결 유지 관리 (부활 및 갱신 포함)
      <br />
      - 보험금 등 지급 심사
      <br />
      - 순보험요율의 산출 검증
      <br />
      - 민원처리 및 분쟁대응
      <br />
      - 적부 및 사고조사 (보험사기 조사포함)
      <br />
      - 보험모집질서의 유지
      <br />
      - 금융거래 관련 업무 (금융거래 신청, 자동이체 등)
      <br />
      <br />
      수집이용할 개인(신용) 정보의 내용
      <br />
      - 개인식별정보 (성명, 주민등록번호, 외국인등록번호, 주소, 성별, 직업, 전화번호, 전자우편주소)
      <br />
      - 금융거래 업무 (보험료 및 보험금 등 출수납 ) 관련 정보 <br />
      <br />
      개인(신용) 정보 보유 이용기간
      <br />- 수집 이용 동의일로부터 (금융)거래 종류 후 5년까지
    </>
  )
}

export default HyundaiKRView
