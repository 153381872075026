import { configureStore } from '@reduxjs/toolkit'
import languageReducer from './language'
import userReducer from './user'
import boardCategoryReducer from './boardCategory'
import countryReducer from './country'
import possibleChatReducer from './possibleChat'

export default configureStore({
  reducer: {
    user: userReducer,
    language: languageReducer,
    boardCategory: boardCategoryReducer,
    country: countryReducer,
    possibleChat: possibleChatReducer,
  },
})
