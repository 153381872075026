function HyundaiJPView() {
  return (
    <>
      <h6>（必須）個人（信用）情報処理のための同意</h6>
      <h6>個人（信用）情報の収集利用に関する事項</h6>
      個人信用情報の収集利用目的
      <br />
      - 保険契約の引受審査締結の維持管理(復活及び更新を含む) <br />
      - 保険金等の支払審査 <br />
      - 純保険料率の算出検証 <br />
      - 苦情処理及び紛争対応 <br />
      - 適否および事故調査(保険詐欺調査を含む) <br />
      - 保険募集秩序の維持 <br />
      - 金融取引関連業務(金融取引申請、自動振替など) <br />
      <br />
      収集利用する個人（信用）情報の内容
      <br />
      - 個人識別情報(氏名、住民登録番号、外国人登録番号、パスポート番号、住所、性別、職業、電話番号、電子メールアドレス)
      <br />
      - 金融取引業務（保険料及び保険金等の出納・受納）関連情報
      <br />
      <br />
      個人（信用）情報保有及び利用期間
      <br />
      - 収集利用同意日から(金融)取引終了後 5年まで
      <br />
      <h6>個人（信用）情報の照会に関する事項</h6>
      個人信用情報の照会目的
      <br />
      - 保険契約の引受審査の締結維持管理(復活及び更新を含む)、保険金等の支給、審査
      <br />
      保険事故調査(保険詐欺調査を含む)
      <br />
      <br />
      照会する個人（信用）情報
      <br />
      - 信用情報集中機関(生命保険協会、損害保険協会、韓国信用情報院)及び保険料率 算出機関が収集管理する保険契約情報、保険金支給に関する情報(事故情報を含む) 被保険者の疾病及び傷害に関する情報
      <br />
      <br />
      照会同意の有効期限及び個人(信用)情報の保有及び利用期間
      <br />
      - 収集利用同意日から取引種類後5年まで(ただし、取引終了後5年が経過した後は、保険金の支払い、金融事故調査、保険詐欺防止摘発、苦情処理、法令上の 義務付けのための場合に限り保有及び利用し、別途保管)
      <br />
      <br />
      <h6>（必須）保険契約締結のための同意</h6>
      <h6>団体保険に関する規約同意</h6>
      第一条（目的）
      <br />
      この規約は、(株)プレミアムパスインターナショナル会員のために提供するサービス利用に関して、会員間協約事項をその目的とします。
      <br />
      <br />
      第二条（団体保険加入の申込み等）
      <br />
      1. (株)プレミアムパスインターナショナルは会員のために契約者として保険会社と団体保険契約を締結することができます。
      <br />
      2. (株)プレミアムパスインターナショナルが契約者である団体保険契約に被保険者として加入することを希望する会員は所定の手続きに従って保険加入を申請します。
      <br />
      3. 保険加入申請をしようとする会員は保険会社と(株)プレミアムパスインターナショナルが告知を要求ずる事項に対して誠実に答えます。
      <br />
      <br />
      <h6> 保険契約の締結、履行等のための個人(信用)情報処理に関しての同意 </h6>
      保険契約の締結、履行等のための個人(信用)情報処理に関しての同意個人信用情報の収集利用に関する事項 個人情報保護法及び信用情報の利用及び保護に関する法律に基づき、本契約と関連して お客様の個人(信用)情報を次のように収集·利用したいと思います。 以上について同意いたしますか？
      <br />
      <br />
      個人信用情報の収集利用目的
      <br />
      - 保険契約の引受審査締結の維持管理(復活及び更新を含む)
      <br />
      - 保険金等の支払審査
      <br />
      - 純保険料率の算出検証
      <br />
      - 苦情処理及び紛争対応
      <br />
      - 適否および事故調査(保険詐欺調査を含む)
      <br />
      - 保険募集秩序の維持
      <br />
      - 金融取引関連業務(金融取引申請、自動振替など)
      <br />
      <br />
      収集利用する個人（信用）情報の内容
      <br />
      - 個人識別情報(氏名、住民登録番号、外国人登録番号、パスポート番号、住所、性別、職業、電話番号、電子メールアドレス)
      <br />
      - 金融取引業務（保険料及び保険金等の出納・受納）関連情報
      <br />
      <br />
      個人（信用）情報保有及び利用期間
      <br />
      - 収集利用同意日から(金融)取引終了後 5年まで
      <br />
    </>
  )
}

export default HyundaiJPView
