import { useSelector } from 'react-redux'

function MainInforView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="section">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-5">
            <p className="text-center">
              <img src="/resources/home/img/main-intro.png" alt="" className="img-fluid" />
            </p>
          </div>
          <div className="col-12 col-md-7 m-center">
            <h2 className="text-primary">
              {language['대한민국을 여행할 때는']} <br />
              {language['언제나 이지트래블케어']}
            </h2>
            <h5>
              {language['안심보험으로 즐겁고 안전한 관광을']} <br className="d-block d-md-none" />
              {language['하실 수 있습니다.']}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainInforView
