import { useSelector } from 'react-redux'

function SpecialServiceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="page-header">
              <h2>{language['특별 혜택']}</h2>
            </div>
            <h5>
              {language['20여 가지의']}
              <br />
              {language['멤버십 특별혜택 제공']}
            </h5>
            <ul className="list">
              <li>{language['컨시어지 데스크 무료이용']}</li>
              <li>{language['공항 픽업 및 의전 서비스 할인']}</li>
              <li>{language['교통 예약 및 발권']}</li>
            </ul>
          </div>
          <div className="col-12 col-md-5">
            <img src="/resources/home/img/service_special.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                <img src="/resources/home/img/svg/service_special.svg" alt="" />
              </p>
              <h3 className="text-center">{language['INFORMATION']}</h3>
              <div className="cardline mt-5">
                <div className="card-body">
                  <h5 className="card-title">{language['멤버십 특별혜택 서비스']}</h5>
                  <ul className="list secondary">
                    <li>{language['인천공항 터미널1 & 터미널2 컨시어지 데스크 무료이용']}</li>
                    <li>{language['공항 픽업/샌딩 서비스 20% 할인']}</li>
                    <li>{language['공항 의전 및 영접 서비스 30% 할인']}</li>
                    <li>{language['수하물 딜리버리 서비스 20% 할인']}</li>
                    <li>{language['대중교통 예약 및 발권 등 탑승 지원 서비스']}</li>
                    <li>{language['항공, KTX, 고속버스 등 교통 예약 및 발권']}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpecialServiceView
