import { createSlice } from '@reduxjs/toolkit'
import axiosConnect from '../network/http-request'

const initialState = {
  value: [],
}

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.value = action.payload
    },
  },
})

export const fetchCountry = () => (dispatch) => {
  let language = 'en'
  if (localStorage.getItem('language') === 'kr') {
    language = 'ko'
  }
  axiosConnect(`/rest/member/getCountryList?lang=${language}`, 'GET')
    .then((result) => {
      dispatch(setCountry(result.data))
    })
    .catch((error) => {
      alert(error)
    })
}

export const { setCountry } = countrySlice.actions
export default countrySlice.reducer
