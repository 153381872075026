import { useSelector } from 'react-redux'

function PurchaseHeaderView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div id="carousel-visual" className="carousel slide" data-ride="carousel">
      <div className="carouselwrap">
        <div className="carousel-inner visual" role="listbox">
          <div className="item active">
            <div style={{ backgroundImage: `url(/resources/home/img/sub2.jpg)` }} className="carousel-bg carousel-bg-sub"></div>
            <div className="carousel-caption carousel-caption-sub">
              <div className="container">
                <h3>{language['서비스 구매']}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseHeaderView
