import { useSelector } from 'react-redux'
import EmailCollectionKRView from './EmailCollectionKRView'
import EmailCollectionENView from './EmailCollectionENView'
import EmailCollectionJPView from './EmailCollectionJPView'
import EmailCollectionCNView from './EmailCollectionCNView'

function EmailCollectionView() {
  const language = useSelector((state: any) => state.language.value)
  const emailCollectionView = () => {
    if (language.code === 'en') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>Rejection of Unauthorized Email Collection</h2>
                </div>
              </div>
            </div>
          </div>
          <EmailCollectionENView />
        </>
      )
    } else if (language.code === 'jp') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>電子メールの無断収集の拒否</h2>
                </div>
              </div>
            </div>
          </div>
          <EmailCollectionJPView />
        </>
      )
    } else if (language.code === 'cn') {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>拒绝擅自收集电子邮件</h2>
                </div>
              </div>
            </div>
          </div>
          <EmailCollectionCNView />
        </>
      )
    } else {
      return (
        <>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2>이메일무단수집거부</h2>
                </div>
              </div>
            </div>
          </div>
          <EmailCollectionKRView />
        </>
      )
    }
  }
  return emailCollectionView()
}

export default EmailCollectionView
