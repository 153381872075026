import MainHeaderView from './view/MainHeaderView'
import MainConciergeView from './view/MainConciergeView'
import MainInforView from './view/MainInforView'
import MainOurServiceView from './view/MainOurServiceView'
import MainPriceView from './view/MainPriceView'
import MainServiceView from './view/MainServiceView'
import MainSoSCallView from './view/MainSoSCallView'

function Main() {
  return (
    <div>
      <MainHeaderView />
      <div className="divide"></div>
      <MainOurServiceView />
      <MainInforView />
      <MainServiceView />
      <div className="divide"></div>
      <MainPriceView />
      <div className="divide"></div>
      <MainSoSCallView />
      <div className="divide"></div>
      <MainConciergeView />
    </div>
  )
}

export default Main
