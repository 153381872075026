function TermsKRView() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
              <h5>제1조(목적)</h5>
              이 약관은 프리미엄패스인터내셔널(이하 ”회사”라 함)이 운영하는 이지트래블케어(easytravelcare)인터넷사이트(이하 “사이트”라 한다)를 통해 제공하는 전자상거래 서비스 (이하 서비스라 함)를 이용함에 있어 회원의 권리와 의무 및 책임사항을 규정하는 것을 목적으로 합니다. 이 약관은 PC 통신, 스마트폰(안드로이드폰, 아이폰 등) 앱, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용됩니다.
              <br />
              <br />
              <h5>제 2 조 (용어의 정의)</h5>
              약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <br />
              1. “사이트"란 회사에 회원으로 등록한 이용자가 다양한 정보와 서비스를 제공받을 수 있도록 회사가 제작, 운영하는 인터넷 웹사이트를 의미합니다. <br />
              현재 "회사"가 운영하는 "사이트"(2023. 01현재)는 아래와 같습니다.
              <br />
              * www.easytravelcare.co.kr
              <br />
              - 추후 "회사"에서 공지하고 제공하는 기타 웹사이트
              <br />
              <br />
              2. "판매자”란 상품 또는 서비스의 원활한 판매를 위하여 “회사”로부터 판매대행서비스를 제공받는 자를 말한다. 단 오픈마켓 서비스의 경우에는 “회원”이 구매한 상품 및 서비스 등의 거래 정보에 대한 직접적인 책임을 지는 자를 말합니다.
              <br />
              <br />
              3. "회원"이라 함은 “사이트”(이하 회사)에 개인정보를 제공하여 회원등록을 한 사용자로서, 회원등록 시 설정한 아이디(ID)로 “사이트”에 자유롭게 접속하여 회사가 제공하는 정보를 지속적으로 제공받을 수 있거나, “회사”의 서비스를 계속적으로 이용할 수 있는 사용자를 말합니다.
              <br />
              <br />
              4. "상품” 등이라 함은 “회사”가 “사이트”를 통하여 회원에게 판매하는 서비스를 말합니다.
              <br />
              <br />
              5. "아이디(ID)"라 함은 “회원”의 식별과 서비스 이용을 위하여 “회원”이 선정하고 회사가 승인하여 등록된 문자 또는 숫자의 조합을 말합니다.
              <br />
              <br />
              6. "비밀번호(Password)"라 함은 “회원”의 동일성 확인과 “회원”의 권익 및 비밀보호를 위하여 “회원” 스스로가 설정하여 “사이트”에 등록한 문자와 숫자의 조합을 말합니다.
              <br />
              <br />
              7. 예약 확인 바우처 : 상품과 서비스 (이하 "상품 등"이라 함)을 구입한 가격으로 이용할 수 있는 디지털 증권을 말합니다.
              <br />
              <br />
              8. 게시판: "회원"이 "서비스"를 이용함에 있어 "사이트"상에 게시한 부호, 문자, 음성 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
              <br />
              <br />
              9. “채팅"이라 함은 상품 등을 구매 후 긴급 서비스 상담을 원할 때 사용할 수 있는 채팅 서비스를 말합니다.
              <br />
              <br />
              10. 이 약관에서 정의되지 않은 용어는 관련법령이 전하는 바에 따릅니다.
              <br />
              <br />
              <h5>제 3 조 (약관 등의 명시와 설명 및 개정)</h5>
              1. “회사”는 이 약관의 내용과 회사의 “상호”, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화번호, 팩스번호, 전자우편주소 등), 통신 판매업 신고 번호, 개인정보관리책임자 등과 함께 회원이 쉽게 확인할 수 있도록 “사이트”의 초기 “서비스” 화면(전면)에 게시한다. 다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있습니다.
              <br />
              <br />
              2. “회사”는 약관의 규제에 관한 법률, 전자상거래 등의 소비자보호에 관한 법률, 전자문서 및 전자거래 기본법, 전자 서명법, 정보통신망 이용촉진 등에 관한 법률, 소비자기본법, 전자금융거래법 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              <br />
              <br />
              3. “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “사이트”의 공지사항 또는 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지한다. 단, “회원”에게 중대한 영향을 주는 불리한 내용으로 변경하는 경우에는 30일 전부터 공지합니다.
              <br />
              <br />
              4. 전 항에 의해 개정약관은 적용일자 이후에 체결된 계약에만 적용되고, 법령에 특별한 규정 또는 기타 부득이한 사유가 없는 한 개정약관 적용일자 이전으로 소급하여 적용되지 아니합니다.
              <br />
              <br />
              5. 전 항에 따라 공지된 적용일자 이후에 회원이 “회사”의 “서비스”를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 본다. 개정된 약관에 동의하지 않은 “회원”은 언제든 자유롭게 “서비스” 이용계약을 해지할 수 있습니다.
              <br />
              <br />
              6. 이 약관에 명시하지 않은 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침과 관련 법령의 규정과 일반 상관례에 따릅니다.
              <br />
              <br />
              <h5>제 4 조 (개별 서비스이용 약관 및 이용 조건)</h5>
              1. “회사”는 제공하는 “서비스” 내의 “개별 서비스”에 대한 별도의 이용 약관 및 이용조건을 각 “서비스” 별로 둘 수 있으며 “개별 서비스”에서 별도로 적용되는 약관에 대한 동의는 “해당 서비스”를 최초로 이용할 경우 별도의 동의절차를 거친 후 이용하여 “서비스” 내용을 동의한 것으로 간주되며, 이 경우 “개별 서비스”에 대한 이용약관 등은 본 약관에 우선합니다.
              <br />
              <br />
              2. 이지트래블케어 서비스는 당일 18:00 이전 구매시, 구매 시각 기준 3시간 이후부터 서비스 제공하며 당일 18:00 이후 구매 시, 다음날 오후12:00 부터 서비스 제공을 기준으로 합니다.
              <br />
              <br />
              <h5>제 5 조 (서비스 제공의 중지)</h5>
              1. "회사"는 다음 각 호에 해당하는 경우 "서비스" 제공을 중지할 수 있습니다.
              <br />
              1） "서비스" 이용 설비의 보수점검, 교체 및 고장 등 공사로 인한 부득이한 경우
              <br />
              2） 전기통신사업법에 규정된 기간통신사업자가 전기통신 "서비스"을 중지했을 경우
              <br />
              3） 기타 불가항력적 사유가 있는 경우
              <br />
              <br />
              2. “회사”는 국가비상사태, 정전, “서비스” 설비의 장애 또는 “서비스” 이용의 폭주 등으로 정상적인 “서비스” 이용에 지장이 있는 때에는 “서비스”의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
              <br />
              <br />
              <h5>제 6 조 (이용계약의 성립)</h5>
              1. 이용계약은 “회원”의 약관 동의, 이용 신청에 대한 “회사”의 승인 후 성립합니다.
              <br />
              2. 이용계약의 성립은 “회사”가 가입완료 표시를 신청절차 상에서 승인 또는 표시한 시점으로 합니다.
              <br />
              <br />
              <h5>제 7 조 (이용신청 및 승낙)</h5>
              1. “회사”는 다음 각 호에 해당하는 이용신청에 대하여는 승인을 거부하거나 유보 및 사후 이용계약을 해지할 수 있습니다.
              <br />
              1） 이미 가입된 “회원”의 성명 및 전자우편주소가 동일한 경우
              <br />
              2） 타인의 명의를 도용하거나, 허위기재, 기재 누락 등이 있는 경우
              <br />
              3） 등록내용에 허위의 정보를 기재하거나, 오기가 있는 경우
              <br />
              4） 이용 신청자가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우
              <br />
              5） “회사”에 의해 이용 자격 상실 후 3개월이 경과 후 “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로 함<br />
              6） 부정한 용도 또는 영리를 추구할 목적으로 “서비스”를 이용하고자 하는 경우(단, “회사”가 승인한 범위 내에서의 서비스 이용은 예외로 한다.)
              <br />
              7） “서비스” 운영에 따른 관련 설비의 여유가 없거나, 기술상 또는 업무상의 지장 또는 문제가 있는 경우 다만, 그 사유가 해소될 때까지 승낙을 유보할 수 있다.
              <br />
              8） 관련 법령, 미풍 양속 혹은 사회의 안녕질서 및 기타 제반 사항을 위반하여 신청하거나 회원의 귀책사유로 인하여 승인이 불가능한 경우
              <br />
              9） 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 “회사”가 합리적인 판단에 의하여 필요하다고 인정하는 경우
              <br />
              <br />
              3. 제1항에 따른 이용 신청에 있어 “회사”는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
              <br />
              <br />
              <h5>제 8 조 (서비스 이용시간)</h5>
              “서비스”의 이용은 “회사”의 업무 또는 기술상 특별한 지장이 없는 경우 365일 1일 24시간을 원칙으로 합니다. 단, 정기 점검 등의 사유로 “회사”가 공지한 날 또는 시간은 제외됩니다.
              <br />
              <br />
              <h5>제 9 조 (회원의 아이디 및 비밀번호에 대한 의무)</h5>
              1. “아이디와 “비밀번호”에 관한 관리책임은 전적으로 “회원”에게 있으며, 이를 소홀히 하여 발생한 모든 민, 형사상의 책임은 “회원” 자신에게 있습니다.
              <br />
              2. “회원”은 자신의 “아이디”및 “비밀번호”를 제 3자에게 대여 및 이용하게 해서는 안된다.
              <br />
              3. “회원”은 자신의 “아이디”및 “비밀번호”를 도난 또는 제 3자가 사용하고 있음을 인지한 경우 그 즉시 “회사”에 통보하고 “회사”의 조치가 있는 경우에는 그에 따라야 합니다.
              <br />
              4. “회원”이 전 항에 따른 통지를 하지 않거나 “회사”의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 전적으로 “회원”에게 있습니다.
              <br />
              <br />
              <h5>제 10 조 (이용계약의 해지 및 종료)</h5>
              1. “회원”의 해지
              <br />
              1） “회원”은 언제든지 “회사”에 연락하여 해지의사를 통지함으로써 이용계약을 해지(회원탈퇴를 요청)할 수 있으며, “회원”의 계정 등록 자료의 삭제를 요청할 수 있습니다.
              <br />
              2） 이용계약은 “회원”의 해지 의사가 “회사”에 도달한 때에 종료됩니다.
              <br />
              3） 전 항에 따라 해지를 한 “회원”(탈퇴한 회원)은 “회사”가 정한 규정 약관에 따라 회원가입절차를 거처 회원으로 다시 가입할 수 있으나 해지(탈퇴)이전 회원 가입시 적용되었던 정보는 소멸되며 환불 또한 적용되지 않습니다
              <br />
              <br />
              2. 회사의 해지 <br />
              1） “회사”는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수 있고, 해당 “회원”에 대한 보유 정보는 개인정보취급방침에 따릅니다.
              <br />
              가. 제7조 제1항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우
              <br />
              나. “회원”이 “회사” 또는 다른 “회원” 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 선량한 풍속 기타 사회질서, 대한민국 법령에 위배되는 행위를 한 경우
              <br />
              다.”회사” 가 판매하는 “상품” 또는 “서비스”에 대한 확인되지 않는 사실과 내용으로 불신을 조장하는 행위를 한 경우 <br />
              라. 거래 질서를 방해할 목적으로 중복 구매 등 사이트의 원활한 진행을 방해하는 행위를 하는 시도한 경우
              <br />
              마. 기타 “회사”가 합리적인 판단에 의한 “회원”이 이 약관에 위배되는 행위를 하는 경우
              <br />
              바. “회사”는 전자우편, 전화, 팩스 기타의 방법을 통하여 해지의사를 밝혀 회원에게 해지 사유를 통지합니다.
              <br />
              다만 회사는 해당 회원에게 사전에 해지 사유에 대한 의견진술의 기회를 부여합니다.
              <br />
              <br />
              4. 본 조항에 따라 이용계약이 종료되는 경우 “회원”은 서비스를 이용할 권한이 없어지며, 회원의 개인정보 및 기록 등은 전부 삭제되며, 사전 구매한 상품 등의 환불 등의 처리에 관하여는 회사의 환불 규정에 의거합니다.
              <br />
              <br />
              5. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 “회원”이 책임을 부담하여야 하고 “회사”는 일체의 책임을 지지 않습니다.
              <br />
              <br />
              <h5>제 11 조 (회원의 의무)</h5>
              1. “회원”은 관련법령, 본 약관규정, 이용안내 등 “회사”가 고지하는 내용을 준수하여야 하며, 기타 “회사” 업무에 방해되는 행위를 해서는 안됩니다.
              <br />
              <br />
              2. “회원”은 “서비스” 이용과 관련하여 다음 각 호의 행위를 해서는 안됩니다.
              <br />
              1) "서비스" 신청 또는 변경 시 허위내용의 등록
              <br />
              2) 타인의 정보 도용 등<br />
              3) “회사"가 게시한 정보의 허가 받지 않은 변경
              <br />
              4) "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
              <br />
              5) "회사" 및 기타 제3자의 저작권, 초상권 등 지식 재산권에 대한 침해
              <br />
              6) "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              <br />
              7) 외설 또는 폭력적인 메시지, 동영상, 음성 기타 공공질서, 미풍양속에 반하는 정보를 "사이트"에 공개 또는 게시하는 행위
              <br />
              8) 기타 관련법령이나 "회사"에서 정한 규정에 위배되는 행위
              <br />
              <br />
              <h5>제 12 조 (회사의 의무)</h5>
              1. “회사”는 법령과 이 약관 금지하거나 공서 양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여야 합니다.
              <br />
              <br />
              2. “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 “회원”의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수한다.
              <br />
              <br />
              <h5>제 13 조 (구매신청 및 개인정보 제공 동의 등)</h5>
              1. "회원"은 “사이트” 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "회사"는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <br />
              1). 상품 등의 검색 및 선택
              <br />
              2). 상품 이용에 관한 예약 정보의 입력(날짜 등)
              <br />
              3). 예약자와 서비스를 받는 사람의 성명, 국적, 여권번호, 주민등록번호, 전자우편주소(또는 이동전화번호) 등의 입력
              <br />
              4). 각 상품별 구성, 포함 사항 및 약관 내용 제한되는 서비스, 등의 비용부담과 관련한 내용에 대한 확인
              <br />
              5). 이 약관에 동의하고 위 4호의 사항을 확인하거나 거부하는 표시(예:마우스 클릭 등)
              <br />
              6). 상품 등의 구매 신청 및 이에 관한 확인 또는 "회사(사이트)"의 확인에 대한 동의
              <br />
              7). 결제 방법의 선택
              <br />
              <br />
              2. 회사는 회원의 구매신청이 있는 경우 회원에게 구매확인통지를 하며, 이 통지가 도달한 시점에 구매계약이 성립합니다.
              <br />
              <br />
              3.”회사”가 제 3자에게 구매자 개인정보를 제공, 제공할 필요가 있는 경우 제공되는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보의 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다.(동의를 받은 사항이 변경되는 경우에도 같다.)
              <br />
              <br />
              4.”회사”가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 개인정보 취급 위탁을 받는 자, 개인정보 취급 위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의 증진과 관련된 경우에는 정보 통신망 이용 촉진 및 정보보호 등에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지 절차와 동의절차를 거치지 않아도 됩니다.
              <br />
              <br />
              <h5>제 14조 (계약의 성립)</h5>
              1. “회사”와 “회원”은 각 구매 상품의 예약 확정까지 걸리는 시간에 대해 각 상품 페이지의 기재 내용을 준수하고 것에 동의합니다. <br /> <br />
              2. "회사"는 제13조와 같은 "회원"의 구매 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. <br />
              1) 신청 내용에 허위, 기재 누락, 오기가 있는 경우
              <br />
              2) 기타 구매 신청에 승낙하는 것이 "회사"의 기술 상 현저히 지장이 있다고 판단하는 경우와 정보 오기재, 정보 누락, 시스템 오류, 품절 등의 사유로 구매 신청한 “상품”과 “서비스” 제공이 불가한 경우 단, 이 경우 “회사”는 “회원”에게 이를 사후 통지하고 필요한 조치를 이행하여야 함
              <br />
              3) 제 3자의 아이디, 명의 또는 결제 수단을 도용하여 구매 신청하는 경우
              <br />
              4) 재판매 목적 또는 "상품 등"을 구매신청 정황 상 부당한 목적으로 구매하였음이 의심되는 경우
              <br />
              5) 구매의사 없이 상품의 구매와 반품을 무분별하게 반복하여 다른 이용자의 구매 기회를 박탈하는 경우
              <br />
              6) 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
              <br />
              7) 기타 이 약관에 위배되거나 악용하고자 하는 부당한 이용신청임이 확인된 경우 및 "회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우
              <br /> <br />
              3. “회사”의 승낙이 제19조 제1항에 따른 수신확인통지형태로 "회원"에게 도달한 시점에 계약이 성립한 것으로 봅니다.
              <br /> <br />
              4. "회사"의 승낙의 의사표시에는 "회원"의 구매 신청에 대한 확인 및 판매 가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
              <br /> <br />
              <h5>제 15 조 (결재 방법)</h5>
              1.“사이트”에서 구매한 상품 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
              <br />
              1) 신용카드
              <br />
              2) 각종 Pay
              <br />
              3) 기타 “회사”가 추가 지정하는 결재 수단 및 전자적 지급 방법에 의한 대금 지급 등<br />
              <br />
              2.”회원”이 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.
              <br />
              <br />
              3.”회사”는 “회원”이 구매대금 결재시 사용한 결제 수단에 대한 본인 정보 확인 또는 정당한 사용권한 유무에 대해 확인할 수 있으며, 이에 대한 확인이 완료전까지 거래를 중단하거나, 확인이 불가능한 경우 해당 거래는 취소 또는 환불 보류 등을 할 수 있다.
              <br />
              <br />
              4.”회사”는 “회원”이 구매한 상품의 구매증명서(현금영수증 등)를 요청하면 실구매액으로 발행합니다.
              <br />
              <br />
              <h5>제 16조 (계약의 성립)</h5>
              1.”회사”는 “회원”이 구매한 “사이트”의 상품의 상세한 내용을 SMS 또는 전자우편 등의 방식을 통해 발송합니다.
              <br />
              <br />
              2.”예약확인증 또는 바우처” 전송 작업은 공휴일과 “회사”의 근무시간과 휴무일 또는 처리과정의 상황에 따라 변경될 수 있으며, 이는 “사이트” 내 해당 콘텐츠에 명시된 내용을 따릅니다.
              <br />
              <br />
              <h5>제 17 조 (수신확인통지. 구매신청 변경 및 취소)</h5>
              1.”사이트”는 “회원”의 구매신청이 있는 경우 “회원”에게 수신 확인통지를 합니다.
              <br />
              <br />
              2.수신 확인 통지를 받은 “회원”은 의사표시의 불일치 등이 있는 경우에는 수신 확인 통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “사이트”는 예약확정 전에 “회원”의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제 20 조의 청약철회 등에 관한 규정에 따릅니다.
              <br />
              <br />
              <h5>제 18 조 (청약철회 등)</h5>
              1. "회사"의 구매취소 및 청약철회 규정은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령을 준수합니다.
              <br />
              <br />
              2. "회원"의 단순 변심에 의한 취소 및 환불일 경우 이의 처리에 발생하는 수수료는 "회원"이 부담합니다.
              <br />
              <br />
              3. "회원"은 "상품 등"의 내용이 표시∙광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 그 "상품 등"을 공급받은 날부터 30일 이내, 그 사실을 청약을 철회할 수 있습니다.
              <br />
              <br />
              4. 신용카드 또는 전자화폐로 결제한 "회원"의 경우 지체없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정지 또는 취소하도록 요청을 합니다. <br />
              <br />
              5. 구매 취소 시 기존에 결제하였던 내역을 취소하며 취소 시점에 따라 예약 취소 수수료를 재승인 한다. 이 경우 구매 취소 시점과 해당 카드사의 환불 처리기준에 따라 취소 금액의 환급 방법과 환급일은 다소 차이가 있을 수 있으며, 사용한 신용카드의 환불 정책은 신용카드회사에 직접 문의하여야 합니다.
              <br />
              <br />
              6. "회원"이 타인의 개인정보를 도용하여 본 약관에서 금지하는 부정한 방법으로 예약 후 부당한 이익 편취라고 의심되는 경우 "회사"는 "회원"의 티켓 구매를 취소처리 또는 제한할 수 있으며, "회원"이 충분한 소명 자료를 제출할 때까지 환불을 보류할 수 있습니다.
              <br />
              <br />
              7. 기타 이 약관 및 "사이트"의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자 피해보상 규정에서 정한 바에 따릅니다.
              <br />
              <br />
              <h5>제 19 조 (상품의 환불 특약)</h5>
              1.”회원”은 서비스 구매 후 다음 각 호에 해당하는 경우 환불 또는 교환을 요청할 수 없습니다.
              <br />
              1) 서비스 시작 후 단순 변심은 환불대상에서 제외되며 각 “판매자”의 판매 규정에 따릅니다.
              <br />
              <br />
              2.”회사”가 상품의 반품절차에 따라 환불하는 경우 “판매자” 또는 “보험업체”와 협의를 마친 것을 확인한 이후 환불이 이루어집니다.
              <br />
              <br />
              <h5>제 20조 (개인정보보호)</h5>
              1.”사이트”는 ‘회원”의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
              <br />
              <br />
              2.”사이트”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
              <br />
              <br />
              3.”사이트”는 “회원”의 개인정보를 수집, 이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
              <br />
              <br />
              4.”사이트”는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용, 제공단계에서 당해 “회원”에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
              <br />
              <br />
              5.”사이트”는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우
              <br />
              개인
              <br />
              정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 "회원"은 언제든지 이 동의를 철회할 수 있습니다
              <br />
              <br />
              6. "회원"은 언제든지 "회사"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. "회사"가 오류의 정정을 요구한 경우에는 "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. 단, 열람 및 오류 정정의 절차는 "회사"가 정한 바에 따릅니다.
              <br />
              <br />
              7. "회사"는 개인정보 보호를 위하여 "회원"의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 "회원"의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 "회원"의 손해에 대하여 모든 책임을 집니다.
              <br />
              <br />
              8. "회사" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
              <br />
              <br />
              9. "회사"는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정 해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 "회원"의 동의 거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 "회원"의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
              <br />
              <br />
              <h5>제 21조 (저작권의 귀속 및 이용제한)</h5>
              1.”사이트”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “사이트”에 귀속합니다.
              <br />
              <br />
              2.”회원”은 “사이트”를 이용함으로써 얻은 정보, 이미지, 데이터 중 “사이트”에게 지적재산권이 귀속된 정보를 “사이트”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
              <br />
              <br />
              3.”회원”은 “사이트”에서 구매한 상품의 데이터 이미지를 “사이트”의 사전 승낙없이 임의로 가공, 복제, 송신, 출발, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
              <br />
              <br />
              <h5>제 22조 (회원자격의 제한 또는 정지)</h5>
              1. “회원”은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원 탈퇴를 처리합니다.
              <br />
              <br />
              2. “회원”이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 즉시 제한 또는 정지시킬 수 있습니다.
              <br />
              <br />
              1) 회원 가입신청 또는 변경 시 허위 내용을 등록한 경우
              <br />
              2) “사이트”를 이용하여 구입한 상품 등의 대금, 기타 “사이트” 이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우
              <br />
              3) 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
              <br />
              4) “사이트”를 이용하여 법령, 이 약관이 금지하거나 공서 양속에 반하는 행위를 하는 경우
              <br />
              5) “사이트”의 건전한 운영을 해하거나 사이트의 업무를 방해하는 경우
              <br />
              가.”사이트”의 운영과 관련하여 근거 없는 사실과 허위의 사실을 적시하거나 유포하여 “회사”의 신뢰성을 해치는 경우
              <br />
              나.”사이트”의 이용 과정에서 “회사” 직원에게 폭언, 협박 또는 음란한 언행 등으로 사이트의 운영을 방해하는 경우
              <br />
              다. 상품 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소∙반품하여 회사의 업무를 방해하는 경우
              <br />
              라.”사이트”를 통해 구매 후 상품 등에 특별한 하자가 없는데도 불구하고 일부 사용 후 상습적인 취소∙전부 또는 일부 반품 등으로 “회사”의 업무를 방해하는 경우
              <br />
              마. “사이트”를 통해 구매한 상품 등에 특별한 하자가 없는데도 불구하고 개인적인 단순 변심, 근거 없는 불만족 등으로 “회사”의 업무를 방해하는 경우 <br />
              <br />
              <h5>제 23조(면책조항)</h5>
              1.”회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”을 제공할 수 없는 경우에는 “서비스” 제공에 대한 책임이 면제됩니다.
              <br />
              <br />
              2.”회사”는 “회원” 귀책사유로 인한 “서비스” 이용의 장애에 대해서는 책임을 지지 않습니다.
              <br />
              <br />
              3.”회사”는 “회원”이 게재한 정보, 자료 등 사실의 신뢰도와 정확성 등 내용에 대해서는 책임을 지지 않습니다.
              <br />
              <br />
              4.”회사”는 “회원”간 또는 “회원”과 제3자간 이루어진 “서비스”에 관하여는 어떠한 책임도 지지 않습니다.
              <br />
              <br />
              5.”회사”는 “회원”이 “서비스”을 이용하여 얻은 기대 수익의 상실과 “서비스”을 통하여 얻은 자료에 대한 손해에 대해서는 어떠한 책임도 지지 않습니다.
              <br />
              <br />
              <h5>제24조(분쟁해결)</h5>
              1.”사이트”는 “회원”이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 보상 처리 기구를 설치, 운영합니다.
              <br />
              <br />
              2.”사이트”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
              <br />
              <br />
              <h5>제25조 (재판권 및 준거법)</h5>
              1.”사이트”와 “회원” 간에 방생한 전자상거래 분쟁에 관한 소송은 제소 당시의 “회원”의 주소에 의하고 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 다만 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
              <br />
              <br />
              2.”사이트”와 “회원” 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
              <br />
              <br />
              부칙
              <br />
              1. 이 약관은 2021년 1월 1일부터 시행한다.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsKRView
