import { createGlobalStyle } from 'styled-components'

const FontCN = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&display=swap');

body {
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans SC", Roboto, "NSimsun", "MS PGothic", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Noto Sans SC', sans-serif !important;
}
.carousel-caption {
	font-family: 'Noto Sans SC', sans-serif !important;
}
.page-header h2 {
	font-family: 'Noto Sans SC', sans-serif !important;
}
`

export default FontCN
