import { useLocation } from 'react-router-dom'

function B2BPurchaseMemberShipView() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const pdfUrl = `/uploadFiles/${queryParams.get('file')}`

  return (
    <div>
      <iframe src={pdfUrl + '#toolbar=0;view=Fit'} style={{ display: 'block', width: '100vw', height: '100vh' }} />
    </div>
  )
}

export default B2BPurchaseMemberShipView
