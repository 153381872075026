import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { changeLanguage } from '../store/language'
import { axiosConnectJson } from '../network/http-request'
import { logout } from '../store/user'

function Navigation() {
  const navigate = useNavigate()

  const language = useSelector((state: any) => state.language.value)
  const loginUser = useSelector((state: any) => state.user.value)

  const dispatch = useDispatch()
  const isActiveLanguage = (code: string) => {
    return language.code === code ? 'active' : ''
  }

  const logoutApi = () => {
    axiosConnectJson('/api/main/logout', 'GET')
      .then(() => {
        $('body').removeClass('nav-expanded')
        dispatch(logout())
        navigate('/')
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <div>
      <div className="d-block d-md-none">
        <nav className="mslide">
          <div className="mtop">
            <ul className="mset">
              <li>
                <a
                  onClick={() => {
                    $('body').removeClass('nav-expanded')
                  }}
                  id="nav-close"
                  title={language['메뉴닫기']}
                >
                  <i className="bi bi-x bi-lg"></i>
                </a>
              </li>
            </ul>
            <ul className="mlogin">
              <li>
                <Link to="/" title="홈">
                  <i className="bi bi-house"></i>
                </Link>
              </li>
              {loginUser ? (
                <li>
                  <a id="nav-logout" onClick={(e) => logoutApi()} title={language['로그아웃']}>
                    <i className="bi bi-power"></i>
                  </a>
                </li>
              ) : (
                <li>
                  <Link to="/login">
                    <i className="bi bi-power"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {loginUser ? (
            <div className="minfowrap">
              <figure>
                <i className="bi bi-person"></i>
              </figure>
              <strong>
                {loginUser?.memberName} {language['님 반갑습니다.']}
              </strong>
              <small>
                <Link to="/my-page">{language['정보수정']}</Link>
              </small>
            </div>
          ) : null}
          <div className="accordion" id="accordionMCategory">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h2 className="mt-0 mb-0">
                  <button className="btn btn-block arrow collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {language['서비스 소개']}
                  </button>
                </h2>
              </div>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionMCategory">
                <ul>
                  <li>
                    <Link to="/information/medical">{language['의료 안심케어']}</Link>
                  </li>
                  <li>
                    <Link to="/information/airport">{language['공항 안심케어']}</Link>
                  </li>
                  <li>
                    <Link to="/information/travel">{language['트래블 안심케어']}</Link>
                  </li>
                  <li>
                    <Link to="/information/business">{language['비즈니스 안심케어']}</Link>
                  </li>
                  <li>
                    <Link to="/information/traveler">{language['여행자 안심케어']}</Link>
                  </li>
                  <li>
                    <Link to="/information/special">{language['특별 혜택']}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <Link to="/purchase/information" className="btn btn-block text-left">
                    {language['서비스 구매']}
                  </Link>
                </h2>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFour">
                <h2 className="mb-0">
                  <button className="btn btn-block arrow collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                    {language['고객센터']}
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionMCategory">
                <ul>
                  <li>
                    <Link to="/help/faq">{language['자주하는 질문']}</Link>
                  </li>
                  <li>
                    <Link to="/help/qna" className="btn btn-block text-left collapsed">
                      {language['문의']}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingNine">
                <h2 className="mb-0">
                  <button className="btn btn-block text-left arrow collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    {language['마이페이지']}
                  </button>
                </h2>
              </div>
              <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionMCategory">
                <ul>
                  <li>
                    <Link to="/my-page">{language['내 회원정보']}</Link>
                  </li>
                  <li>
                    <Link to="/my-page/purchase">{language['구매내역']}</Link>
                  </li>
                  <li>
                    <Link to="/my-page/withdrawal">{language['회원탈퇴']}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pt-4 pl-3 pr-3 pb-1">
            <div className="row">
              <div className="col-12">
                <Link to="/purchase/information" className="btn btn-secondary btn-round btn-block">
                  {language['서비스 구매']}
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center">
            <ul className="menu-btm">
              <li className={isActiveLanguage('kr')}>
                <a
                  onClick={(e) => {
                    dispatch(changeLanguage('kr'))
                  }}
                >
                  한국어
                </a>
              </li>
              <li className={isActiveLanguage('en')}>
                <a
                  onClick={(e) => {
                    dispatch(changeLanguage('en'))
                  }}
                >
                  English
                </a>
              </li>
              <li className={isActiveLanguage('cn')}>
                <a
                  onClick={(e) => {
                    dispatch(changeLanguage('cn'))
                  }}
                >
                  中文
                </a>
              </li>
              <li className={isActiveLanguage('jp')}>
                <a
                  onClick={(e) => {
                    dispatch(changeLanguage('jp'))
                  }}
                >
                  日本語
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="navbar fixed-top mnavbar">
          <a
            onClick={() => {
              navigate(-1)
            }}
            className="nav-back"
            title="이전화면"
          >
            <i className="bi bi-arrow-left"></i>
          </a>
          <Link to="/" className="navbar-brand">
            <img src="/resources/home/img/svg/logo-w.svg" alt="도도인터내셔널" />
          </Link>
          <a
            className="nav-expander"
            onClick={() => {
              $('body').toggleClass('nav-expanded')
            }}
            title="메뉴보기"
          >
            <i className="bi bi-list"></i>
          </a>
        </nav>
      </div>

      <nav className="navbar navbar-dark navbar-inverse fixed-top navbar-fixed-top navbar-expanded d-none d-md-block">
        <div className="navbar justify-content-end navbar-gnb">
          <div className="container d-flex justify-content-end">
            <ul className="gnb-right">
              {loginUser ? (
                <>
                  <li className="dropdown">
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false">
                      <i className="bi bi-person mr-1"></i>
                      {language['마이페이지']}
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/my-page">
                        {language['내 회원정보']}
                      </Link>
                      <Link className="dropdown-item" to="/my-page/purchase">
                        {language['구매내역']}
                      </Link>
                      <a className="dropdown-item" onClick={(e) => logoutApi()}>
                        {language['로그아웃']}
                      </a>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/login">{language['로그인']}</Link>
                  </li>
                  <li>
                    <Link to="/sign-up">{language['회원가입']}</Link>
                  </li>
                </>
              )}
              <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false">
                  <i className="bi bi-globe2 mr-1"></i>
                  {language['언어']}
                </a>
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      dispatch(changeLanguage('kr'))
                    }}
                  >
                    한국어
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      dispatch(changeLanguage('en'))
                    }}
                  >
                    English
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      dispatch(changeLanguage('cn'))
                    }}
                  >
                    中文
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      dispatch(changeLanguage('jp'))
                    }}
                  >
                    日本語
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="container d-flex justify-content-end">
          <nav className="navbar navbar-expand-sm">
            <Link to="/" className="navbar-brand">
              <img src="/resources/home/img/svg/logo-w.svg" alt="트래블데이" />
            </Link>
            <ul className="navbar-nav ml-auto my-2 navbar-nav-scroll">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  {language['서비스 소개']}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/information/medical">
                      {language['의료 안심케어']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/information/airport">
                      {language['공항 안심케어']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/information/travel">
                      {language['트래블 안심케어']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/information/business">
                      {language['비즈니스 안심케어']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/information/traveler">
                      {language['여행자 안심케어']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/information/special">
                      {language['특별 혜택']}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/purchase/information" className="nav-link">
                  {language['서비스 구매']}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  {language['고객센터']}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/help/faq">
                      {language['자주하는 질문']}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/help/qna">
                      {language['문의']}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    </div>
  )
}

export default Navigation
