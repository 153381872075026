import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

function QnADeleteModal(props: any) {
  const language = useSelector((state: any) => state.language.value)
  const { open, close, deleteQnA } = props

  return (
    <Modal show={open} onHide={close} centered>
      <Modal.Header>
        <button type="button" className="close" onClick={close}>
          <img src="/resources/home/img/svg/icon-close.svg" alt="" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="font-weight-bold text-center">
          {language['게시글을 삭제하시겠습니까?']}
          <br />
          {language['삭제하신 후에는 복구가 되지 않습니다.']}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={deleteQnA}>
          {language['삭제']}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default QnADeleteModal
