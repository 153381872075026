import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axiosConnect from '../../../network/http-request'
import AlertModal from '../../common/AlertModal'
import { useSelector } from 'react-redux'

function SOSCallChatRoomList() {
  const navigate = useNavigate()
  const loginUser = useSelector((state: any) => state.user.value)
  const language = useSelector((state: any) => state.language.value)
  const [roomList, setRoomList] = useState<any[]>([])

  useEffect(() => {
    getChatRoomList()
  }, [])

  function getChatRoomList() {
    axiosConnect(`/rest/chat/api/chatList/${loginUser.memberSid}`, 'GET')
      .then((result) => {
        setRoomList(result.data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  function deleteChatRoon() {
    axiosConnect('/rest/chat/deleteChat', 'POST', { chatRoomId: deleteRoomId })
      .then((result) => {
        getChatRoomList()
        closeModal()
      })
      .catch((error) => {
        alert(error)
      })
  }

  function enterChatRoom(room: any) {
    navigate(`/sos/chat/room/${room?.chatRoomId ?? 'new'}`, {
      state: {
        roomId: room?.chatRoomId ?? '',
        roomName: room?.chatRoomName ?? '',
      },
    })
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [deleteRoomId, setDeletRoomId] = useState('')
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  function showDeleteModal(room: any) {
    setDeletRoomId(room.chatRoomId)
    openModal()
  }

  return (
    <>
      <div>
        <nav className="navbar fixed-top mnavbar navchat">
          <Link to="/" className="nav-chat" title="이지트래블케어">
            <img src="/resources/home/img/svg/logo-w.svg" alt="이지트래블케어" />
          </Link>
          <a className="navbar-brand" href="#none">
            <span className="chat-info">{language['수십 분 내에 응답합니다.']}</span>
          </a>
          <a
            onClick={() => {
              window.close()
            }}
            className="nav-expander"
            title="메뉴보기"
          >
            <i className="bi bi-x bi-lg"></i>
          </a>
        </nav>
      </div>

      <div className="chatwrap">
        <h1 className="text-primary text-center">{language['WELCOME!']}</h1>
        <h5 className="text-center">{language['How can I Help today?']}</h5>
        <p className="text-center mt-5">
          <a onClick={() => enterChatRoom(null)} className="btn btn-primary">
            <i className="bi bi-chat-text-fill mr-2"></i>
            {language['New Chat']}
          </a>
        </p>
        <h6 className="text-center">{language['운영시간: 00:00 ~ 24:00']}</h6>
        <p className="clearfix mt-5"></p>

        {roomList.map((room: any, index: number) => {
          return (
            <div className="container" key={`chat_list_${index}`}>
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="row-talk-col">
                    <div className="col">
                      <a onClick={() => enterChatRoom(room)}>
                        <figure>
                          <img src="/resources/home/img/svg/soschat.svg" alt="soschat" />
                        </figure>
                      </a>
                    </div>
                    <div className="col">
                      <a onClick={() => enterChatRoom(room)}>
                        <h5>{room.chatRoomName}</h5>
                        <p>
                          <small className="grey">{room.chatLastTime}</small>
                        </p>
                      </a>
                    </div>
                    <div className="col">
                      <a onClick={() => showDeleteModal(room)}>
                        <i className="bi bi-trash"></i>
                      </a>
                    </div>
                  </div>
                  {roomList.length - 1 === index ? <hr className="mt-0" /> : null}
                </div>
              </div>
            </div>
          )
        })}

        <p className="chatbtm">
          <img src="/resources/home/img/svg/line-korea.svg" alt="" className="img-fluid" />
        </p>

        <AlertModal
          open={modalOpen}
          close={closeModal}
          icon="/resources/home/img/svg/icon-close.svg"
          body={
            <div className="modal-body d-flex justify-content-center align-items-center">
              <p className="font-weight-bold text-center">{language['정말 삭제하시겠습니까?']} </p>
            </div>
          }
          footer={
            <button type="button" className="btn btn-primary" onClick={() => deleteChatRoon()}>
              {language['확인']}
            </button>
          }
        />
      </div>
    </>
  )
}

export default SOSCallChatRoomList
