function PrivacyKRView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
            <h3>개인정보 처리방침</h3>
            ㈜프리미엄패스인터내셔널(이하 ‘본 사‘라 함)는 귀하의 개인정보보호를 매우 중요시하며, 『개인정 보보호법』을 준수하고 있습니다. 본 사는 개인정보 처리방침을 통하여 귀하께서 제공하시는 개인 정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            <br />
            <br />
            <h5>1. 처리하는 개인정보의 항목</h5>본 사는 안심 케어, 보험 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다. 선택 항목은 입력하지 않더라도 서비스 이용에는 제한이 없습니다.
            <br />
            <br />
            <h6>[회원가입시 수집항목]</h6>
            - 필수항목: 성명, 이메일, 생년월일, 성별, 주민번호(내국인), 여권번호(외국인), 전화번호
            <br />- 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로 생 성되어 수집될 수 있습니다. (서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보)
            <br />
            <br />
            <h5>2. 개인정보의 처리 목적</h5>
            본 사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 고객이 제공한 모든 정보는 다음에 기술한 목적 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다. <br />
            - 홈페이지 회원가입 및 관리, 본인확인, 개인식별, 부정사용방지, 비인가 사용방지
            <br />
            - 고객 신원파악, 금융거래 및 서비스 업무, 본인확인, 개인식별, 부정사용방지, 비인가 사용방지
            <br />
            <br />
            <h6>민원처리</h6>- 현대해상 보험 가입, 안심 케어 서비스 제공, 금융거래 및 서비스 업무, 요금 추심
            <br /> - 민원처리
            <br />- 서비스 홍보 및 마케팅 업무
            <br />
            <br />
            <h5>3. 개인정보의 처리 및 보유기간</h5>개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 고객의 개인정보를 지체 없이 파기합니다.
            <br />
            - 회원정보 : 회원탈퇴 및 제명시까지 보유
            <br />
            - 계약 또는 청약철회 등에 관한 기록, 대금결제 및 재화 등의 공급에 관한 기록 : 5년간 보유
            <br />
            - 소비자 불만, 분쟁처리 : 3년간 보유
            <br />
            - 서비스 홍보 및 마케팅 업무를 위한 정보 : 3년
            <br />
            <br />
            단, 다음은 예외로 합니다.
            <br />
            가. 요금 정산 상의 문제로 결제가 완료되지 않은 경우
            <br />
            나. 다른 일반 회원을 보호할 필요가 있어서 당사가 보존할 필요가 있다고 판단되는 경우
            <br />
            다. 상법 등 다른 법령의 규정에 의하여 보존할 필요성이 있는 경우
            <br />
            라. 소송이나 분쟁에 있어 근거자료로 보존할 필요성이 있는 경우
            <br />
            마. 기타 개별적으로 이용자의 동의를 받은 경우
            <br />
            바. 기타 당사의 내부 규정에 의해 일정기간 동안 보존할 필요성이 있는 경우
            <br />
            그러나 이 경우에도 정당한 근거와 함께 사전에 개인정보가 보유되는 기간을 정확하게 고지하고 이후 완전 파기합니다.
            <br /> <br />
            <h5>4. 개인정보의 파기에 관한 사항</h5>본 사는 『개인정보 처리 목적』이 달성되거나 『처리 및 보유기간』이 경과한 후에는 해당 개인정보 를 즉시 파기합니다. <br />
            - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
            <br />
            - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기
            <br />
            <br />
            <h5>5. 개인정보의 제3자 제공에 관한 사항</h5>
            본 사는 귀하의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는 귀하의 정보를 제3자 에게 제공하지 않습니다.
            <br />
            제3자 제공 항목 제공 목적 <br />
            제공정보: 성명, 전화번호, 이메일, 상품명 제공목적: 전자결제
            <br />
            현대 해상 제공정보: 성명, 주민등록번호, 여권번호(외국인), 성별, 전화번호, 이메일 상품명 제공
            <br />
            목적: 보험 가입
            <br />
            - 개인정보 보유기간: 목적에 따른 개인정보 제공 시부터 제공목적 달성시까지 또는, 3조에 명시된 기한까지
            <br />
            <br />
            <h5>6. 정보주체의 권리‧의무 및 그 행사방법에 관한 사항</h5>귀하는 안심 케어 서비스를 위해 개인정보의 수집ㆍ이용 및 제공에 대해 동의하신 내용을 언제 든지 철회할 수 있습니다. 귀하는 본인 개인정보의 열람, 정정, 처리정지, 삭제 요청을 할 수 있으 며, 개인정보 보호책임자에게 서면, 전화 또는 Fax 등으로 연락하시면 지체 없이 파기하는 등 필 요한 조치를 하겠습니다. 단, 법에서 정한 의무사항이 있으면 권리 행사가 제한될 수 있습니다.
            <br />
            <br />
            <h5>7. 개인정보 보호책임자</h5>귀하의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 두고 있습니다.
            <br />
            이름 : 한진희
            <br />
            직책 : 대리
            <br />
            부서 : 이지트래블케어 전담
            <br />
            연락처 : 070-4818-1991
            <br />
            <br />
            <h5>8. 개인정보의 안전성 확보조치</h5>이용자께서 보내시는 모든 정보는 방화벽 등 보안시스템에 안전하게 관리되고 있습니다. 또한 본 사는 이용자의 개인정보보호를 위한 관리적 대책으로서 개인정보에 대한 접근 권한을 차등부 여하고 있고, 이용자의 개인정보를 취급하는 인원을 최소한으로 제한하여 지속적인 보안교육을 실시하고 있습니다. 또한 개인정보를 처리하는 시스템의 사용자를 지정하여 로그 기록을 보관하 고 있습니다.
            <br />
            <br />
            <h5>9. 개인정보 처리방침 변경</h5>개인정보 처리방침 변경에 관한 사항이 개인정보 처리방침은 2021년 1월 1일에 제정되었으며 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 변경되는 개인정보 처리방침을 시행하기 최소 7일전에 본 사 홈페이지를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다. <br />
            <br />- 공고일자 : 2021년 1월 1일 / 시행일자 : 2021년 1월 1일
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyKRView
