import { Outlet } from 'react-router-dom'
import BreadcrumbView from '../../common/BreadcrumbView'
import PageHeaderView from '../../common/PageHeaderView'
import TabView from '../../common/TabView'
import { KeyValueType } from '../../../utils/types'
import { useSelector } from 'react-redux'

function ConciergePage() {
  const language = useSelector((state: any) => state.language.value)

  const tabItems = [
    {
      link: '/concierge/incheon-t1',
      title: language['인천공항 T1'],
    },
    {
      link: '/concierge/incheon-t2',
      title: language['인천공항 T2'],
    },
    {
      link: '/concierge/jeonbuk',
      title: language['전북쇼핑트래블라운지'],
    },
    {
      link: '/concierge/songdo',
      title: language['송도 컨벤시아'],
    },
  ]
  const informationName: KeyValueType = {
    '/concierge/incheon-t1': language['인천공항 T1'],
    '/concierge/incheon-t2': language['인천공항 T2'],
    '/concierge/jeonbuk': language['전북쇼핑트래블라운지'],
    '/concierge/songdo': language['송도 컨벤시아'],
  }

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub1.jpg" title={language['컨시어지데스크']} />
      <TabView tabItems={tabItems} />
      <BreadcrumbView title={language['컨시어지데스크']} link="/concierge/incheon-t1" names={informationName} />
      <Outlet />
    </>
  )
}

export default ConciergePage
