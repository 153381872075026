function TermsCNView() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
              <h5>第一条（目的）</h5>
              该条款是以会员在PREMIUMPASS INTERNATIONAL（以下称“公司”）运营的 EASY TRAVERL CARE网站，在使用公司为会员提供的电商交易服务（以下称“服务”）时，规定全员的权利、义务及责任事项为目的。 该条款在不违反其性质的情况下，均适用于利用PC通讯、智能手机（Android手机、iPhone等）应用程序，无线等的电商交易。
              <br />
              <br />
              <h5>第二条（用语定义）</h5>
              条款中使用的用语定议如下。
              <br />
              1. “网站”是指公司为使注册会员的用户享受多种信息及服务，而制作、运营的网页。目前“公司”运营的“网站”（2021.01起）如下。
              <br />
              www.easytravelcare.co.kr
              <br />
              - 今后，“公司”公告及提供的其他网站
              <br />
              <br />
              2. “销售者”指为了商品或服务的顺利销售，从“公司”获取销售代理服务的人。但其方式为开放式销售模式的情况时，是指对“会员”购买的商品或服务等交易信息负直接责任的人。
              <br />
              <br />
              3. “会员”是指向“网站”（以下称公司）提供个人信息进行会员注册的用户，使用注册会员时设置的用户名（ID），自由登录网站并持续获取公司提供的信息或使用公司提供的服务的用户。
              <br />
              <br />
              4. “商品”是指“公司”通过网站向会员销售的商品或优惠券。
              <br />
              <br />
              5. “用户名（ID）”是指为识别“会员”以及使用服务，由“会员”选定，并经“公司”批准注册的文字或数字的组合。
              <br />
              <br />
              6. “密码（Password）是指为确认“会员”的同一性、保护“会员”的权益及秘密，“会员”自行设定并注册在“网站”上的文字和数字的组合。
              <br />
              <br />
              7. 预约确认服务券：可以以购买价格使用的商品和服务（以下称“商品”）的数码证券。
              <br />
              <br />
              8. 留言板：指“会员”在使用“服务”时，在“网站“上发表的符号、文字、语音形式的文章、图片、视频及各种文件和连接等。
              <br />
              <br />
              9. “聊天”是指购买商品后，需要进行紧急咨询时，使用的聊天服务。
              <br />
              <br />
              10. 该条款未定义的术语遵照相关法律规定。
              <br />
              <br />
              <h5>第3条（条款明示、说明及修改）</h5>
              1. 为了方便会员确认，“公司”将该条款内容及公司名、营业所在地、法人代表姓名、营业证号码、联系方式（电话号码、传真号码、电子邮箱等）、通信销售业申报号码、个人信息管理负责人等信息，在“网站”的初期“服务”页面显示。但，条款的具体内容，会员可通过连接画面查看。
              <br />
              <br />
              2. “公司”在不违背条款限制相关法律、电商交易等消费者保护相关法律、电子文件及电子交易基本法、电子签名法、促进利用信息通信网等相关法律、消费者基本法、电子金融交易法等相关法令的范围内，可修改该条款内容。
              <br />
              <br />
              3. “公司”修改条款时，应明示适用日期及修改理由，从适用日期7日之前至适用日期前一天，在现行条款和“网站”的公告栏或首页予以公告。但如修改内容不利于“会员”，且产生重大影响时，需在30日前公告。
              <br />
              <br />
              4. 根据第3条第3款，修改条款只适用于适用日期以后签订的合同，如没有法律的特别规定或其他不得已的理由，不适用修改适用日期之前的条款。
              <br />
              <br />
              5. 根据第3条第3款，公告修改适用日期之后，会员继续使用“公司”的“服务”时，视为同意修改后的条款。如会员不同意修改后的条款，可随时解除使用合同。
              <br />
              <br />
              6. 关于该条款没有明示的事项及此条款的解释，依照电商交易等消费者保护相关法律、条款限制相关法律，公平交易会员会规定的电商交易等消费者保护方针和相关法律的规定及一般惯例。
              <br />
              <br />
              <h5>第4条 (个别服务使用条款及使用条款）</h5>
              1. “公司”有权在所提供的“服务”范围内的各个“服务”项目，另设“个别服务”使用条款及使用条件，对“个别服务”单独适用的条款，会员首次使用“相关服务”时，经过单独条款同意流程后，视为同意使用“服务”内容，此时“个别服务”的使用条款优先于总条款。
              <br />
              <br />
              2. 当天18:00之前购买Easy Travel Care服务时，从购买时刻3小时之后，开始提供服务；当天18:00之后购买时，将在此日中午12:00开始提供服务。
              <br />
              <br />
              <h5>第5条 (停止提供服务）</h5>
              1. 如符合以下事项，“公司”可以停止提供“服务”
              <br />
              ① 服务使用设备的维修或检查、更换及故障等因保修工程不得已的情况
              <br />
              ② 《电信事业法》规定的基干通信事业者终止电子通信服务时
              <br />
              ③ 有其他不可抗拒的原因时
              <br />
              <br />
              2. “公司”因国家紧急状态、停电、服务设备故障或服务使用暴增等影响无法正常使用服务时，可限制或停止部分或全部“服务”。
              <br />
              <br />
              <h5>第6条（使用合同的成立）</h5>
              1. 使用合同将于“公司”批准“会员”的条款同意、使用申请后成立。
              <br />
              <br />
              2. 使用合同的成立始点为“公司”通过申请程序批准或标明注册完成提示之时。
              <br />
              <br />
              <h5>第7条（使用申请及批准）</h5>
              1. “公司”对相应于以下各号的使用申请，可以拒绝或保留批准、也可以事后解除使用合同。
              <br />
              ① 姓名及电子邮箱，与已经加入的“会员”一致的。
              <br />
              ② 盗用他人的名义、虚假记载或遗漏记载等。
              <br />
              ③ 注册内容有虚假信息或有错误记载的。
              <br />
              ④ 使用申请人根据该条款内容，此前有丧失会员资格的。
              <br />
              ⑤ 因“公司”规定丧失使用资格，经过3个月后，从“公司”获取重新注册许可的情况除外。
              <br />
              ⑥ 以不正当的用途或追求盈利为目的申请使用“服务”的（但使用“公司”许可范围内的服务时，除外）。
              <br />
              ⑦ 运营“服务”的相关设备不充裕，或技术上或业务上出现障碍时，解除障碍为止，可保留许可。
              <br />
              ⑧ 为申请服务违反相关法律、美风良俗、社会安定秩序及其他诸多事项，或者因会员的归责理由无法批准的。
              <br />
              ⑨ 其他违背该条款以及被确认为违法或使用不正当的方法申请服务时，“公司”根据合理的理由判断为有必要的。
              <br />
              <br />
              2. 根据第1项的使用申请，“公司”可通过专业机构申请实名确认及本人认证。
              <br />
              <br />
              <h5>第8条（服务使用时间）</h5>
              原则上“公司”的业务或技术上无特殊障碍时，“服务”的使用时间是365天（1天24小时）。但，由于定期检查等事由，“公司”公告的日期或时间除外。
              <br />
              <br />
              <h5>第9条（关于会员的用户名及密码的义务）</h5>
              1. 关于“用户名”和“密码”的管理责任全全归于“会员”本人，因疏忽其事项导致的所有民、刑事责任，全部在于“会员”本人。
              <br />
              <br />
              2. “会员”有义务保护自己的“用户名”及“密码”不被第三方租赁或使用。
              <br />
              <br />
              3. “会员”如发现自己的“用户名”及“密码”被盗或被第三方使用时，应立即通知“公司”，如“公司”对其有措施，则应遵从。
              <br />
              <br />
              4. 根据前项所述，如“会员”没有通知公司或没有遵从“公司“的措施，而发生的不利后果，其责任全全在于“会员”本人。
              <br />
              <br />
              <h5>第10条（使用合同解除及终止）</h5>
              1. “会员”的解除
              <br />
              ① “会员”可随时联系“公司”通知解除意项，并解除使用合同（申请注销会员），并可要求删除“会员”提供的注册账户信息资料。
              <br />
              ② 使用合同将在“会员”向公司提供解除意项同时终止。
              <br />
              ③ 根据前项解除合同的“会员（注销的会员）”，可根据“公司”指定的规定，通过注册会员程序，重新注册会员。但解除（注销）前，注册会员时所适用的信息将全部被删除，同时也不可申请退款。
              <br />
              <br />
              2. “公司”的解除
              <br />
              如有以下所列理由，“公司”可解除使用合同，该“会员”的信息将根据隐私政策处理。
              <br />
              ① 根据第7条第一项规定，确认为使用合同有拒绝许可理由时。
              <br />
              ② “会员”侵害“公司”或其他“会员”等他人的权利、名誉、信誉等正当利益时，以及违背善良风俗、其他社会秩序、大韩民国法律的行为。
              <br />
              ③ 用未经确认的事实或内容，对“公司”销售的“商品”或“服务”制造不利于公司的造谣时。
              <br />
              ④ 以扰乱交易秩序为目的，试图进行重复购买等妨碍网站程序的行为时。
              <br />
              ⑤ “公司”通过合理的判断，确认“会员”有其他违背该条款的行为时。
              <br />
              ⑥ “公司”通过电子邮件，电话，传真或其他方法，表明终止合同的意向，通知该会员解除合同的理由。
              <br />
              但，公司给予该会员事前对解除事由进行意见陈述的机会。
              <br />
              <br />
              3. 根据本条规定，使用合同终止时，“会员”将丧失使用服务的权限，会员的个人信息及记录等信息会全部被删除，关于事前购买的商品的退款等事宜，根据公司的退款规定进行处理。
              <br />
              <br />
              4. 终止使用合同所造成的损失，由解除合同的该“会员”承担，“公司”不承担一切责任。
              <br />
              <br />
              <h5>第11条（会员的义务）</h5>
              1. “会员”应遵守相关法律、该条款规定、使用指南等“公司”公告的内容，不得有其他妨碍“公司”业务的行为。
              <br />
              <br />
              2. “会员”在使用“服务”时，不得有以下各号的行为。
              <br />
              ① 申请或变更“服务”时，填写虚假内容。
              <br />
              ② 盗用他人信息。
              <br />
              ③ 未根据“公司”公告的内容，变更信息。
              <br />
              ④ 发送或发布“公司”指定信息以外的信息（电脑程序等）。
              <br />
              ⑤ 侵害“公司”及其他第三方的制作权、肖像权等知识财产权。
              <br />
              ⑥ 损害“公司”及其他第三方的名誉或妨碍业务的行为。
              <br />
              ⑦ 公开或发布违背公共秩序及美风良俗的猥亵、暴力情报、视频、音频等信息。
              <br />
              ⑧ 其他违背相关法律或“公司”指定规定的行为。
              <br />
              <br />
              <h5>第12条（公司的义务）</h5>
              1. “公司”不得实施相关法律或该条款禁止的行为或违反公序良俗的行为，根据该条款的规定，为提供持续、稳定的“服务”而竭尽全力。
              <br />
              <br />
              2. 为“会员”可安全利用“服务”，“公司”应具备为保护“会员”个人信息（包括信用信息）的系统，公示及遵守隐私政策。
              <br />
              <br />
              <h5>第13条（购买申请及同意提供个人信息等）</h5>
              1. “公员”通过“网站”，按照以下或类似于以下方法申请购买，用户申请购买时，“公司”应提供以下内容。
              <br />
              ① 搜索及选择商品。
              <br />
              ② 填写相关使用商品的预约信息（日期等）。
              <br />
              ③ 填写预约者与接受服务之人的姓名、国籍、护照码号、身份证号码、电子邮箱（或手机号）等信息
              <br />
              ④ 确认每个商品的构成、包含事项及受限于条款内容的服务等关于承担费用的内容。
              <br />
              ⑤ 同意该条款，标识接受或拒绝（第13条1-④）条款内容（例：用鼠标点击）。
              <br />
              ⑥ 同意申请购买商品等以及对其确认或“公司（网站）”的确认。
              <br />
              ⑦ 选择结算方式。
              <br />
              <br />
              2. 公司收到会员的购买申请时，向该会员发送购买确认通知，该通知抵达至会员时，购买合同开始成立。
              <br />
              <br />
              3. “公司”向第三方提供或有必要提供购买者的个人信息时，需要提前通知购买者关于公司所提供的个人信息接收者、接收个人信息的当事者使用个人信息的目的、被提供的个人信息项目、接收个人信息的当事者对个人信息的存档及使用期限等信息，并征得购买者的同意（经同意的内容有变更时，也同样适用）。
              <br />
              <br />
              4. “公司”委托第三方处理购买者的个人信息取缔业务时，必须向购买者告知个人信息处理委托方、委托处理个人信息的业务内容，并征得购买者的同意（经同意的内容有变更时，也同样适用）。但，如果有必要提供相关服务合同的履行，而且与增进购买者的便利有关时，通过促进信息通信网利用及信息保护等规定的方法，根据个人信息收集方针进行通知，无需经过通知程序及同意程序。
              <br />
              <br />
              <h5>第14条（合同成立）</h5>
              1. 相关预约及确认各商品所需要时间的内容，“公司”与“会员”双方皆同意遵守各商品页面所记载的内容。
              <br />
              <br />
              2. 第13条条款所列的“会员”提出购买申请时，如符合以下各号的内容，“公司“可决绝不予批准。
              <br />
              ① 申请内容包含虚假、遗漏记载、错误记载等。
              <br />
              ② 判断为购买申请对“公司”现存的技术有明显障碍时，因误报信息、信息遗漏、系统障碍、停止销售等理由，无法提供申请购买的“商品”和“服务”时（但“公司”事后必须通知“会员”并采取必要措施）。
              <br />
              ③ 以盗用第三方的用户名、名义或者结算方式等途径申请购买的。
              <br />
              ④ 以二次销售为目的，或者被怀疑以不正当的目的购买“商品“的。
              <br />
              ⑤ 无意购买商品，盲目重复购买与退款，导致其他用户丧失购买机会。
              <br />
              ⑥ 以违背相关法律、抵害社会秩序或美风良俗的目的，申请购买的。
              <br />
              ⑦ 其他违背该条款或被确认为以恶意利用为目的不正当申请，以及“公司” 根据合理的判断认为有必要的。
              <br />
              <br />
              3. “公司”的许可，根据第19条第1项的规定，以收信确认通知形式抵达“会员”时，合同视为成效。
              <br />
              <br />
              4. “公司”的许可意向表示，需要包含对“会员”购买申请的确认及可否销售与否、购买申请的变更、取消等相关信息。
              <br />
              <br />
              <h5>第15条（结算方式）</h5>
              1. 在“网站”购买的相关商品或人力的款项的支付方式，可在以下各号所列的方法中选择。
              <br />
              ① 信用卡
              <br />
              ② 各种PAY
              <br />
              ③ 其他“公司”指定的结算方式及通过电子支付方式支付货款
              <br />
              2. “会员”在结算过程中，输入的信息及因此信息发生的责任与不利，由会员全全负责。
              <br />
              <br />
              3. “公司”有权对“会员”结算货款时使用的结算方式确认本人信息或确认是否具有正当使用权，对此，在确认结束之前，可中断交易，无法确认时，可以取消该交易或保留退款。
              <br />
              <br />
              4. 如“会员”要求购买商品的购买确认（现金收据等）时，“公司”以实际销售金额发放。
              <br />
              <br />
              <h5>第16条（预约确认件或订单号）</h5>
              1. “会员”在“网站”上购买的商品，“公司”通过SMS或电子邮件等方式， 将其的详细内容发送给“会员”。
              <br />
              <br />
              2. “预约确认件或订单号”传送业务，因公休日、休息日、“公司”的营业时间或处理过程等状况，会有所变更，其内容遵循“网站”提示的相送内容。
              <br />
              <br />
              <h5>第17条（收信确认通知，变更或取消购买申请）</h5>
              1. “网站”收到“会员”的购买申请时，发送收信确认通知给“会员”。
              <br />
              <br />
              2. 收到收信确认通知的“会员”，如发现意见不一致的内容，请在收到确认通知之后，立即要求变更或取消购买申请，在确定预约前，如有“会员”的变更或取消要求，“网站”必须立即按照其要求进行处理。但，如果已经支付货款时，依照第20条取消订单的规定执行处理。
              <br />
              <br />
              <h5>第18条（取消订单等）</h5>
              1. “会员”的取消购买及取消订单的规定，遵守电商交易等消费者保护相关法令。
              <br />
              <br />
              2. “会员”纯粹变心导致的取消或退款，对处理其事项产生的手续费由“会员”承担。
              <br />
              <br />
              3. 如“会员”购买的“商品”的内容与标示、广告内容或合同内容不符时，至收到“商品”之日起30日内，可以撤回其订单。
              <br />
              <br />
              4. 使用信用卡或电子货币结算的“会员”，可立即向相应结算平台提出停止或取消支付货款。
              <br />
              <br />
              5. 取消购买时，取消已经结算的明细，并根据取消时间，重新测定并收取预约手续费。根据取消时间和相应信用卡公司的退款规定，取消金额的退款方式和退款日期会有所差异，信用卡的退款政策，需向相应信用卡公司直接咨询。
              <br />
              <br />
              6. 如“会员“盗用他人的个人信息，通过该条款禁止的不正当途径进行预约，并被怀疑骗取不正当利益时，“公司”有权取消或限制“会员”的购买申请，在“会员”提交辩明资料前，“公司”有权保留退款处理。
              <br />
              <br />
              7. 其他此条款及“网站”的使用指南尚无规定的取消及退款事项，按照消费者损失赔偿规定执行。
              <br />
              <br />
              <h5>第19条（商品的退款特约）</h5>
              1. “会员”购买服务后，如符合以下各号内容，则无法申请取消或退款。
              <br />
              ① 开始提供服务后，“会员”纯粹变心导致的取消邀请，将不被列为退款对象，其内容根据“卖方”的销售规定处理。
              <br />
              ② 该商品的使用期限和时间，因用户的事先选择而受到限制时。
              <br />
              2. “公司”按照退款程序进行退款时，需提前与“卖方”或“保险公司”确认协商后，进行退款。
              <br />
              <br />
              <h5>第20条（保护个人信息）</h5>
              1. 为提供相关服务，“网站”在收集“会员”个人信息时，在所需范围内收集最低限度的个人信息。
              <br />
              <br />
              2. 注册会员时，“网站”不可提前收集履行购买合同所需的信息。但，履行相关法律所规定的义务，签订购买合同需提前进行本人确认后收集最基本个人信息的情况除外。
              <br />
              <br />
              3. “网站”在收集、使用“会员”的个人信息时，应向该使用者提前告知其目的，并征得同意。
              <br />
              <br />
              4. “网站”不能将收集的个人信息，用于其目的以外的用途，如有新增的使用目的或需要提供至第三方时，在提供阶段，必须提前告知该“会员”，并征得通意。但，如果相关法律有其他规定时除外。
              <br />
              <br />
              5. 根据第2项与第3项，“网站”需要提前征得用户同意的情况 个人信息管理负责人的身份（所属机构、姓名及电话号码、其他联系方式），信息的收集及使用目的，第三方提供信息的相关事项（信息接收方、提供目的及提供的信息内容）等信息通信网促进使用及信息保护等相关法律第22条第2项规定的事项，必须提前告知“会员”，并征得同意，“会员”可随时撤回该同意内容。
              <br />
              <br />
              6. “会员“可随时要求查看“公司”保存的个人信息并更正错误信息，“公司”对此有义务立即采取必要措施。“会员”要求更正错误信息时，在其错误信息更正之前，“公司”则不使用该个人信息。但，阅览及错误更正的程序，按照“公司“的规定执行。
              <br />
              <br />
              7. 为保护个人信息，“公司”应尽量限制经营“会员”个人信息的人员，对包括信用卡、银行账号等“会员”的个人信息，如因丢失、被盗、流失、伪造、未经同意提供至第三方等原因对“会员”造成损失，“公司”承担全部责任。
              <br />
              <br />
              8. 第三方通过“公司”或其处接收个人信息后，达到收集或提供个人信息的目的时，应立即销毁该个人信息。
              <br />
              <br />
              9. “公司”不提前设置收集、使用、提供个人信息的同意栏。另外，必须提前告知“会员”，如拒绝同意提供、收集、使用个人息时，受限服务的具体内容。不得因“会员”拒绝提供非必须项目的个人信息为理由，而对该会员限制或拒绝提供服务。
              <br />
              <br />
              <h5>第21条（制作权的归属及使用限制）</h5>
              1. “网站”制作的作品的制作权和其他知识产权归“网站”所有。
              <br />
              <br />
              2. “会员”通过使用“网站”时获取的信息、图片、数据中，归属于“网站”的知识产权信息，未经“网站”的同意，不得通过复制、发送、出版、传播、广播等方式，将其用于盈利目的或提供至第三方。
              <br />
              <br />
              3. “会员”通过“网站”购买的商品的数据图片，未经“网站”同意，不得擅自通过加工、复制、发送、出版、传播、广播等方式，将其用于盈利目的或提供至第三方。
              <br />
              <br />
              <h5>第22条（会员资格限制或停止）</h5>
              1. “会员”随时可以向“公司”申请注销会员，“公司”需立即处理会员注销。
              <br />
              <br />
              2. “会员”如符合以下各号内容，“公司”有权立即限制或停止会员资格。
              <br />
              ① 注册会员或变更时，填写虚假内容的。
              <br />
              ② “会员”没有按时缴纳通过“网站”购买商品等货款，或使用“网站”的其他服务而需要支付相应费用的。
              <br />
              ③ 妨碍其他用户使用“网站”或盗用其信息等扰乱电商交易秩序的。
              <br />
              ④ 通过使用“网站”，违反法律或该条款禁止或公署约定的行为。
              <br />
              ⑤ 妨碍“网站”的健康运营或妨碍网站的业务的。
              <br />
              I. 相关“网站”的运营，散步毫无根据的事实和虚假事实，损害“公司”的信誉度的行为。
              <br />
              II. 在使用“网站”的过程中，对“公司”职员以粗暴、威胁或淫乱的言行妨碍网站正常运营的行为。
              <br />
              III. 购买商品之后，无正当理由，经常或反复取消或退货，妨碍公司正常业务的行为。
              <br />
              IV. 通过“网站”购买商品，使用部份服务之后，在商品没有特别缺陷的情况下，经常取消、部份或全部退款的行为。
              <br />
              V. 通过“网站”购买商品后，在商品没有特别缺陷的情况下，纯粹因为个人变心或没有根据的不满而妨碍“公司”正常业务的行为。
              <br />
              <br />
              <h5>第23条（免责条款）</h5>
              1. “公司”因天灾或类似不可抗力的原因，不能提供“服务”时，可免除提供“服务”的责任。
              <br />
              <br />
              2. 因“会员”自身事由导致的“服务”使用障碍，“公司”不对其负任何责任。
              <br />
              <br />
              3. 对“会员”填写的信息、资料等内容的事实可信度和准确度，“公司” 不负任何责任。
              <br />
              <br />
              4. “会员”之间或“会员”与第三方之间达成的“服务”协议，“公司”不负任何责任。
              <br />
              <br />
              5. “会员”丧失通过使用“服务”获得的预期收益，或者因通过“服务”获取的资料而受损失，“公司”对其不负任何责任。
              <br />
              <br />
              <h5>第24条（解决纠纷）</h5>
              1. 本“网站”反映“会员”提供的正当意见或不满，为赔偿或处理其损失，设立运营赔偿处理机构。
              <br />
              <br />
              2. “网站”首先处理用户提出的不满事项及意见。但，如无法迅速处理时，应立即通知用户该理由和处理进度。
              <br />
              <br />
              <h5>第25条（审判权及准据法）</h5>
              1. 关于“网站”与“会员”之间发生的电商交易纠纷的诉讼，根据诉讼当时“会员”的地址。如没有地址时，由该用户暂时居住的住所管辖内地方法院专属管理。但，起诉当时，用户的地址或住所不明确或者是外国居住者时，会向民事诉讼法的管辖法院提出诉讼。
              <br />
              <br />
              2. “网站”与“会员”之间提出的电商交易诉讼，适用韩国法律。
              <br />
              <br />
              附：该条款于2021年1月1日起实行。
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsCNView
