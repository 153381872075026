import axios from 'axios'

let customAxios = axios.create({
  timeout: 600 * 10000,
})

function axiosConnect(paramUrl, paramMethod, postParams = {}, getParams = {}) {
  let header = {
    'Content-Type': 'multipart/form-data',
  }
  return new Promise((resolve, reject) => {
    customAxios({
      method: paramMethod,
      url: `${paramUrl}`,
      data: postParams,
      params: getParams,
      headers: header,
    })
      .then((bodyData) => {
        resolve(bodyData)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function axiosConnectFormUrlEncoded(paramUrl, paramMethod, postParams = {}, getParams = {}) {
  let header = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return new Promise((resolve, reject) => {
    customAxios({
      method: paramMethod,
      url: `${paramUrl}`,
      data: postParams,
      params: getParams,
      headers: header,
    })
      .then((bodyData) => {
        resolve(bodyData)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function axiosConnectJson(paramUrl, paramMethod, postParams = {}, getParams = {}) {
  return new Promise((resolve, reject) => {
    customAxios({
      method: paramMethod,
      url: `${paramUrl}`,
      data: postParams,
      params: getParams,
    })
      .then((bodyData) => {
        resolve(bodyData)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function axiosConnectFileUpload(paramUrl, paramMethod, postParams = {}) {
  let header = {
    'Content-Type': 'multipart/form-data',
  }
  return new Promise((resolve, reject) => {
    customAxios({
      method: paramMethod,
      url: `${paramUrl}`,
      data: postParams,
      headers: header,
    })
      .then((bodyData) => {
        resolve(bodyData)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default axiosConnect
