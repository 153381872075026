import { useSelector } from 'react-redux'

function ConciergeMapoView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="page-header">
              <h2>{language['마포관광 정보센터']}</h2>
            </div>
            <hr className="blue" />
            <ul className="list">
              <li>
                <h6>{language['위치: 지하철 2호선 홍대입구역 8번 출구에서 도보 3분 거리']}</h6>
              </li>
            </ul>
            <hr className="blue" />
          </div>
          <div className="col-12 col-md-7">
            <img src="/resources/home/img/desk_mapo.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light-blue mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="col-12 offset-md-1 col-md-10 mt-5">
                <p className="text-center">
                  <img src="/resources/home/img/svg/desk_mapo_map_en.svg" alt="" className="d-block w-100" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConciergeMapoView
