import { useSelector } from 'react-redux'

function B2BTermENView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <>
        <h6>{language['이지트래블케어 이용 약관']}</h6>
        Enjoy a safe & comfortable tour in Korea! <br />
        Please contact Easy Travel Care Center whenever you need help during your trip to Korea! Easy Travel Care Center is open 365 days, all year around.
        <br />
        <br />
        Easy Travel Care Center provides Worry-Free Insurance & Care services for travelers in Korea via mobile chat, phone calls, and e-mail.
        <br />
        <br />
        Your experience in Korea with Easy Travel Care provides luxurious and foreigner-exclusive customized concierge services with safety and comfort at all times.
        <br />
        <br />
        <h6>Introduction to Easy Travel Care Service</h6>
        Easy Travel Care is a tourism platform service that offers travel insurance and specialized concierge services to foreign tourists in Korea.
        <br />
        <br />
        The offered services are Medical Easy Care Service, Travel Easy Care Service, Airport Easy Care Service, Business Easy Care Service, Traveler’s Worry-Free care and special benefits for premium memberships provided by PREMIUMPASS INTERNATIONAL Corp..
        <br />
        <br />
        About PREMIUMPASS INTERNATIONAL Corp.
        <br />
        Established in 2007 originally as a protocol and concierge service company, PREMIUMPASS INTERNATIONAL has successfully expanded its business in diverse hospitality sectors. <br />
        <br />
        PREMIUMPASS INTERNATIONAL's services, representatively of the Easy Travel Care service, are now recognized globally. The company specializes and provides services specifically in MICE business, protocol/transport business, airport-related business, travel business, concierge business, and operates Travel Center & Lounge in major tourist destinations and transportation hubs.
        <br />
        <br />
        <h6>Outline of Easy Travel Care</h6>
        <br />
        1. Subscription to Easy Travel Care
        <br />
        To sign up for Easy Travel Care service, the following information is needed and shall be collected from you: Nationality, Name, Date of birth, Gender, Mobile number, E-mail address, and Identity verification will be proceeded.
        <br />
        <br />
        If registration is successful, the service manual will be sent to you via designated platform. If you request for special form such as a separate registration certificate will be processed and sent to you via e-mail. <br />
        <br />
        Service may be restricted if the information is incorrect.
        <br />
        Please make sure your contact number and e-mail address are corresponding upon sign-up. In case of an error, you can modify your information by contacting the Easy Travel Care Center or through our chat service.
        <br />
        <br />
        In case of any emergency during traveling, your passport number would be required for verification before and after the service.
        <br />
        <br />
        2. How to Contact Easy Travel Care Center
        <br />
        If you need any help or in case of any emergency, please contact us by SOS request via our chat service on Easy Travel Care platform, or call Easy Travel Care Center (1644-1493/ 82-2-2063-3544).
        <br />
        <br />
        The care center operates 24/7 and can be contacted via phone, e-mail, chat service, or Kakao Talk messenger. You can also leave your contact number if you need a call directly from the manager. Mobiles that do not use Korean SIM cards may have limited services.
        <br />
        <br />
        ※ E-mail or Kakao Talk.
        <br />
        You can request a service through e-mail (care@tothepp.com) or KakaoTalk Plus Friend (@ Easy Travel Care CENTER)
        <br />
        <br />
        <h6>Terms and conditions of Easy Travel Care Service</h6>
        <h6>Term 1. Purpose</h6>
        This agreement aims to clarify the rights and obligations of members and subscribers about the Easy Travel Care service provided by PREMIUMPASS INTERNATIONAL Corp..
        <br />
        <br />
        <h6>Term 2. Terminology</h6>
        1. Members
        <br />
        Individuals or groups that have provided personal verification and itinerary information by phone, e-mail, website, or mobile app to register for the safe tourism platform service, or through the advertisement agencies of Easy Travel Care.
        <br />
        2. Subscribers
        <br />
        Members who have agreed to the terms and conditions of Easy Travel Care service and received a certificate number for the payment made.
        <br />
        3. Easy Travel Care Center
        <br />
        The total service center for non-contact primary services providing convenience, and insurances for possible inconveniences during travel.
        <br />
        4. Subscription Certificate
        <br />
        A document provided by the Easy Travel Care Center to the subscribers as evidence of subscription stated with the certificate number, personal verification information, and major subscription conditions.
        <br />
        5. Care Manager
        <br />
        A professional service manager authorized to be involved and make decisions and also eligible for evaluating, modifying, and supervising every part of the service and insurances.
        <br />
        6. Special Membership Benefits
        <br />
        Special benefits decided by PREMIUMPASS INTERNATIONAL Corp., Ltd. for qualified members of Easy Travel Care service.
        <br />
        <h6>Term 3. Service Details</h6>
        Easy Travel Care provides the following safe tourism platform services:
        <br />
        1. Medical Easy Care
        <br />
        Customized services for any possible accidents that could occur during your travel in Korea including arrangements with a medical interpreter, resolutions for medical expenses, travel insurance, medical care, and etc. <br />
        2. Travel Easy Care
        <br />
        Various concierge services prepared for emergencies, such as:
        <br />
        - Support service for lost items (wallet, passport, etc.) <br />
        - Care service for emergencies during traveling
        <br />
        - Arrangement service for vehicles and transportations
        <br />
        - Immediate accommodation reservation service
        <br />
        3. Airport Easy Care
        <br />
        Specialized Easy Care service within the international airport <br />
        - Support service for the elderly <br />
        - Concierge services
        <br />
        - Immediate airline ticketing and visa support service
        <br />
        4. Business Easy Care
        <br />
        Specialized concierge services for convenient travel and successful business trips
        <br />
        - Immediate arrangements for interpreters or translators <br />
        - Arrangements and reservations for meeting rooms or places
        <br />
        - Ticketing and support system for KTX, Express Bus, and Airlines
        <br />
        5. Traveler’s Worry-Free care
        <br />
        Traveler’s Worry-Free care provides and follows the terms and conditions of the affiliated insurance company. <br />
        - Accidental Death (During Domestic Trip): KRW100,000,000(Excluding under age 15)
        <br />
        - Accident Disability (During Domestic Trip): KRW100,000,000
        <br />
        - Death by Illness or 80% Severe Disability by Illness(During Domestic Trip): KRW20,000,000 (Excluding under age 15, age 80 or more)
        <br />
        - Domestic Travel Injury Insurance_Medical Expense Coverage: KRW10,000,000 (limit of KRW100,000 per Outpatient)
        <br />
        - (Non-covered) Domestic Travel Injury Insurance_Medical Expense Coverage: KRW10,000,000 (KRW100,000 per Outpatient, limit of 100 visits per year)
        <br />
        - Lump Sum Compensation (Domestic Trip): KRW5,000,000, Self-payment KRW10,000
        <br />
        - Personal Belongings Damage (Except Loss): KRW200,000, Self-payment KRW10,000
        <br />
        6. Special Membership Benefits
        <br />
        Easy Travel Care is a member-exclusive service that provides diverse concierge services and discounts in terms of airport, travel, and business. Please note that the membership service can be modified by the parent company, PREMIUMPASS INTERNATIONAL Corp.. The changes will be updated accordingly on the website and mobile app for your reference. <br />
        7. Precautions for Other Services
        <br />
        - If the subscriber has been in an accident during traveling Korea, Information for Emergency Response Procedure, translation services, arrangements with the related agencies, along with on-site dispatch will be provided.
        <br />
        - This service is only effective for accidents that occurred in South Korea and is restricted in any other countries.
        <br />
        - It is recommended that the subscriber immediately reports to the Easy Travel Care Service Center in case of an accident. If a third party is engaged before the arrival of the Easy Travel Care manager, our service can be limited.
        <br />
        <br />
        <h6>Term 4. Effective Date and Termination of Service</h6>
        Easy Travel Care service is effective from the first day of registration and is immediately terminated on the last day or after departure from Korea. The number of days is counted by 24 hours. <br />
        <br />
        <h6>Term 5. Effect of the Contract</h6>
        1. These terms and conditions take effect when Easy Travel Care posts them on websites, mobile applications, or informs the subscribers.
        <br />
        2. If Easy Travel Care amends these terms and conditions, it will be posted or notified in the same way as in paragraph 1 from one week before the date of application to the day before the date of application. At this time, the amended terms and conditions apply only to contracts whose service date starts after the date of application, and contracts already started apply to the terms and conditions before the amendment.
        <br />
        3. Subscribers may request unsubscribing if they do not agree to the revised terms and conditions.
        <br />
        4. The subscriber is deemed to have agreed to the revised terms and conditions by using the service if not request unsubscribing.
        <br />
        <br />
        <h6>Term 6. Priority of Application of Terms and Conditions</h6>
        1. For the service associated with Easy Travel Care services and to the insurance company’s products, general terms and special terms of the insurance product apply to all matters related to insurance.
        <br />
        2. In case the contents of the general terms and the contents of special terms conflict, the effect of the special terms takes precedence over the general terms.
        <br />
        <br />
        <h6>Term 7. Qualification and Disqualification for Members and Subscribers</h6>
        1. All individuals and groups are eligible for membership by filling in or providing personal identification information (nationality, name, date of birth, gender, phone number, email address, etc.) and itinerary information required by Easy Travel Care. However, you must present your passport to receive Easy Travel Care service.
        <br />
        2. If false or incomplete information is updated or the registration is without progress up to 12 hours, Easy Travel Care shall be eligible of terminating or discarding all itinerary information of the member.
        <br />
        3. Easy Travel Care is eligible of using the member's personal / itinerary information for membership registration.
        <br />
        4. Members who have been successfully qualified (refer to term 7.1) shall receive a certificate number.
        <br />
        <br />
        <h6>Term 8. EASY TRAVEL CARE’s Rights and Obligations</h6>
        1. Easy Travel Care shall constantly make effort to provide stabilized services and customer service. However, affiliates could also proceed with customer service, depending on the needs and circumstances.
        <br />
        2. Easy Travel Care could restrict or discontinue the service, completely or partly, if applicable to any of the following cases: <br />
        A. System and equipment repair, etc.
        <br />
        B. No service provided from the telecommunication service provider
        <br />
        C. Other unavoidable cases, such as national emergencies, power outages, network congestion, etc.
        <br />
        3. Easy Travel Care should inform the members or subscribers in case of discontinuation or restriction of service via methods specified in term 5.1.
        <br />
        4. If a member or a subscriber has an existing disease or a symptom caused by an accident or disease in the past, registration and insurance could be limited and exempted.
        <br />
        5. Easy Travel Care has the right to store and monitor conversations to prevent any disorders.
        <br />
        6. It is considered that the subscriber has agreed to the use of stored information as evidence in case of any service-related disorders.
        <br />
        7. The subscriber shall fill in and submit any medical records to the Easy Travel Care Center, including the records that occurred before the trip if Easy Travel Care requests the information for better service. Easy Travel Care shall not be responsible for any disadvantages caused by the subscriber's refusal for submission.
        <br />
        8. In case of occurrence of accidents and diseases, Easy Travel Care is eligible to immediately exercise all rights on behalf of the subscriber, regarding the service-related third parties, such as individuals, legal entities, and public institutions. The subscriber cannot cancel the rights and activities and must comply with all legal procedures. <br />
        9. Among Easy Travel Care services, Traveler’s Worry-Free care will be paid following the insurance company's terms and conditions with which the "Premiumpass" is affiliated.
        <br />
        10. Guarantee for the travel insurance shall be provided with the company affiliated with "PREMIUMPASS INTERNATIONAL" as Easy Travel Care service is based on the Worry-Free Tourism Concierge service. The member is responsible for the concierge service payments.
        <br />
        <br />
        <h6>Term 9. Rights and Obligations of Members and Subscribers</h6>
        1. Members and subscribers shall agree and follow every policy and precaution stated in this contract by Easy Travel Care. <br />
        2. Members and subscribers shall be aware of information misuse and immediately report to Easy Travel Care if any misuse is detected.
        <br />
        3. Members and subscribers cannot transfer or assure the rights and obligations to a third party.
        <br />
        4. Members and subscribers have the right to reject the use of their personal information by a third party, excluding the cases designated by law.
        <br />
        <br />
        <h6>Term 10. Cancellation or Termination of Subscription</h6>
        1. Subscribers can always withdraw their subscription and receive a full refund before the service effective date stated on the certificate.
        <br />
        2. Modification of service period or cancellation is only available before the commencement date.
        <br />
        3. "PREMIUMPASS INTERNATIONAL Corp." is eligible to cancel the service if it is considered that there is a possibility of injustice, malice, or deliberate accidents.
        <br />
        4. If the subscriber requests service cancellation after the service commencement date, a full refund excluding the period of use shall be available. The deduction depends on the insurance short rate and service rate. If the subscriber does not exert the right to claim for a return until 3 years after the disqualification, it would be considered as an extinctive prescription. However, the right to claim for refund would follow the Insurance Business Act.
        <br />
        5. Submission of Documents for Cancellation and Termination
        <br />
        A. Cancellation before the commencement date: cancellation request letter or e-mail/bankbook copy
        <br />
        B. Termination due to simple change of mind: application for termination/passport copy/bankbook copy (refund as of the date of receipt of the application for termination)
        <br />
        C. Termination due to early return: passport copy/bankbook copy/ return E-TICKET copy or certificate of entry & exit (E-TICKET cannot include documents with re-entry schedules before the insurance expiry. Refunds for the certificate of entry and exit are based on the final date of exit within the term of insurance.)
        <br />
        <br />
        <h6>Term 11. Renewal of Easy Travel Care Subscription</h6>
        1. If the subscriber's duration of entitlement expires while staying in Korea, the subscriber shall inform the center via SOS calls or emails before the expiry date.
        <br />
        2. Renewal will be limited if the duration of entitlement has already expired or the subscriber does not complete the renewal process within the expiry date.
        <br />
        3. Renewal and insurance would be limited if the subscriber has diseases or symptoms caused by accidents or illness before the commencement of the renewed service and insurance payments of Easy Travel Care may be exempted.
        <br />
        <br />
        <h6>Term 12. Subscription for Group Insurance etc.</h6>
        1. Easy Travel Care, as a contractor for members and subscribers, can conclude a group insurance contract with the insurance company and provide the service.
        <br />
        2. Members and subscribers who wish to set Easy Travel Care as the insurant of the concluded group insurance contract could proceed by subscribing to a plan that includes the insurance service.
        <br />
        3. Members and subscribers who wish to subscribe to a plan that includes the insurance service shall thoroughly read the terms and conditions of the affiliated insurance company and follow the requirements of Easy Travel Care.
        <br />
        4. If subscribing to the plan that includes the insurance service, the beneficiary would be the insurant and the legal heir.
        <br />
        5. For group insurance policies that are not specified in this document, the terms, and conditions of the affiliation agreement will be applied.
        <br />
        <br />
        <h6>Term 13. Travel Insurance Convenience Support</h6>
        Travel insurance within the Easy Travel Care plan is group insurance of "PREMIUMPASS INTERNATIONAL Corp." that provides group discounts. Each plan provides optimal insurance coverage and a subscription fee. Insurance claims, proportional insurance coverage, and final guidance to the insurance company are also provided.
        <br />
        <br />
        <h6>Term 14. Restrictions on Easy Travel Care Insurance Service Support</h6>
        Easy Travel Care would not provide services occurred by the following reasons. If service is delivered due to the subscriber's request, additional costs may be incurred.
        <br />
        ① Services that are not listed in the subscription certificate
        <br />
        ② Intentional or grave negligence of the subscriber or guardian (including the affiliates)
        <br />
        ③ All medical costs of the subscriber's illness or casualties, such as diagnosis expenses, medical expenses, and medication costs
        <br />
        ④ Death penalty, self-harm, suicide, suicide attempt, and any crimes or violence designated by law. However, support is provided if admitted as self-defense or emergency evacuation
        <br />
        ⑤ Insanity or mental illness
        <br />
        ⑥ Injury, diseases, or complications resulting from the treatments from other medical institutions without the approval of Easy Travel Care and its affiliates <br />
        ⑦ All expenses incurred by a companion who is not a subscriber of Easy Travel Care.
        <br />
        ⑧ Infectious diseases picked up on the return from certain countries and uncooperative actions to government measures, such as vaccinations or travel restrictions.
        <br />
        ⑨ Services generated by the subscriber's voluntary entrance to war, overseas armed force, revolutions, civil war, riots, disturbances, labor disputes, and other similar incidents.
        <br />
        ⑩ Radioactive, explosive, and other accidents caused by nuclear fuel (Includes the used ones, same for below cases) or substances contaminated by nuclear fuel (includes nuclear fission products)
        <br />
        ⑪ If the purpose of the tour is revealed to be medical practice or pregnancy, childbirth, abortion, and other surgical operations that are uncaused by accidents or accidental illnesses
        <br />
        ⑫ Earthquake, eruption, tsunami, or similar natural disasters.
        <br />
        ⑬ Other reasons for exemption under this agreement.
        <br />
        <br />
        <h6>Term 15. Solution of Conflict</h6>
        1. Easy Travel Care and its subscribers should endeavor to resolve service-related disputes.
        <br />
        2. If a lawsuit is filed due to disputes between the two parties, notwithstanding the regulation above (15.1), the competent court for all legal matters concerning the contractual relationship will be in Republic of Korea. <br />
        3. All disputes over contracts would conform to the law of Republic of Korea. Insurance-related disputes would conform to the general and special terms of the affiliated insurance company.
        <br />
        <br />
        PREMIUMPASS INTERNATIONAL Corp. Unit 501, Bldg. A, 22 Magokjungang 4-ro, Gangseo-gu, Seoul, Republic of Korea
        <br />
        PREMIUMPASS INTERNATIONAL Corp. Care Center +82-2-2063-3544
        <br />
        PREMIUMPASS INTERNATIONAL Corp. Care Center’s E-mail care@tothepp.com <br />
        PREMIUMPASS INTERNATIONAL Corp. Care Center’s KakaoTalk ID (@Easy Travel Care CENTER) <br />
        PREMIUMPASS INTERNATIONAL Corp. Subscription Counseling 1644-1493
        <br />
        PREMIUMPASS INTERNATIONAL Corp. Fax FAX +82-2-2138-0164
        <br />
        <br />
      </>
      <h6>{language['현대해상 화재보험 약관']}</h6>
      <>
        <h6>Data Processing Agreement</h6>
        Matters related to the Collection of Personal Credit Information
        <br />
        Purpose of Collection and Use of Personal Credit Information
        <br />
        - Maintaining the Insurance Underwriting (Including Resurrection and Renewal)
        <br />
        - Examining the Insurance Payment
        <br />
        - Calculating the Net Insurance Premium Rate
        <br />
        - Handling Civil Appeals
        <br />
        - Investigating the Propriety and Accidents (Including Insurance Fraud)
        <br />
        - Maintaining the order of Insurance Business Act
        <br />
        - Financial Transaction (Applying Financial Transaction and Direct Debit)
        <br />
        <br />
        Contents of Personal (Credit) Information to collect and use.
        <br />
        - Personal Identification Information (Name, Resident Registration Number, Passport Number, Address, Gender, Job, Mobile, E-mail Address)
        <br />
        - Financial Transaction Related Information (Receipt of Insurance Premium)
        <br />
        <br />
        Holding Period of Personal (Credit) Information
        <br />
        - Up to 5 years from the Date of Personal Information Collection and Usage Agreement
        <br />
        <br />
        <h6>Matters Related to Personal Information(Credit) Inquiry</h6>
        Purpose of Personal Information(Credit) Inquiry
        <br />
        - Maintaining the Insurance Underwriting (Including Resurrection and Renewal), Examining the Insurance Payment, Investigating the Propriety and Accidents (Including Insurance Fraud)
        <br />
        <br />
        Contents of Personal Information(credit) to Inquire
        <br />
        - Credit Information Collection Agency (Korea Life Insurance Association, General Insurance Association of Korea, Korea Credit Information Services) and Premium Rate Insurance Contract Information that output agencies collect and manage, Information about the Insurance Payment (Including accident information, information about disease and injury) of the insured.
        <br />
        <br />
        Expiration Date of Agreement on Information Inquiry and Holding Period of Personal Information(credit)
        <br />
        - Up to 5 years after Complete Transaction from the Date of Personal Information Collection and Usage Agreement. However, five years after the transaction is completed, the personal information is collected and kept separately only for insurance payment, financial accident investigation, prevention of insurance fraud, handling of civil appeals, and fulfillment of legal obligations.
        <br />
        <br />
        <h6>Agreement on Conclusion of the Insurance Contract</h6>
        Agreement on Regulations of Group Insurance
        <br />
        Article 1. (Purpose)
        <br />
        The purpose of this regulation is to make agreements between members regarding the usage of service provided for members of Premiumpass International Co., Ltd.
        <br />
        <br />
        Article 2. Group Insurance Subscription
        <br />
        1. Premium Pass International Co., Ltd. can settle a group insurance contract with the insurance company for its members as a contractor.
        <br />
        2. Members who wish to take out a group insurance contract in which Premiumpass International is a contractor can apply for insurance through the specific formalities.
        <br />
        3. Members who wish to take out an insurance shall faithfully respond to the requests made by the insurance company and Premiumpass International.
        <br />
        <br />
        <h6>Agreement on Processing of Personal Information(credit) for the Conclusion and the Fulfillment of a Contract</h6>
        Matters concerning the Collection and Use of Personal Credit Information
        <br />
        We would like to collect and use your personal information(credit) regarding this contract based on the law of personal information protection act and protection of the credit information.
        <br />
        Do you agree as above? <br />
        <br />
        Purpose of Collection and Use of Personal Credit Information
        <br />
        - Maintaining the Insurance Underwriting (Including Resurrection and Renewal)
        <br />
        - Examining the Insurance Payment
        <br />
        - Calculating the Net Insurance Premium Rate
        <br />
        - Handling Civil Appeals
        <br />
        - Investigating the Propriety and Accidents (Including Insurance Fraud)
        <br />
        - Maintaining the order of Insurance Business Act
        <br />
        - Financial Transaction (Applying Financial Transaction and Direct Debit)
        <br />
        <br />
        Contents of Personal Information(credit) to collect and use
        <br />
        - Personal Identification Information (Name, Resident Registration Number, Passport Number, Address, Gender, Job, Mobile, E-mail Address.)
        <br />
        - Financial Transaction Related Information (Receipt of Insurance Premium)
        <br />
        <br />
        Holding Period of Personal Information(credit)
        <br />
        - Up to 5 years from the Date of Personal Information Collection and Usage Agreement
        <br />
        <br />
      </>
      <h6>{language['이용약관']}</h6>
      <>
        <h6>Article 1 (Purpose)</h6>
        These terms and conditions are for using the e-commerce service (hereinafter referred to as the service) provided through the easytravelcare Internet site (hereinafter referred to as the “site”) operated by Premium Pass International (hereinafter referred to as the “Company”). <br />
        Its purpose is to stipulate the rights, duties and responsibilities of members.
        <br />
        These terms and conditions apply mutatis mutandis to e-commerce transactions using PC communication, smart phone (Android phone, iPhone, etc.) apps, wireless, etc., unless contrary to their nature.
        <br />
        <br />
        <h6>Article 2 (Definition of Terms)</h6>
        The definitions of terms used in the Terms and Conditions are as follows.
        <br />
        <br />
        1. “Site” means an internet website created and operated by the company so that users registered as members of the company can receive various information and services.
        <br />
        The "site" currently operated by the "company" (as of January 2023) is as follows.
        <br />
        * www.easytravelcare.co.kr
        <br />
        - Other websites announced and provided by the "Company" in the future
        <br />
        <br />
        2. "Seller" refers to a person who receives sales agency service from the "company" for smooth sales of products or services.
        <br />
        However, in the case of open market services, direct information on transaction information such as products and services purchased by "members" It means the one who is responsible.
        <br />
        <br />
        3. "Member" means a user who has registered as a member by providing personal information to the "Site" (hereinafter referred to as the Company), and freely accesses the "Site" with the ID set during membership registration to receive information provided by the Company. <br />
        It refers to a user who can be continuously provided with information or who can continuously use the service of the “Company”.
        <br />
        <br />
        4. "Products" refers to products or coupons that the "Company" sells to members through the "Site".
        <br />
        <br />
        5. "ID" refers to a combination of letters or numbers selected by a "member" and approved by the company for the purpose of identifying the "member" and using the service.
        <br />
        <br />
        6. "Password" refers to a combination of letters and numbers set by the "member" himself and registered on the "site" for the purpose of verifying the identity of the "member" and protecting the rights and interests of the "member".
        <br />
        <br />
        7. Reservation Confirmation Voucher: Refers to digital securities that can be used at the price of purchasing goods and services (hereinafter referred to as “products, etc.”).
        <br />
        <br />
        8. Bulletin board: Refers to texts, photos, videos, and various files and links posted on the "site" by "members" in the form of codes, texts, and voices when using the "service".
        <br />
        <br />
        9. "Chat" refers to a chat service that can be used when you want to consult an emergency service after purchasing a product.
        <br />
        <br />
        10. Terms not defined in these terms and conditions shall be governed by the relevant laws and regulations.
        <br />
        <br />
        <h6>Article 3 (Specification, explanation and revision of terms and conditions, etc.)</h6>
        1. "Company" refers to the contents of these terms and conditions, the company's "company name", business location, representative's name, business registration number, contact information (telephone number, fax number, e-mail address, etc.), mail-order business report number, personal information It is posted on the initial “service” screen (front) of the “site” so that members can easily check it together with the manager. <br />
        However, the specific contents of the terms and conditions can be viewed by the member through the connection screen.
        <br />
        <br />
        2. "Company" refers to the Act on the Regulation of Terms and Conditions, <br />
        the Act on Consumer Protection such as Electronic Commerce, the Framework Act on Electronic Documents and Electronic Transactions, <br />
        the Electronic Signature Act, the Act on Promotion of Information and Communications Network Utilization, <br />
        the Consumer Framework Act, and the Electronic Financial Transaction Act. <br />
        These terms and conditions may be amended to the extent that they do not violate the law.
        <br />
        <br />
        3. If the "company" revises the terms and conditions, the date of application and the reason for the revision shall be specified and announced along with the current terms and conditions on the notice or initial screen of the "site" from 7 days before the effective date to the day before the effective date. <br />
        However, in the case of unfavorable changes that significantly affect “members”, notice will be made 30 days in advance.
        <br />
        <br />
        4. Under the preceding paragraph, the revised terms and conditions apply only to contracts entered into after the date of application, and are not retroactively applied prior to the date of application of the revised terms and conditions unless there are special provisions in the law or other unavoidable reasons.
        <br />
        <br />
        5. If a member continues to use the “service” of the “company” after the effective date announced in accordance with the preceding paragraph, <br />
        he/she is deemed to have agreed to the revised terms and conditions. <br />
        “Members” who do not agree to the revised terms and conditions may freely terminate the “service” use contract at any time.
        <br />
        <br />
        6. Matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions are related to the Consumer Protection Act in Electronic Commerce, etc., the Act on the Regulation of Terms and Conditions, and the Consumer Protection Guidelines in Electronic Commerce, etc. set by the Fair Trade Commission.
        <br />
        In accordance with the provisions of the law and general commercial practices.
        <br />
        <br />
        <h6>Article 4 (Individual Service Terms and Conditions)</h6>
        The “company” may have separate terms and conditions for “individual services” within the “services” it provides for each “service”, and consent to the terms and conditions separately applied in “individual services” is referred to as “corresponding service”. <br />
        When using for the first time, it is considered that you have agreed to the contents of the “service” after going through a separate consent procedure, and in this case, the terms of use for “individual service” take precedence over these terms and conditions.
        <br />
        <br />
        <br />
        <h6>Article 5 (Suspension of Service Provision)</h6>
        1. "Company" may stop providing "Service" in the following cases.
        <br />
        1) In the case of unavoidable cases due to construction such as maintenance, replacement, and breakdown of facilities using the "service"
        <br />
        2) In case the telecommunications business operator stipulated in the Telecommunications Business Act has stopped the telecommunications "service“
        <br />
        3) In case of other force majeure reasons
        <br />
        <br />
        2. The “Company” may limit or suspend all or part of the “Service” when there is a problem with the normal use of the “Service” due to a national emergency, power outage, failure of the “Service” facility, or excessive use of the “Service”. there is.
        <br />
        <br />
        <h6>Article 6 (Establishment of contract of use)</h6>
        1. The use contract is established after the “member” agrees to the terms and conditions and the “company” approves the application for use.
        <br />
        <br />
        2. The establishment of the contract of use shall be at the time when the “company” approves or displays the indication of subscription completion in the application process.
        <br />
        <br />
        <h6>Article 7 (Application for Use and Acceptance)</h6>
        1. "Company" may refuse approval or cancel reservation and post-use contracts for applications that fall under any of the following subparagraphs.
        <br />
        1) If the name and e-mail address of the already registered “member” are the same
        <br />
        2) In case of stealing someone else's name, false information, or omission of information
        <br />
        3) If false information is entered in the registration details or there is a typo
        <br />
        4) If the applicant for use has previously lost membership in accordance with these Terms and Conditions
        <br />
        5) Exceptions are made when the “company” obtains approval for member re-registration of the “company” after 3 months have elapsed after the loss of user qualification.
        <br />
        6) If you want to use the “service” for fraudulent purposes or for the purpose of profit-seeking (However, use of the service within the scope approved by the “company” is an exception.)
        <br />
        7) If there is no room for related facilities due to the operation of the “service”, or there is a technical or business obstacle or problem,
        <br />
        however, approval may be withheld until the cause is resolved.
        <br />
        8) In case of application in violation of related laws, morals, social well-being and order, or other matters, or if approval is not possible due to reasons attributable to the member
        <br />
        9) Other cases where it is confirmed that the application is in violation of these terms and conditions, illegal or unfair, or when the “company” deems it necessary based on reasonable judgment
        <br />
        <br />
        3. In the application for use pursuant to Paragraph 1, the “Company” may request real name verification and identity verification through a specialized institution.
        <br />
        <br />
        4. The “company” subdivides the number of times of use and “service” menu for “members” and classifies them by grade according to company policy.
        <br />
        <br />
        5. "Company" may restrict use of "members" or restrictions by grade according to age compliance and grade according to the Youth Protection Act and the Act on the Promotion of Movies and Videos.
        <br />
        <br />
        <h6>Article 8 (Service Hours)</h6>
        In principle, the use of the “service” is 24 hours a day, 365 days a year,
        <br />
        if there is no special obstacle to the business or technology of the “company”. However, the day or time notified by the "company" for reasons such as regular inspection is excluded.
        <br />
        <br />
        <h6>Article 9 (Obligations regarding member ID and password)</h6>
        1. The “member” is entirely responsible for managing “ID” and “password”, and all civil and criminal responsibilities arising from negligence are the “member” himself.
        <br />
        <br />
        2. “Members” must not rent and use their “ID” and “Password” to a third party.
        <br />
        <br />
        3. “Members” should immediately notify the “company” if they recognize that their “ID” and “password” are stolen or used by a third party, and follow the measures of the “company”.
        <br />
        <br />
        4. “Members” are solely responsible for any disadvantages caused by the “members” not notifying or not responding to the measures of the “company” in accordance with the preceding paragraph.
        <br />
        <br />
        <h6>Article 10 (Cancellation and Termination of User Agreement)</h6>
        1. Termination of “Membership”
        <br />
        1) “Member” may terminate the use contract (request withdrawal of membership) by contacting the “Company” at any time and notifying the intention to cancel, and may request deletion of the “Member” account registration data.
        <br />
        2) The contract of use is terminated when the intention of cancellation of the “member” reaches the “company”.
        <br />
        3) “Members” (withdrawal members) who have been terminated in accordance with the preceding paragraph may go through the membership registration procedure according to the terms and conditions set forth by the “Company” and rejoin as members, but the level applied at the time of membership registration prior to cancellation (withdrawal) , Points, etc. retention conditions will be extinguished, and refunds will not be applied.
        <br />
        However, re-registration may be restricted for a certain period of time.
        <br />
        <br />
        2. Termination of the company
        <br />
        1) The "company" may terminate the use contract for the following reasons, and the information retained about the "member" is subject to the privacy policy.
        <br />
        ⓵. If it is confirmed that there is a reason for refusal to accept the use contract set forth in Article 7 Paragraph 1<br />
        ⓶. In case a "member" violates the rights, honor, credit or other legitimate interests of the "company" or other "members" or others, or violates good morals or other social order, or the laws of the Republic of Korea
        <br />
        ⓷. In the case of promoting distrust with unconfirmed facts and contents about the “products” or “services” sold by the “Company”
        <br />
        ⓸. In case of an attempt to interfere with the smooth progress of the site, such as duplicate purchase, for the purpose of disrupting transaction order
        <br />
        ⓹.In case other "members" by reasonable judgment of "company" act in violation of these terms and conditions
        <br />
        ⓺. The "Company" notifies the member of the reason for termination by expressing its intention to terminate the membership through e-mail, telephone, fax or other methods.
        <br />
        However, the company gives the member an opportunity to express his or her opinion on the reason for cancellation in advance.
        <br />
        <br />
        4. If the contract of use is terminated in accordance with this clause, the “member” will lose the right to use the service, all personal information and records of the member will be deleted, and the company will process refunds of pre-purchased products, etc. In accordance with the refund policy of
        <br />
        <br />
        5. Damages incurred in connection with the termination of the contract of use shall be borne by the "member" whose contract of use has been terminated.
        <br />
        and the “Company” does not take any responsibility.
        <br />
        <br />
        <h6>Article 11 (Obligations of Members)</h6>
        1. "Members" must comply with the contents notified by the "Company", such as related laws, these terms and conditions, and user guides, and must not engage in other acts that interfere with the "Company" business.
        <br />
        <br />
        2. “Members” must not engage in any of the following acts in relation to the use of “services”.
        <br />
        <br />
        1) Registration of false information when applying for or changing "service"
        <br />
        2) Stealing other people's information, etc.
        <br />
        3) Unauthorized change of information posted by "Company“
        <br />
        4) Transmission or posting of information (computer programs, etc.) other than the information set by the "Company“
        <br />
        5) Infringement of intellectual property rights such as copyright and portrait rights of "company" and other third parties
        <br />
        6) Acts that damage the reputation of the "Company" and other third parties or interfere with their work
        <br />
        7)The act of disclosing or posting obscene or violent messages, videos, voices, or other information that is against public order or morals on the website
        <br />
        8) Acts that violate other related laws or regulations set by the "Company“
        <br />
        <br />
        <h6>Article 12 (Obligations of the Company)</h6>
        1. The "company" shall not prohibit any act against the law and these terms and conditions or against public order and morals, and shall do its best to provide "service" continuously and reliably as stipulated in these terms and conditions.
        <br />
        <br />
        2. The “company” must have a security system to protect the personal information (including credit information) of “members” so that “members” can safely use the “service” and disclose and comply with the privacy policy.
        <br />
        <br />
        <h6>Article 13 (Purchase application and consent to provide personal information, etc.)</h6>
        1. "Members" apply for purchases on the "site" by the following or similar methods, and the "company" must provide each of the following in an easy-to-understand manner when users apply for purchases.
        <br />
        1). Search and select products, etc.
        <br />
        2). Input of reservation information related to product use (date, etc.)
        <br />
        3). Enter the name, nationality, passport number, resident registration number, e-mail address (or mobile phone number) of the person making the reservation and receiving the service
        <br />
        4). Confirmation of contents related to cost burden such as composition of each product, contents of included items and terms and conditions, service with limited right to withdraw subscription, etc.
        <br />
        5). Indicate that you agree to these terms and conditions and confirm or reject the items in subparagraph 4 above (e.g. mouse clicks, etc.)
        <br />
        6). Consent to request for purchase of goods, etc. and confirmation thereof or confirmation of the "company (site)“
        <br />
        7). Choice of payment method
        <br />
        <br />
        2. The company notifies the member of the purchase confirmation when there is a purchase request from the member, and the purchase contract is established at the time this notice arrives.
        <br />
        <br />
        3. If the “company” provides or needs to provide buyer’s personal information to a third party, the person to whom the provided personal information is provided, the purpose of using the personal information of the person to whom the personal information is provided, the items of personal information provided, and the individual The period of retention and use of the personal information of the person receiving the information must be notified to the purchaser and consent must be obtained. (The same applies even if the consent is changed.)
        <br />
        <br />
        4. If the "company" entrusts a third party with the task of handling the purchaser's personal information, the person entrusted with the handling of personal information and the details of the task entrusted with the handling of personal information must be notified to the purchaser and consent must be obtained. <br />
        (The same applies even if the consented items are changed.)
        <br />
        However, if it is necessary for the execution of the contract for the provision of services and related to the improvement of the purchaser's convenience, <br />
        personal information is handled in the manner prescribed in Promotion of Information and Communications Network Utilization and Information Protection, etc. By notifying through the policy, you do not have to go through the notification and consent procedures.
        <br />
        <br />
        <h6>Article 14 (Establishment of Contract)</h6>
        1. "Company" and "Members" agree to abide by the contents of each product page regarding the time taken to confirm the reservation of each purchased product.
        <br />
        <br />
        2. "Company" may not accept purchase requests from "members" as in Article 13 if they fall under any of the following cases.
        <br />
        1) If there is falsehood, omission, or typo in the application
        <br />
        2) If it is judged that accepting the other purchase request is significantly impeded by the technology of the "company", and the provision of the "product" and "service" requested for purchase due to reasons such as information misrepresentation, omission of information, system error, out of stock, etc. not allowed
        <br />
        However, in this case, the "company" must notify the "member" afterward and take necessary measures.
        <br />
        3) In case of requesting a purchase by stealing a third party's ID, name or payment method
        <br />
        4) If it is suspected that the purchase of “products, etc.” or for resale purposes is unfair in the context of the purchase application
        <br />
        5) Depriving other users of purchasing opportunities by indiscriminately repeating product purchase and return without intention to purchase
        <br />
        6) In the case of abnormal transactions using discount coupons, events, etc. provided by the “Company”
        <br />
        7) In case of application for the purpose of violating related laws or hindering social order or morals
        <br />
        8) If it is confirmed that it is an unfair use application that violates or abuses these terms and conditions, and the "Company" deems it necessary based on reasonable judgment
        <br />
        <br />
        3. The contract is considered to be concluded when the consent of the “Company” reaches the “Member” in the form of a confirmation of receipt pursuant to Article 19 Paragraph 1.
        <br />
        <br />
        4. The "company"'s declaration of intention to accept must include information on the confirmation of the purchase request of the "member" and whether or not it can be sold, correction and cancellation of the purchase request, etc.
        <br />
        <br />
        <h6>Article 15 (Payment method)</h6>
        1. The payment method for goods or services purchased on the “website” can be made by any of the following methods.
        <br />
        However, "Site" cannot collect any nominal fee in addition to the price of goods, services, etc. for the user's payment method.
        <br />
        1) Cash, credit card, etc.
        <br />
        2) Various Pay
        <br />
        3) Other payment methods and electronic payment methods additionally designated by the "Company", etc.
        <br />
        <br />
        2. The information entered by the “member” in relation to the payment of the purchase price and the responsibility and disadvantages arising from the information are entirely borne by the member.
        <br />
        <br />
        3. The "Company" can verify the identity of the payment method used by the "Member" or whether there is a legitimate right to use the payment method used by the "Member".
        <br />
        if it’s impossible, the transaction may be canceled or withholding a refund.
        <br />
        <br />
        4. The "Company" issues a purchase certificate (cash receipt, etc.) for the actual purchase amount upon request by a "Member".
        <br />
        <br />
        <h6>Article 16 (Transmission of Reservation Confirmation or Voucher)</h6>
        1. The "Company" sends the details of the products purchased by the "Member" on the "Site" via SMS or e-mail.
        <br />
        <br />
        2. The “reservation confirmation or voucher” transmission operation may be changed depending on holidays and the working hours and holidays of the “Company” or the situation of the processing process, which is in accordance with the contents specified in the “site”.
        <br />
        <br />
        <h6>Article 17 (Notification of Receipt. Change and Cancellation of Purchase Request)</h6>
        1. The "site" notifies the "member" of receipt when there is a purchase request from the "member".
        <br />
        <br />
        2. The "member" who received the acknowledgment notice can request change or cancellation of the purchase application immediately after receiving the acknowledgment notice if there is any discordance in the expression of intent, <br />
        and the "website" can request a change or cancellation of the purchase request before the reservation is confirmed. <br />
        In this case, the request must be processed without delay. <br />
        However, if payment has already been made, the provisions of Article 20 regarding subscription withdrawal, etc. shall be followed.
        <br />
        <br />
        <h6>Article 18 (Withdrawal of subscription, etc.)</h6>
        1. "Company"'s purchase cancellation and subscription withdrawal regulations comply with relevant laws such as the Consumer Protection Act in Electronic Commerce, etc.
        <br />
        <br />
        2. In the case of cancellation or refund due to a simple change of mind of a "member", the "member" shall bear the handling fee.
        <br />
        <br />
        3. "Members" may withdraw the subscription within 30 days from the date of supply of the "products, etc." if the contents of "products, etc.“
        <br />
        <br />
        4. In the case of a "member" who paid with a credit card or electronic money, request the business that provided the payment method to suspend or cancel the payment without delay.
        <br />
        <br />
        5. When canceling the purchase, the previously paid details are canceled and the reservation cancellation fee is re-approved according to the time of cancellation. In this case, the refund method and refund date of the cancellation amount may differ slightly depending on the time of cancellation of the purchase and the refund processing standard of the credit card company.
        <br />
        <br />
        6. If a "member" steals other people's personal information and is suspected of defrauding unfair profits after making a reservation in an illegal way prohibited by these terms and conditions, the "company" may cancel or restrict the "member"'s ticket purchase, and " Refunds may be withheld until the "member" submits sufficient explanatory materials.
        <br />
        <br />
        7. Other cancellations and refunds that are not stipulated in these terms and conditions and the user guide of the "site" are subject to the consumer damage compensation regulations.
        <br />
        <br />
        <h6>Article 19 (Special Agreement on Product Refund)</h6>
        1. “Members” cannot request a refund or exchange in the following cases after purchasing the service.
        <br />
        1) Simple change of mind after service starts is not eligible for refund and is subject to the sales regulations of each “seller”.
        <br />
        <br />
        2. If the "company" makes a refund according to the product return procedure, the refund will be made after confirming that negotiations with the "seller" or "insurance company" have been completed.
        <br />
        <br />
        <h6>Article 20 (Personal Information Protection)</h6>
        1. "Site" collects the minimum personal information to the extent necessary to provide services when collecting personal information of "members".
        <br />
        <br />
        2. "Site" does not collect information necessary for fulfillment of purchase contract in advance when signing up for membership. However, this is not the case when minimum specific personal information is collected in the case where identity verification is required prior to the purchase contract to fulfill obligations under the relevant laws and regulations.
        <br />
        <br />
        3. When the “site” collects and uses the personal information of “members”, it notifies the user of the purpose and obtains consent.
        <br />
        <br />
        4. “Site” cannot use the collected personal information for any purpose other than the purpose, and when a new purpose of use occurs or when it is provided to a third party, the “member” is notified of the purpose and consent is given during the use and provision stage. receive. However, exceptions are made in cases where the relevant laws and regulations stipulate otherwise.
        <br />
        <br />
        5. When the "site" needs to obtain the user's consent according to paragraphs 2 and 3<br />
        individual
        <br />
        Information such as the identity of the person in charge of information management (affiliation, name and phone number, other contact information), purpose of collection and use of information, matters related to the provision of information to third parties (recipient, purpose of provision and content of information to be provided), etc. The matters stipulated in Article 22, Paragraph 2 of the Act on Promotion of Communication Network Utilization and Information Protection, etc. must be specified or notified in advance, and "members" can withdraw this consent at any time.
        <br />
        <br />
        6. "Members" may request to view and correct errors on their personal information possessed by the "company" at any time, and the "company" is obliged to take necessary measures without delay. If the "company" requests correction of an error, the "company" will not use the personal information until the error is corrected. However, the procedure for reading and correcting errors is in accordance with the "Company".
        <br />
        <br />
        7. "Company" shall limit the person who handles personal information of "member" to a minimum in order to protect personal information, and the loss, theft, leakage, or unauthorized removal of personal information of "member" including credit card, bank account, etc. We take full responsibility for damages of "members" due to third party provision, falsification, etc.
        <br />
        <br />
        8. The "company" or a third party who receives personal information from it destroys the personal information without delay when the purpose of collecting or receiving the personal information is achieved.
        <br />
        <br />
        9. "Company" does not set the consent column for the collection, use, and provision of personal information to a pre-selected one. In addition, the services restricted when the "member" refuses to consent to the collection, use, and provision of personal information are specified in detail, and the "member" refuses to consent to the collection, use, and provision of non-essential personal information. We do not limit or refuse to provide services such as membership registration.
        <br />
        <br />
        <h6>Article 21 (Attribution of Copyright and Restrictions on Use)</h6>
        1. Copyright and other intellectual property rights for the works created by the “Site” belong to the “Site”.
        <br />
        <br />
        2. "Members" may reproduce, transmit, publish, distribute, broadcast or otherwise copy information, images and data obtained by using the "site", the intellectual property rights of which are attributed to the "site", without the prior consent of the "site" You must not use it for commercial purposes or let a third party use it.
        <br />
        <br />
        3. "Members" arbitrarily process, copy, transmit, depart, distribute, broadcast or otherwise use the data images of products purchased on the "site" for commercial purposes or use them for third parties without prior consent from the "site" shouldn't make it happen.
        <br />
        <br />
        <h6>Article 22 (Restriction or Suspension of Membership Qualification)</h6>
        1. “Members” may request withdrawal from the “Company” at any time, and the “Company” will immediately process membership withdrawal.
        <br />
        <br />
        2. If a “member” falls under any of the following reasons, the “company” may immediately limit or suspend membership.
        <br />
        1) In the case of registering false information when applying for or changing membership
        <br />
        2) If the member does not pay the debts borne by the member in relation to the use of the “site” and the payment for products purchased using the “site” on time
        <br />
        3) Threatening the order of e-commerce, such as interfering with other people's use of the site or stealing the information
        <br />
        4) In the case of using the “site” to act against the law, these terms and conditions, or against public order and morals
        <br />
        5) In case of harming the sound operation of the “site” or interfering with the business of the site
        <br />
        A. In case of harming the credibility of the “Company” by indicating or disseminating unfounded facts and false facts in relation to the operation of the “site”
        <br />
        B. In case of interfering with the operation of the site by abusive language, threats, or obscene words and actions to "company" employees in the process of using the "site"
        <br />
        all. In the case of interfering with the company's business by habitually or repeatedly canceling/returning products without justifiable reasons after purchasing products, etc.
        <br />
        D. If you interfere with the business of the "Company" by habitually canceling/returning all or part of the product after using it even though there is no special defect in the product after purchasing through the "Site"
        <br />
        mind. If you interfere with the business of the “Company” due to simple personal change of heart, groundless dissatisfaction, etc., even though there are no special defects in products purchased through the “Site”
        <br />
        <br />
        <h6>Article 23 (Disclaimer)</h6>
        1. "Company" is exempted from responsibility for providing "service" if it is unable to provide "service" due to natural disasters or equivalent force majeure.
        <br />
        <br />
        2. The "company" is not responsible for any obstacles in using the "service" due to reasons attributable to the "member".
        <br />
        <br />
        3. The "company" is not responsible for the reliability and accuracy of facts such as information and materials posted by "members".
        <br />
        <br />
        4. The “Company” does not take any responsibility for “services” between “members” or between “members” and third parties.
        <br />
        <br />
        5. The "Company" shall not be held liable for any loss of expected revenue obtained by the "Member" using the "Service" and damage to data obtained through the "Service".
        <br />
        <br />
        <h6>Article 24 (Dispute Resolution)</h6>
        1. "Site" installs and operates a damage compensation processing organization to reflect legitimate opinions or complaints raised by "members" and compensate for the damages.
        <br />
        <br />
        2. "Site" takes priority over complaints and opinions submitted by users. However, if prompt processing is difficult, the user will be immediately notified of the reason and processing schedule.
        <br />
        <br />
        <h6>Article 25 (Jurisdiction and Governing Law)</h6>
        1. Lawsuits regarding e-commerce disputes between the “site” and “members” are based on the address of the “member” at the time of filing, and if there is no address, the district court having jurisdiction over the place of residence shall have exclusive jurisdiction. However, if the address or residence of the user is not clear at the time of filing, or if the user is a foreign resident, the complaint shall be brought to the competent court under the Civil Procedure Act.
        <br />
        <br />
        2. Korean law applies to e-commerce lawsuits filed between the “site” and “members”.
        <br />
        <br />
        Addendum
        <br />
        1. These Terms and Conditions are effective from January 1, 2021.
        <br />
        <br />
      </>
      <>
        <h6>Privacy Policy</h6>
        We, PREMIUMPASS INTERNATIONAL Corp. shall acknowledge the importance of personal information and hereby pledge to devote thorough efforts to the proper treatment and protection of personal information, based on the following policy. <br />
        <br />
        <h6>1. Use of Personal Information</h6>The information acquired is used to provide Easy Care and Travel Insurance services. Optional items are not necessary for service.
        <br />
        <br />
        <h6>[Collected Information Upon Registration]</h6>
        -Required: Name, Email, Date of Birth, Gender, Identification Number (Residents), Passport Number (Foreigners), Phone Number
        <br />
        -The following information can be created during service or proceedings: service usage history, access log, cookies, access IP information
        <br />
        <br />
        <h6>2. Purpose of Collection</h6>
        We shall use the personal information that we acquired only for the purpose of the following. We shall not use such personal information for any purpose other than the purpose of attainment of those objectives without consent. <br />
        - Membership registration and management, personal verification, prevention of fraud, prevention of unjust use
        <br />
        - Registration for Hyundai Marine & Fire Insurance Group, Worry-free Care Service, transactions and services, Payments Receipt <br />
        - Customer Service
        <br />
        - Advertising and Marketing related
        <br />
        <br />
        <h6>3. Retention and Proceeding of Personal Information</h6>
        Personal information of Members is immediately discarded after the purposes have been fulfilled. <br />
        - Membership information: retained until withdrawal or expulsion
        <br />
        - History of contracts, subscription withdrawal, payments, and supply of goods: up to 5 years
        <br />
        - Customer complaints, dispute resolution: up to 3 years
        <br />
        - Information for advertisements and marketing: up to 3 years, However, there are exclusions such as: <br />
        1. Incomplete settlement of fees
        <br />
        2. If it is considered necessary for protecting other members
        <br />
        3. If required under the commercial laws or other regulations
        <br />
        4. If it serves as evidence for disputes or lawsuits
        <br />
        5. If the member agreed to additional retention
        <br />
        6. If it is considered necessary for retention, based on the company policy
        <br />
        For the above cases as well, the additional retention period will be accurately notified to the members and the information will be discarded immediately after the designated date.
        <br />
        <br />
        <h6>4. Deletion of Personal Information</h6>
        PREMIUMPASS INTERNATIONAL Corp. shall immediately delete the acquired personal information after the fulfillment of the purpose of personal information processing and the expiry of the retention period. <br />
        - Personal information saved as digital files would be unable to be opened after deletion.
        <br />
        - Personal information on paper would be deleted with a grinder or incinerated.
        <br />
        <br />
        <h6>5. Supply of Personal Information to Third Parties</h6>
        We shall not disclose or supply the personal information that we acquired from the Suppliers to any third party, except information that is approved for release by the consent of the members or required by law.
        <br />
        <br />
        Purpose and Information Provided to Third Parties <br />
        Provided information: name, phone number, email, name of the product: electronic payment
        <br />
        Information provided to Hyundai Marine & Fire Insurance Group: name, identification number, passport number (foreigners), gender, phone number, email, name of the product: insurance registration <br />
        The retention period for personal information: from the provision of information to the fulfillment of purpose or the periods specified in term 3.
        <br />
        <br />
        <h6>6. Rights and Obligations of the Principal and the Use of Information</h6>
        You are eligible of terminating the agreement on privacy policy. You can apply for immediate inspection, modification, termination, and deletion of your information by contacting the personal information manager face-to-face or through phone calls and Fax. However, your rights can be limited if there are obligations designated by law.
        <br />
        <br />
        <h6>7. Personal Information Manager</h6>The below staff is in charge of protecting your information and proceeding with personal information-related customer service.
        <br />
        Name: Jinhee Han
        <br />
        Position: Assistant manager
        <br />
        Department: Easy Travel Care
        <br />
        Contact number: 0216441493
        <br />
        <br />
        <h6>8. Measures to Secure Personal Information Safety</h6>All of the information acquired from you is protected through a strong security system. Also, the right to access your information is strictly limited to specific personnel. Training regarding personal information security is constantly performed. A specialist is designated to proceed with your information and manage the log history as well.
        <br />
        <br />
        <h6>9. Information about Modification of Privacy Policy</h6>This privacy policy has been modified on Jan 1, 2021. In case of additional modification on policies, laws, or security technology, it will be notified through the official website 7 days before the effective date.
        <br />
        <br />
        Announcement date: Jan 1, 2021
        <br />
        Enforcement date: Jan 1, 2021
        <br />
        <br />
      </>
      <h6>{language['이메일무단수집거부']}</h6>
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>Collection of the email address specified on this website through email collecting software programs and other technology involved is strictly prohibited by law and would be subject to criminal punishment.</li>
            <li>Act On Promotion of Information and Communications Network Utilization and Information Protection (partial revision 2002.12.18. Act no. 0697)</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">Article 50.2 (Prohibition of Unauthorized Collection of Email Addresses)</h6>
              <ul className="list secondary">
                <li>Collection of the email addresses on websites through email collecting software programs and other technology involved is strictly prohibited.</li>
                <li>Sales or trades of email addresses collected through violation of term 1 are strictly prohibited.</li>
                <li>Transfer of email addresses collected through violation of terms 1 and 2 is strictly prohibited.</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">Article 65.2 (penalty) Whoever corresponds to any of the following is fined 10 million won or less.</h6>
              <ul className="list secondary">
                <li>Whoever took technical measures violating Article 50.4</li>
                <li>Whoever transferred promotional information for commercial purposes violating Article 50.6</li>
                <li>Whoever collected, sold, or traded email addresses violating Article 50.2</li>
              </ul>
            </div>
          </div>
          Posted on Jan 4, 2021
        </div>
      </div>
    </>
  )
}

export default B2BTermENView
