import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axiosConnect from '../../../../network/http-request'
import B2BTermENView from '../terms/B2BTermsENView'
import B2BTermJPView from '../terms/B2BTermsJPView'
import B2BTermCNView from '../terms/B2BTermsCNView'
import B2BTermKRView from '../terms/B2BTermsKRView'

function B2BPurchaseUploadModel(props: any) {
  const language = useSelector((state: any) => state.language.value)

  const { modalOpen, closeModal } = props
  const [title, setTitle] = useState('')
  const [fileName, setFileName] = useState('')
  const [selectFile, setSelectFile] = useState('')
  const [agree, setAgree] = useState(false)

  const handleFileSelect = (event: any) => {
    setSelectFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  const handleUpload = () => {
    if (!agree) {
      alert(language['약관에 동의해주시기 바랍니다.'])
      return false
    }

    if (!selectFile) {
      alert(language['파일을 등록해주세요.'])
      return false
    }

    const formData = new FormData()
    formData.append('file', selectFile)
    formData.append('title', title)

    axiosConnect(`/rest/member/b2c/excelUpload`, 'POST', formData)
      .then((result) => {
        setTitle('')
        setFileName('')
        setSelectFile('')
        setAgree(false)
        closeModal()
      })
      .catch((error) => {
        alert(error)
      })
  }

  const getEasyTravelCareTerm = () => {
    if (language.code === 'en') {
      return <B2BTermENView />
    } else if (language.code === 'jp') {
      return <B2BTermJPView />
    } else if (language.code === 'cn') {
      return <B2BTermCNView />
    } else {
      return <B2BTermKRView />
    }
  }

  return (
    <Modal show={modalOpen} size="lg" centered onHide={() => closeModal()} animation={false}>
      <Modal.Header>
        <h5 className="modal-title" id="excelModalLabel">
          {language['서비스 구매 엑셀 업로드']}
        </h5>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <form className="form-add">
            <hr className="dark" />

            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['제목']}</label>
              <div className="col-md-8">
                <input type="text" className="form-control" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
            </div>
            <hr />
            <div className="form-group row no-gutters align-items-center">
              <label className="col-md-2 col-form-label">{language['파일첨부']}</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input id="fileInput" type="file" placeholder="" onChange={handleFileSelect} style={{ display: 'none' }} />
                  <input type="text" className="form-control" placeholder="" value={fileName} />
                  <label htmlFor="fileInput" className="btn btn-outline-dark ml-2">
                    {language['파일첨부']}
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <h6>{language['You can upload the list only if you agree to the terms and conditions below']}</h6>
            <div className="form-group border terms">{getEasyTravelCareTerm()}</div>
            <div className="form-group row align-items-center">
              <div className="col-12">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" id="customCheckboxInlineT" name="customCheckboxInlineT" className="custom-control-input" checked={agree} onChange={() => setAgree(!agree)} />
                  <label className="custom-control-label" htmlFor="customCheckboxInlineT">
                    <strong>{language['We agree to the regulations and signing up for Easy Care Service.']}</strong>
                  </label>
                </div>
              </div>
            </div>
            <hr className="dark" />
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-dark" onClick={closeModal}>
          {language['취소']}
        </button>
        <button type="button" className="btn btn-primary" onClick={handleUpload}>
          {language['엑셀 업로드']}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default B2BPurchaseUploadModel
