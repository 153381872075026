import { useSelector } from 'react-redux'

function ConciergeIncheonT2View() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="page-header">
              <h2>
                {language['인천국제공항 제2터미널']} <br />
                T2 _ {language['컨시어지데스크']}
              </h2>
            </div>
            <hr className="blue" />
            <ul className="list">
              <li>
                <h6>{language['부스명: MICE Concierge Desk']}</h6>
              </li>
              <li>
                <h6>{language['위치: 제2터미널 4번게이트']}</h6>
              </li>
            </ul>
            <hr className="blue" />
          </div>
          <div className="col-12 col-md-7">
            <img src="/resources/home/img/desk_incheon2.jpg" className="img-fluid img-round" alt="" />
          </div>
        </div>
      </div>

      <div className="section bg-light-blue mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>
                <span className="badge badge-primary">T2</span> {language['Terminal']} 2
              </h3>
              <div className="col-12 offset-md-1 col-md-10 mt-5">
                <p className="text-center">
                  <img src="/resources/home/img/svg/desk_incheon2_map_en.svg" alt="" className="d-block w-100" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConciergeIncheonT2View
