import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateUtil } from '../../../../utils/date-format'

interface QnAListItemProp {
  content: any
}

function QnAListItemView(prop: QnAListItemProp) {
  const language = useSelector((state: any) => state.language.value)
  const category = useSelector((state: any) => state.boardCategory.value)

  const getCategoryName = (faqCategory: string) => {
    let findCategory = category.find((item: any) => item.categoryNo === parseInt(faqCategory))
    return findCategory?.categoryName
  }

  return prop.content.map((qna: any, index: number) => (
    <div key={`qna_${qna.sq}`}>
      <div className="row align-items-center">
        <div className="col-12 col-md-2 date">{getCategoryName(qna.faqCategory)}</div>
        <div className="col-12 col-md-6">
          <Link to={`/help/qna/${qna.faqNo}`}>
            <strong>{qna[`faqTitle`]}</strong>
          </Link>
        </div>
        <div className="col-12 col-md-2 date">{DateUtil.convertDate(new Date(qna[`faqWriteDate`]))}</div>
        {parseInt(qna.replyCounts) > 0 ? (
          <div className="col-12 col-md-2 date">
            <span className="badge badge-pill badge-info badge-md">{language['답변완료']}</span>
          </div>
        ) : (
          <div className="col-12 col-md-2 date">
            <span className="badge badge-pill badge-danger badge-md">{language['답변대기']}</span>
          </div>
        )}
      </div>
      <hr className={prop.content.length - 1 === index ? 'dark' : ''} />
    </div>
  ))
}

export default QnAListItemView
