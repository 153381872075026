function EmailCollectionCNView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>拒绝利用电子邮件收集程序或其他技术设备擅自收集本站公布的电子邮箱地址，如有违返，请留意根据信息通信网法进行刑事处罚。</li>
            <li>信息通信网利用及信息保护等相关法律（部分修订2002.12.18 法律第0697号）</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">第50条第2款（禁址擅自收集电子邮箱地址等）</h6>
              <ul className="list secondary">
                <li>任何人不得在明确标示拒绝收集电子邮箱地址的网站上，利用电子邮件收集程序或其他技术设备擅自收集电子邮箱地址。</li>
                <li>依据第一款规定，任何人不得擅自销售、流通已收集的电子邮箱地址。</li>
                <li>依据第一款及第二款规定，任何人不得在明知是禁止收集、销售及流通的电子邮箱的情况下，仍将其用于信息传送。</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">第65条第2款 （处罚）符合下列内容者，处以1千万韩元以下的罚款。</h6>
              <ul className="list secondary">
                <li>采取违反第50条第4款所规定的技术性措施之人</li>
                <li>传送违反第50条第6款所规定的具有盈利为目的的广告信息之人</li>
                <li>违反第50条第2款规定，将电子邮箱用于收集、销售、流通或者信息传送之人。</li>
              </ul>
            </div>
          </div>
          发布日期：2021-01-04
        </div>
      </div>
    </section>
  )
}

export default EmailCollectionCNView
