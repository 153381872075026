import { createSlice } from '@reduxjs/toolkit'

const initalStateValue = () => {
  const expireTime = 24 * 60 * 60 * 1000
  const userData = window.localStorage.getItem('user')
  if (userData && userData !== 'null') {
    const { user, time } = JSON.parse(userData)
    const now = new Date().getTime()
    if (now - time > expireTime) {
      localStorage.setItem('user', null)
      return null
    } else {
      return JSON.parse(user)
    }
  }
  return null
}

export const userSlice = createSlice({
  name: 'user',
  initialState: { value: initalStateValue() },
  reducers: {
    login: (state, action) => {
      state.value = action.payload
      if (action.payload.memberAuth !== 5) {
        const savedData = {
          user: JSON.stringify(action.payload),
          time: new Date().getTime(),
        }

        localStorage.setItem('user', JSON.stringify(savedData))
      }
    },
    logout: (state) => {
      state.value = null
      localStorage.setItem('user', null)
    },
  },
})

export const { login, logout } = userSlice.actions

export default userSlice.reducer
