import { useSelector } from 'react-redux'

function ChatBotAnswerView(props: any) {
  const language = useSelector((state: any) => state.language.value)
  const { chat, chatBotClick, isEnable, createChatRoom } = props
  function getParentId() {
    return chat.find((answer: any) => answer.chatBotType === 'A').chatBotParent
  }

  return (
    <div className="message-me">
      <div className="message-body">
        <div className="message-box">
          <ul className="chatlist">
            {Array.isArray(chat)
              ? chat
                  .filter((answer: any) => answer.chatBotType === 'A')
                  .map((answer: any, index: number) => {
                    return (
                      <li key={`chat_answer_${answer.chatBotId}_${index}`}>
                        <a href="javascript:;" onClick={() => isEnable && chatBotClick(answer)} dangerouslySetInnerHTML={{ __html: answer.chatBotContent }} />
                      </li>
                    )
                  })
              : null}
            <li>
              <a
                href="javascript:;"
                onClick={() => {
                  createChatRoom(getParentId())
                }}
              >
                <i className="bi bi-headset"></i>
                {language['상담원 연결이 필요해요!']}
              </a>
            </li>
          </ul>
        </div>
        <div className="message-date">{chat.currentTime}</div>
      </div>
    </div>
  )
}

export default ChatBotAnswerView
