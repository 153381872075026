import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import PageHeaderView from '../../common/PageHeaderView'

function FindLoginIdPasswordPage() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <>
      <PageHeaderView image="/resources/home/img/sub2.jpg" title={language['아이디/비밀번호 찾기']} />
      <Outlet />
      <div className="divide m-5">
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default FindLoginIdPasswordPage
