import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function MainConciergeView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="section main-concierge">
      <h2 className="en text-center">{language['지역별 컨시어지 데스크']}</h2>
      <div className="container mt-2">
        <h5 className="text-center text-primary">
          {language['대한민국 관광 주요거점에']} <br className="d-block d-md-none" />
          {language['위치한 각 트래블센터에서']} <br />
          {language['맞춤형 컨시어지 서비스를 제공합니다.']}
        </h5>
        <h6 className="text-center">
          {language['이지트래블 케어 서비스는']} <br className="d-block d-md-none" />
          {language['여행 중 각 컨시어지데스크에']} <br />
          {language['방문하셔서 문의주시면 안심관광을']} <br className="d-block d-md-none" />
          {language['위한 도움을 드리고 있습니다.']}
        </h6>
        <div className="row mt-5">
          <div className="col-md-3">
            <div className="list-group" id="list-tab" role="tablist">
              <a className="list-group-item list-group-item-action active" id="list-incheonT1-list" data-toggle="list" href="#list-incheonT1" role="tab" aria-controls="list-incheonT1">
                {language['인천공항 T1']}
              </a>
              <a className="list-group-item list-group-item-action" id="list-incheonT2-list" data-toggle="list" href="#list-incheonT2" role="tab" aria-controls="list-incheonT2">
                {language['인천공항 T2']}
              </a>
              <a className="list-group-item list-group-item-action" id="list-jeonbuk-list" data-toggle="list" href="#list-jeonbuk" role="tab" aria-controls="list-jeonbuk">
                {language['전북쇼핑트래블라운지']}
              </a>
              <a className="list-group-item list-group-item-action" id="list-songdo-list" data-toggle="list" href="#list-songdo" role="tab" aria-controls="list-songdo">
                {language['송도 컨벤시아']}
              </a>
            </div>
          </div>
          <div className="col-md-9">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="list-incheonT1" role="tabpanel" aria-labelledby="list-incheonT1-list">
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-1">
                      <h5 className="text-secondary mt-3">
                        {language['인천국제공항 제1터미널']}
                        <br />
                        <span className="en">T1</span> _ {language['컨시어지데스크']}
                      </h5>
                      <hr className="blue" />
                      <ul className="list">
                        <li>
                          <h6>{language['부스명: MICE Concierge Desk(40번 데스크)']}</h6>
                        </li>
                        <li>
                          <h6>{language['위치: 제1터미널 8번과 9번 게이트 사이']}</h6>
                        </li>
                      </ul>
                      <hr className="blue" />
                      <p className="text-right">
                        <Link to="concierge/incheon-t1" className="btn btn-outline-primary btn-round en">
                          {language['Details']}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img src="/resources/home/img/desk_incheon1.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="list-incheonT2" role="tabpanel" aria-labelledby="list-incheonT2-list">
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-1">
                      <h5 className="text-secondary mt-3">
                        {language['인천국제공항 제2터미널']}
                        <br />
                        T2 _ {language['컨시어지데스크']}
                      </h5>
                      <hr className="blue" />
                      <ul className="list">
                        <li>
                          <h6>{language['부스명: MICE Concierge Desk']}</h6>
                        </li>
                        <li>
                          <h6>{language['위치: 제2터미널 4번게이트']}</h6>
                        </li>
                      </ul>
                      <hr className="blue" />
                      <p className="text-right">
                        <Link to="concierge/incheon-t2" className="btn btn-outline-primary btn-round en">
                          {language['Details']}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img src="/resources/home/img/desk_incheon2.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="list-jeonbuk" role="tabpanel" aria-labelledby="list-jeonbuk-list">
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-1">
                      <h5 className="text-secondary mt-3">{language['전북쇼핑트래블라운지']}</h5>
                      <hr className="blue" />
                      <ul className="list">
                        <li>
                          <h6>{language['위치: 전주시 완산구 충경로 1층']}</h6>
                        </li>
                      </ul>
                      <hr className="blue" />
                      <p className="text-right">
                        <Link to="concierge/jeonbuk" className="btn btn-outline-primary btn-round en">
                          {language['Details']}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img src="/resources/home/img/desk_jeonbuk.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="list-songdo" role="tabpanel" aria-labelledby="list-songdo-list">
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-1">
                      <h5 className="text-secondary mt-3">
                        {language['프리미엄라운지']}
                        <br />
                        {language['송도컨벤시아점']}
                      </h5>
                      <hr className="blue" />
                      <ul className="list">
                        <li>
                          <h6>{language['위치: 인천 송도컨벤시아 내(Gate1)']}</h6>
                        </li>
                      </ul>
                      <hr className="blue" />
                      <p className="text-right">
                        <Link to="concierge/songdo" className="btn btn-outline-primary btn-round en">
                          {language['Details']}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img src="/resources/home/img/desk_songdo.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 className="text-center mt-4">
          {language['이지트래블케어 서비스 외에 컨시어지 서비스']} <br className="d-block d-md-none" />
          {language['이용 시 별도의 비용이 발생됩니다.']} <br />
          {language['비용은 각 컨시어지데스크 요금정책에 따릅니다.']}
        </h6>
        <h5 className="text-center mt-5 mb-4">{language['컨시어지 데스크 이용방법']}</h5>
        <ul className="status-step nav-justified">
          <li>
            <div>
              <figure>
                <img src="/resources/home/img/svg/icon-step1.svg" alt="" />
              </figure>
              {language['컨시어지 데스크 방문']}
            </div>
          </li>
          <li>
            <div>
              <figure>
                <img src="/resources/home/img/svg/icon-step2.svg" alt="" />
              </figure>
              {language['이지트래블케어 바우처 제시']}
            </div>
          </li>
          <li>
            <div>
              <figure>
                <img src="/resources/home/img/svg/icon-step3.svg" alt="" />
              </figure>
              {language['바우처의 내용 확인']}
            </div>
          </li>
          <li>
            <div>
              <figure>
                <img src="/resources/home/img/svg/icon-step4.svg" alt="" />
              </figure>
              {language['서비스 시작']}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MainConciergeView
