import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function MainSoSCallView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="section bg-secondary">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-5">
            <p className="text-center">
              <img src="/resources/home/img/main-sos.png" alt="" className="img-fluid" />
            </p>
          </div>
          <div className="col-12 col-md-7 m-center">
            <h2>
              {language['여행 중 곤란한 상황이']} <br className="d-block d-md-none" />
              {language['발생하셨나요?']}
            </h2>
            <Link to="/sos/terms" className="btn btn-outline-light btn-round">
              {language['SOS 콜 채팅 바로가기']}
              <i className="bi bi-arrow-right ml-2"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSoSCallView
