import { useState } from 'react'
import { axiosConnectJson } from '../../../../network/http-request'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../../store/user'

function WithdrawalView() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const language = useSelector((state: any) => state.language.value)

  const [comment, setComment] = useState('')
  const [password, setPassword] = useState('')
  const [agree, setAgree] = useState(false)
  const [reason1, setReason1] = useState(false)
  const [reason2, setReason2] = useState(false)
  const [reason3, setReason3] = useState(false)
  const [reason4, setReason4] = useState(false)
  const [reason5, setReason5] = useState(false)
  const [reason6, setReason6] = useState(false)

  const loginUser = useSelector((state: any) => state.user.value)

  const clickWithdrawal = () => {
    validate() && fetchWithdrawal()
  }

  function validate() {
    if (!agree) {
      alert(language['약관에 동의해주시기 바랍니다.'])
      return false
    }

    return true
  }

  function fetchWithdrawal() {
    axiosConnectJson(`/api/withdrawal`, 'POST', {
      memberId: loginUser.memberId,
      comment: comment,
      reason: JSON.stringify([reason1, reason2, reason3, reason4, reason5, reason6]),
      password: password,
    })
      .then((result) => {
        dispatch(logout())
        navigate('/')
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['회원탈퇴']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-md-12">
            <ul className="list secondary">
              <li>
                <strong>{language['탈퇴한 아이디는 복구가 불가능하며, 추후 동일한 아이디나 닉네임으로 재가입할 수 없습니다.']}</strong>
              </li>
              <li>{language['서비스 이용을 위해 등록된 정보들은 삭제되며, 삭제된 데이터는 복구되지 않습니다.']}</li>
              <li>{language['단, 댓글을 포함하여 등록된 게시물은 삭제되지 않습니다.']}</li>
            </ul>
          </div>
        </div>
        <form className="form-add">
          <div className="form-group row  ml-2">
            <div className="col-md-12">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck0" checked={agree} onChange={() => setAgree(!agree)} />
                <label className="custom-control-label" htmlFor="customCheck0">
                  <strong>{language['유의사항을 모두 확인하였으며, 이에 동의합니다.']}</strong>
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group row align-items-center">
            <label className="col-md-3 col-form-label">{language['비밀번호']}</label>
            <div className="col-md-5">
              <input type="password" className="form-control" placeholder={language['비밀번호를 입력하세요.']} value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <hr />
          <div className="form-group row align-items-center">
            <label className="col-md-3 col-form-label">{language['탈퇴사유']}</label>
            <div className="col-md-9">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" checked={reason1} onChange={() => setReason1(!reason1)} />
                <label className="custom-control-label" htmlFor="customCheck1">
                  {language['회원탈퇴 후 재가입']}
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck2" checked={reason2} onChange={() => setReason2(!reason2)} />
                <label className="custom-control-label" htmlFor="customCheck2">
                  {language['서비스를 더 이상 이용하지 않음']}
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck3" checked={reason3} onChange={() => setReason3(!reason3)} />
                <label className="custom-control-label" htmlFor="customCheck3">
                  {language['이용이 불편하고 장애가 많음']}
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck4" checked={reason4} onChange={() => setReason4(!reason4)} />
                <label className="custom-control-label" htmlFor="customCheck4">
                  {language['콘텐츠의 유용성 부족']}
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck5" checked={reason5} onChange={() => setReason5(!reason5)} />
                <label className="custom-control-label" htmlFor="customCheck5">
                  {language['고객센터 및 서비스 불만']}
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck6" checked={reason6} onChange={() => setReason6(!reason6)} />
                <label className="custom-control-label" htmlFor="customCheck6">
                  {language['기타']}
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group row align-items-center">
            <label className="col-md-3 col-form-label">{language['기타의견']}</label>
            <div className="col-md-7">
              <textarea className="form-control mt-2" id="inputMemo" rows={3} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
            </div>
          </div>
          <hr className="dark" />
        </form>
        <div className="divide" />

        <div className="text-center">
          <a onClick={clickWithdrawal} className="btn btn-danger btn-lg" data-toggle="modal" data-target="#applyModal">
            {language['탈퇴하기']}
          </a>
        </div>
      </div>
    </>
  )
}

export default WithdrawalView
