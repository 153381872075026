import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosConnect from '../../../../network/http-request'
import { validateEmail, validatePassword } from '../../../../utils/checkValidity'
import _ from 'lodash'

function MyPageInformationView() {
  const language = useSelector((state: any) => state.language.value)
  const loginUser = useSelector((state: any) => state.user.value)
  const [user, setUser] = useState<any>({})
  const [countryList, setCountryList] = useState([])
  const [country, setCountry] = useState({
    countryCode: '',
    countryNameKo: '',
    countryNameEn: '',
    countryNameCn: '',
    countryNameJp: '',
  })

  const [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [memberLanguage, setMemberLanguage] = useState('')

  useEffect(() => {
    fetchUserInformation()
  }, [])

  useEffect(() => {
    fetchCountry()
  }, [user])

  function getCountryName() {
    if (!country || country.countryCode === '') {
      return ''
    }

    if (language.code === 'en') {
      return country.countryNameEn
    } else if (language.code === 'cn') {
      return country.countryNameCn
    } else if (language.code === 'jp') {
      return country.countryNameJp
    } else {
      return country.countryNameKo
    }
  }

  function fetchUserInformation() {
    axiosConnect(`/api/member/${loginUser.memberId}`, 'GET')
      .then((result) => {
        setUser(result.data)
        setEmail(result.data.memberEmail)
        setPhoneNumber(result.data.memberPhone)
        setMemberLanguage(result.data.memberLang)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const confirmPassword = () => {
    if (password === passwordCheck) {
      return true
    }
    return false
  }

  const validate = () => {
    if (password === '') {
      return true
    }

    if (!validatePassword(password)) {
      alert(language['비밀번호는 영문 숫자 특수문자를 모두 포함하여 최소 6자 최대 16자 입니다.'])
      return false
    } else if (!confirmPassword()) {
      alert(language['비밀번호와 비밀번호 확인란이 일치하지 않습니다. 다시 한번 확인해 주세요.'])
      return false
    }

    if (!validateEmail(email)) {
      alert(language['이메일을 형식에 맞게 작성해주세요.'])
      return false
    }

    return true
  }

  const updateUserInformation = () => {
    validate() &&
      axiosConnect(`/rest/member/updateMember`, 'POST', {
        memberPwd: password,
        memberEmail: email,
        memberPhone: phoneNumber,
        memberLang: memberLanguage,
      })
        .then((result) => {
          setPassword('')
          setPasswordCheck('')
          fetchUserInformation()
        })
        .catch((error) => {
          alert(error)
        })
  }

  function fetchCountry() {
    let lang = 'en'
    if (language.code === 'kr') {
      lang = 'ko'
    }

    axiosConnect(`/rest/member/getCountryList?lang=${lang}`, 'GET')
      .then((result) => {
        setCountryList(result.data)
        setCountry(_.find(result.data, (value: any) => value.countryCode === user.memberCountry))
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['내 회원정보']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-12">
            <form className="form-add">
              <hr className="dark" />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['아이디']}</label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={user.memberId} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['국적']}</label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={getCountryName()} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['성별']}</label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={user.memberGender} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['이름']}</label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={user.memberName} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">
                  {language['여권번호']}
                  <br />
                  {'(' + language['외국인등록번호'] + ')'}
                </label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={user.memberPassportNum} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['생년월일']}</label>
                <div className="col-md-4">
                  <input type="text" className="form-control-plaintext" value={user.memberBirthday} placeholder="" disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['새 비밀번호']}</label>
                <div className="col-md-10">
                  <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <small className="form-text text-muted">{language['영문, 숫자, 특수 문자를 모두 사용해 6~16자로 작성해주세요.']}</small>
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['새 비밀번호 확인']}</label>
                <div className="col-md-10">
                  <input type="password" className="form-control" value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['이메일']}</label>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['휴대폰 번호']}</label>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  <small className="form-text text-muted">{language['하이픈(-)을 포함해서 작성해주세요.']}</small>
                </div>
              </div>
              <hr />
              <div className="form-group row no-gutters align-items-center">
                <label className="col-md-2 col-form-label">{language['사용언어']}</label>
                <div className="col-md-4">
                  <select className="form-control mr-1" value={memberLanguage} onChange={(e) => setMemberLanguage(e.target.value)}>
                    <option value="KR">한국어</option>
                    <option value="EN">English</option>
                    <option value="JP">中文</option>
                    <option value="CN">日本語</option>
                  </select>
                </div>
              </div>
              <hr className="dark" />
            </form>
            <div className="text-center mt-4">
              <a onClick={updateUserInformation} className="btn btn-primary btn-lg">
                {language['확인']}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyPageInformationView
