import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

function PurchaseStatusView() {
  const location = useLocation()
  const language = useSelector((state: any) => state.language.value)

  function isAcitve(path: string) {
    return path === location.pathname ? 'active' : ''
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb d-flex justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="bi bi-house"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">{language['서비스 구매']}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {language['정보입력']}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h2>{language['정보입력']}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="text-center">
          <div className="step-bg mt-3">
            <span className="line"></span>
            <ol className="start-step d-flex justify-content-center">
              <li className={isAcitve('/purchase/information')}>
                <a href="javascript:;">
                  <span>
                    <strong>1</strong>
                    <small>{language['정보입력']}</small>
                  </span>
                </a>
              </li>
              <li className={isAcitve('/purchase/payment')}>
                <a href="javascript:;">
                  <span>
                    <strong>2</strong>
                    <small>{language['결제하기']}</small>
                  </span>
                </a>
              </li>
              <li className={isAcitve('/purchase/completed')}>
                <a href="javascript:;">
                  <span>
                    <strong>3</strong>
                    <small>{language['구매완료']}</small>
                  </span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}

export default PurchaseStatusView
