function PrivacyENView() {
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <div className="termwrap" onContextMenu={(e) => e.preventDefault()}>
            <h3>Privacy Policy</h3>
            We, PREMIUMPASS INTERNATIONAL Corp. shall acknowledge the importance of personal information and hereby pledge to devote thorough efforts to the proper treatment and protection of personal information, based on the following policy. <br />
            <br />
            <h5>1. Use of Personal Information</h5>The information acquired is used to provide Easy Care and Travel Insurance services. Optional items are not necessary for service.
            <br />
            <br />
            <h6>[Collected Information Upon Registration]</h6>
            -Required: Name, Email, Date of Birth, Gender, Identification Number (Residents), Passport Number (Foreigners), Phone Number
            <br />
            -The following information can be created during service or proceedings: service usage history, access log, cookies, access IP information
            <br />
            <br />
            <h5>2. Purpose of Collection</h5>
            We shall use the personal information that we acquired only for the purpose of the following. We shall not use such personal information for any purpose other than the purpose of attainment of those objectives without consent. <br />
            - Membership registration and management, personal verification, prevention of fraud, prevention of unjust use
            <br />
            - Registration for Hyundai Marine & Fire Insurance Group, Worry-free Care Service, transactions and services, Payments Receipt <br />
            - Customer Service
            <br />
            - Advertising and Marketing related
            <br />
            <br />
            <h5>3. Retention and Proceeding of Personal Information</h5>
            Personal information of Members is immediately discarded after the purposes have been fulfilled. <br />
            - Membership information: retained until withdrawal or expulsion
            <br />
            - History of contracts, subscription withdrawal, payments, and supply of goods: up to 5 years
            <br />
            - Customer complaints, dispute resolution: up to 3 years
            <br />
            - Information for advertisements and marketing: up to 3 years, However, there are exclusions such as: <br />
            1. Incomplete settlement of fees
            <br />
            2. If it is considered necessary for protecting other members
            <br />
            3. If required under the commercial laws or other regulations
            <br />
            4. If it serves as evidence for disputes or lawsuits
            <br />
            5. If the member agreed to additional retention
            <br />
            6. If it is considered necessary for retention, based on the company policy
            <br />
            For the above cases as well, the additional retention period will be accurately notified to the members and the information will be discarded immediately after the designated date.
            <br />
            <br />
            <h5>4. Deletion of Personal Information</h5>
            PREMIUMPASS INTERNATIONAL Corp. shall immediately delete the acquired personal information after the fulfillment of the purpose of personal information processing and the expiry of the retention period. <br />
            - Personal information saved as digital files would be unable to be opened after deletion.
            <br />
            - Personal information on paper would be deleted with a grinder or incinerated.
            <br />
            <br />
            <h5>5. Supply of Personal Information to Third Parties</h5>
            We shall not disclose or supply the personal information that we acquired from the Suppliers to any third party, except information that is approved for release by the consent of the members or required by law.
            <br />
            <br />
            Purpose and Information Provided to Third Parties <br />
            Provided information: name, phone number, email, name of the product: electronic payment
            <br />
            Information provided to Hyundai Marine & Fire Insurance Group: name, identification number, passport number (foreigners), gender, phone number, email, name of the product: insurance registration <br />
            The retention period for personal information: from the provision of information to the fulfillment of purpose or the periods specified in term 3.
            <br />
            <br />
            <h5>6. Rights and Obligations of the Principal and the Use of Information</h5>
            You are eligible of terminating the agreement on privacy policy. You can apply for immediate inspection, modification, termination, and deletion of your information by contacting the personal information manager face-to-face or through phone calls and Fax. However, your rights can be limited if there are obligations designated by law.
            <br />
            <br />
            <h5>7. Personal Information Manager</h5>The below staff is in charge of protecting your information and proceeding with personal information-related customer service.
            <br />
            Name: Jinhee Han
            <br />
            Position: Assistant manager
            <br />
            Department: Easy Travel Care
            <br />
            Contact number: 0216441493
            <br />
            <br />
            <h5>8. Measures to Secure Personal Information Safety</h5>All of the information acquired from you is protected through a strong security system. Also, the right to access your information is strictly limited to specific personnel. Training regarding personal information security is constantly performed. A specialist is designated to proceed with your information and manage the log history as well.
            <br />
            <br />
            <h5>9. Information about Modification of Privacy Policy</h5>This privacy policy has been modified on Jan 1, 2021. In case of additional modification on policies, laws, or security technology, it will be notified through the official website 7 days before the effective date.
            <br />
            <br />
            Announcement date: Jan 1, 2021
            <br />
            Enforcement date: Jan 1, 2021
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyENView
