function EasyTravelCareTermJPView() {
  return (
    <>
      これからの大韓民国旅行は、 安心して楽しく、快適にお楽しみください！！
      <br />
      <br />
      大韓民国の旅行中に助けが必要な時は、いつでもEasy Travel Careセンターまでご連絡ください。 Easy Travel Careセンターは1年365日年中無休です。
      <br />
      <br />
      Easy Travel Careセンターは、韓国の観光地を旅行する観光客のために、モバイルチャット、 電話、 メールを利用して、安心観光保険&ケアサービスを提供しております。
      <br />
      <br />
      常にお客様に安全で快適な大韓民国観光をお楽しみいただけるようお手伝いし、大韓民国を訪れる外国人観光客のニーズに合わせたコンシェルジュサービスを提供することはもちろん、快適で安全で品位ある価値観光特化コンシェルジュサービスを提供し、観光の品格を高めます。
      <br />
      <br />
      <h6>Easy Travel Careの紹介</h6>
      Easy Travel Careは、大韓民国を訪れる外国人観光客がリラックスして楽しく、安心して 観光できるよう、外国人専用の旅行者保険サービスに合わせたコンシェルジュサービスを提供する安心観光プラットフォームサービスです。
      <br />
      主なサービスとしては、外国人専用旅行者保険サービスと㈱プレミアムパスインターナショナルが提供している医療安心ケア、トラベル安心ケア、空港安心ケア、ビジネス安心ケア、旅行者安心保険、そしてプレミアムメンバーシップ特別特典を追加提供いたします。
      <br />
      サービス提供会社である(株)プレミアムパスインターナショナルは、2007年度に儀典コンシェルジュサービス専門企業として設立し、現在グローバルホスピタリティ専門企業として急成長している企業です。
      <br />
      (株)プレミアムパスインターナショナルは、MICE事業、儀典輸送事業、空港関連サービス事業、旅行事業、コンシェルジュ事業など、観光トータルサービス専門企業で、現在観光および交通の主要拠点にトラベルセンター&ラウンジを運営しております。 <br />
      <br />
      <h6>Easy Travel Care 概要</h6>
      1. Easy Travel Careサービス加入
      <br />
      Easy Travel Careサービスに加入するためには、所属国名、氏名、生年月日、 性別、携帯電話番号, Eメールアドレスなどの登録が必要となり、サービス提供過程でその情報を通じた本人確認が行われます。 情報が一致しない場合は、サービス提供が制限されることがあります。
      <br />
      Easy Travel Careに加入する際は、加入証明書、サービスマニュアルがプラットフォーム を通して提供されます。 別途、登録証明書などの電子メールでのご要請があれば紙面でもご提供できます。
      <br />
      登録者は、登録時に記録された本人情報が正しいか必ず確認してください。 特に、緊急時に連絡可能な電話番号、Eメールアドレスなどは正しく記載されているか確認して、エラーがある場合は直ちにEasy Travel Careセンターまたはチャットサービスで修正してください。
      <br />
      旅行中に緊急事態や緊急事態が発生した場合はパスポート番号を教えていただかなければサービスをご利用いただけません。なお、応急処理後にはパスポートで本人確認をしていただきます。
      <br />
      <br />
      2. Easy Travel Careセンターの連絡方法
      <br />
      助けが必要な場合、または非常事態発生時にはEasy Travel Careプラットフォーム内のチャット サービスであるSOS申し込み、またはEasy Travel Careセンター(1644-1493、82-2-2063-3544)に連絡してください。
      <br />
      電話、Eメール、プラットフォームチャットサービスによるSOS申し込み、またはカカオトークを通じて24時間ケアセンターに連絡することができ、通話が必要な場合は連絡可能な電話番号を教えていただければ担当コーディネーターが電話番号に電話を差し上げます。 韓国のUSIMチップを使用しない電話番号は連絡が制限されることがあります。
      <br />
      <br />
      ※ Eメールまたはカカオトーク
      <br />
      Easy Travel Careセンター代表のEメール(care@tothepp.com)またはカカオトークプラスの友達
      <br />
      (@ Easy Travel Care CENTER)でサービス要請が可能です。
      <br />
      <br />
      <h6>Easy Travel Careサービス普通約款</h6>
      <h6>第一条 目的</h6>
      本約款は、㈱プレミアムパスインターナショナル（以下、「プレミアムパス」という。）が提供する安心観光プラットフォームサービスであるEasy Travel Careサービスに関連し、 会員および加入者間の権利義務を明確にすることを目的とします。
      <br />
      <br />
      <h6>第二条 用語の定義</h6>
      1. 会員
      <br />
      Easy Travel Careサービスの安心観光プラットフォームサービスへの加入申し込みを目的に、 電話, 電子メール、ウェブページ、モバイルアプリケーションなどを通じて、あるいはEasy Travel Care広告代行提携業者を通じて、完全なサービス利用のためにEasy Travel Careが要求する個人識別情報や旅行スケジュール情報をEasy Travel Careセンターに提供した個人や団体。
      <br />
      2. 加入者
      <br />
      Easy Travel Careの約款に明示された権利と義務に同意し、承諾の意味でEasy Travel Careが提示した加入費を納付することで加入証明番号を付与された会員。
      <br />
      3. Easy Travel Careセンター
      <br />
      会員が旅行中に発生した困難に対して便宜、保険などのサービスを提供するため、非対面1次サービス応対を行うトータルサービスセンター
      <br />
      4. 加入証明書
      <br />
      Easy Travel Careセンターが加入者に提供される証明書で, 加入証明番号とともに 個人識別情報や主要加入条件などが明示されている書類。
      <br />
      5. ケアマネジャー
      <br />
      約款に基づいて加入者が要請した医療および現場サービスと保険サービスに対する対応について、直接•間接的に関連するすべての事項に干渉し決定する権限を与え、サービス提供を評価•調整•監督する専門サービスマネージャー。
      <br />
      6. メンバーシップの特別特典
      <br />
      正常なEasy Travel Careサービス資格を取得した会員が受けられる特別特典として, Easy Travel Careサービス運営者であるプレミアムパスが決定します。
      <br />
      <br />
      <h6>第三条 サービスの内容</h6>
      Easy Travel Careが提供する安心観光プラットフォームサービスは 以下の通りです。
      <br />
      1. 医療安心ケア
      <br />
      大韓民国の旅行中に発生しうる交通事故、事故などのオーダーメイド型ケアサービスで医療通訳士の手配、医療費の解決方法の模索、旅行者保険&医療支援などのケアサービスを提供
      <br />
      2. トラベル安心ケア
      <br />
      大韓民国の旅行中に緊急事態が発生した際に提供される様々なトラベルコンシェルジュサービス
      <br />
      - 財布、パスポートなどを紛失した場合の支援ケアサービス
      <br />
      - 旅行中に緊急事項が発生した場合のケアサービス
      <br />
      - 交通便の予約発券及び車両手配サービス
      <br />
      - ギブ級宿泊予約支援サービスなど
      <br />
      3. 空港安心ケア
      <br />
      国際空港という特別な空間での旅行客のための安心ケアサービス
      <br />
      - 高齢者などの空港でのサポート及び不便事項のケア
      <br />
      - 国際空港におけるコンシェルジュサービスの提供
      <br />
      - ビザおよび緊急航空券ケアサービス
      <br />
      4. ビジネス安心ケア
      <br />
      快適で安全な旅行とビジネス成功のための特化コンシェルジュサービス
      <br />
      - 緊急通訳•翻訳、人材手配サービス
      <br />
      - 会議施設及びミーティング場所の予約支援サービス
      <br />
      - KTX、高速バス、航空券などの予約支援サービス
      <br />
      5. 旅行者安心保険
      <br />
      旅行安心保険サービスは「プレミアムパス」の提携保険会社のサービスで、提携保険会社の規定および保険約款に従い安心保険サービスが提供されます。
      <br />
      -（国内旅行中の） 傷害(死亡):1億ウォン(15歳未満除く)
      <br />
      -（国内旅行中の）傷害後遺障害：１億ウォン
      <br />
      -（国内旅行中の）疾病死亡及び80%高度後遺障害：２千万ウォン 15歳未満、８０歳以上保障除外
      <br />
      -（国内旅行中の）傷害給与_医療実費保障：１千万ウォン 通院一回当たり　10万ウォン限定
      <br />
      -（国内旅行中の）傷害非給与_ 医療実費保障：1千万ウォン　通院１回当たり　10万ウォン、通院回数　年間100回限定
      <br />
      -（国内旅行中の）賠償責任: 500万ウォン　　自己負担金 10,000ウォン
      <br />
      -（国内旅行中の）携帯用品(紛失除外)損害(外国人専用)：20万ウォン　自己負担金 10,000ウォン
      <br />
      6. メンバーシップの特別特典
      <br />
      Easy Travel Careサービス会員に提供するメンバーシップ特化サービスで、空港、旅行、ビジネスなどの様々なコンシェルジュサービスおよび割引サービスを提供しています。 メンバーシップサービスは、 Easy Travel Careサービス の提供 会社である「プレミアムパス」によって随時変更されることがあるので、ウェブページ及びモバイルアプリケーションなどで変更事項を確認することができます。
      <br />
      7. その他のサービス提供に伴う注意事項
      <br />
      - 加入者が大韓民国旅行中に交通事故や安全事故に遭った場合、緊急対処方法の案内、関係機関との連結および通訳サービス、現場出動を提供します。
      <br />
      - 本支援は、大韓民国内で発生した事故に対して処理することができ、母国または乗り継ぎ局など国外地域での事故にはサービスの提供が制限されます。 - 本サービスは加入者に事故が発生したらすぐにEasy Travel Careセンター へサービス要請を勧め、Easy Travel Careが関連業務を任される前、またはEasy Travel Careのマネージャが事故現場を受け付ける前に他社の関係者または3者が先に業務を遂行した場合、Easy Travel Careは事故処理の手続きが円滑に進められる のに役立つことがあります。
      <br />
      <br />
      <h6>第4条 サービスの開始及び終了</h6>
      Easy Travel Care加入者に対するサービス開始は、加入期間の初日に 始まり、最終日または大韓民国出国後すぐに終了し、日数別基準時間は24時を基準に算定します。
      <br />
      <br />
      <h6>第5条 約款の効力等</h6>
      1. 本約款は、Easy Travel Careがウェブページ、モバイルアプリケーション等に掲示し, 又は加入者に告知することによってその効力が生じます。
      <br />
      2. Easy Travel Careが本約款を改定する場合は、適用日及び改定理由を明示し 、その適用日の1週間前から適用日の前日まで1項と同じ方法で掲示または告知します。 この時、その改正約款は、サービス開始日がその適用日以降に該当する契約にのみ適用され、それ以前にすでに始まった契約については、改正前の約款条項が適用されます。
      <br />
      3. 登録者は改定された約款に同意しない場合、登録の取消しを要請することができます。
      <br />
      4. 加入者は契約の取り消しを要請せずにサービスを利用することで、改定された約款に同意したものとみなされます。
      <br />
      <br />
      <h6>第6条 約款適用の優先順位</h6>
      1. Easy Travel Careサービスと保険会社の保険商品と連携したサービスの場合 、保険と関連するすべての事項は当該保険商品の普通約款および特別約款が適用されます。
      <br />
      2. Easy Travel Careの普通約款と特別約款の内容が衝突する場合、特別約款 の効力はこの約款に優先されます。
      <br />
      <h6>第7条 会員及び加入者資格の取得</h6>
      1. すべての個人及び団体は、登録申請を希望する明確な意味で、Easy Travel Careが要求する 個人識別情報(国籍、氏名、生年月日、性別、電話番号、Eメールアドレスなど)及び旅行日程情報をEasy Travel Careが提供する媒体を通して入力または提供することにより、会員資格を取得します。 ただし、Easy Travel Careサービスの提供を受けるためには、必ずパスポートを提示しなければ なりません。
      <br />
      2. 会員が、Easy Travel Careが要求する個人識別情報及び旅行日程情報を不完全に 、又は虚偽で 入力(提供)し、又は12時間以上登録申請段階を行わない場合、Easy Travel Careは会員が入力した 個人識別情報及び旅行日程情報をすべて削除し、会員資格を剥奪する権利があります。
      <br />
      3. Easy Travel Careは、登録申請手続きを進めるため、会員の個人識別情報及び 旅行日程情報 を活用することができます。
      <br />
      4.1項により会員資格を取得した会員のうち、登録手続きを経て登録証明番号の発行を受けることにより、加入者資格が得られます。
      <br />
      <br />
      <h6>第8条 EASY TRAVEL CAREの義務と権利</h6>
      1. Easy Travel Careは、安定したサービス提供のため努力を続け、会員または加入者から苦情が 寄せられる場合は、誠実に応対します。 ただし、必要性と適切性によって提携会社が代わりに応じることができます。
      <br />
      2. Easy Travel Careは、次の各号の1に該当する場合、サービスの全部または一部を制限 したり中止することができます。
      <br />
      カ. システム及び設備の保守等。
      <br />
      ナ. サービスの根幹となる基幹通信事業者の網サービスの提供中止。
      <br />
      ダ. 国家非常事態、停電、通信網接続の輻輳等その他の不可抗力的な場合。
      <br />
      3. EASY TRAVEL CAREがサービスの提供を制限または中止したときは、その理由及び 制限期間等を第5条1項の方法により遅滞なく会員及び加入者に通知します。
      <br />
      4.新規加入の有効期間開始以前の既往症、つまり既存に保有していた疾病、或いは既存に発生した事故や疾病に起因する症状及びその合併症がある場合、加入が制限され、又はEasy Travel Careの保険金支払い義務が免責されることがあります。
      <br />
      5. Easy Travel Careはサービスの提供及び紛争予防のため、通信上の会話内容を保存保管し、 監視する権利を有します。
      <br />
      6。 加入者は、本手続きおよび提供されたサービスに関し、紛争発生時に証明資料として保存保管された内容を使用することに明示的に同意したものです。
      <br />
      7. Easy Travel Careが円滑かつ正確なサービス提供のために要請する場合、加入者は医療記録 (旅行前に発生したものも含め、どのような種類でもEasy Travel Careがサービスを提供する ために必要だと判断した全ての記録)を作成して、Easy Travel Careセンターに提出しなければならず、もし提出を拒否して円滑で正確なサービスが履行されなかった場合は、Easy Travel Careはこれに対して責任が免除されます。
      <br />
      8。 Easy Travel Careは、加入者に発生した事故や疾病等の事件により、加入者本人に提供されるサービスに関する個人、法的個体、公共機関を含む第三者に対する加入者の全ての権利と行動を直ちに、そして自動的に代理して行使する権利があり、加入者はこのような権利と活動を委譲し、これを取り消すことはできず、またこれに必要な全ての法的手続きに従わなければならず、加入者に要求される全てのことに協力しなければなりません。
      <br />
      9. Easy Travel Careサービスのうち、旅行者安心保険については 「プレミアムパス」が提携している保険会社の規定と約款に基づいて支払われます。
      <br />
      10. Easy Travel Careサービスは安心観光コンシェルジュサービス基盤であるため、旅行者保険金に該当する保障は「プレミアムパス」の提携保険会社を通じて保障を受けることができ、コンシェルジュサービスの提供によって実際に発生する費用については会員が負担します。
      <br />
      <br />
      <h6>第9条 会員及び加入者の義務及び権利</h6>
      1. 会員及び加入者は、本約款に規定する事項及びサービス利用案内又は注意事項等、Easy Travel Careが告知する事項を遵守しなければなりません。
      <br />
      2. 会員及び加入者は、個人識別情報の不正使用が発生しないよう注意しなければならず、不正使用を知った場合は、直ちにEasy Travel Careに通知して被害防止に協力しなければなりません。
      <br />
      3. 会員及び加入者は、サービスに関する権利義務の一部又は全部を第三者に譲渡し、又は担保にすることはできません。
      <br />
      4. 会員及び加入者は、法律で定める場合を除き、本人の同意なく本人の個人情報が第三者に提供、利用されることを拒否する権利があります。
      <br />
      <br />
      <h6>第10条 加入の取消し及び中途解約</h6>
      1. Easy Travel Care登録証明書に記載された開始日以前には、いつでも契約の解約(取消)ができ、すでに納付された登録費全額が払い戻されます。
      <br />
      2. サービス期間の変更や取消は、開始日以前のみ可能となります。
      <br />
      3. サービス開始中であっても、個人識別情報の不正及び悪意、故意の事故発生の恐れがあると「プレミアムパス」が判断した場合、サービスを中途解約することができます。
      <br />
      4. 契約証明書に記載された開始日以降の解約の際は(中途解約)、使用期間に該当する契約費を差し引いた全額を払い戻し、差し引き額の算定は保険短期料率とサービス料料率に従います。 加入費返還請求権は加入資格を喪失した日から3年間行使しないと消滅時効になります。 ただし、保険料返還請求権に関する規定は保険業法を準用します。
      <br />
      5. 取り消し及び中途解約事由による提出書類
      <br />
      カ. 開始日以前のキャンセル: キャンセル申請書又は電子メールによるキャンセルの意思表示通帳のコピー
      <br />
      ナ. 単純変心による中途解約: 解約申請書 パスポートのコピー 通帳のコピー (解約申請書の受付日を基準に払い戻し)
      <br />
      ダ。 早期帰国による中途解約: パスポートのコピー通帳のコピー帰国航空券E-TICKETのコピー、または出入国事実証明書(E-TICKETの場合、加入保険期間内の再入国日程が含まれた書類の認定不可、出入国事実証明書の場合は加入保険期間内の最終帰国日を基準に還付)
      <br />
      <br />
      <h6>第11条 旅行の延長によるEasy Travel Care再加入</h6>
      1. 加入後、国内滞在中に加入期間が満了し、再加入または延長しなければならない場合、加入者は加入期間満了前にSOS申請及び電話やメールなどでセンターに再加入または延長意思を表示しなければなりません。
      <br />
      2. Easy Travel Careの案内を問わず以前の加入期間満了日が経過した場合、または以前の加入期間満了日前に再加入手続きが完了しない場合は再加入の意思がないものとみなされ、その後の再加入が制限されます。
      <br />
      3. 再加入の有効期間開始以前の既往証、つまり既存に持っていた疾病、あるいは既存に発生した事故や疾病に起因する症状及びその合併症がある場合、再加入が制限され、またはEasy Travel Careの提携保険会社は保険金の支払い義務が免責されることがあります。
      <br />
      <br />
      <h6>第12条団体保険加入申込等</h6>
      1. Easy Travel Careは、会員及び加入者のために契約者として保険会社と団体保険契約を締結し、会員に提供できます。
      <br />
      2. Easy Travel Careが契約者である団体保険契約に被保険者として加入することを希望する会員は、所定の手続きに従って保険が含まれたプランに加入申請(保険契約)をすることができ、加入手続きが完了した会員は加入者となります。
      <br />
      3. 保険が含まれたプランに加入申請をしようとする会員は、Easy Travel Care提携保険会社の加入案内事項および保険約款などを十分に読んで理解した上で、加入申請をしなければならず、保険会社とEasy Travel Careが告知を要求する事項に対して誠実に応じなければなりません。
      <br />
      4。 保険が含まれたプランを加入する場合、会員が加入する保険の受益者は、保険加入者(被保険者)本人とその法定相続人となります。
      <br />
      5. Easy Travel Careが契約した団体保険に関して、その他、本規約に定められていない事項についてはEasy Travel Careが保険会社と約定した内容に従います。
      <br />
      <br />
      <h6>第13 条旅行保険便宜支援</h6>
      Easy Travel Careプラン内の旅行保険は、契約者が「プレミアムパス」の団体保険で保険料に対する団体割引が適用されており、各プランは最適の保険保障内訳および加入金額になるようになっています。 また、保険会社への保険金請求代行、比例補償保険金受付代行および最終案内をいたします。
      <br />
      <br />
      <h6>第14条 Easy Travel Care 保険サービス支援の制限</h6>
      Easy Travel Careは、その原因の直接または間接を問わず、以下の理由を原因として発生したサービス発生に対してはサポートしないことが原則であり、加入者の要請によりサービス進行時に別途の費用が発生することがあります。
      <br />
      ① 加入証明書に記載されていないサービス内容
      <br />
      ②加入者又は保護者（所属機関含む）の故意又は重大な過失。
      <br />
      ③ 加入者の疾病又は傷害による診断費、治療費、薬剤費等の医療費の一切。
      <br />
      ④ 死刑または加入者の自害、自殺、自殺未遂、刑法上の犯罪行為または暴力行為。 ただし、刑法上正当行為、緊急避難及び正当防衛と認められる場合は支援します。
      <br />
      ⑤ 加入者の心神喪失または精神疾患。
      <br />
      ⑥ Easy Travel Careあるいは提携機関の承認なしに加入者が他の医療機関から受けた治療の結果生じた傷害、疾病又は合併症。
      <br />
      ⑦ Easy Travel Careの加入者ではなく、その同伴旅行者に発生した一切の費用。
      <br />
      ⑧ 加入者が伝染病発生国に対する政府の旅行禁止または予防接種措置に応じないで当該国を旅行して帰ってから発生した伝染病。
      <br />
      ⑨ 加入者が自発的に戦争、外国の武力行使、革命、内乱、事変、暴動、騒擾、労働争議、その他これらに類する事態が発生した地域に入って発生したサービス内容
      <br />
      ⑩ 核燃料物質（使用済燃料を含みます。 以下同じです）又は核燃料物質によって汚染された物（原子核分裂生成物を含む。）の放射性、爆発性又はその他の有害な特性による事故。（その他の放射線を浴びるもの又は放射能の汚染を含む。）
      <br />
      ⑪ 偶然かつ急激な事故や疾病に起因しない妊娠、出産、流産または外科的手術その他の医療処置であるか、旅行の目的自体が既存の傷害または疾病治療等の医療行為であることが確認された場合。
      <br />
      ⑫ 地震、噴火、津波又はこれと類する天災地変
      <br />
      ⑬ その他本約款上の免責事由
      <br />
      <br />
      <h6>第15条紛争の解決</h6>
      1. Easy Travel Careと加入者は、サービスに関して紛争が発生した場合、これを円満に解決するよう努めなければなりません。
      <br />
      2. 前項の規定にかかわらず、当事者間の紛争により訴訟が提起される場合、加入者とEasy Travel Careとの間の契約関係に関するすべての法的事項については、大韓民国の裁判所を合意に基づく管轄裁判所とします。
      <br />
      3.契約に関して紛争がある場合には大韓民国の法律に基づき、保険と関連した紛争が発生した場合には提携保険会社の普通約款および特別約款の内容に従います。
      <br />
      <br />
      PREMIUMPASS International Corp. ソウル市江西区麻谷中央4路22(麻谷洞)ファインスクエアA棟501号
      <br />
      PREMIUMPASS International Corp. ケアセンター +82-2 2063-3544
      <br />
      PREMIUMPASS International Corp. ケアセンターEメール care@tothepp.com
      <br />
      PREMIUMPASS International Corp. ケアセンター カカオトークID (@Easy Travel Care CENTER)
      <br />
      PREMIUMPASS International Corp. 加入相談 1644-1493
      <br />
      PREMIUMPASS International Corp. FAX +82-2-2138-0164
      <br />
    </>
  )
}

export default EasyTravelCareTermJPView
