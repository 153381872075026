import { useSelector } from 'react-redux'

function B2BTermKRView() {
  const language = useSelector((state: any) => state.language.value)
  return (
    <>
      <>
        <h6>{language['이지트래블케어 이용 약관']}</h6>
        이제 대한민국 여행, 안심하고 즐겁고 편안하게 즐기세요!
        <br />
        대한민국 여행 도중 도움이 필요할 때는 언제나 Easy Travel Care 센터로 연락을 주십시오. Easy Travel Care 센터는 1년 365일, 연중무휴 운영됩니다.
        <br />
        <br />
        Easy Travel Care 센터는 한국의 관광지를 여행하는 관광객을 위해 모바일 채팅과 전화 통화, 메일을 이용하여 안심 관광 보험 & 케어 서비스를 제공합니다.
        <br />
        <br />
        언제나 고객님들에게 안전하고 편안한 대한민국 관광을 즐길 수 있도록 도와드리고, 대한민국을 찾는 외국인 관광객에게 맞춤형 컨시어지 서비스 제공은 물론 편안하고, 안전하고, 품위 있는 가치관광 특화 컨시어지 서비스 제공으로 관광의 품격을 높이겠습니다.
        <br />
        <br />
        <h6>Easy Travel Care 소개</h6>
        Easy Travel Care는 대한민국을 찾는 외국인 관광객들에게 편안하고, 즐겁고, 안심하게 관광할 수 있도록 외국인 전용 여행자보험서비스에 맞춤형 컨시어지 서비스를 제공해드리는 안심 관광 플랫폼 서비스입니다.
        <br />
        주요서비스로는 외국인 전용 여행자보험 서비스와 (주)프리미엄패스인터내셔널에서 제공해드리는 의료 안심케어, 트래블 안심케어, 공항 안심케어, 비즈니스 안심케어, 여행자 안심보험 그리고 프리미엄 멤버십 특별 혜택을 추가 제공해 드립니다.
        <br />
        <br />
        서비스 제공사인 (주)프리미엄패스인터내셔널은 2007년도에 의전 컨시어지 서비스 전문기업으로 설립하여 현재 글로벌 호스피탈리티 전문기업으로 급성장 하고 있는 기업입니다. <br />
        (주)프리미엄패스인터내셔널은 MICE 사업, 의전/수송 사업, 공항 관련 서비스사업, 여행사업, 컨시어지 사업 등 관광 토탈 서비스 전문기업으로 현재 관광 및 교통의 주요거점에 트래블센터 &라운지를 운영하고 있습니다. <br />
        <br />
        <h6>Easy Travel Care 개요</h6>
        1. Easy Travel Care 서비스 가입
        <br />
        Easy Travel Care 서비스를 가입하기 위해서는 소속 국가명, 성명, 생년월일, 성별, 휴대폰 번호, 이메일주소 등이 등록되어야 하며, 서비스 제공 과정에서 해당 정보를 통하여 본인확인이 진행됩니다. 정보가 불일치할 때에는 서비스 제공이 제한될 수 있습니다. <br />
        Easy Travel Care 가입 시 가입증명서, 서비스 매뉴얼이 플랫폼을 통해서 제공됩니다. 별도로 가입증명서 등을 이메일 요청 시에는 지면으로도 제공하여 줄 수 있습니다.
        <br />
        가입자는 가입 시 기록된 본인 정보가 정확한지 반드시 확인하셔야 합니다. 특히 응급상황 시 연락 가능한 전화번호, 이메일 주소 등은 바르게 기재되어 있는지 확인하시고, 오류가 있을 때에는 즉시 Easy Travel Care 센터 또는 채팅 서비스를 통하여 수정하시기 바랍니다. <br />
        여행 중 긴급 상황이나 응급상황 발생 시는 여권번호를 알려 주셔야 서비스를 받을 수 있고, 응급처리 후 여권으로 본인확인을 하게 됩니다.
        <br />
        2. Easy Travel Care 센터 연락 방법
        <br />
        도움이 필요한 경우 또는 비상 상황 발생 시 Easy Travel Care 플랫폼 내 채팅 서비스인 SOS신청 또는 Easy Travel Care 센터(1644-1493, 82-2-2063-3544)로 연락합니다.
        <br />
        전화, 이메일, 플랫폼 채팅 서비스를 통한 SOS신청 또는 카카오톡을 통해 24시간 케어 센터로 연락하실 수 있으며, 전화 통화가 필요한 경우는 연락 가능한 전화번호를 알려주시면 담당 코디네이터가 해당 전화번호로 전화를 드립니다. 한국의 USIM칩을 사용하지 않는 전화번호는 연락이 제한될 수 있습니다. <br />
        <br />
        ※이메일 또는 카카오톡
        <br />
        Easy Travel Care 센터 대표 이메일 (care@tothepp.com) 또는 카카오톡 플러스친구
        <br />
        (@ Easy Travel Care CENTER)로 서비스 요청이 가능합니다.
        <br />
        <br />
        <h6>Easy Travel Care 서비스 보통약관</h6>
        <h6>제1조 목적</h6>
        이 약관은 (주)프리미엄패스인터내셔널 (이하 “프리미엄패스”)가 제공하는 안심 관광 플랫폼 서비스인 Easy Travel Care 서비스와 관련하여 회원 및 가입자 간의 권리 의무를 명확히 함을 그 목적으로 합니다.
        <br />
        <br />
        <h6>제2조 용어의 정의</h6>
        1. 회원
        <br />
        Easy Travel Care 서비스의 안심 관광 플랫폼 서비스 가입신청을 목적으로 전화, 이메일, 웹 페이지, 모바일 어플리케이션 등을 통하거나 혹은 Easy Travel Care 광고 대행 제휴업체를 통하여, 완전한 서비스 이용을 위해 Easy Travel Care가 요구하는 개인 식별정보 및 여행 일정 정보를 Easy Travel Care 센터에 제공한 개인 및 단체.
        <br />
        2. 가입자
        <br />
        Easy Travel Care의 약관에 명시된 권리와 의무에 동의하여 승낙의 의미로 Easy Travel Care가 제시한 가입비를 납부함으로써 가입증명번호를 부여받은 회원.
        <br />
        3. Easy Travel Care 센터
        <br />
        회원이 여행 중 발생한 어려움에 대해 편의, 보험 등의 서비스를 제공해드리고자 비대면으로 1차 서비스 응대를 진행하는 토탈 서비스 센터
        <br />
        4. 가입증명서
        <br />
        Easy Travel Care 센터가 가입자에게 제공되는 증명서로 가입증명번호와 함께 개인 식별 정보 및 주요 가입조건 등이 명시되어 있는 서류.
        <br />
        5. 케어 매니저
        <br />
        약관에 따라 가입자가 요청한 의료 및 현장 서비스와 보험서비스 응대에 대해 직·간접적으로 관련된 모든 사항을 간섭하고 결정하는 권한을 부여받아 서비스 제공을 평가, 조정, 감독하는 전문 서비스 매니저.
        <br />
        6. 멤버십 특별혜택
        <br />
        정상적인 Easy Travel Care 서비스 자격을 취득한 회원이 누릴 수 있는 특별 혜택으로 Easy Travel Care 서비스 운영자인 프리미엄패스가 정합니다. <br />
        <br />
        <h6>제3조 서비스의 내용</h6>
        Easy Travel Care가 제공하는 안심 관광 플랫폼 서비스는 다음과 같습니다.
        <br />
        1. 의료 안심케어
        <br />
        대한민국 여행 중 발생할 수 있는 교통사고, 안전사고 등 맞춤형 케어 서비스로 의료통역사 수배, 의료비 해결방안 모색, 여행자보험 & 의료지원 등 케어 서비스 제공
        <br />
        2. 트래블 안심케어
        <br />
        대한민국 여행 중 긴급사항 발생 시 제공되는 다양한 트래블 컨시어지 서비스
        <br />
        - 지갑, 여권 등 분실 시 지원 케어 서비스
        <br />
        - 여행 도중 긴급사항 발생 시 케어 서비스
        <br />
        - 교통편 예약 발권 및 차량 수배 서비스
        <br />
        - 긴급 숙박 예약 지원 서비스 등<br />
        3. 공항 안심케어
        <br />
        국제공항이라는 특별한 공간에서의 여행객을 위한 안심 케어 서비스
        <br />
        - 노약자 등 공항에서 지원 및 불편사항 케어
        <br />
        - 국제공항에서의 컨시어지 서비스 제공
        <br />
        - 비자 및 긴급 항공권 케어 서비스
        <br />
        4. 비즈니스 안심케어
        <br />
        편안하고 안전한 여행과 성공적인 비즈니스를 위한 특화 컨시어지 서비스
        <br />
        - 긴급 통·번역, 인력 수배 서비스
        <br />
        - 회의 시설 및 미팅 장소 예약 지원 서비스
        <br />
        - KTX, 고속버스, 항공권 등 예약 지원 서비스
        <br />
        5. 여행자 안심보험
        <br />
        여행 안심보험 서비스는 “프리미엄패스”의 제휴 보험사의 서비스로 제휴보험사의 규정 및 보험 약관에 따라 안심보험 서비스가 제공됩니다. <br />
        - 국내여행중 상해사망 1억원(15세 미만 제외)
        <br />
        - 국내여행중 상해후유장해: 1억원
        <br />
        - 국내여행중 질병사망 및 80%고도후유장해: 2천만원(15세 미만/ 80세 이상 제외)
        <br />
        - 국내여행중 일괄배상 (국내여행): 5백만원(타인 신체의 상해 및 재물의 손해에 대한 배상)
        <br />
        - 국내여행중 휴대품손해(분실제외): 20만원(도난, 파손 등 사고로 손해 발생 시)
        <br />
        - 국내여행 상해급여_의료실비보장: 1천만원(통원 1회당 10만원 한도)
        <br />
        - 국내여행 상해비급여_의료실비보장: 1천만원(통원 1회당 10만원, 통원 횟수 연간 100회 한도)
        <br />
        6. 멤버십 특별혜택
        <br />
        Easy Travel Care 서비스 회원에게 제공해드리는 멤버십 특화 서비스로 공항 및 여행, 비즈니스 등 다양한 컨시어지 서비스 및 할인서비스를 제공합니다. 멤버십 서비스는 Easy Travel Care 서비스의 제공사인 “프리미엄패스”에 의해 수시로 변경될 수 있으므로 웹페이지 및 모바일 어플리케이션 등에서 변경사항을 확인할 수 있습니다. <br />
        7. 기타 서비스 제공에 따른 유의사항 <br />
        - 가입자가 대한민국 여행 도중 교통사고 및 안전사고를 당하였을 경우 긴급 대처 방법 안내, 유관기관 연결 및 통역 서비스, 현장 출동을 제공합니다.
        <br />
        - 본 지원은 대한민국 내에서 발생한 사고에 대하여 처리가 가능하며, 모국 또는 환승국 등 국외 지역에서의 사고에는 서비스 제공이 제한됩니다.
        <br />
        - 본 서비스는 가입자에게 사고가 발생되는 즉시 Easy Travel Care 센터로 서비스 요청하는 것을 권고하며, Easy Travel Care가 관련 업무를 위임 받기 이전 또는 Easy Travel Care의 매니저가 사고 현장을 접수하기 전에 타 사 관계자 또는 3자가 먼저 업무를 수행한 경우 Easy Travel Care는 사고 처리절차가 원활히 진행되는데 도움을 드리기 어려울 수 있습니다.
        <br />
        <br />
        <h6>제4조 서비스 개시 및 종료</h6>
        Easy Travel Care 가입자에 대한 서비스 개시는 가입기간의 첫날 시작하여 마지막날 혹은 대한민국 출국 즉시 종료되며, 일수별 기준시간은 24시를 기준으로 산정합니다.
        <br />
        <br />
        <h6>제5조 약관의 효력 등</h6>
        1. 이 약관은 Easy Travel Care가 웹페이지, 모바일 어플리케이션 등에 게시하거나 가입자에게 고지함으로써 그 효력이 생깁니다.
        <br />
        2. Easy Travel Care가 이 약관을 개정할 경우에는 적용일 및 개정사유를 명시하여 그 적용일의 1주 전부터 적용일의 전일까지 1항과 같은 방법으로 게시 혹은 고지합니다. 이 때, 그 개정약관은 서비스 시작일자가 그 적용일자 이후에 해당되는 계약에만 적용되고 그 이전에 이미 시작된 계약에 대해서는 개정 전의 약관조항이 적용됩니다.
        <br />
        3. 가입자는 개정된 약관에 동의하지 않을 경우 가입취소를 요청할 수 있습니다.
        <br />
        4. 가입자는 가입취소를 요청하지 않고 서비스를 이용함으로써 개정된 약관에 동의한 것으로 간주됩니다.
        <br />
        <br />
        <h6>제6조 약관 적용의 우선순위</h6>
        1. Easy Travel Care 서비스와 보험사의 보험 상품과 연계된 서비스인 경우 보험과 관련한 모든 사항은 해당 보험 상품의 보통약관 및 특별약관이 적용됩니다.
        <br />
        2. Easy Travel Care의 보통약관과 특별약관의 내용이 충돌하는 경우 특별약관의 효력이 이 약관에 우선합니다.
        <br />
        <br />
        <h6>제7조 회원 및 가입자 자격 득실</h6>
        1. 모든 개인 및 단체는 가입신청을 희망하는 명확한 뜻으로 Easy Travel Care가 요구하는 개인 식별정보(국적, 성명, 생년월일, 성별, 전화번호, 이메일주소 등) 및 여행 일정 정보를 Easy Travel Care가 제공하는 매체를 통해 입력 혹은 제공함으로써 회원자격을 얻게 됩니다. 단 Easy Travel Care 서비스를 제공받기 위해서는 반드시 여권을 제시하여야 합니다. <br />
        2. 회원이 Easy Travel Care가 요구하는 개인 식별정보 및 여행 일정 정보를 불완전하게 혹은 허위로 입력(제공)하거나 12시간 이상 가입신청 단계를 진행하지 않는 경우 Easy Travel Care는 회원이 입력한 개인 식별정보 및 여행일정 정보를 모두 삭제하고 회원자격을 박탈할 권리가 있습니다.
        <br />
        3. Easy Travel Care는 가입신청 절차 진행을 위해 회원의 개인 식별정보 및 여행 일정 정보를 활용할 수 있습니다.
        <br />
        4. 1항에 따라 회원자격을 얻은 회원 중 가입절차를 거쳐 가입증명번호를 발급받음으로써 가입자 자격을 얻게 됩니다.
        <br />
        <br />
        <h6>제8조 EASY TRAVEL CARE의 의무와 권리</h6>
        1. Easy Travel Care는 안정적인 서비스 제공을 위하여 지속적으로 노력하며, 회원 혹은 가입자로부터 민원이 접수되는 경우 성실히 응대합니다. 다만 필요성과 적절성 여부에 따라 제휴사가 대신 응할 수 있습니다.
        <br />
        2. Easy Travel Care는 다음 각 호의 1에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        <br />
        가. 시스템 및 설비 보수 등.
        <br />
        나. 서비스의 근간이 되는 기간통신사업자의 망 서비스 제공 중지.
        <br />
        다. 국가비상사태, 정전, 통신망 접속 폭주 등 기타 불가항력적인 경우.
        <br />
        3. EASY TRAVEL CARE가 서비스 제공을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 제5조 1항의 방법으로 지체 없이 회원 및 가입자에게 알립니다.
        <br />
        4. 신규가입 유효기간 개시 이전의 기왕증 즉, 기존에 가지고 있던 질병 혹은 기존에 발생한 사고나 질병에 기인한 증상 및 그 합병증이 있는 경우 가입이 제한되거나 Easy Travel Care의 보험금 지급 의무가 면책될 수 있습니다.
        <br />
        5. Easy Travel Care는 서비스 제공 및 분쟁 예방을 위해 통신상 대화 내용을 저장/보관하고 감시할 권리가 있습니다.
        <br />
        6. 가입자는 이 절차 및 제공된 서비스와 관련하여 분쟁 발생 시 증명자료로 저장/보관된 내용을 사용하는 것에 명시적으로 동의를 한 것입니다.
        <br />
        7. Easy Travel Care가 원활하고 정확한 서비스 제공을 위해 요청하는 경우, 가입자는 의료 기록(여행 전에 발생한 것도 포함, 어떤 종류이든 Easy Travel Care가 서비스를 제공하기 위해 필요 하다고 판단하는 모든 기록)을 작성하여 Easy Travel Care 센터에 제출하여야 하며, 만약 제출을 거부하여 원활하고 정확한 서비스가 이행되지 못한 경우 Easy Travel Care는 이에 대해 책임이 면제됩니다.
        <br />
        8. Easy Travel Care는 가입자에게 발생한 사고나 질병 등 사건으로 인하여 가입자 본인에게 제공되는 서비스와 관련한 개인, 법적 개체, 공공기관을 포함하는 제3자에 대한 가입자의 모든 권리와 행동을 즉시 그리고 자동적으로 대리하여 행사할 권리가 있으며, 가입자는 이러한 권리와 활동을 이양하며 이를 취소할 수는 없고, 또한 이것에 필요한 모든 법적 절차를 따라야 하며 가입자에게 요구될 수 있는 모든 것에 협조해야 합니다.
        <br />
        9. Easy Travel Care 서비스 중 여행자 안심보험에 대하여는 “프리미엄패스”가 제휴 되어있는 보험사의 규정과 약관에 따라 지급됩니다.
        <br />
        10. Easy Travel Care 서비스는 안심관광 컨시어지 서비스 기반이므로 여행자 보험금에 해당하는 보장은 “프리미엄패스”의 제휴 보험사를 통하여 보장받을 수 있고, 컨시어지 서비스제공에 따른 실제로 발생하는 비용에 대하여는 회원이 부담합니다.
        <br />
        <br />
        <h6>제9조 회원 및 가입자의 의무와 권리</h6>
        1. 회원 및 가입자는 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항 등 Easy Travel Care가 고지하는 사항을 준수하여야 합니다.
        <br />
        2. 회원 및 가입자는 개인 식별정보의 부정사용이 발생하지 않도록 유의하여야 하며, 부정 사용을 알게 된 경우 즉시 Easy Travel Care에 알려 피해방지에 협력해야 합니다.
        <br />
        3. 회원 및 가입자는 서비스에 관한 권리 의무의 일부 또는 전부를 제3자에게 양도하거나 담보로 제공할 수 없습니다.
        <br />
        4. 회원 및 가입자는 법에서 정한 경우를 제외하고 본인의 동의 없이 본인의 개인 정보가 제 3 자에게 제공, 이용되는 것을 거부할 권리가 있습니다.
        <br />
        <br />
        <h6>제10조 가입취소 및 중도해지</h6>
        1. Easy Travel Care 가입 증명서에 기재된 개시일 이전에는 언제든지 가입 해지(취소)가 가능 하며 기 납부된 가입비 전액이 환불됩니다. <br />
        2. 서비스 기간 변경이나 취소는 개시일 이전에만 가능합니다.
        <br />
        3. 서비스 개시 중이라도 개인 식별정보의 부정 및 악의, 고의의 사고 발생 우려가 있다고 “프리미엄패스”가 판단하면 서비스를 중도 해지할 수 있습니다. <br />
        4. 가입 증명서에 기재된 개시일 이후 해지 시에는(중도해지) 사용 기간에 해당하는 가입비를 차감한 전액을 환불하며, 차감액 산정은 보험 단기요율과 서비스료 요율에 따릅니다. 가입비 반환청구권은 가입 자격 손실일로부터 3년간 행사하지 않으면 소멸시효가 완성됩니다. 다만, 보험료 반환청구권에 대한 규정은 보험업법을 준용합니다.
        <br />
        5. 취소 및 중도해지 사유에 따른 제출서류
        <br />
        가. 개시일 이전 취소: 취소신청서 또는 전자메일을 통한 취소의사표시/ 통장사본
        <br />
        나. 단순변심에 의한 중도해지: 해지신청서/ 여권사본/ 통장사본 (해지신청서 접수일 기준 환급)
        <br />
        다. 조기귀국에 의한 중도해지: 여권사본/ 통장사본/ 귀국 항공권 E-TICKET사본 또는 출입국사실증명서(E-TICKET의 경우 가입보험기간 내 재입국 일정이 포함된 서류 인정 불가, 출입국사실증명서의 경우 가입보험기간 내 최종귀국일자 기준으로 환급)
        <br />
        <br />
        <h6>제11조 여행의 연장으로 인한 Easy Travel Care 재가입</h6>
        1. 가입 후 국내 체류 중에 가입 기간이 만료되어 재가입 또는 연장해야 하는 경우 가입자는 가입 기간 만료 이전에 SOS 신청 및 전화나 메일 등을 통해 센터로 재가입 또는 연장 의사를 표시해야 합니다.
        <br />
        2. Easy Travel Care의 안내 여부를 불문하고 이전 가입 기간 만료일이 경과한 경우 혹은 이전 가입 기간 만료일 전에 재가입 절차가 완료되지 아니한 경우 재가입 의사가 없는 것으로 간주되어 이후 재가입이 제한됩니다.
        <br />
        3. 재가입 유효기간 개시 이전의 기왕증 즉, 기존에 가지고 있던 질병 혹은 기존에 발생한 사고나 질병에 기인한 증상 및 그 합병증이 있는 경우 재가입이 제한되거나 Easy Travel Care의 제휴 보험사로부터 보험금 지급 의무가 면책될 수 있습니다.
        <br />
        <br />
        <h6>제12조 단체보험가입 청약 등</h6>
        1. Easy Travel Care는 회원 및 가입자를 위하여 계약자로서 보험사와 단체보험계약을 체결하여 회원에게 제공할 수 있습니다.
        <br />
        2. Easy Travel Care가 계약자인 단체보험계약에 피보험자로 가입하기를 희망하는 회원은 소정의 절차에 따라 보험이 포함된 플랜에 가입신청(보험청약)을 할 수 있고, 가입절차가 완료된 회원은 가입자가 됩니다.
        <br />
        3. 보험이 포함된 플랜에 가입신청을 하고자 하는 회원은 Easy Travel Care 제휴 보험사의 가입 안내사항 및 보험 약관 등을 충분히 읽고 이해한 후 가입신청을 하여야 하며, 보험사와 Easy Travel Care가 고지를 요구하는 사항에 대하여 성실히 응해야 합니다.
        <br />
        4. 보험이 포함된 플랜을 가입하는 경우 회원이 가입하는 보험의 수익자는 보험가입자(피보험자) 본인과 그 법정상속인이 됩니다.
        <br />
        5. Easy Travel Care가 계약한 단체보험에 관하여 기타 이 규약에서 정하지 않은 사항은 Easy Travel Care가 보험사와 약정한 바에 따릅니다.
        <br />
        <br />
        <h6>제13조 여행보험 편의 지원</h6>
        Easy Travel Care 플랜 내 여행보험은 계약자가 “프리미엄패스”의 단체보험으로 보험료에 대한 단체할인이 적용되어 있으며, 각 플랜은 최적의 보험 보장 내역 및 가입금액이 될 수 있도록 하고 있습니다. 또한 보험사에 보험금 청구대행, 비례보상 보험금 접수대행 및 최종 안내를 해드립니다.
        <br />
        <br />
        <h6>제14조 Easy Travel Care 보험서비스 지원의 제한</h6>
        Easy Travel Care는 그 원인의 직접 또는 간접을 묻지 아니하고 아래의 사유를 원인으로 하여 생긴 서비스 발생에 대해서는 도움을 드리지 않는 것이 원칙이며, 가입자 요청으로 서비스 진행 시 별도의 비용이 발생할 수 있습니다.
        <br />
        ① 가입 증명서에 기재되지 않은 서비스 내용
        <br />
        ② 가입자 또는 보호자(소속 기관 포함)의 고의 또는 중대한 과실.
        <br />
        ③ 가입자의 질병 또는 상해로 인한 진단비, 치료비, 약제비 등 의료비 일체.
        <br />
        ④ 사형 또는 가입자의 자해, 자살, 자살 미수, 형법상의 범죄행위 또는 폭력행위. 다만, 형법상 정당행위, 긴급피난 및 정당방위로 인정되는 경우에는 지원합니다.
        <br />
        ⑤ 가입자의 심신상실 또는 정신질환.
        <br />
        ⑥ Easy Travel Care 혹은 제휴 기관의 승인 없이 가입자가 다른 의료기관으로부터 받은 치료의 결과로 생긴 상해, 질병 또는 합병증.
        <br />
        ⑦ Easy Travel Care 가입자가 아닌 그 동반여행자에게 발생한 일체의 비용.
        <br />
        ⑧ 가입자가 전염병 발생 국가에 대한 정부의 여행 금지 또는 예방접종 조치에 응하지 않고 해당 국가를 여행하고 돌아와서 생긴 전염병.
        <br />
        ⑨ 가입자가 자발적으로 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동, 소요, 노동쟁의 및 기타 이들과 유사한 사태가 발생한 지역에 들어가서 발생한 서비스 내용
        <br />
        ⑩ 핵연료 물질(사용이 끝난 연료를 포함합니다. 이하 같습니다) 또는 핵연료 물질에 의하여 오염된 물질(원자핵분열 생성물을 포함합니다)의 방사성, 폭발성 또는 그 밖의 유해한 특성에 의한 사고. (그 외 방사선을 쬐는 것 또는 방사능 오염을 포함합니다.)
        <br />
        ⑪ 우연하고 급격한 사고나 질병으로 기인하지 않은 임신, 출산, 유산 또는 외과적 수술, 그 밖의 의료처치이거나 여행의 목적 자체가 기존 상해 또는 질병 치료 등 의료행위인 것으로 확인된 경우.
        <br />
        ⑫ 지진, 분화, 해일 또는 이와 비슷한 천재지변.
        <br />
        ⑬ 기타 본 약관상의 면책 사유.
        <br />
        <br />
        <h6>제15조 분쟁의 해결</h6>
        1. Easy Travel Care와 가입자는 서비스와 관련하여 분쟁이 발생한 경우에 이를 원만하게 해결하기 위하여 노력하여야 합니다.
        <br />
        2. 전항의 규정에도 불구하고, 당사자 간 분쟁으로 인하여 소송이 제기될 경우 가입자와 Easy Travel Care 사이에 계약 관계에 관한 모든 법적인 사항에 관해서는 대한민국 법원을 합의에 따른 관할법원으로 합니다.
        <br />
        3. 계약에 관하여 분쟁이 있는 경우에는 대한민국 법률에 따르며, 보험과 관련된 분쟁이 발생한 경우에는 제휴 보험사의 보통약관 및 특별약관의 내용에 따릅니다.
        <br />
        <br />
        PREMIUMPASS International Co., Ltd. 서울시 강서구 마곡중앙4로 22(마곡동) 파인스퀘어 A동 501호
        <br />
        PREMIUMPASS International Co., Ltd. 케어센터 +82-2-2063-3544
        <br />
        PREMIUMPASS International Co., Ltd. 케어센터 이메일 care@tothepp.com <br />
        PREMIUMPASS International Co., Ltd. 케어센터 카카오톡ID (@Easy Travel Care CENTER) <br />
        PREMIUMPASS International Co., Ltd. 가입상담 1644-1493
        <br />
        PREMIUMPASS International Co., Ltd. 팩스 +82-2-2138-0164
        <br />
        <br />
      </>
      <h6>{language['현대해상 화재보험 약관']}</h6>
      <>
        <h6>(필수) 개인(신용) 정보 처리동의</h6>
        <h6>개인(신용)정보의 수집 이용에 관한 사항</h6>
        개인신용정보의 수집 이용 목적
        <br />
        - 보험계약의 인수 심사 체결 유지 관리 (부활 및 갱신 포함)
        <br />
        - 보험금 등 지급 심사
        <br />
        - 순보험요율의 산출 검증
        <br />
        - 민원처리 및 분쟁대응
        <br />
        - 적부 및 사고조사 (보험사기 조사포함)
        <br />
        - 보험모집질서의 유지
        <br />
        - 금융거래 관련 업무 (금융거래 신청, 자동이체 등) 수집이용할 개인(신용) 정보의 내용
        <br />
        - 개인식별정보 (성명, 주민등록번호, 외국인등록번호, 여권번호, 주소, 성별, 직업, 전화번호, 전자우편주소)
        <br />
        - 금융거래 업무 (보험료 및 보험금 등 출수납 ) 관련 정보 개인(신용) 정보 보유 이용기간
        <br />
        - 수집 이용 동의일로부터 (금융)거래 종류 후 5년까지
        <br />
        <br />
        <h6>개인(신용)정보의 조회에 관한 사항</h6>
        개인신용정보의 조회목적
        <br />
        - 보험계약의 인수심사 체결 유지 관리 (부활 및 갱신 포함), 보험금 등 지급, 심사 보험사고조사 (보험사기조사 포함) 조회할 개인(신용) 정보
        <br />
        - 신용정보집중기관 (생명보험협회, 손해보험협회, 한국신용정보원) 및 보험요율 산출기관에서 수집 관리하는 보험계약정보, 보험금지급 관련 정보 (사고정보 포함), 피보험자의 질병 및 상해관련 정보 조회동의 유효기간 및 개인(신용)정보의 보유 이용 기간
        <br />
        - 수집 이용 동의일로부터 거래종류 후 5년까지 (단, 거래종료 후 5년이 경과한 후에는 보험금 지급, 금융사고조사, 보험사기 방지 적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유 이용하며 별도 보관)
        <br />
        <br />
        <h6>(필수) 보험계약 체결을 위한 동의</h6>
        <h6>단체보험 규약동의</h6>
        제1조 (목적)
        <br />
        이 규약은 (주)프리미엄패스인터내셔널 회원들을 위하여 제공하는 서비스 이용에 관하여 회원들 간 협약사항을 그 목적으로 합니다.
        <br />
        제2조 (단체보험가입 청약 등)
        <br />
        1. (주)프리미엄패스인터내셔널은 회원들을 위하여 계약자로서 보험사와 단체보험계약을 체결할 수 있습니다.
        <br />
        2. (주)프리미엄패스인터내셔널이 계약자인 단체보험계약에 피보험자로 가입하기를 희망하는 회원은 소정의 절차에 따라 보험가입신청을 합니다.
        <br />
        3. 보험가입신청을 하고자 하는 회원은 보험사와 (주)프리미엄패스인터내셔널이 고지를 요구하는 사항에 대하여 성실히 답합니다.
        <br />
        <br />
        <h6>보험계약의 체결, 이행 등을 위한 개인(신용)정보 처리동의</h6>
        개인신용정보의 수집 이용에 관한 사항 개인정보보호법 및 신용정보의 이용 및 보호에 관한 법률에 따라 본 계약과 관련 하여 귀하의 개인(신용)정보를 다음과 같이 수집 이용 하고자 합니다.
        <br />
        이에 대하여 동의하십니까? <br />
        <br />
        개인 신용정보의 수집 이용 목적
        <br />
        - 보험계약의 인수 심사 체결 유지 관리 (부활 및 갱신 포함)
        <br />
        - 보험금 등 지급 심사
        <br />
        - 순보험요율의 산출 검증
        <br />
        - 민원처리 및 분쟁대응
        <br />
        - 적부 및 사고조사 (보험사기 조사포함)
        <br />
        - 보험모집질서의 유지
        <br />
        - 금융거래 관련 업무 (금융거래 신청, 자동이체 등)
        <br />
        <br />
        수집이용할 개인(신용) 정보의 내용
        <br />
        - 개인식별정보 (성명, 주민등록번호, 외국인등록번호, 주소, 성별, 직업, 전화번호, 전자우편주소)
        <br />
        - 금융거래 업무 (보험료 및 보험금 등 출수납 ) 관련 정보 <br />
        <br />
        개인(신용) 정보 보유 이용기간
        <br />- 수집 이용 동의일로부터 (금융)거래 종류 후 5년까지
        <br />
        <br />
      </>
      <h6>{language['이용약관']}</h6>
      <>
        <h6>제1조(목적)</h6>
        이 약관은 프리미엄패스인터내셔널(이하 ”회사”라 함)이 운영하는 이지트래블케어(easytravelcare)인터넷사이트(이하 “사이트”라 한다)를 통해 제공하는 전자상거래 서비스 (이하 서비스라 함)를 이용함에 있어 회원의 권리와 의무 및 책임사항을 규정하는 것을 목적으로 합니다. 이 약관은 PC 통신, 스마트폰(안드로이드폰, 아이폰 등) 앱, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용됩니다.
        <br />
        <br />
        <h6>제 2 조 (용어의 정의)</h6>
        약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        1. “사이트"란 회사에 회원으로 등록한 이용자가 다양한 정보와 서비스를 제공받을 수 있도록 회사가 제작, 운영하는 인터넷 웹사이트를 의미합니다. <br />
        현재 "회사"가 운영하는 "사이트"(2023. 01현재)는 아래와 같습니다.
        <br />
        * www.easytravelcare.co.kr
        <br />
        - 추후 "회사"에서 공지하고 제공하는 기타 웹사이트
        <br />
        <br />
        2. "판매자”란 상품 또는 서비스의 원활한 판매를 위하여 “회사”로부터 판매대행서비스를 제공받는 자를 말한다. 단 오픈마켓 서비스의 경우에는 “회원”이 구매한 상품 및 서비스 등의 거래 정보에 대한 직접적인 책임을 지는 자를 말합니다.
        <br />
        <br />
        3. "회원"이라 함은 “사이트”(이하 회사)에 개인정보를 제공하여 회원등록을 한 사용자로서, 회원등록 시 설정한 아이디(ID)로 “사이트”에 자유롭게 접속하여 회사가 제공하는 정보를 지속적으로 제공받을 수 있거나, “회사”의 서비스를 계속적으로 이용할 수 있는 사용자를 말합니다.
        <br />
        <br />
        4. "상품” 등이라 함은 “회사”가 “사이트”를 통하여 회원에게 판매하는 서비스를 말합니다.
        <br />
        <br />
        5. "아이디(ID)"라 함은 “회원”의 식별과 서비스 이용을 위하여 “회원”이 선정하고 회사가 승인하여 등록된 문자 또는 숫자의 조합을 말합니다.
        <br />
        <br />
        6. "비밀번호(Password)"라 함은 “회원”의 동일성 확인과 “회원”의 권익 및 비밀보호를 위하여 “회원” 스스로가 설정하여 “사이트”에 등록한 문자와 숫자의 조합을 말합니다.
        <br />
        <br />
        7. 예약 확인 바우처 : 상품과 서비스 (이하 "상품 등"이라 함)을 구입한 가격으로 이용할 수 있는 디지털 증권을 말합니다.
        <br />
        <br />
        8. 게시판: "회원"이 "서비스"를 이용함에 있어 "사이트"상에 게시한 부호, 문자, 음성 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
        <br />
        <br />
        9. “채팅"이라 함은 상품 등을 구매 후 긴급 서비스 상담을 원할 때 사용할 수 있는 채팅 서비스를 말합니다.
        <br />
        <br />
        10. 이 약관에서 정의되지 않은 용어는 관련법령이 전하는 바에 따릅니다.
        <br />
        <br />
        <h6>제 3 조 (약관 등의 명시와 설명 및 개정)</h6>
        1. “회사”는 이 약관의 내용과 회사의 “상호”, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화번호, 팩스번호, 전자우편주소 등), 통신 판매업 신고 번호, 개인정보관리책임자 등과 함께 회원이 쉽게 확인할 수 있도록 “사이트”의 초기 “서비스” 화면(전면)에 게시한다. 다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있습니다.
        <br />
        <br />
        2. “회사”는 약관의 규제에 관한 법률, 전자상거래 등의 소비자보호에 관한 법률, 전자문서 및 전자거래 기본법, 전자 서명법, 정보통신망 이용촉진 등에 관한 법률, 소비자기본법, 전자금융거래법 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        <br />
        <br />
        3. “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “사이트”의 공지사항 또는 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지한다. 단, “회원”에게 중대한 영향을 주는 불리한 내용으로 변경하는 경우에는 30일 전부터 공지합니다.
        <br />
        <br />
        4. 전 항에 의해 개정약관은 적용일자 이후에 체결된 계약에만 적용되고, 법령에 특별한 규정 또는 기타 부득이한 사유가 없는 한 개정약관 적용일자 이전으로 소급하여 적용되지 아니합니다.
        <br />
        <br />
        5. 전 항에 따라 공지된 적용일자 이후에 회원이 “회사”의 “서비스”를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 본다. 개정된 약관에 동의하지 않은 “회원”은 언제든 자유롭게 “서비스” 이용계약을 해지할 수 있습니다.
        <br />
        <br />
        6. 이 약관에 명시하지 않은 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침과 관련 법령의 규정과 일반 상관례에 따릅니다.
        <br />
        <br />
        <h6>제 4 조 (개별 서비스이용 약관 및 이용 조건)</h6>
        1. “회사”는 제공하는 “서비스” 내의 “개별 서비스”에 대한 별도의 이용 약관 및 이용조건을 각 “서비스” 별로 둘 수 있으며 “개별 서비스”에서 별도로 적용되는 약관에 대한 동의는 “해당 서비스”를 최초로 이용할 경우 별도의 동의절차를 거친 후 이용하여 “서비스” 내용을 동의한 것으로 간주되며, 이 경우 “개별 서비스”에 대한 이용약관 등은 본 약관에 우선합니다.
        <br />
        <br />
        2. 이지트래블케어 서비스는 당일 18:00 이전 구매시, 구매 시각 기준 3시간 이후부터 서비스 제공하며 당일 18:00 이후 구매 시, 다음날 오후12:00 부터 서비스 제공을 기준으로 합니다.
        <br />
        <br />
        <h6>제 5 조 (서비스 제공의 중지)</h6>
        1. "회사"는 다음 각 호에 해당하는 경우 "서비스" 제공을 중지할 수 있습니다.
        <br />
        1） "서비스" 이용 설비의 보수점검, 교체 및 고장 등 공사로 인한 부득이한 경우
        <br />
        2） 전기통신사업법에 규정된 기간통신사업자가 전기통신 "서비스"을 중지했을 경우
        <br />
        3） 기타 불가항력적 사유가 있는 경우
        <br />
        <br />
        2. “회사”는 국가비상사태, 정전, “서비스” 설비의 장애 또는 “서비스” 이용의 폭주 등으로 정상적인 “서비스” 이용에 지장이 있는 때에는 “서비스”의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
        <br />
        <br />
        <h6>제 6 조 (이용계약의 성립)</h6>
        1. 이용계약은 “회원”의 약관 동의, 이용 신청에 대한 “회사”의 승인 후 성립합니다.
        <br />
        2. 이용계약의 성립은 “회사”가 가입완료 표시를 신청절차 상에서 승인 또는 표시한 시점으로 합니다.
        <br />
        <br />
        <h6>제 7 조 (이용신청 및 승낙)</h6>
        1. “회사”는 다음 각 호에 해당하는 이용신청에 대하여는 승인을 거부하거나 유보 및 사후 이용계약을 해지할 수 있습니다.
        <br />
        1） 이미 가입된 “회원”의 성명 및 전자우편주소가 동일한 경우
        <br />
        2） 타인의 명의를 도용하거나, 허위기재, 기재 누락 등이 있는 경우
        <br />
        3） 등록내용에 허위의 정보를 기재하거나, 오기가 있는 경우
        <br />
        4） 이용 신청자가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우
        <br />
        5） “회사”에 의해 이용 자격 상실 후 3개월이 경과 후 “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로 함<br />
        6） 부정한 용도 또는 영리를 추구할 목적으로 “서비스”를 이용하고자 하는 경우(단, “회사”가 승인한 범위 내에서의 서비스 이용은 예외로 한다.)
        <br />
        7） “서비스” 운영에 따른 관련 설비의 여유가 없거나, 기술상 또는 업무상의 지장 또는 문제가 있는 경우 다만, 그 사유가 해소될 때까지 승낙을 유보할 수 있다.
        <br />
        8） 관련 법령, 미풍 양속 혹은 사회의 안녕질서 및 기타 제반 사항을 위반하여 신청하거나 회원의 귀책사유로 인하여 승인이 불가능한 경우
        <br />
        9） 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 “회사”가 합리적인 판단에 의하여 필요하다고 인정하는 경우
        <br />
        <br />
        3. 제1항에 따른 이용 신청에 있어 “회사”는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
        <br />
        <br />
        <h6>제 8 조 (서비스 이용시간)</h6>
        “서비스”의 이용은 “회사”의 업무 또는 기술상 특별한 지장이 없는 경우 365일 1일 24시간을 원칙으로 합니다. 단, 정기 점검 등의 사유로 “회사”가 공지한 날 또는 시간은 제외됩니다.
        <br />
        <br />
        <h6>제 9 조 (회원의 아이디 및 비밀번호에 대한 의무)</h6>
        1. “아이디와 “비밀번호”에 관한 관리책임은 전적으로 “회원”에게 있으며, 이를 소홀히 하여 발생한 모든 민, 형사상의 책임은 “회원” 자신에게 있습니다.
        <br />
        2. “회원”은 자신의 “아이디”및 “비밀번호”를 제 3자에게 대여 및 이용하게 해서는 안된다.
        <br />
        3. “회원”은 자신의 “아이디”및 “비밀번호”를 도난 또는 제 3자가 사용하고 있음을 인지한 경우 그 즉시 “회사”에 통보하고 “회사”의 조치가 있는 경우에는 그에 따라야 합니다.
        <br />
        4. “회원”이 전 항에 따른 통지를 하지 않거나 “회사”의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 전적으로 “회원”에게 있습니다.
        <br />
        <br />
        <h6>제 10 조 (이용계약의 해지 및 종료)</h6>
        1. “회원”의 해지
        <br />
        1） “회원”은 언제든지 “회사”에 연락하여 해지의사를 통지함으로써 이용계약을 해지(회원탈퇴를 요청)할 수 있으며, “회원”의 계정 등록 자료의 삭제를 요청할 수 있습니다.
        <br />
        2） 이용계약은 “회원”의 해지 의사가 “회사”에 도달한 때에 종료됩니다.
        <br />
        3） 전 항에 따라 해지를 한 “회원”(탈퇴한 회원)은 “회사”가 정한 규정 약관에 따라 회원가입절차를 거처 회원으로 다시 가입할 수 있으나 해지(탈퇴)이전 회원 가입시 적용되었던 정보는 소멸되며 환불 또한 적용되지 않습니다
        <br />
        <br />
        2. 회사의 해지 <br />
        1） “회사”는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수 있고, 해당 “회원”에 대한 보유 정보는 개인정보취급방침에 따릅니다.
        <br />
        가. 제7조 제1항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우
        <br />
        나. “회원”이 “회사” 또는 다른 “회원” 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 선량한 풍속 기타 사회질서, 대한민국 법령에 위배되는 행위를 한 경우
        <br />
        다.”회사” 가 판매하는 “상품” 또는 “서비스”에 대한 확인되지 않는 사실과 내용으로 불신을 조장하는 행위를 한 경우 <br />
        라. 거래 질서를 방해할 목적으로 중복 구매 등 사이트의 원활한 진행을 방해하는 행위를 하는 시도한 경우
        <br />
        마. 기타 “회사”가 합리적인 판단에 의한 “회원”이 이 약관에 위배되는 행위를 하는 경우
        <br />
        바. “회사”는 전자우편, 전화, 팩스 기타의 방법을 통하여 해지의사를 밝혀 회원에게 해지 사유를 통지합니다.
        <br />
        다만 회사는 해당 회원에게 사전에 해지 사유에 대한 의견진술의 기회를 부여합니다.
        <br />
        <br />
        4. 본 조항에 따라 이용계약이 종료되는 경우 “회원”은 서비스를 이용할 권한이 없어지며, 회원의 개인정보 및 기록 등은 전부 삭제되며, 사전 구매한 상품 등의 환불 등의 처리에 관하여는 회사의 환불 규정에 의거합니다.
        <br />
        <br />
        5. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 “회원”이 책임을 부담하여야 하고 “회사”는 일체의 책임을 지지 않습니다.
        <br />
        <br />
        <h6>제 11 조 (회원의 의무)</h6>
        1. “회원”은 관련법령, 본 약관규정, 이용안내 등 “회사”가 고지하는 내용을 준수하여야 하며, 기타 “회사” 업무에 방해되는 행위를 해서는 안됩니다.
        <br />
        <br />
        2. “회원”은 “서비스” 이용과 관련하여 다음 각 호의 행위를 해서는 안됩니다.
        <br />
        1) "서비스" 신청 또는 변경 시 허위내용의 등록
        <br />
        2) 타인의 정보 도용 등<br />
        3) “회사"가 게시한 정보의 허가 받지 않은 변경
        <br />
        4) "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
        <br />
        5) "회사" 및 기타 제3자의 저작권, 초상권 등 지식 재산권에 대한 침해
        <br />
        6) "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
        <br />
        7) 외설 또는 폭력적인 메시지, 동영상, 음성 기타 공공질서, 미풍양속에 반하는 정보를 "사이트"에 공개 또는 게시하는 행위
        <br />
        8) 기타 관련법령이나 "회사"에서 정한 규정에 위배되는 행위
        <br />
        <br />
        <h6>제 12 조 (회사의 의무)</h6>
        1. “회사”는 법령과 이 약관 금지하거나 공서 양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여야 합니다.
        <br />
        <br />
        2. “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 “회원”의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수한다.
        <br />
        <br />
        <h6>제 13 조 (구매신청 및 개인정보 제공 동의 등)</h6>
        1. "회원"은 “사이트” 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "회사"는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        <br />
        1). 상품 등의 검색 및 선택
        <br />
        2). 상품 이용에 관한 예약 정보의 입력(날짜 등)
        <br />
        3). 예약자와 서비스를 받는 사람의 성명, 국적, 여권번호, 주민등록번호, 전자우편주소(또는 이동전화번호) 등의 입력
        <br />
        4). 각 상품별 구성, 포함 사항 및 약관 내용 제한되는 서비스, 등의 비용부담과 관련한 내용에 대한 확인
        <br />
        5). 이 약관에 동의하고 위 4호의 사항을 확인하거나 거부하는 표시(예:마우스 클릭 등)
        <br />
        6). 상품 등의 구매 신청 및 이에 관한 확인 또는 "회사(사이트)"의 확인에 대한 동의
        <br />
        7). 결제 방법의 선택
        <br />
        <br />
        2. 회사는 회원의 구매신청이 있는 경우 회원에게 구매확인통지를 하며, 이 통지가 도달한 시점에 구매계약이 성립합니다.
        <br />
        <br />
        3.”회사”가 제 3자에게 구매자 개인정보를 제공, 제공할 필요가 있는 경우 제공되는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보의 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다.(동의를 받은 사항이 변경되는 경우에도 같다.)
        <br />
        <br />
        4.”회사”가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 개인정보 취급 위탁을 받는 자, 개인정보 취급 위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의 증진과 관련된 경우에는 정보 통신망 이용 촉진 및 정보보호 등에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지 절차와 동의절차를 거치지 않아도 됩니다.
        <br />
        <br />
        <h6>제 14조 (계약의 성립)</h6>
        1. “회사”와 “회원”은 각 구매 상품의 예약 확정까지 걸리는 시간에 대해 각 상품 페이지의 기재 내용을 준수하고 것에 동의합니다. <br /> <br />
        2. "회사"는 제13조와 같은 "회원"의 구매 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. <br />
        1) 신청 내용에 허위, 기재 누락, 오기가 있는 경우
        <br />
        2) 기타 구매 신청에 승낙하는 것이 "회사"의 기술 상 현저히 지장이 있다고 판단하는 경우와 정보 오기재, 정보 누락, 시스템 오류, 품절 등의 사유로 구매 신청한 “상품”과 “서비스” 제공이 불가한 경우 단, 이 경우 “회사”는 “회원”에게 이를 사후 통지하고 필요한 조치를 이행하여야 함
        <br />
        3) 제 3자의 아이디, 명의 또는 결제 수단을 도용하여 구매 신청하는 경우
        <br />
        4) 재판매 목적 또는 "상품 등"을 구매신청 정황 상 부당한 목적으로 구매하였음이 의심되는 경우
        <br />
        5) 구매의사 없이 상품의 구매와 반품을 무분별하게 반복하여 다른 이용자의 구매 기회를 박탈하는 경우
        <br />
        6) 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
        <br />
        7) 기타 이 약관에 위배되거나 악용하고자 하는 부당한 이용신청임이 확인된 경우 및 "회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우
        <br /> <br />
        3. “회사”의 승낙이 제19조 제1항에 따른 수신확인통지형태로 "회원"에게 도달한 시점에 계약이 성립한 것으로 봅니다.
        <br /> <br />
        4. "회사"의 승낙의 의사표시에는 "회원"의 구매 신청에 대한 확인 및 판매 가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
        <br /> <br />
        <h6>제 15 조 (결재 방법)</h6>
        1.“사이트”에서 구매한 상품 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
        <br />
        1) 신용카드
        <br />
        2) 각종 Pay
        <br />
        3) 기타 “회사”가 추가 지정하는 결재 수단 및 전자적 지급 방법에 의한 대금 지급 등<br />
        <br />
        2.”회원”이 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.
        <br />
        <br />
        3.”회사”는 “회원”이 구매대금 결재시 사용한 결제 수단에 대한 본인 정보 확인 또는 정당한 사용권한 유무에 대해 확인할 수 있으며, 이에 대한 확인이 완료전까지 거래를 중단하거나, 확인이 불가능한 경우 해당 거래는 취소 또는 환불 보류 등을 할 수 있다.
        <br />
        <br />
        4.”회사”는 “회원”이 구매한 상품의 구매증명서(현금영수증 등)를 요청하면 실구매액으로 발행합니다.
        <br />
        <br />
        <h6>제 16조 (계약의 성립)</h6>
        1.”회사”는 “회원”이 구매한 “사이트”의 상품의 상세한 내용을 SMS 또는 전자우편 등의 방식을 통해 발송합니다.
        <br />
        <br />
        2.”예약확인증 또는 바우처” 전송 작업은 공휴일과 “회사”의 근무시간과 휴무일 또는 처리과정의 상황에 따라 변경될 수 있으며, 이는 “사이트” 내 해당 콘텐츠에 명시된 내용을 따릅니다.
        <br />
        <br />
        <h6>제 17 조 (수신확인통지. 구매신청 변경 및 취소)</h6>
        1.”사이트”는 “회원”의 구매신청이 있는 경우 “회원”에게 수신 확인통지를 합니다.
        <br />
        <br />
        2.수신 확인 통지를 받은 “회원”은 의사표시의 불일치 등이 있는 경우에는 수신 확인 통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “사이트”는 예약확정 전에 “회원”의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제 20 조의 청약철회 등에 관한 규정에 따릅니다.
        <br />
        <br />
        <h6>제 18 조 (청약철회 등)</h6>
        1. "회사"의 구매취소 및 청약철회 규정은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령을 준수합니다.
        <br />
        <br />
        2. "회원"의 단순 변심에 의한 취소 및 환불일 경우 이의 처리에 발생하는 수수료는 "회원"이 부담합니다.
        <br />
        <br />
        3. "회원"은 "상품 등"의 내용이 표시∙광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 그 "상품 등"을 공급받은 날부터 30일 이내, 그 사실을 청약을 철회할 수 있습니다.
        <br />
        <br />
        4. 신용카드 또는 전자화폐로 결제한 "회원"의 경우 지체없이 당해 결제수단을 제공한 사업자로 하여금 대금의 청구를 정지 또는 취소하도록 요청을 합니다. <br />
        <br />
        5. 구매 취소 시 기존에 결제하였던 내역을 취소하며 취소 시점에 따라 예약 취소 수수료를 재승인 한다. 이 경우 구매 취소 시점과 해당 카드사의 환불 처리기준에 따라 취소 금액의 환급 방법과 환급일은 다소 차이가 있을 수 있으며, 사용한 신용카드의 환불 정책은 신용카드회사에 직접 문의하여야 합니다.
        <br />
        <br />
        6. "회원"이 타인의 개인정보를 도용하여 본 약관에서 금지하는 부정한 방법으로 예약 후 부당한 이익 편취라고 의심되는 경우 "회사"는 "회원"의 티켓 구매를 취소처리 또는 제한할 수 있으며, "회원"이 충분한 소명 자료를 제출할 때까지 환불을 보류할 수 있습니다.
        <br />
        <br />
        7. 기타 이 약관 및 "사이트"의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자 피해보상 규정에서 정한 바에 따릅니다.
        <br />
        <br />
        <h6>제 19 조 (상품의 환불 특약)</h6>
        1.”회원”은 서비스 구매 후 다음 각 호에 해당하는 경우 환불 또는 교환을 요청할 수 없습니다.
        <br />
        1) 서비스 시작 후 단순 변심은 환불대상에서 제외되며 각 “판매자”의 판매 규정에 따릅니다.
        <br />
        <br />
        2.”회사”가 상품의 반품절차에 따라 환불하는 경우 “판매자” 또는 “보험업체”와 협의를 마친 것을 확인한 이후 환불이 이루어집니다.
        <br />
        <br />
        <h6>제 20조 (개인정보보호)</h6>
        1.”사이트”는 ‘회원”의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
        <br />
        <br />
        2.”사이트”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
        <br />
        <br />
        3.”사이트”는 “회원”의 개인정보를 수집, 이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
        <br />
        <br />
        4.”사이트”는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용, 제공단계에서 당해 “회원”에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
        <br />
        <br />
        5.”사이트”는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우
        <br />
        개인
        <br />
        정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 "회원"은 언제든지 이 동의를 철회할 수 있습니다
        <br />
        <br />
        6. "회원"은 언제든지 "회사"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. "회사"가 오류의 정정을 요구한 경우에는 "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. 단, 열람 및 오류 정정의 절차는 "회사"가 정한 바에 따릅니다.
        <br />
        <br />
        7. "회사"는 개인정보 보호를 위하여 "회원"의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 "회원"의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 "회원"의 손해에 대하여 모든 책임을 집니다.
        <br />
        <br />
        8. "회사" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
        <br />
        <br />
        9. "회사"는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정 해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 "회원"의 동의 거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 "회원"의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
        <br />
        <br />
        <h6>제 21조 (저작권의 귀속 및 이용제한)</h6>
        1.”사이트”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “사이트”에 귀속합니다.
        <br />
        <br />
        2.”회원”은 “사이트”를 이용함으로써 얻은 정보, 이미지, 데이터 중 “사이트”에게 지적재산권이 귀속된 정보를 “사이트”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br />
        <br />
        3.”회원”은 “사이트”에서 구매한 상품의 데이터 이미지를 “사이트”의 사전 승낙없이 임의로 가공, 복제, 송신, 출발, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
        <br />
        <br />
        <h6>제 22조 (회원자격의 제한 또는 정지)</h6>
        1. “회원”은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원 탈퇴를 처리합니다.
        <br />
        <br />
        2. “회원”이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 즉시 제한 또는 정지시킬 수 있습니다.
        <br />
        <br />
        1) 회원 가입신청 또는 변경 시 허위 내용을 등록한 경우
        <br />
        2) “사이트”를 이용하여 구입한 상품 등의 대금, 기타 “사이트” 이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우
        <br />
        3) 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
        <br />
        4) “사이트”를 이용하여 법령, 이 약관이 금지하거나 공서 양속에 반하는 행위를 하는 경우
        <br />
        5) “사이트”의 건전한 운영을 해하거나 사이트의 업무를 방해하는 경우
        <br />
        가.”사이트”의 운영과 관련하여 근거 없는 사실과 허위의 사실을 적시하거나 유포하여 “회사”의 신뢰성을 해치는 경우
        <br />
        나.”사이트”의 이용 과정에서 “회사” 직원에게 폭언, 협박 또는 음란한 언행 등으로 사이트의 운영을 방해하는 경우
        <br />
        다. 상품 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소∙반품하여 회사의 업무를 방해하는 경우
        <br />
        라.”사이트”를 통해 구매 후 상품 등에 특별한 하자가 없는데도 불구하고 일부 사용 후 상습적인 취소∙전부 또는 일부 반품 등으로 “회사”의 업무를 방해하는 경우
        <br />
        마. “사이트”를 통해 구매한 상품 등에 특별한 하자가 없는데도 불구하고 개인적인 단순 변심, 근거 없는 불만족 등으로 “회사”의 업무를 방해하는 경우 <br />
        <br />
        <h6>제 23조(면책조항)</h6>
        1.”회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”을 제공할 수 없는 경우에는 “서비스” 제공에 대한 책임이 면제됩니다.
        <br />
        <br />
        2.”회사”는 “회원” 귀책사유로 인한 “서비스” 이용의 장애에 대해서는 책임을 지지 않습니다.
        <br />
        <br />
        3.”회사”는 “회원”이 게재한 정보, 자료 등 사실의 신뢰도와 정확성 등 내용에 대해서는 책임을 지지 않습니다.
        <br />
        <br />
        4.”회사”는 “회원”간 또는 “회원”과 제3자간 이루어진 “서비스”에 관하여는 어떠한 책임도 지지 않습니다.
        <br />
        <br />
        5.”회사”는 “회원”이 “서비스”을 이용하여 얻은 기대 수익의 상실과 “서비스”을 통하여 얻은 자료에 대한 손해에 대해서는 어떠한 책임도 지지 않습니다.
        <br />
        <br />
        <h6>제24조(분쟁해결)</h6>
        1.”사이트”는 “회원”이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 보상 처리 기구를 설치, 운영합니다.
        <br />
        <br />
        2.”사이트”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
        <br />
        <br />
        <h6>제25조 (재판권 및 준거법)</h6>
        1.”사이트”와 “회원” 간에 방생한 전자상거래 분쟁에 관한 소송은 제소 당시의 “회원”의 주소에 의하고 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 다만 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
        <br />
        <br />
        2.”사이트”와 “회원” 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
        <br />
        <br />
        부칙
        <br />
        1. 이 약관은 2021년 1월 1일부터 시행한다.
        <br />
        <br />
      </>
      <>
        <h6>개인정보 처리방침</h6>
        ㈜프리미엄패스인터내셔널(이하 ‘본 사‘라 함)는 귀하의 개인정보보호를 매우 중요시하며, 『개인정 보보호법』을 준수하고 있습니다. 본 사는 개인정보 처리방침을 통하여 귀하께서 제공하시는 개인 정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
        <br />
        <br />
        <h6>1. 처리하는 개인정보의 항목</h6>본 사는 안심 케어, 보험 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다. 선택 항목은 입력하지 않더라도 서비스 이용에는 제한이 없습니다.
        <br />
        <br />
        <h6>[회원가입시 수집항목]</h6>
        - 필수항목: 성명, 이메일, 생년월일, 성별, 주민번호(내국인), 여권번호(외국인), 전화번호
        <br />- 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로 생 성되어 수집될 수 있습니다. (서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보)
        <br />
        <br />
        <h6>2. 개인정보의 처리 목적</h6>
        본 사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 고객이 제공한 모든 정보는 다음에 기술한 목적 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다. <br />
        - 홈페이지 회원가입 및 관리, 본인확인, 개인식별, 부정사용방지, 비인가 사용방지
        <br />
        - 고객 신원파악, 금융거래 및 서비스 업무, 본인확인, 개인식별, 부정사용방지, 비인가 사용방지
        <br />
        <br />
        <h6>민원처리</h6>- 현대해상 보험 가입, 안심 케어 서비스 제공, 금융거래 및 서비스 업무, 요금 추심
        <br /> - 민원처리
        <br />- 서비스 홍보 및 마케팅 업무
        <br />
        <br />
        <h6>3. 개인정보의 처리 및 보유기간</h6>개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 고객의 개인정보를 지체 없이 파기합니다.
        <br />
        - 회원정보 : 회원탈퇴 및 제명시까지 보유
        <br />
        - 계약 또는 청약철회 등에 관한 기록, 대금결제 및 재화 등의 공급에 관한 기록 : 5년간 보유
        <br />
        - 소비자 불만, 분쟁처리 : 3년간 보유
        <br />
        - 서비스 홍보 및 마케팅 업무를 위한 정보 : 3년
        <br />
        <br />
        단, 다음은 예외로 합니다.
        <br />
        가. 요금 정산 상의 문제로 결제가 완료되지 않은 경우
        <br />
        나. 다른 일반 회원을 보호할 필요가 있어서 당사가 보존할 필요가 있다고 판단되는 경우
        <br />
        다. 상법 등 다른 법령의 규정에 의하여 보존할 필요성이 있는 경우
        <br />
        라. 소송이나 분쟁에 있어 근거자료로 보존할 필요성이 있는 경우
        <br />
        마. 기타 개별적으로 이용자의 동의를 받은 경우
        <br />
        바. 기타 당사의 내부 규정에 의해 일정기간 동안 보존할 필요성이 있는 경우
        <br />
        그러나 이 경우에도 정당한 근거와 함께 사전에 개인정보가 보유되는 기간을 정확하게 고지하고 이후 완전 파기합니다.
        <br /> <br />
        <h6>4. 개인정보의 파기에 관한 사항</h6>본 사는 『개인정보 처리 목적』이 달성되거나 『처리 및 보유기간』이 경과한 후에는 해당 개인정보 를 즉시 파기합니다. <br />
        - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
        <br />
        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기
        <br />
        <br />
        <h6>5. 개인정보의 제3자 제공에 관한 사항</h6>
        본 사는 귀하의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는 귀하의 정보를 제3자 에게 제공하지 않습니다.
        <br />
        제3자 제공 항목 제공 목적 <br />
        제공정보: 성명, 전화번호, 이메일, 상품명 제공목적: 전자결제
        <br />
        현대 해상 제공정보: 성명, 주민등록번호, 여권번호(외국인), 성별, 전화번호, 이메일 상품명 제공
        <br />
        목적: 보험 가입
        <br />
        - 개인정보 보유기간: 목적에 따른 개인정보 제공 시부터 제공목적 달성시까지 또는, 3조에 명시된 기한까지
        <br />
        <br />
        <h6>6. 정보주체의 권리‧의무 및 그 행사방법에 관한 사항</h6>귀하는 안심 케어 서비스를 위해 개인정보의 수집ㆍ이용 및 제공에 대해 동의하신 내용을 언제 든지 철회할 수 있습니다. 귀하는 본인 개인정보의 열람, 정정, 처리정지, 삭제 요청을 할 수 있으 며, 개인정보 보호책임자에게 서면, 전화 또는 Fax 등으로 연락하시면 지체 없이 파기하는 등 필 요한 조치를 하겠습니다. 단, 법에서 정한 의무사항이 있으면 권리 행사가 제한될 수 있습니다.
        <br />
        <br />
        <h6>7. 개인정보 보호책임자</h6>귀하의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 두고 있습니다.
        <br />
        이름 : 한진희
        <br />
        직책 : 대리
        <br />
        부서 : 이지트래블케어 전담
        <br />
        연락처 : 070-4818-1991
        <br />
        <br />
        <h6>8. 개인정보의 안전성 확보조치</h6>이용자께서 보내시는 모든 정보는 방화벽 등 보안시스템에 안전하게 관리되고 있습니다. 또한 본 사는 이용자의 개인정보보호를 위한 관리적 대책으로서 개인정보에 대한 접근 권한을 차등부 여하고 있고, 이용자의 개인정보를 취급하는 인원을 최소한으로 제한하여 지속적인 보안교육을 실시하고 있습니다. 또한 개인정보를 처리하는 시스템의 사용자를 지정하여 로그 기록을 보관하 고 있습니다.
        <br />
        <br />
        <h6>9. 개인정보 처리방침 변경</h6>개인정보 처리방침 변경에 관한 사항이 개인정보 처리방침은 2021년 1월 1일에 제정되었으며 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 변경되는 개인정보 처리방침을 시행하기 최소 7일전에 본 사 홈페이지를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다. <br />
        <br />- 공고일자 : 2021년 1월 1일 / 시행일자 : 2021년 1월 1일
        <br />
        <br />
      </>
      <h6>{language['이메일무단수집거부']}</h6>
      <div className="row">
        <div className="col-12">
          <p className="text-center">
            <img src="/resources/home/img/svg/noemail.svg" alt="" />
          </p>
          <ul className="list">
            <li>본 사이트에 게시된 이메일 주소가 전자우편 수집프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망법에 의해 형사처분됨을 유념하시기 바랍니다.</li>
            <li>정보통신망이용 및 정보보호 등에 관한법률(일부개정 2002.12.18 법률 제0697호)</li>
          </ul>
          <div className="cardline mt-5">
            <div className="card-body">
              <h6 className="text-center">제 50조의 2(전자우편주소의 무단수집행위 등 금지)</h6>
              <ul className="list secondary">
                <li>누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자 우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.</li>
                <li>누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니 된다.</li>
                <li>누구든지 제1항 및 제2항의 규정에 의하여 수집 판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니 된다.</li>
              </ul>
            </div>
            <div className="card-body">
              <h6 className="text-center">제65조의 2(벌칙) 다음 각호의 1에 해당하는 자는 1천만원 이하의 벌금에 처한다.</h6>
              <ul className="list secondary">
                <li>제50조 제4항의 규정을 위반하여 기술적 조치를 한자</li>
                <li>제50조 제6항의 규정을 위반하여 영리목적의 광고성 정보를 전송한 자</li>
                <li>제50조 제2항의 규정을 위반하여 전자우편주소를 수집 · 판매 · 유통 또는 정보전송에 이용한자</li>
              </ul>
            </div>
          </div>
          게시일: 2021-01-04
        </div>
      </div>
    </>
  )
}

export default B2BTermKRView
