import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosConnect from '../../../../network/http-request'
import { Link } from 'react-router-dom'
import QnAListItemView from './QnAListItemView'
import PagenationView from '../../../common/PaginationView'
import { CategoryType } from '../../../../utils/types'

function QnAListView() {
  const [qnaList, setQnaList] = useState<any>({ content: [] })
  const categoryList = useSelector((state: any) => state.boardCategory.value)

  const language = useSelector((state: any) => state.language.value)
  const [pageNo, setPageNo] = useState(1)
  const [category, setCategory] = useState('')
  const [tempSearchKeyword, setTempSearchKeyword] = useState('')
  const [keyword, setKeyword] = useState('')
  const searchBoardList = (value: string) => {
    setPageNo(1)
    setKeyword(value)
    getBoarderList()
  }

  const fetchBoardList = (params: string) => {
    axiosConnect(`/api/faqboard/list/${params}`, 'GET')
      .then((result) => {
        let resultData = result.data
        if (resultData.totalPages === 0) {
          resultData.totalPages = 1
        } else {
          if (resultData.numberOfElements < resultData.pageable.pageSize) {
            resultData.totalPages = resultData.totalPages - 1
          }
        }
        setQnaList(resultData)
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    if (categoryList.length > 0) {
      setCategory(categoryList[0].categoryNo.toString())
    }
  }, [])

  useEffect(() => {
    getBoarderList()
  }, [pageNo, keyword, category])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.nativeEvent.isComposing === false && tempSearchKeyword !== '') {
      searchBoardList(tempSearchKeyword)
    }
  }

  const getBoarderList = () => {
    if (keyword === '') {
      fetchBoardList(`${pageNo}/${category}`)
    } else {
      fetchBoardList(`${pageNo}/${category}/${keyword}`)
    }
  }

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-12">
          <ul className="list secondary">
            <li>{language['궁금한 내용을 작성하시면 댓글로 답변해드립니다.']}</li>
            <li>{language['광고, 상업적 성격의 글은 관리자에 의해 임의로 수정 또는 삭제될 수 있습니다.']}</li>
          </ul>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6 pt-2">
          <div className="input-group mt-2">
            <select className="form-control col-md-5" value={category} onChange={(e) => setCategory(e.target.value)}>
              {categoryList.length > 1
                ? categoryList.map((category: CategoryType, index: number) => {
                    return (
                      <option key={`category_${index}`} value={category.categoryNo}>
                        {category.categoryName}
                      </option>
                    )
                  })
                : []}
            </select>
          </div>
        </div>

        <div className="col-md-6 form-inline justify-content-end">
          <div className="input-group mt-2">
            <input
              type="text"
              className="form-control col-10 mr-1"
              value={tempSearchKeyword}
              onChange={(e) => {
                setTempSearchKeyword(e.target.value)
              }}
              placeholder="검색어를 입력해주세요."
              onKeyDown={handleKeyDown}
            />
            <a
              className="btn btn-outline-dark"
              onClick={(e) => {
                searchBoardList(tempSearchKeyword)
              }}
            >
              <i className="bi bi-search"></i>
            </a>
          </div>
          <Link to="/help/qna/create" className="btn btn-primary ml-2 mt-2">
            <i className="bi bi-pencil mr-1"></i>
            {language['작성하기']}
          </Link>
        </div>
      </div>

      <div className="row tblist">
        <div className="col-12">
          <hr className="dark mt-0" />
          <div className="row align-items-center">
            <div className="col-md-2 text-center d-none d-md-block">
              <strong>{language['카테고리']}</strong>
            </div>
            <div className="col-md-6 text-center d-none d-md-block">
              <strong>{language['제목']}</strong>
            </div>
            <div className="col-md-2 text-center d-none d-md-block">
              <strong>{language['작성일']}</strong>
            </div>
            <div className="col-md-2 text-center d-none d-md-block">
              <strong>{language['상태']}</strong>
            </div>
          </div>
          <hr className="d-none d-md-block" />
          {qnaList.content && <QnAListItemView content={qnaList.content} />}
          <PagenationView
            currentPage={pageNo}
            totalPages={qnaList.totalPages}
            updatePage={(index: number) => {
              setPageNo(index)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default QnAListView
