import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axiosConnect from '../../../../network/http-request'
import { useSelector } from 'react-redux'
import { DateUtil } from '../../../../utils/date-format'
import QnADeleteModal from '../modal/QnADeleteModal'

function QnADetailView() {
  const { id } = useParams()
  const [qna, setQna] = useState<any>({})
  const [reply, setReply] = useState<any>([])

  const [isCreateUser, setCreateUser] = useState(false)
  const loginUser = useSelector((state: any) => state.user.value)

  const language = useSelector((state: any) => state.language.value)
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  const deleteQnA = () => {
    setModalOpen(false)
    axiosConnect(`/rest/faqboard/delete`, 'POST', {
      faqNo: id,
    })
      .then((result) => {
        alert(language['삭제 되었습니다.'])
        navigate('/help/qna')
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    axiosConnect(`/api/faqboard/readView/${id}`, 'GET')
      .then((result) => {
        setQna(result.data)
        setCreateUser(result.data.faqMemberSid === loginUser.memberSid)
      })
      .catch((error) => {
        alert(error)
      })
    axiosConnect(`/rest/reply/getReplyList`, 'GET', {}, { faqNo: id })
      .then((result) => {
        setReply(result.data.replyList)
      })
      .catch((error) => {
        alert(error)
      })
  }, [])

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-12">
          <hr className="dark mt-0" />
          <div className="row align-items-center">
            <div className="col-md-12">
              <h4 className="mt-1 mb-1 text-center">{qna.faqTitle}</h4>
            </div>
          </div>
          <hr />
          <div className="row align-items-center">
            <div className="col-md-12">
              {qna.faqWriter} /{DateUtil.convertDateYYYMMDDHHMM(new Date(qna.faqWriteDate))}
            </div>
          </div>
          <hr />
          <div className="row align-items-center">
            <div className="col-md-12">
              <p className="memo">{qna.faqContent}</p>
            </div>
          </div>
          <hr className="dark" />
          {reply &&
            reply.map((replay: any) => (
              <>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    {replay.replyWriter} /{` ${replay.replyWriteDate} `}
                    <span className="badge badge-pill badge-info badge-md">{language['답변완료']}</span>
                  </div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <p
                      className="memo"
                      dangerouslySetInnerHTML={{
                        __html: replay.replyContent,
                      }}
                    />
                  </div>
                </div>
                <hr className="dark" />
              </>
            ))}

          <div className="text-center mt-4">
            <a
              onClick={() => {
                navigate(-1)
              }}
              className="btn btn-outline-dark btn-lg"
            >
              {language['목록']}
            </a>
            {isCreateUser ? (
              <>
                <Link to={`/help/qna/edit/${qna.faqNo}`} className="btn btn-primary btn-lg">
                  {language['수정']}
                </Link>
                <a className="btn btn-danger btn-lg" onClick={openModal}>
                  {language['삭제']}
                </a>
              </>
            ) : (
              <></>
            )}

            <QnADeleteModal open={modalOpen} close={closeModal} deleteQnA={deleteQnA} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QnADetailView
