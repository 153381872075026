function EasyTravelCareTermKRView() {
  return (
    <>
      이제 대한민국 여행, 안심하고 즐겁고 편안하게 즐기세요!
      <br />
      대한민국 여행 도중 도움이 필요할 때는 언제나 Easy Travel Care 센터로 연락을 주십시오. Easy Travel Care 센터는 1년 365일, 연중무휴 운영됩니다.
      <br />
      <br />
      Easy Travel Care 센터는 한국의 관광지를 여행하는 관광객을 위해 모바일 채팅과 전화 통화, 메일을 이용하여 안심 관광 보험 & 케어 서비스를 제공합니다.
      <br />
      <br />
      언제나 고객님들에게 안전하고 편안한 대한민국 관광을 즐길 수 있도록 도와드리고, 대한민국을 찾는 외국인 관광객에게 맞춤형 컨시어지 서비스 제공은 물론 편안하고, 안전하고, 품위 있는 가치관광 특화 컨시어지 서비스 제공으로 관광의 품격을 높이겠습니다.
      <br />
      <br />
      <h6>Easy Travel Care 소개</h6>
      Easy Travel Care는 대한민국을 찾는 외국인 관광객들에게 편안하고, 즐겁고, 안심하게 관광할 수 있도록 외국인 전용 여행자보험서비스에 맞춤형 컨시어지 서비스를 제공해드리는 안심 관광 플랫폼 서비스입니다.
      <br />
      주요서비스로는 외국인 전용 여행자보험 서비스와 (주)프리미엄패스인터내셔널에서 제공해드리는 의료 안심케어, 트래블 안심케어, 공항 안심케어, 비즈니스 안심케어, 여행자 안심보험 그리고 프리미엄 멤버십 특별 혜택을 추가 제공해 드립니다.
      <br />
      <br />
      서비스 제공사인 (주)프리미엄패스인터내셔널은 2007년도에 의전 컨시어지 서비스 전문기업으로 설립하여 현재 글로벌 호스피탈리티 전문기업으로 급성장 하고 있는 기업입니다. <br />
      (주)프리미엄패스인터내셔널은 MICE 사업, 의전/수송 사업, 공항 관련 서비스사업, 여행사업, 컨시어지 사업 등 관광 토탈 서비스 전문기업으로 현재 관광 및 교통의 주요거점에 트래블센터 &라운지를 운영하고 있습니다. <br />
      <br />
      <h6>Easy Travel Care 개요</h6>
      1. Easy Travel Care 서비스 가입
      <br />
      Easy Travel Care 서비스를 가입하기 위해서는 소속 국가명, 성명, 생년월일, 성별, 휴대폰 번호, 이메일주소 등이 등록되어야 하며, 서비스 제공 과정에서 해당 정보를 통하여 본인확인이 진행됩니다. 정보가 불일치할 때에는 서비스 제공이 제한될 수 있습니다. <br />
      Easy Travel Care 가입 시 가입증명서, 서비스 매뉴얼이 플랫폼을 통해서 제공됩니다. 별도로 가입증명서 등을 이메일 요청 시에는 지면으로도 제공하여 줄 수 있습니다.
      <br />
      가입자는 가입 시 기록된 본인 정보가 정확한지 반드시 확인하셔야 합니다. 특히 응급상황 시 연락 가능한 전화번호, 이메일 주소 등은 바르게 기재되어 있는지 확인하시고, 오류가 있을 때에는 즉시 Easy Travel Care 센터 또는 채팅 서비스를 통하여 수정하시기 바랍니다. <br />
      여행 중 긴급 상황이나 응급상황 발생 시는 여권번호를 알려 주셔야 서비스를 받을 수 있고, 응급처리 후 여권으로 본인확인을 하게 됩니다.
      <br />
      2. Easy Travel Care 센터 연락 방법
      <br />
      도움이 필요한 경우 또는 비상 상황 발생 시 Easy Travel Care 플랫폼 내 채팅 서비스인 SOS신청 또는 Easy Travel Care 센터(1644-1493, 82-2-2063-3544)로 연락합니다.
      <br />
      전화, 이메일, 플랫폼 채팅 서비스를 통한 SOS신청 또는 카카오톡을 통해 24시간 케어 센터로 연락하실 수 있으며, 전화 통화가 필요한 경우는 연락 가능한 전화번호를 알려주시면 담당 코디네이터가 해당 전화번호로 전화를 드립니다. 한국의 USIM칩을 사용하지 않는 전화번호는 연락이 제한될 수 있습니다. <br />
      <br />
      ※이메일 또는 카카오톡
      <br />
      Easy Travel Care 센터 대표 이메일 (care@tothepp.com) 또는 카카오톡 플러스친구
      <br />
      (@ Easy Travel Care CENTER)로 서비스 요청이 가능합니다.
      <br />
      <br />
      <h6>Easy Travel Care 서비스 보통약관</h6>
      <h6>제1조 목적</h6>
      이 약관은 (주)프리미엄패스인터내셔널 (이하 “프리미엄패스”)가 제공하는 안심 관광 플랫폼 서비스인 Easy Travel Care 서비스와 관련하여 회원 및 가입자 간의 권리 의무를 명확히 함을 그 목적으로 합니다.
      <br />
      <br />
      <h6>제2조 용어의 정의</h6>
      1. 회원
      <br />
      Easy Travel Care 서비스의 안심 관광 플랫폼 서비스 가입신청을 목적으로 전화, 이메일, 웹 페이지, 모바일 어플리케이션 등을 통하거나 혹은 Easy Travel Care 광고 대행 제휴업체를 통하여, 완전한 서비스 이용을 위해 Easy Travel Care가 요구하는 개인 식별정보 및 여행 일정 정보를 Easy Travel Care 센터에 제공한 개인 및 단체.
      <br />
      2. 가입자
      <br />
      Easy Travel Care의 약관에 명시된 권리와 의무에 동의하여 승낙의 의미로 Easy Travel Care가 제시한 가입비를 납부함으로써 가입증명번호를 부여받은 회원.
      <br />
      3. Easy Travel Care 센터
      <br />
      회원이 여행 중 발생한 어려움에 대해 편의, 보험 등의 서비스를 제공해드리고자 비대면으로 1차 서비스 응대를 진행하는 토탈 서비스 센터
      <br />
      4. 가입증명서
      <br />
      Easy Travel Care 센터가 가입자에게 제공되는 증명서로 가입증명번호와 함께 개인 식별 정보 및 주요 가입조건 등이 명시되어 있는 서류.
      <br />
      5. 케어 매니저
      <br />
      약관에 따라 가입자가 요청한 의료 및 현장 서비스와 보험서비스 응대에 대해 직·간접적으로 관련된 모든 사항을 간섭하고 결정하는 권한을 부여받아 서비스 제공을 평가, 조정, 감독하는 전문 서비스 매니저.
      <br />
      6. 멤버십 특별혜택
      <br />
      정상적인 Easy Travel Care 서비스 자격을 취득한 회원이 누릴 수 있는 특별 혜택으로 Easy Travel Care 서비스 운영자인 프리미엄패스가 정합니다. <br />
      <br />
      <h6>제3조 서비스의 내용</h6>
      Easy Travel Care가 제공하는 안심 관광 플랫폼 서비스는 다음과 같습니다.
      <br />
      1. 의료 안심케어
      <br />
      대한민국 여행 중 발생할 수 있는 교통사고, 안전사고 등 맞춤형 케어 서비스로 의료통역사 수배, 의료비 해결방안 모색, 여행자보험 & 의료지원 등 케어 서비스 제공
      <br />
      2. 트래블 안심케어
      <br />
      대한민국 여행 중 긴급사항 발생 시 제공되는 다양한 트래블 컨시어지 서비스
      <br />
      - 지갑, 여권 등 분실 시 지원 케어 서비스
      <br />
      - 여행 도중 긴급사항 발생 시 케어 서비스
      <br />
      - 교통편 예약 발권 및 차량 수배 서비스
      <br />
      - 긴급 숙박 예약 지원 서비스 등<br />
      3. 공항 안심케어
      <br />
      국제공항이라는 특별한 공간에서의 여행객을 위한 안심 케어 서비스
      <br />
      - 노약자 등 공항에서 지원 및 불편사항 케어
      <br />
      - 국제공항에서의 컨시어지 서비스 제공
      <br />
      - 비자 및 긴급 항공권 케어 서비스
      <br />
      4. 비즈니스 안심케어
      <br />
      편안하고 안전한 여행과 성공적인 비즈니스를 위한 특화 컨시어지 서비스
      <br />
      - 긴급 통·번역, 인력 수배 서비스
      <br />
      - 회의 시설 및 미팅 장소 예약 지원 서비스
      <br />
      - KTX, 고속버스, 항공권 등 예약 지원 서비스
      <br />
      5. 여행자 안심보험
      <br />
      여행 안심보험 서비스는 “프리미엄패스”의 제휴 보험사의 서비스로 제휴보험사의 규정 및 보험 약관에 따라 안심보험 서비스가 제공됩니다. <br />
      - 국내여행중 상해사망 1억원(15세 미만 제외)
      <br />
      - 국내여행중 상해후유장해: 1억원
      <br />
      - 국내여행중 질병사망 및 80%고도후유장해: 2천만원(15세 미만/ 80세 이상 제외)
      <br />
      - 국내여행중 일괄배상 (국내여행): 5백만원(타인 신체의 상해 및 재물의 손해에 대한 배상)
      <br />
      - 국내여행중 휴대품손해(분실제외): 20만원(도난, 파손 등 사고로 손해 발생 시)
      <br />
      - 국내여행 상해급여_의료실비보장: 1천만원(통원 1회당 10만원 한도)
      <br />
      - 국내여행 상해비급여_의료실비보장: 1천만원(통원 1회당 10만원, 통원 횟수 연간 100회 한도)
      <br />
      6. 멤버십 특별혜택
      <br />
      Easy Travel Care 서비스 회원에게 제공해드리는 멤버십 특화 서비스로 공항 및 여행, 비즈니스 등 다양한 컨시어지 서비스 및 할인서비스를 제공합니다. 멤버십 서비스는 Easy Travel Care 서비스의 제공사인 “프리미엄패스”에 의해 수시로 변경될 수 있으므로 웹페이지 및 모바일 어플리케이션 등에서 변경사항을 확인할 수 있습니다. <br />
      7. 기타 서비스 제공에 따른 유의사항 <br />
      - 가입자가 대한민국 여행 도중 교통사고 및 안전사고를 당하였을 경우 긴급 대처 방법 안내, 유관기관 연결 및 통역 서비스, 현장 출동을 제공합니다.
      <br />
      - 본 지원은 대한민국 내에서 발생한 사고에 대하여 처리가 가능하며, 모국 또는 환승국 등 국외 지역에서의 사고에는 서비스 제공이 제한됩니다.
      <br />
      - 본 서비스는 가입자에게 사고가 발생되는 즉시 Easy Travel Care 센터로 서비스 요청하는 것을 권고하며, Easy Travel Care가 관련 업무를 위임 받기 이전 또는 Easy Travel Care의 매니저가 사고 현장을 접수하기 전에 타 사 관계자 또는 3자가 먼저 업무를 수행한 경우 Easy Travel Care는 사고 처리절차가 원활히 진행되는데 도움을 드리기 어려울 수 있습니다.
      <br />
      <br />
      <h6>제4조 서비스 개시 및 종료</h6>
      Easy Travel Care 가입자에 대한 서비스 개시는 가입기간의 첫날 시작하여 마지막날 혹은 대한민국 출국 즉시 종료되며, 일수별 기준시간은 24시를 기준으로 산정합니다.
      <br />
      <br />
      <h6>제5조 약관의 효력 등</h6>
      1. 이 약관은 Easy Travel Care가 웹페이지, 모바일 어플리케이션 등에 게시하거나 가입자에게 고지함으로써 그 효력이 생깁니다.
      <br />
      2. Easy Travel Care가 이 약관을 개정할 경우에는 적용일 및 개정사유를 명시하여 그 적용일의 1주 전부터 적용일의 전일까지 1항과 같은 방법으로 게시 혹은 고지합니다. 이 때, 그 개정약관은 서비스 시작일자가 그 적용일자 이후에 해당되는 계약에만 적용되고 그 이전에 이미 시작된 계약에 대해서는 개정 전의 약관조항이 적용됩니다.
      <br />
      3. 가입자는 개정된 약관에 동의하지 않을 경우 가입취소를 요청할 수 있습니다.
      <br />
      4. 가입자는 가입취소를 요청하지 않고 서비스를 이용함으로써 개정된 약관에 동의한 것으로 간주됩니다.
      <br />
      <br />
      <h6>제6조 약관 적용의 우선순위</h6>
      1. Easy Travel Care 서비스와 보험사의 보험 상품과 연계된 서비스인 경우 보험과 관련한 모든 사항은 해당 보험 상품의 보통약관 및 특별약관이 적용됩니다.
      <br />
      2. Easy Travel Care의 보통약관과 특별약관의 내용이 충돌하는 경우 특별약관의 효력이 이 약관에 우선합니다.
      <br />
      <br />
      <h6>제7조 회원 및 가입자 자격 득실</h6>
      1. 모든 개인 및 단체는 가입신청을 희망하는 명확한 뜻으로 Easy Travel Care가 요구하는 개인 식별정보(국적, 성명, 생년월일, 성별, 전화번호, 이메일주소 등) 및 여행 일정 정보를 Easy Travel Care가 제공하는 매체를 통해 입력 혹은 제공함으로써 회원자격을 얻게 됩니다. 단 Easy Travel Care 서비스를 제공받기 위해서는 반드시 여권을 제시하여야 합니다. <br />
      2. 회원이 Easy Travel Care가 요구하는 개인 식별정보 및 여행 일정 정보를 불완전하게 혹은 허위로 입력(제공)하거나 12시간 이상 가입신청 단계를 진행하지 않는 경우 Easy Travel Care는 회원이 입력한 개인 식별정보 및 여행일정 정보를 모두 삭제하고 회원자격을 박탈할 권리가 있습니다.
      <br />
      3. Easy Travel Care는 가입신청 절차 진행을 위해 회원의 개인 식별정보 및 여행 일정 정보를 활용할 수 있습니다.
      <br />
      4. 1항에 따라 회원자격을 얻은 회원 중 가입절차를 거쳐 가입증명번호를 발급받음으로써 가입자 자격을 얻게 됩니다.
      <br />
      <br />
      <h6>제8조 EASY TRAVEL CARE의 의무와 권리</h6>
      1. Easy Travel Care는 안정적인 서비스 제공을 위하여 지속적으로 노력하며, 회원 혹은 가입자로부터 민원이 접수되는 경우 성실히 응대합니다. 다만 필요성과 적절성 여부에 따라 제휴사가 대신 응할 수 있습니다.
      <br />
      2. Easy Travel Care는 다음 각 호의 1에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
      <br />
      가. 시스템 및 설비 보수 등.
      <br />
      나. 서비스의 근간이 되는 기간통신사업자의 망 서비스 제공 중지.
      <br />
      다. 국가비상사태, 정전, 통신망 접속 폭주 등 기타 불가항력적인 경우.
      <br />
      3. EASY TRAVEL CARE가 서비스 제공을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 제5조 1항의 방법으로 지체 없이 회원 및 가입자에게 알립니다.
      <br />
      4. 신규가입 유효기간 개시 이전의 기왕증 즉, 기존에 가지고 있던 질병 혹은 기존에 발생한 사고나 질병에 기인한 증상 및 그 합병증이 있는 경우 가입이 제한되거나 Easy Travel Care의 보험금 지급 의무가 면책될 수 있습니다.
      <br />
      5. Easy Travel Care는 서비스 제공 및 분쟁 예방을 위해 통신상 대화 내용을 저장/보관하고 감시할 권리가 있습니다.
      <br />
      6. 가입자는 이 절차 및 제공된 서비스와 관련하여 분쟁 발생 시 증명자료로 저장/보관된 내용을 사용하는 것에 명시적으로 동의를 한 것입니다.
      <br />
      7. Easy Travel Care가 원활하고 정확한 서비스 제공을 위해 요청하는 경우, 가입자는 의료 기록(여행 전에 발생한 것도 포함, 어떤 종류이든 Easy Travel Care가 서비스를 제공하기 위해 필요 하다고 판단하는 모든 기록)을 작성하여 Easy Travel Care 센터에 제출하여야 하며, 만약 제출을 거부하여 원활하고 정확한 서비스가 이행되지 못한 경우 Easy Travel Care는 이에 대해 책임이 면제됩니다.
      <br />
      8. Easy Travel Care는 가입자에게 발생한 사고나 질병 등 사건으로 인하여 가입자 본인에게 제공되는 서비스와 관련한 개인, 법적 개체, 공공기관을 포함하는 제3자에 대한 가입자의 모든 권리와 행동을 즉시 그리고 자동적으로 대리하여 행사할 권리가 있으며, 가입자는 이러한 권리와 활동을 이양하며 이를 취소할 수는 없고, 또한 이것에 필요한 모든 법적 절차를 따라야 하며 가입자에게 요구될 수 있는 모든 것에 협조해야 합니다.
      <br />
      9. Easy Travel Care 서비스 중 여행자 안심보험에 대하여는 “프리미엄패스”가 제휴 되어있는 보험사의 규정과 약관에 따라 지급됩니다.
      <br />
      10. Easy Travel Care 서비스는 안심관광 컨시어지 서비스 기반이므로 여행자 보험금에 해당하는 보장은 “프리미엄패스”의 제휴 보험사를 통하여 보장받을 수 있고, 컨시어지 서비스제공에 따른 실제로 발생하는 비용에 대하여는 회원이 부담합니다.
      <br />
      <br />
      <h6>제9조 회원 및 가입자의 의무와 권리</h6>
      1. 회원 및 가입자는 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항 등 Easy Travel Care가 고지하는 사항을 준수하여야 합니다.
      <br />
      2. 회원 및 가입자는 개인 식별정보의 부정사용이 발생하지 않도록 유의하여야 하며, 부정 사용을 알게 된 경우 즉시 Easy Travel Care에 알려 피해방지에 협력해야 합니다.
      <br />
      3. 회원 및 가입자는 서비스에 관한 권리 의무의 일부 또는 전부를 제3자에게 양도하거나 담보로 제공할 수 없습니다.
      <br />
      4. 회원 및 가입자는 법에서 정한 경우를 제외하고 본인의 동의 없이 본인의 개인 정보가 제 3 자에게 제공, 이용되는 것을 거부할 권리가 있습니다.
      <br />
      <br />
      <h6>제10조 가입취소 및 중도해지</h6>
      1. Easy Travel Care 가입 증명서에 기재된 개시일 이전에는 언제든지 가입 해지(취소)가 가능 하며 기 납부된 가입비 전액이 환불됩니다. <br />
      2. 서비스 기간 변경이나 취소는 개시일 이전에만 가능합니다.
      <br />
      3. 서비스 개시 중이라도 개인 식별정보의 부정 및 악의, 고의의 사고 발생 우려가 있다고 “프리미엄패스”가 판단하면 서비스를 중도 해지할 수 있습니다. <br />
      4. 가입 증명서에 기재된 개시일 이후 해지 시에는(중도해지) 사용 기간에 해당하는 가입비를 차감한 전액을 환불하며, 차감액 산정은 보험 단기요율과 서비스료 요율에 따릅니다. 가입비 반환청구권은 가입 자격 손실일로부터 3년간 행사하지 않으면 소멸시효가 완성됩니다. 다만, 보험료 반환청구권에 대한 규정은 보험업법을 준용합니다.
      <br />
      5. 취소 및 중도해지 사유에 따른 제출서류
      <br />
      가. 개시일 이전 취소: 취소신청서 또는 전자메일을 통한 취소의사표시/ 통장사본
      <br />
      나. 단순변심에 의한 중도해지: 해지신청서/ 여권사본/ 통장사본 (해지신청서 접수일 기준 환급)
      <br />
      다. 조기귀국에 의한 중도해지: 여권사본/ 통장사본/ 귀국 항공권 E-TICKET사본 또는 출입국사실증명서(E-TICKET의 경우 가입보험기간 내 재입국 일정이 포함된 서류 인정 불가, 출입국사실증명서의 경우 가입보험기간 내 최종귀국일자 기준으로 환급)
      <br />
      <br />
      <h6>제11조 여행의 연장으로 인한 Easy Travel Care 재가입</h6>
      1. 가입 후 국내 체류 중에 가입 기간이 만료되어 재가입 또는 연장해야 하는 경우 가입자는 가입 기간 만료 이전에 SOS 신청 및 전화나 메일 등을 통해 센터로 재가입 또는 연장 의사를 표시해야 합니다.
      <br />
      2. Easy Travel Care의 안내 여부를 불문하고 이전 가입 기간 만료일이 경과한 경우 혹은 이전 가입 기간 만료일 전에 재가입 절차가 완료되지 아니한 경우 재가입 의사가 없는 것으로 간주되어 이후 재가입이 제한됩니다.
      <br />
      3. 재가입 유효기간 개시 이전의 기왕증 즉, 기존에 가지고 있던 질병 혹은 기존에 발생한 사고나 질병에 기인한 증상 및 그 합병증이 있는 경우 재가입이 제한되거나 Easy Travel Care의 제휴 보험사로부터 보험금 지급 의무가 면책될 수 있습니다.
      <br />
      <br />
      <h6>제12조 단체보험가입 청약 등</h6>
      1. Easy Travel Care는 회원 및 가입자를 위하여 계약자로서 보험사와 단체보험계약을 체결하여 회원에게 제공할 수 있습니다.
      <br />
      2. Easy Travel Care가 계약자인 단체보험계약에 피보험자로 가입하기를 희망하는 회원은 소정의 절차에 따라 보험이 포함된 플랜에 가입신청(보험청약)을 할 수 있고, 가입절차가 완료된 회원은 가입자가 됩니다.
      <br />
      3. 보험이 포함된 플랜에 가입신청을 하고자 하는 회원은 Easy Travel Care 제휴 보험사의 가입 안내사항 및 보험 약관 등을 충분히 읽고 이해한 후 가입신청을 하여야 하며, 보험사와 Easy Travel Care가 고지를 요구하는 사항에 대하여 성실히 응해야 합니다.
      <br />
      4. 보험이 포함된 플랜을 가입하는 경우 회원이 가입하는 보험의 수익자는 보험가입자(피보험자) 본인과 그 법정상속인이 됩니다.
      <br />
      5. Easy Travel Care가 계약한 단체보험에 관하여 기타 이 규약에서 정하지 않은 사항은 Easy Travel Care가 보험사와 약정한 바에 따릅니다.
      <br />
      <br />
      <h6>제13조 여행보험 편의 지원</h6>
      Easy Travel Care 플랜 내 여행보험은 계약자가 “프리미엄패스”의 단체보험으로 보험료에 대한 단체할인이 적용되어 있으며, 각 플랜은 최적의 보험 보장 내역 및 가입금액이 될 수 있도록 하고 있습니다. 또한 보험사에 보험금 청구대행, 비례보상 보험금 접수대행 및 최종 안내를 해드립니다.
      <br />
      <br />
      <h6>제14조 Easy Travel Care 보험서비스 지원의 제한</h6>
      Easy Travel Care는 그 원인의 직접 또는 간접을 묻지 아니하고 아래의 사유를 원인으로 하여 생긴 서비스 발생에 대해서는 도움을 드리지 않는 것이 원칙이며, 가입자 요청으로 서비스 진행 시 별도의 비용이 발생할 수 있습니다.
      <br />
      ① 가입 증명서에 기재되지 않은 서비스 내용
      <br />
      ② 가입자 또는 보호자(소속 기관 포함)의 고의 또는 중대한 과실.
      <br />
      ③ 가입자의 질병 또는 상해로 인한 진단비, 치료비, 약제비 등 의료비 일체.
      <br />
      ④ 사형 또는 가입자의 자해, 자살, 자살 미수, 형법상의 범죄행위 또는 폭력행위. 다만, 형법상 정당행위, 긴급피난 및 정당방위로 인정되는 경우에는 지원합니다.
      <br />
      ⑤ 가입자의 심신상실 또는 정신질환.
      <br />
      ⑥ Easy Travel Care 혹은 제휴 기관의 승인 없이 가입자가 다른 의료기관으로부터 받은 치료의 결과로 생긴 상해, 질병 또는 합병증.
      <br />
      ⑦ Easy Travel Care 가입자가 아닌 그 동반여행자에게 발생한 일체의 비용.
      <br />
      ⑧ 가입자가 전염병 발생 국가에 대한 정부의 여행 금지 또는 예방접종 조치에 응하지 않고 해당 국가를 여행하고 돌아와서 생긴 전염병.
      <br />
      ⑨ 가입자가 자발적으로 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동, 소요, 노동쟁의 및 기타 이들과 유사한 사태가 발생한 지역에 들어가서 발생한 서비스 내용
      <br />
      ⑩ 핵연료 물질(사용이 끝난 연료를 포함합니다. 이하 같습니다) 또는 핵연료 물질에 의하여 오염된 물질(원자핵분열 생성물을 포함합니다)의 방사성, 폭발성 또는 그 밖의 유해한 특성에 의한 사고. (그 외 방사선을 쬐는 것 또는 방사능 오염을 포함합니다.)
      <br />
      ⑪ 우연하고 급격한 사고나 질병으로 기인하지 않은 임신, 출산, 유산 또는 외과적 수술, 그 밖의 의료처치이거나 여행의 목적 자체가 기존 상해 또는 질병 치료 등 의료행위인 것으로 확인된 경우.
      <br />
      ⑫ 지진, 분화, 해일 또는 이와 비슷한 천재지변.
      <br />
      ⑬ 기타 본 약관상의 면책 사유.
      <br />
      <br />
      <h6>제15조 분쟁의 해결</h6>
      1. Easy Travel Care와 가입자는 서비스와 관련하여 분쟁이 발생한 경우에 이를 원만하게 해결하기 위하여 노력하여야 합니다.
      <br />
      2. 전항의 규정에도 불구하고, 당사자 간 분쟁으로 인하여 소송이 제기될 경우 가입자와 Easy Travel Care 사이에 계약 관계에 관한 모든 법적인 사항에 관해서는 대한민국 법원을 합의에 따른 관할법원으로 합니다.
      <br />
      3. 계약에 관하여 분쟁이 있는 경우에는 대한민국 법률에 따르며, 보험과 관련된 분쟁이 발생한 경우에는 제휴 보험사의 보통약관 및 특별약관의 내용에 따릅니다.
      <br />
      <br />
      PREMIUMPASS International Co., Ltd. 서울시 강서구 마곡중앙4로 22(마곡동) 파인스퀘어 A동 501호
      <br />
      PREMIUMPASS International Co., Ltd. 케어센터 +82-2-2063-3544
      <br />
      PREMIUMPASS International Co., Ltd. 케어센터 이메일 care@tothepp.com <br />
      PREMIUMPASS International Co., Ltd. 케어센터 카카오톡ID (@Easy Travel Care CENTER) <br />
      PREMIUMPASS International Co., Ltd. 가입상담 1644-1493
      <br />
      PREMIUMPASS International Co., Ltd. 팩스 +82-2-2138-0164
      <br />
    </>
  )
}

export default EasyTravelCareTermKRView
