export const DateUtil = {
  convertDate: function (datetime) {
    if (datetime === null || datetime === undefined) {
      return '-'
    }
    let date = new Date(datetime)
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    return `${date.getFullYear()}-${month}-${day}`
  },
  convertDateTime: function (datetime) {
    if (datetime === null || datetime === undefined) {
      return '-'
    }
    let date = new Date(datetime)
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    return `${date.getFullYear()}-${month}-${day} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  },
  convertDateYYYMMDDHHMM: function (datetime) {
    if (datetime === null || datetime === undefined) {
      return '-'
    }
    let date = new Date(datetime)
    let month = ('0' + (date.getMonth() + 1)).slice(-2)
    let day = ('0' + date.getDate()).slice(-2)

    return `${date.getFullYear()}-${month}-${day} ${date.getHours()}:${date.getMinutes()}`
  },
  convertDateString: function (date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
  },
  convertDateStringYYYYMMDD: function (date) {
    return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
  },
  getDateDiff: function (first, second) {
    return Math.round((new Date(second).getTime() - new Date(first).getTime()) / (1000 * 60 * 60 * 24))
  },
  addDate: function (date, day) {
    let initDate = new Date(date)
    initDate.setDate(initDate.getDate() + day)
    return initDate
  },
  getDateAdd: function (date, val) {
    let newDate = new Date(date)
    return newDate.setDate(newDate.getDate() + val)
  },
  getFormatDate: function (dateTime) {
    if (dateTime == null) return ''
    const date = new Date(dateTime)
    var hours = date.getHours()
    var min = date.getMinutes()
    let strMin = min >= 10 ? min : '0' + min
    return hours + ':' + strMin
  },
  toLocalDateString: function (timestamp) {
    return timestamp[0] + '-' + ('0' + timestamp[1]).slice(-2) + '-' + ('0' + timestamp[2]).slice(-2)
  },
}
